import React, { useState, useEffect } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
import {
  IIconProps,
  ActionButton,
} from "office-ui-fabric-react";
import { getAppStatusAction } from "../../actions/super-admin-reports-actions";
import { selectAppStatus } from "../../reducers/superAdminReports";
import { connect, ConnectedProps } from "react-redux";
import { State, StatusInterval } from "../../store/types";
import { useViewport } from "../../hooks/responsive";
import dayjs from "dayjs";
import FormSelectField from "../common/FormSelectField";
import "./osAndAppStatus.scss";

const refreshIcon: IIconProps = { iconName: "Refresh" };

const statusIntervalOptions = [
  { key: StatusInterval.HOUR, text: "Hour" },
  { key: StatusInterval.HALF_DAY, text: "12 Hours" },
  { key: StatusInterval.DAY, text: "Day" },
  { key: StatusInterval.TREE_DAYS, text: "3 Days" },
  { key: StatusInterval.FIVE_DAYS, text: "5 Days" },
  { key: StatusInterval.WEEK, text: "Week" },
];

const mapDispatchToProps = {
  getAppStatus: getAppStatusAction
};
const mapStateToProps = (state: State) => ({
  appStatus: selectAppStatus(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const OsAndAppStatus = ({
  getAppStatus,
  appStatus,
}: Props) => {
  const { isMiddleScreen } = useViewport();
  useEffect(() => {
    getAppStatus(statusInterval);
  }, []);
  useEffect(() => {
    setReservations(appStatus?.reservationCreationRate || []);
    setClients(appStatus?.clientCreationRate || []);
  }, [appStatus]);

  const [statusInterval, setStatusInterval] = useState(StatusInterval.DAY);
  const [reservations, setReservations] = useState(appStatus?.reservationCreationRate || []);
  const [clients, setClients] = useState(appStatus?.clientCreationRate || []);

  let interval = Math.abs(Math.floor(dayjs().diff(appStatus?.startDate, 'milliseconds') / 100));
  let startDay = appStatus?.startDate && new Date(appStatus.startDate) || new Date();
  let xStart = Date.UTC(startDay.getUTCFullYear(), startDay.getMonth(), startDay.getDate(), startDay.getHours(), startDay.getMinutes()) - interval;

  const reservationPoints = reservations.reverse().map((point, index) => {
    return { x: xStart + (interval * index), y: point }
  });
  const clientPoints = clients.reverse().map((point, index) => {
    return { x: xStart + (interval * index), y: point }
  });

  const chartOptions = (title: string, yLabel: string, data: any, name: string) => ({
    title: {
      text: title
    },
    credits: {
      enabled: false
    },
    yAxis: {
      title: {
        text: yLabel
      }
    },
    xAxis: {
      title: {
        text: 'Date'
      },
      type: 'datetime',
    },
    series: [{
      data: data,
      type: 'line',
      name: name,
      showInLegend: false,
    }]
  });

  const changeStatusInterval = (key: StatusInterval) => {
    if (key) {
      setStatusInterval(key);
      getAppStatus(key);
    }
  }

  const onRefresh = () => {
    if (statusInterval) {
      getAppStatus(statusInterval);
    }
  };

  return (
    <div className="os-and-app-status">
      <div className="title h4">
        <div>App Monitoring</div>
        <ActionButton
          iconProps={refreshIcon}
          allowDisabledFocus
          onClick={onRefresh}
        />
      </div>
      <div className="interval-selector">
        <div className="subtitle2">Interval</div>
        <FormSelectField
          options={statusIntervalOptions}
          onChange={(_: any, option) => {
            changeStatusInterval(option?.key as StatusInterval)
          }}
          defaultSelectedKey={statusInterval}
        />
      </div>
      <div className="status-container">
        <div className={`status-list ${isMiddleScreen ? "mobile" : ""}`}>
          <div className={`status-block os-status-block ${isMiddleScreen ? "mobile" : ""}`}>
            <div className="label overline">CPU Usage</div>
            <div className="value h6">{appStatus?.cpuUsage.toFixed(2)}%</div>
          </div>
          <div className={`status-block os-status-block ${isMiddleScreen ? "mobile" : ""}`}>
            <div className="label overline">RAM</div>
            <div className="value h6">{appStatus?.RAMTotal.toFixed(2)} MB / {appStatus?.RAMFree.toFixed(2)} MB</div>
          </div>
        </div>
      </div>
      <div className="status-container">
        {reservations.length > 0 && (
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions('Reservation status', 'Number of Reservations', reservationPoints, 'Reservations')}
          />
        )}
      </div>
      <div className="status-container">
        {clients.length > 0 && (
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions('Client status', 'Number of Clients', clientPoints, 'Clients')}
          />
        )}
      </div>
      <div className="status-container">
        <div className="title overline">Last Reservation</div>
        <div className={`status-list ${isMiddleScreen ? "mobile" : ""}`}>
          <div className={`status-block ${isMiddleScreen ? "mobile" : ""}`}>
            <div className="label overline">Number</div>
            <div className="value h6">{appStatus?.lastReservation.number}</div>
          </div>
          <div className={`status-block ${isMiddleScreen ? "mobile" : ""}`}>
            <div className="label overline">Price</div>
            <div className="value h6">{appStatus?.lastReservation.price}</div>
          </div>
          <div className={`status-block ${isMiddleScreen ? "mobile" : ""}`}>
            <div className="label overline">Created Date</div>
            <div className="value h6">{appStatus?.lastReservation.createdAt}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connector(OsAndAppStatus);
