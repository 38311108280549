import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { get } from '../../actions/api';
import { goHostAction, logoutAction } from "../../actions/auth-actions";
import { getConfigsAction } from "../../actions/configs-actions";
import { getSettingsAction } from "../../actions/settings-actions";
import { setWebConfigUIAction } from "../../actions/ui-actions";
import RexIcon from "../../assets/rex-logo-white.png";
import { webConfigUI } from "../../constants/webConfigUI";
import { useViewport } from "../../hooks/responsive";
import { selectIsFromSuperAdmin, selectIsPartnerAdmin, selectIsSuperAdmin, selectIsVenueAdmin, selectReadonly, selectUser, selectWebConfigUI } from "../../reducers/auth-reducer";
import { selectIsConfigsRequestInProgress } from "../../reducers/configs";
import { selectIsLoadingPackage, selectIsLoadingPackages } from "../../reducers/packages";
import { selectIsReportRequestInProgress } from "../../reducers/reports";
import { selectIsRequestInProgress } from "../../reducers/reservation";
import { selectIsLoadingResourceRelations } from "../../reducers/resource-relations";
import { selectIsLoadingResourceCategories } from "../../reducers/resources";
import { selectIsSettingsRequestInProgress, selectSettingsState } from "../../reducers/settings";
import { selectIsTenantRequestInProgress, selectIsUserRequestInProgress } from "../../reducers/tenants";
import { selectUIConfig } from "../../reducers/ui-reducer";
import {
  selectIsLoadingVenue,
  selectIsLoadingVenues,
} from "../../reducers/venues";
import { State } from "../../store/types";
import MainContainer from "../MainContainer";
import DemoLink from "../common/DemoLink";
import Header from "../common/Header";
import LeftSidePanel from "./LeftSidePanel";
import "./home.scss";
import { selectIsLoadingQuestions } from "../../reducers/questions";

export interface LeftPanelList {
  key: webConfigUI;
  text: string;
  icon: string;
  hide?: boolean;
}

const mapDispatchToProps = {
  logout: logoutAction,
  setWebConfigUI: setWebConfigUIAction,
  getSettings: getSettingsAction,
  goToHost: goHostAction,
  getConfigs: getConfigsAction,
};
const mapStateToProps = (state: State) => ({
  user: selectUser(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  isLoadingVenues: selectIsLoadingVenues(state),
  isLoadingPackage: selectIsLoadingPackage(state),
  isLoadingPackages: selectIsLoadingPackages(state),
  isRequestInProgress: selectIsRequestInProgress(state),
  isSettingsRequestInProgress: selectIsSettingsRequestInProgress(state),
  isReportRequestInProgress: selectIsReportRequestInProgress(state),
  isTenantRequestInProgress: selectIsTenantRequestInProgress(state),
  isUserRequestInProgress: selectIsUserRequestInProgress(state),
  isLoadingResourceCategories: selectIsLoadingResourceCategories(state),
  isLoadingResourceRelations: selectIsLoadingResourceRelations(state),
  isConfigsRequestInProgress: selectIsConfigsRequestInProgress(state),
  mainContainerUI: selectWebConfigUI(state),
  isSuperAdmin: selectIsSuperAdmin(state),
  isPartnerAdmin: selectIsPartnerAdmin(state),
  isVenueAdmin: selectIsVenueAdmin(state),
  isFromSuperAdmin: selectIsFromSuperAdmin(state),
  uiConfig: selectUIConfig(state),
  readonly: selectReadonly(state),
  settings: selectSettingsState(state),
  isLoadingQuestions: selectIsLoadingQuestions(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const Home = ({
  logout,
  user,
  isLoadingVenue,
  isLoadingVenues,
  isLoadingPackage,
  isLoadingPackages,
  isRequestInProgress,
  isSettingsRequestInProgress,
  isReportRequestInProgress,
  isTenantRequestInProgress,
  isUserRequestInProgress,
  mainContainerUI,
  isSuperAdmin,
  isPartnerAdmin,
  isVenueAdmin,
  isFromSuperAdmin,
  uiConfig,
  readonly,
  setWebConfigUI,
  getSettings,
  isLoadingResourceCategories,
  isLoadingResourceRelations,
  isConfigsRequestInProgress,
  goToHost,
  getConfigs,
  settings,
  isLoadingQuestions
}: Props) => {
  const { isMiddleScreen } = useViewport();
  useEffect(() => {
    getSettings();
    if (isSuperAdmin) {
      setWebConfigUI(webConfigUI.EXEC_DASHBOARD);
      getConfigs();
    }
    if (isPartnerAdmin) {
      setWebConfigUI(webConfigUI.TENANTS)
    }
  }, [isSuperAdmin, isPartnerAdmin]);
  useEffect(() => {
    get('/api/version').then(response => {
      if (response?.data?.version) {
        setVersion(response?.data?.version)
      }
    })
  }, [])
  const [version, setVersion] = useState('')

  let homeLogoImg = RexIcon
  if (!isSuperAdmin && !isPartnerAdmin && uiConfig?.homeLogo) {
    homeLogoImg = uiConfig.homeLogo
  }
  let leftPanelList: LeftPanelList[] = [{
    key: webConfigUI.RESERVATION,
    text: 'Reservations',
    icon: 'reservation',
  },
  {
    key: webConfigUI.VENUES,
    text: 'Venues',
    icon: 'location',
  },
  {
    key: webConfigUI.ADDONS,
    text: 'Add Ons',
    icon: 'addons',
  },
  {
    key: webConfigUI.ADDON_MODIFIERS,
    text: 'Add On Modifiers',
    icon: 'addonModifiers',
  },
  {
    key: webConfigUI.SETTINGS,
    text: 'Settings',
    icon: 'setting',
  },
  {
    key: webConfigUI.REPORTS,
    text: 'Reports',
    icon: 'report',
  },
  {
    key: webConfigUI.REPORTSV2,
    text: 'ReportsV2',
    icon: 'report',
    hide: !settings?.showReportV2Page,
  },
  {
    key: webConfigUI.HOST_APP,
    text: 'Host App',
    icon: 'hostApp',
  },
  {
    key: webConfigUI.USERS,
    text: 'Users',
    icon: 'users',
    hide: isVenueAdmin || readonly,
  },
  {
    key: webConfigUI.DISCOUNTS,
    text: 'Discounts',
    icon: 'discounts',
  },
  {
    key: webConfigUI.QUESTIONS,
    text: 'Questions',
    icon: 'questions',
  },
  {
    key: webConfigUI.MEMBERSHIP,
    text: 'Memberships',
    icon: 'membership',
    hide: !uiConfig?.hasMemberships,
  },];
  if (isSuperAdmin) {
    leftPanelList = [{
      key: webConfigUI.EXEC_DASHBOARD,
      text: 'Exec Dashboard',
      icon: 'report',
    },
    {
      key: webConfigUI.TENANTS,
      text: 'Clients',
      icon: 'tenant',
    },
    {
      key: webConfigUI.PARTNERS,
      text: 'Partners',
      icon: 'partners',
    },
    {
      key: webConfigUI.MONITORING_APP,
      text: 'App Monitoring',
      icon: 'monitoring',
    },
    {
      key: webConfigUI.SUPER_ADMIN_SETTINGS,
      text: 'Settings',
      icon: 'setting',
    },
    {
      key: webConfigUI.TRANSFERS,
      text: 'Sync',
      icon: 'sync',
    }];
  } else if (isPartnerAdmin) {
    leftPanelList = [{
      key: webConfigUI.TENANTS,
      text: 'Clients',
      icon: 'tenant',
    },
    ];
  }
  leftPanelList = leftPanelList.filter(({ hide }) => !hide);
  return (
    <div className="main">
      {(isLoadingVenue ||
        isLoadingVenues ||
        isLoadingPackage ||
        isLoadingPackages ||
        isRequestInProgress ||
        isReportRequestInProgress ||
        isSettingsRequestInProgress ||
        isTenantRequestInProgress ||
        isLoadingResourceCategories ||
        isUserRequestInProgress ||
        isLoadingResourceRelations ||
        isConfigsRequestInProgress ||
        isLoadingQuestions) && (
          <div className="loading">
            <Spinner size={SpinnerSize.large} />
          </div>
        )}
      {(uiConfig?.isDemo && !isSuperAdmin && !isPartnerAdmin && !uiConfig.isHideDemoMessage) && <DemoLink link={uiConfig.upgradePlanUrl} />}
      {/* <LabelLink /> */}
      <Header
        logout={logout}
        showExitButton={isFromSuperAdmin && !isSuperAdmin && !isPartnerAdmin}
        userName={user?.username || ""}
        homeLogo={homeLogoImg}
        mainContainerUI={mainContainerUI}
        setMainContainerUI={setWebConfigUI}
        leftPanelList={leftPanelList}
        goToHost={goToHost}
      />
      <div className="container">
        {!isMiddleScreen && (
          <LeftSidePanel
            mainContainerUI={mainContainerUI}
            setMainContainerUI={setWebConfigUI}
            leftPanelList={leftPanelList}
            goToHost={goToHost}
            version={version}
          />
        )}
        <MainContainer
          mainContainerUI={mainContainerUI}
          setMainContainerUI={setWebConfigUI}
        />
      </div>
    </div>
  );
};

export default connector(Home);
