import React from "react";
import {
    ActionButton,
    IDropdownOption,
    Checkbox,
} from "office-ui-fabric-react";
import FormTextField from "../../../../../../../common/components/FormTextField";
import FormSelectField from "../../../../common/FormSelectField";
import { InventoryMethodTypes, TimeSlotSettingDto } from "../../../../../store/types";
import { useViewport } from "../../../../../hooks/responsive";
import TrashIcon from "../../../../../assets/trash-icon.svgr";
import "./timeSlotRow.scss";

const toggleStyle = {
    root: {
        margin: 0,
    },
};

interface OwnProps {
    slot: TimeSlotSettingDto;
    times: IDropdownOption[];
    onChange: (slot: TimeSlotSettingDto, field: string, value: string | number | boolean) => void;
    removeSlot: (slotId: string) => void;
    timeSlotDuration: number;
    inventoryMethod: InventoryMethodTypes;
    ageForPolicy: string;
}

export const TimeSlotRow = ({
    slot,
    times,
    onChange,
    removeSlot,
    timeSlotDuration,
    inventoryMethod,
    ageForPolicy,
}: OwnProps) => {
    const { isMobile } = useViewport();
    let slotsInHour = 60 / timeSlotDuration;
    let startTimeKey = slot.startTime ? Math.floor(slot.startTime / timeSlotDuration) : slot.start * slotsInHour / 2;
    let endTimeKey = slot.endTime ? Math.floor(slot.endTime / timeSlotDuration) : slot.end * slotsInHour / 2;
    const isAvailabilityDisabled = inventoryMethod === InventoryMethodTypes.byGuests || inventoryMethod === InventoryMethodTypes.byResources;
    const isGuestCapacityDisabled = inventoryMethod === InventoryMethodTypes.byLanes || inventoryMethod === InventoryMethodTypes.byResources;
    return (
        <div className={`timeSlotRow ${isMobile ? "mobile" : ""}`}>
            <FormSelectField
                label="Start time"
                options={times}
                className={`dropdown ${isMobile ? "mobile" : ""}`}
                selectedKey={startTimeKey}
                onChange={(_: any, option) => {
                    onChange(slot, "start", option?.key || 0);
                }}
            />
            <div className="divider" />
            <FormSelectField
                label="End time"
                options={times}
                className={`dropdown ${isMobile ? "mobile" : ""}`}
                selectedKey={endTimeKey}
                onChange={(_: any, option) =>
                    onChange(slot, "end", option?.key || 0)
                }
            />
            {!isMobile && <div className="divider" />}
            <FormTextField
                label="Rate"
                className={isMobile ? "mobile" : ""}
                value={String(slot.rate)}
                onChange={(_: any, text?: string) =>
                    onChange(slot, "rate", text || "")
                }
                autoComplete="off"
            />
            <div className="divider" />
            <FormTextField
                label="Available"
                className={isMobile ? "mobile" : ""}
                value={isAvailabilityDisabled ? '' : String(slot.lanesCount)}
                onChange={(_: any, text?: string) =>
                    onChange(slot, "lanesCount", text || "")
                }
                autoComplete="off"
                disabled={isAvailabilityDisabled}
            />
            <div className="divider" />
            <FormTextField
                label="Staff Cap."
                className={isMobile ? "mobile" : ""}
                value={String(slot.staffCapacity)}
                onChange={(_: any, text?: string) =>
                    onChange(slot, "staffCapacity", text || "")
                }
                autoComplete="off"
            />
            <div className="divider" />
            <FormTextField
                label="Guests Cap."
                className={isMobile ? "mobile" : ""}
                value={isGuestCapacityDisabled ? '' : String(slot.guestsCapacity)}
                onChange={(_: any, text?: string) =>
                    onChange(slot, "guestsCapacity", text || "")
                }
                autoComplete="off"
                disabled={isGuestCapacityDisabled}
            />
            <div className="divider" />
            <div className="check-21">
                <div className="check-label body2">{ageForPolicy || '21'}+</div>
                <Checkbox
                    checked={slot.is21plus}
                    onChange={(_, isChecked) =>
                        onChange(slot, "is21plus", !!isChecked)
                    }
                    styles={toggleStyle}
                />

            </div>
            <ActionButton onClick={() => removeSlot(slot.id)} styles={{ root: { height: 20, marginBottom: "10px" } }}><TrashIcon /></ActionButton>
        </div>
    );
};
