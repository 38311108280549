import React from "react";
import "./integrationButtonBlock.scss";

type Props = {
  srcLogo: string;
  title: string;
  subtitle: string;
  isConnected: boolean;
  disabled?: boolean;
  onConnect: () => void;
  onDisconnect: () => void;
};

const IntegrationButtonBlock = (props: Props) => {
  const { srcLogo, title, subtitle, isConnected, disabled, onConnect, onDisconnect } =
    props;
  return (
    <div className="connect">
      <div className="info">
        <img
          src={srcLogo}
          alt={title}
          style={{ objectFit: "cover", height: "64px", borderRadius: "5px" }}
        />
        <div className="title-text">
          <h2>
            {title}{" "}
            <div className={`connected-batch ${isConnected ? "show" : ""}`}>
              <div /> Connected
            </div>
          </h2>
          <p>{subtitle}</p>
        </div>
      </div>
      <button
        className={`connect-button ${isConnected ? "disconnected" : ""}`}
        onClick={isConnected ? onDisconnect : onConnect}
        disabled={disabled}
      >
        {isConnected ? "Disconnect" : "Connect"}
      </button>
    </div>
  );
};
export default IntegrationButtonBlock;
