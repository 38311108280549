import { IDropdownOption, Modal } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import CancelButton from "../../../../../common/components/CancelButton";
import ColorButton from "../../../../../common/components/ColorButton";
import { FormField } from "../../../../../common/components/FormField/FormField";
import FormTextField from "../../../../../common/components/FormTextField";
import LinkButton from "../../../../../common/components/LinkButton";
import { PhoneInput } from "../../../../../common/components/PhoneInput/PhoneInput";
import { formatPhone } from "../../../../../common/utils/formats";
import { isValidEmail } from "../../../../../common/utils/validate";
import {
  cloneVenueAction,
  createVenueAction,
  updateVenueAction,
} from "../../../actions/venue-actions";
import {
  DEFAULT_CARD_COLOR,
  DEFAULT_TEXT_CARD_COLOR,
  useViewport,
} from "../../../hooks/responsive";
import { selectReadonly } from "../../../reducers/auth-reducer";
import { selectVenue } from "../../../reducers/venues";
import {
  FeeType,
  GoTabWorkflowType,
  ReservationTaxType,
  State,
  TripleSeatBlockedStatus,
} from "../../../store/types";
import FormSelectField from "../../common/FormSelectField";
import "./editVenueInfo.scss";
import { timeZones } from "../../../constants/timeZones";

const timeZonesOptions: IDropdownOption[] = timeZones.map((key) => ({
  key,
  text: key,
}));

const ageForPolicyOptions: IDropdownOption[] = Array.apply(null, Array(6)).map(
  (_, index) => {
    const age = (index + 16).toString();
    return {
      key: age,
      text: age,
    };
  }
);

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
  createVenue: createVenueAction,
  cloneVenue: cloneVenueAction,
};
const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  readonly: selectReadonly(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
  isCloneVenue: boolean;
};

const EditVenueInfo = ({
  updateVenue,
  createVenue,
  cloneVenue,
  venue,
  readonly,
  onClose,
  isCloneVenue,
}: Props) => {
  const { isMobile } = useViewport();
  useEffect(() => {
    setName(venue?.name);
    setPrefix(venue?.prefix);
    setAddress(venue?.address);
    setAddress2(venue?.address2);
    setCity(venue?.city);
    setState(venue?.state);
    setZipCode(venue?.zipCode);
    setPhone(venue?.phone);
    setTimeZone(venue?.timeZone);
    setEmail(venue?.email);
    setEventsEmail(venue?.eventsEmail);
    setAgeForPolicy(venue?.ageForPolicy);
    setLocationUrl(venue?.locationUrl);
    setMapUrl(venue?.mapUrl);
  }, [venue]);
  useEffect(() => {
    setName(!isCloneVenue ? venue?.name : "");
  }, [isCloneVenue]);
  const [name, setName] = useState(venue?.name);
  const [prefix, setPrefix] = useState(venue?.prefix);
  const [address, setAddress] = useState(venue?.address);
  const [address2, setAddress2] = useState(venue?.address2);
  const [city, setCity] = useState(venue?.city);
  const [state, setState] = useState(venue?.state);
  const [zipCode, setZipCode] = useState(venue?.zipCode);
  const [phone, setPhone] = useState(venue?.phone);
  const [timeZone, setTimeZone] = useState(venue?.timeZone);
  const [email, setEmail] = useState(venue?.email);
  const [eventsEmail, setEventsEmail] = useState(venue?.eventsEmail);
  const [ageForPolicy, setAgeForPolicy] = useState(venue?.ageForPolicy);
  const [locationUrl, setLocationUrl] = useState(venue?.locationUrl);
  const [mapUrl, setMapUrl] = useState(venue?.mapUrl);

  const closed = venue?.closed || false;

  const isValidVenueInfo = () => {
    return (
      name &&
      prefix &&
      address &&
      city &&
      state &&
      zipCode &&
      timeZone &&
      email &&
      isValidEmail(email) &&
      eventsEmail &&
      isValidEmail(eventsEmail) &&
      ageForPolicy &&
      locationUrl &&
      mapUrl
    );
  };

  const getTimeSlotShifting = (timeSlotShifting: any) => {
    return [0, 0, 0, 0, 0, 0, 0].map((n: number, index: number) =>
      timeSlotShifting && timeSlotShifting[index] ? +timeSlotShifting[index] : n
    );
  };

  const getPatch = () => {
    return {
      name,
      prefix,
      address,
      address2,
      city,
      state,
      zipCode,
      phone: formatPhone(phone),
      timeZone,
      email,
      eventsEmail,
      ageForPolicy,
      locationUrl,
      mapUrl,
    };
  };

  const onUpdate = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isValidVenueInfo() && !closed) {
      return;
    }
    if (venue) {
      const patch = getPatch();
      isCloneVenue
        ? cloneVenue({ ...venue, ...patch })
        : updateVenue({ ...venue, ...patch });
    } else {
      if (
        !(
          name &&
          prefix &&
          address &&
          city &&
          state &&
          zipCode &&
          timeZone &&
          email &&
          isValidEmail(email) &&
          eventsEmail &&
          isValidEmail(eventsEmail) &&
          ageForPolicy &&
          locationUrl &&
          mapUrl
        )
      ) {
        return;
      }
      createVenue({
        name,
        prefix,
        address,
        address2,
        city,
        state,
        zipCode,
        phone: formatPhone(phone),
        timeZone,
        email,
        eventsEmail,
        ageForPolicy,
        locationUrl,
        mapUrl,
        allowedReservationInterval: 60,
        allowedReservationIntervalForAdmin: undefined,
        ignoreStaffCapacityForAdmin: false,
        maxGuestsForInquiry: 0,
        minGuestsForInquiry: 0,
        venueInfo: "Write your venue policies here",
        deposit: 100,
        depositType: FeeType.PERCENT,
        textBox: "",
        closed: false,
        isComingSoon: false,
        enableCheckIn: false,
        tax: 0,
        taxForServiceFee: "",
        serviceFee: "",
        serviceFeeName: "",
        serviceFeeType: "",
        packagesTitle: "",
        packagesTabName: "",
        choosePackageTitle: "Choose your package",
        eventInquiryTitle: "",
        eventInquiryTabName: "",
        eventConfirmationPageTitle: "",
        eventConfirmationPageCopy: "",
        eventEmailTitle: "",
        eventEmailCopy: "",
        packagesDescription: "",
        contactUsButtonText: "",
        description:
          "Write a short paragraph about your venue and reservations here",
        descriptionTitle: "Description Title",
        descriptionParty:
          "Write a short paragraph explaining how party inquiries work at your venue",
        partyUrl: "",
        closedMessage: "",
        maxGuests: 12,
        guestSplit: 6,
        showPolicy: false,
        allowCancellation: false,
        cancellationCutOff: 0,
        cancellationFee: 0,
        cancellationFeeType: FeeType.PERCENT,
        allowModification: false,
        modificationCutOff: 0,
        modificationFee: 0,
        modificationFeeType: FeeType.PERCENT,
        venueImage: "",
        secondaryVenueImages: [],
        mapImage: "",
        cardColor: DEFAULT_CARD_COLOR,
        textCardColor: DEFAULT_TEXT_CARD_COLOR,
        daysForBooking: 56,
        totalCountOfLanes: 10,
        durationInSlots: "2, 3, 4",
        durationInSlotsForAdmin: "",
        boldTextInAnEmail:
          "This reservation confirmation must be displayed at the site as a printout or saved on your mobile device. Please bring your matching credit card and photo ID to the venue.",
        section1Title: "",
        section2Title: "",
        section2Paragraph: "",
        faqTitle: "",
        faqs: "",
        delineationTextInAnEmail: "",
        makePartyNoteText: "",
        reservationIsNotAvailableText: "",
        lat: 0,
        lng: 0,
        dressCode: "",
        agePolicy: "",
        enableDayReport: true,
        enableQSR: false,
        qsrCompanyId: "",
        qsrSiteId: "",
        enableSevenRooms: false,
        sevenRoomsVenueId: "",
        enableSeatninja: false,
        splitSeatNinjaReservations: false,
        seatninjaRestId: "",
        seatninjaPartyType: "",
        seatninjaPartyFlags: null,
        enableTripleSeats: false,
        enableTripleSeatsScheduleUpdate: false,
        tripleSeatRoomId: null,
        tripleSeatLocationId: null,
        tripleSeatBlockedStatus: TripleSeatBlockedStatus.Definite,
        tripleTimezoneOffset: 0,
        enablePOS: false,
        steltronicUrl: "",
        steltronicUsername: "",
        steltronicPassword: "",
        enableSteltronic: false,
        posLocationId: "",
        sandBoxPayment: true,
        paymentUrl: "https://cert.api2.heartlandportico.com",
        mid: "test",
        paymentKey: "pkapi_cert_j0TFsUMpjPz8fJcuzf",
        paymentSecret: "skapi_cert_MRE2AgCnzGEAiXqoukaN_Oley73lOktSHcdXUF_85A",
        stripePublishableKey: "",
        stripeSecretKey: "",
        enableCaptureStripePayment: true,
        fortisUrl: "",
        fortisDeveloperId: "",
        fortisUserId: "",
        fortisUserApiKey: "",
        fortisLocationId: "",
        sezzlePublishableKey: "",
        sezzleSecretKey: "",
        sezzleUrl: "",
        enableSalesForce: false,
        enableMailChimp: false,
        enableTripleSeatsParty: false,
        enableMailChimpParty: false,
        enableSeatninjaParty: false,
        dayReportEmails: "",
        dayReportTime: 14,
        reservationDetails: "",
        timeSlotShifting: getTimeSlotShifting([]),
        lightScript: "",
        enableCustomNotes: false,
        enableRequiredCustomNotes: false,
        customNotesTitle: "",
        customNotesPrefilledText: "",
        customNotesExplanationText: "",
        inactivePackageMessage: "",
        preBufferTimeForReservation: 0,
        bufferTimeForReservation: 0,
        enableAssignDuration: false,
        assignDuration: "",
        assignDurationForAdmin: "",
        shiftingForReservationStart: 0,
        useDiscount: false,
        useGiftCard: false,
        giftCardClientKey: "",
        giftCardTerminalId: "",
        giftCardWsdlUrl: "",
        addonsTitle: "",
        addonsDescription: "",
        enableSalesForceReservation: false,
        salesForceReservationUrl: "",
        salesForceReservationClientId: "",
        salesForceReservationClientSecret: "",
        salesForceReservationUsername: "",
        salesForceReservationPassword: "",
        reservationTaxes: ReservationTaxType.venueTax,
        defaultEmailForSkip: "",
        defaultPhoneForSkip: "",
        defaultFirstNameForSkip: "",
        defaultLastNameForSkip: "",
        skipGuestName: false,
        groupResourcesSideBySide: false,
        autoCompleteReservations: true,
        autoNoShowReservations: true,
        showSkipPaymentInHost: true,
        showPaymentLinkInHost: false,
        showRecordCashInHost: true,
        showManagersCompInHost: true,
        showGiftCardInHost: true,
        showManuallyEnterCardInHost: true,
        showCardReaderInHost: true,
        showStripeInvoiceInHost: false,
        enableAboutGolfIntegration: false,
        aboutGolfFacilityId: "",
        enableGoTabIntegration: false,
        goTabLocation: "",
        goTabProductId: "",
        goTabSpotUuid: "",
        goTabWorkflowType: GoTabWorkflowType.ReservationDeposit,
        goTabCreateTabOnCheckIn: true,
        goTabChargeTaxesFeesOnDeposit: false,
        paymentType: "",
        enableTwilio: false,
        enableTwilioPhoneVerification: false,
        twilioPhoneNumber: "",
        calendarStartTime: 0,
        calendarEndTime: 28,
        calendarTimeWindow: 60,
        calendarZoomLevel: 1,
        quickChatText: "",
        enableReservationTimer: false,
        reservationTimerAlert: "",
        reservationTimerInterval: 0,
        squareIsEnable: false,
        squareLocationName: "",
        squareLocationId: "",
        isSquareCashAppEnable: false,
        isSquareAfterPayEnable: false,
        facebookUrl: "",
        instagramUrl: "",
        twitterUrl: "",
        tikTokUrl: "",
        sidebarTitle: "",
        sidebarDescription: "",
        sidebarLinks: [],
        sidebarIcons: [],
        sidebarUrls: [],
        viewMenuUrl: "",
        customTabEnable: false,
        customTabTabName: "Custom Tab",
        customTabDescription: "",
        customTabScript: "",
        thankYouEmailEnable: false,
        thankYouEmailMinutes: 0,
        thankYouEmailSubject: "",
        thankYouEmailHeader: "",
        thankYouEmailBody: "",
        isStripeSandboxPayment: false,
        isStripeV2OtherMethodsEnable: false,
        eventInquiryEmailEnable: false,
        markAsPaidSourceOptions: '',
        stripeTerminal: '',
        errorNotificationEmails: '',
        stripeTerminalOptions: '',
        stripeAdditionalMethods: '',
        enableTwilioConfirmationText: false,
        enableTwilioReminderText: false,
        twilioConfirmationText: '',
        twilioReminderText: '',
        ageGroupsPricingNote: '',
        enableCalendarLinks: false,
        keepMeUpdatedText: '',
      });
    }
    onClose();
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-venue"
    >
      <div className="title h4">
        Venue Info
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="venue" onSubmit={onUpdate}>
        <div className="edit-venue-fields">
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormTextField
              label="Venue Name"
              value={name}
              onChange={(_: any, text?: string) => setName(text)}
              autoComplete="off"
              required={!closed}
            />
            {!isMobile && <div className="delimiter" />}
            <FormTextField
              label="Prefix"
              value={prefix}
              onChange={(_: any, text?: string) => setPrefix(text)}
              autoComplete="off"
              required={!closed}
            />
          </div>
          <div className="row">
            <FormTextField
              label="Address"
              value={address}
              onChange={(_: any, text?: string) => setAddress(text)}
              autoComplete="off"
              required={!closed}
            />
          </div>
          <div className="row">
            <FormTextField
              label="Address 2"
              value={address2}
              onChange={(_: any, text?: string) => setAddress2(text)}
              autoComplete="off"
            />
          </div>
          <div className="row">
            <FormTextField
              label="City"
              value={city}
              onChange={(_: any, text?: string) => setCity(text)}
              autoComplete="off"
              required={!closed}
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormTextField
              label="State"
              value={state}
              onChange={(_: any, text?: string) => setState(text)}
              autoComplete="off"
              required={!closed}
            />
            {!isMobile && <div className="delimiter" />}
            <FormTextField
              label="Zip"
              value={zipCode}
              onChange={(_: any, text?: string) => setZipCode(text)}
              autoComplete="off"
              required={!closed}
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormField label="Phone">
              <PhoneInput
                className="row phone-field"
                placeholder="Phone number"
                defaultCountry="US"
                value={phone || ""}
                onChange={(text?: string) => setPhone(text)}
                limitMaxLength={true}
                autoComplete="off"
              />
            </FormField>
            {!isMobile && <div className="delimiter" />}
            <FormSelectField
              label="Time Zone"
              options={timeZonesOptions}
              selectedKey={timeZone}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption
              ) => setTimeZone(item?.key as string)}
              required={!closed}
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormTextField
              label="Reservations Email Address"
              value={email}
              onChange={(_: any, text?: string) => setEmail(text)}
              autoComplete="off"
              required={!closed}
            />
            {!isMobile && <div className="delimiter" />}
            <FormTextField
              label="Events Email Address"
              value={eventsEmail}
              onChange={(_: any, text?: string) => setEventsEmail(text)}
              autoComplete="off"
              required={!closed}
            />
          </div>
          <div className="row">
            <FormTextField
              label="Location URL"
              value={locationUrl}
              onChange={(_: any, text?: string) => setLocationUrl(text)}
              autoComplete="off"
              required={!closed}
            />
          </div>
          <div className="row">
            <FormTextField
              label="Map URL"
              value={mapUrl}
              onChange={(_: any, text?: string) => setMapUrl(text)}
              autoComplete="off"
              required={!closed}
            />
          </div>
          <div className="row">
            <FormSelectField
              label="Age for Policy"
              options={ageForPolicyOptions}
              selectedKey={ageForPolicy}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption
              ) => setAgeForPolicy(item?.key as string)}
              required={!closed}
            />
          </div>
        </div>
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit" disabled={readonly}>
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditVenueInfo);
