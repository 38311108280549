import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Modal, IDropdownOption } from "office-ui-fabric-react";
import {
  setVenueErrorAction,
  updateVenueAction,
} from "../../../../actions/venue-actions";
import {
  selectVenue,
  selectIsLoadingVenue,
  selectVenueError,
} from "../../../../reducers/venues";
import { selectUIConfig } from "../../../../reducers/ui-reducer";
import { State } from "../../../../store/types";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import FormSelectField from "../../../common/FormSelectField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import {
  formatTimeForCalendar,
  formatTimeWindowForCalendar,
} from "../../../../utils/formats";
import "./editCalendarRangeSettings.scss";

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
  setVenueError: setVenueErrorAction,
};
const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  readonly: selectReadonly(state),
  error: selectVenueError(state),
  uiConfig: selectUIConfig(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditCalendarRangeSettings = ({
  updateVenue,
  venue,
  readonly,
  error,
  isLoadingVenue,
  uiConfig,
  onClose,
  setVenueError,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    setVenueError("");
  }, []);
  useEffect(() => {
    if (!isLoadingVenue && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingVenue]);
  useEffect(() => {
    setCalendarStartTime(venue?.calendarStartTime);
    setCalendarEndTime(venue?.calendarEndTime);
    setCalendarTimeWindow(venue?.calendarTimeWindow || 0);
  }, [venue]);
  const twelveHourClockFormat = !!uiConfig?.twelveHourClockFormat;
  const calendarRangeOptions: IDropdownOption[] = Array(29)
    .fill(null)
    .map((_, index) => ({
      key: index,
      text: formatTimeForCalendar(twelveHourClockFormat, index),
    }));
  const calendarWindowOptions: IDropdownOption[] = [60, 30, 15, 10, 5].map(key => ({ key, text: formatTimeWindowForCalendar(key) }))

  const [calendarStartTime, setCalendarStartTime] = useState(
    venue?.calendarStartTime
  );
  const [calendarEndTime, setCalendarEndTime] = useState(
    venue?.calendarEndTime
  );
  const [calendarTimeWindow, setCalendarTimeWindow] = useState(
    venue?.calendarTimeWindow || 0
  );

  const getPatch = () => {
    return {
      calendarStartTime: calendarStartTime || 0,
      calendarEndTime: calendarEndTime || 0,
      calendarTimeWindow: calendarTimeWindow || 0,
    };
  };

  const onUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    if (venue) {
      saving.current = true;
      const patch = getPatch();
      updateVenue({ ...venue, ...patch });
    }
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-calendar-range-settings"
    >
      <div className="title h4">
        Admin Reservation Settings
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="calendar-range-settings" onSubmit={onUpdate}>
        <div className="calendar-range-fields">
          <FormSelectField
            label="Calendar Start Time"
            className="row"
            options={calendarRangeOptions}
            selectedKey={calendarStartTime}
            onChange={(
              _: React.FormEvent<HTMLDivElement>,
              option?: IDropdownOption
            ) => setCalendarStartTime(option?.key ? +option?.key : undefined)}
          />
          <FormSelectField
            label="Calendar End Time"
            className="row"
            options={calendarRangeOptions}
            selectedKey={calendarEndTime}
            onChange={(
              _: React.FormEvent<HTMLDivElement>,
              option?: IDropdownOption
            ) => setCalendarEndTime(option?.key ? +option?.key : undefined)}
          />
          <FormSelectField
            label="Calendar Time Window"
            className="row"
            options={calendarWindowOptions}
            selectedKey={calendarTimeWindow}
            onChange={(
              _: React.FormEvent<HTMLDivElement>,
              option?: IDropdownOption
            ) => setCalendarTimeWindow(option?.key ? +option?.key : 0)}
          />
        </div>
        {error && <div className="error">{error}</div>}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit" disabled={readonly}>
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditCalendarRangeSettings);
