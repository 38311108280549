import React, {useEffect, useState} from 'react';
import './index.scss'
import deleteIcon from "../../../../assets/delete.svg"
import {Icon, Toggle} from "office-ui-fabric-react";
import ColorButton from "../../../../../../common/components/ColorButton";
import FormTextField from "../../../../../../common/components/FormTextField";
import {selectCancellationReasons, selectCancellationReasonsActive} from "../../../../reducers/settings";
import {State} from "../../../../store/types";
import {connect, ConnectedProps} from "react-redux";
import {updateSettingsAction} from "../../../../actions/settings-actions";
import Panel from "../../../common/Panel";
import {selectReadonly} from "../../../../reducers/auth-reducer";
import LinkButton from '../../../../../../common/components/LinkButton';

const mapStateToProps = (state: State) => ({
  cancellationReasons: selectCancellationReasons(state),
  cancellationReasonsActive: selectCancellationReasonsActive(state),
  readonly: selectReadonly(state)
})

const mapDispatchToProps = {
  updateSettings: updateSettingsAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>

const CancellationReasons = (
  {
    cancellationReasons,
    cancellationReasonsActive,
    updateSettings,
    readonly,
  }: Props
) => {
  const [newReasons, setNewReasons] = useState<string[]>([]);

  useEffect(() => {
    const splitReasons = cancellationReasons.split(';').filter(string => !!string);


    setNewReasons(splitReasons)
  }, [cancellationReasons])

  const onChangeReason = (index: number) => (_: any, text?: string) => {
    const reasons = [...newReasons]
    reasons[index] = text as string
    setNewReasons(reasons)
  }

  const onDeleteRow = (index: number) => () => {
    const reasons = [...newReasons]
    reasons.splice(index, 1)
    setNewReasons(reasons)
  }

  const onAddItem = () => {
    setNewReasons(prevState => [...prevState, ''])
  }

  const onSaveReasons = () => {
    updateSettings({
      cancellationReasons: newReasons.join(';')
    })
  }


  const renderTitle = () => (
    <div className='cancellation-reasons-title'>
      <span className='h6'>Cancellation Reasons</span>
      <Toggle
        checked={cancellationReasonsActive}
        onChange={(_: any, value?: boolean) =>
          updateSettings({
            cancellationReasonsActive: !!value,
          })
        }
        disabled={readonly}
      />
    </div>
  )
  return (
    <Panel className='cancellation-reasons panel-shift' title={renderTitle()}>
      {
        cancellationReasonsActive && (
          <div className='cancellation-reasons-body'>
            <div className='cancellation-reasons-inputs'>
              {
                newReasons.map((category, index) => (
                  <div className='cancellation-reasons-inputs-row' key={index}>
                    <div className='cancellation-reasons-input'>
                      <FormTextField value={category} onChange={onChangeReason(index)}
                                     label='Reason' disabled={readonly}/>
                    </div>
                    <button className='delete-button' onClick={onDeleteRow(index)}>
                      <img src={deleteIcon} alt="Delete Row"/>
                    </button>
                  </div>
                ))
              }
            </div>
            <LinkButton className="cancellation-reasons-add-item" onClick={onAddItem}>
              <Icon iconName="Add" className="add-icon" />
              Add item
            </LinkButton>
            <ColorButton onClick={onSaveReasons}>Save Changes</ColorButton>
          </div>
        )
      }
    </Panel>
  );
};

export default connector(CancellationReasons);

