import React, { useEffect, useState } from 'react';
import {
    ActionButton, DetailsList, DetailsListLayoutMode, IColumn, Icon, IContextualMenuProps, ScrollablePane,
    SelectionMode, Spinner, SpinnerSize
} from "office-ui-fabric-react";
import { connect, ConnectedProps } from "react-redux";
import ColorButton from "../../../../common/components/ColorButton";
import {
    getQuestionsAction, removeQuestionAction, selectQuestionAction, setFilteredQuestionAction
} from '../../actions/question-action';
import LocationIcon from "../../assets/locations-icon.svgr";
import { webConfigUI } from "../../constants/webConfigUI";
import { selectReadonly } from '../../reducers/auth-reducer';
import {
    selectFilteredQuestions, selectIsLoadingQuestion, selectIsLoadingQuestions, selectQuestions
} from '../../reducers/questions';
import { QuestionType, State, QuestionSettingDto } from "../../store/types";
import ActionsButton from "../common/ActionsButton";
import FormSearchField from "../common/FormSearchField";
import EditQuestion from './Edit/EditQuestion';
import './questions.scss';
import ThreeDots from "../../assets/three-dots.svgr";

const mapDispatchToProps = {
    getQuestions: getQuestionsAction,
    selectQuestion: selectQuestionAction,
    setFilteredQuestions: setFilteredQuestionAction,
    onDelete: removeQuestionAction
};

const mapStateToProps = (state: State) => ({
    questions: selectQuestions(state),
    filteredQuestions: selectFilteredQuestions(state),
    readonly: selectReadonly(state),
    isLoadingQuestions: selectIsLoadingQuestions(state),
    isLoadingQuestion: selectIsLoadingQuestion(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & {
    setMainContainerUI: (view: webConfigUI) => void;
};

const convertQuestionType = (type: QuestionType) => {
    switch (type) {
        case QuestionType.string:
            return "Single line text";
        case QuestionType.dropdown:
            return "Dropdown";
        case QuestionType.multiSelect:
            return "Multi-select";
        case QuestionType.date:
            return "Date (MM/DD/YY)";
        case QuestionType.dateMonth:
            return "Date (MM/DD)";
        case QuestionType.email:
            return "Email";
        default:
            return "Single line text";
    }
};

const Questions = ({
    getQuestions,
    questions,
    filteredQuestions,
    setMainContainerUI,
    selectQuestion,
    setFilteredQuestions,
    readonly,
    onDelete,
    isLoadingQuestions,
    isLoadingQuestion
}: Props) => {

    const columns: IColumn[] = [
        {
            key: "internalName",
            name: "INTERNAL NAME",
            fieldName: "internalName",
            minWidth: 130,
            maxWidth: 200,
            isResizable: true,
            className: "bold-column subtitle2",
            data: "string",
            isPadded: true,
        },
        {
            key: "displayName",
            name: "DISPLAY NAME",
            fieldName: "displayName",
            minWidth: 130,
            maxWidth: 200,
            isResizable: true,
            className: "bold-column subtitle2",
            data: "string",
            isPadded: true,
        },
        {
            key: "questionType",
            name: "QUESTION TYPE",
            fieldName: "questionType",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            className: "column body2",
            data: "string",
            isPadded: true,
            onRender: (item: QuestionSettingDto) => {
                return (
                    <div className="question-type-container">
                        <div className={`question-type-text body2`}>
                            {convertQuestionType(item.questionType)}
                        </div>
                    </div>
                );
            },
        },
        {
            key: "options",
            name: "OPTIONS",
            fieldName: "options",
            minWidth: 130,
            maxWidth: 200,
            isResizable: true,
            className: "column body2",
            data: "string",
            isPadded: true,
        },
        {
            key: "priority",
            name: "PRIORITY",
            fieldName: "priority",
            minWidth: 70,
            maxWidth: 100,
            isResizable: true,
            className: "column body2",
            data: "string",
            isPadded: true,
            isSorted: true,
            isSortedDescending: false,
        },
        {
            key: "action",
            name: "",
            minWidth: 25,
            maxWidth: 25,
            className: "column body2 context",
            onRender: (item: QuestionSettingDto) => {
                const menuProps: IContextualMenuProps = {
                    shouldFocusOnMount: true,
                    isBeakVisible: false,
                    items: [
                        { key: 'delete', text: 'Delete', onClick: () => onDelete(item.id) },
                    ],
                };
                return <div onClick={(e) => e.stopPropagation()}>
                    <ActionButton className="contextual-menu" menuProps={menuProps}>
                        <ThreeDots />
                    </ActionButton>
                </div>;
            },
            isPadded: true,
        },
    ];

    const [isCreating, setIsCreating] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState<QuestionSettingDto | null>(null);

    useEffect(() => {
        getQuestions();
    }, [getQuestions]);

    useEffect(() => {
        questions && setFilteredQuestions(questions);
    }, [questions, setFilteredQuestions]);

    useEffect(() => {
        if (selectedQuestion) {
            setIsCreating(true);
        }
    }, [selectedQuestion]);

    const onItemInvoked = (item: QuestionSettingDto): void => {
        const target = document.activeElement;
        if (!target?.closest('.contextual-menu')) {
            setSelectedQuestion(item);
            selectQuestion(item);
            setIsCreating(true);
        }
    };

    const onFilter = (ev: any, searchText: string | undefined = ""): void => {
        setFilteredQuestions(
            searchText
                ? questions.filter((item) =>
                    item.internalName.toLowerCase().includes(searchText.toLowerCase())
                )
                : questions
        );
    };

    const addQuestion = () => {
        setSelectedQuestion(null); // Ensures it's in create mode
        setIsCreating(true);
    };

    const handleModalClose = () => {
        setIsCreating(false);
        setSelectedQuestion(null);
    };

    return (
        <>
            <div className="questions">
                <div className="questions-header">
                    <h1 className="h4 title">Questions</h1>
                    <ActionsButton onClick={addQuestion} disabled={readonly}>
                        <Icon iconName="Add" className="add-icon" />
                        <div className="add-button-text">Add question</div>
                    </ActionsButton>
                </div>
                <div className="question-list-container">
                    <div className="searchbox-block">
                        <FormSearchField
                            placeholder="Search..."
                            onChange={onFilter}
                        />
                    </div>
                </div>
                {questions?.length > 0 ? (
                    <div className="questions-list">
                        <ScrollablePane>
                            <DetailsList
                                items={filteredQuestions}
                                styles={{ focusZone: { cursor: "pointer" } }}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                getKey={(item) => item.id}
                                setKey="none"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                                onActiveItemChanged={onItemInvoked}
                            />
                        </ScrollablePane>
                    </div>
                ) : (
                    <div className="empty-question-list-container">
                        <div className="empty-question-list">
                            <LocationIcon className="location-icon" />
                            <div className="empty-question-list-message">
                                Let’s get your first question added!
                            </div>
                            <ColorButton
                                className="empty-question-list-button"
                                onClick={addQuestion}
                                disabled={readonly}
                            >
                                <Icon iconName="Add" className="add-icon" />
                                <div className="add-button-text">Add question</div>
                            </ColorButton>
                        </div>
                    </div>
                )}
            </div>
            {isCreating && (
                <EditQuestion
                    onClose={handleModalClose}
                    selectedQuestion={selectedQuestion || undefined}
                />
            )}
        </>
    );
};

export default connector(Questions);
