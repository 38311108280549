import { Pricing, AddonType, DepositPercentageType } from "../entities/enums";
import { AddonsRelationsDto } from "./addonsRelations.dto";
import { MembershipPlanDto } from "./membershipPlan.dto";
import { ScheduleAddonSettingDto } from "./scheduleAddonSetting.dto";
import { VenueTaxDto } from "./venueTax.dto";

export class AddonSettingDto {
  id: string;
  name: string;
  priority: number;
  description: string;
  shortDescription: string;
  status: boolean;
  required: boolean;
  minimumQuantity: number;
  maximumQuantity: number;
  price: number;
  depositPercentage: DepositPercentageType;
  image: string;
  method: Pricing;
  schedulesAddon: ScheduleAddonSettingDto[];
  addonRelations: AddonsRelationsDto[];
  attributions: string;
  tags: string;
  addonType: AddonType;
  allowCustomerNotes: boolean;
  internalName: string;
  matchGuestCount: boolean;
  isMemberOnlyAddon: boolean;
  membershipPlanIds: string[]
}

export class CreateAddonSettingDto {
  name: string;
  priority: number;
  description: string;
  shortDescription: string;
  status: boolean;
  required: boolean;
  minimumQuantity: number;
  maximumQuantity: number;
  price: number;
  depositPercentage: DepositPercentageType;
  image: string;
  method: Pricing;
  schedulesAddon: ScheduleAddonSettingDto[];
  attributions: string;
  tags: string;
  addonType: AddonType;
  allowCustomerNotes: boolean;
  internalName: string;
  matchGuestCount: boolean;
  isMemberOnlyAddon: boolean;
  membershipPlanAddonRelations: MembershipPlanAddonRelationsDto[]
}


export class AddonsPackageVenueRelationDto {
  id: string;
  selected: boolean;
  venueName: string;
  venueId: string;
  packageName?: string;
  packageId?: string;
  status?: boolean;
  numberOfLanes?: number;
  method?: Pricing;
  minGuests?: number;
  maxGuests?: number;
  duration: number;
  durationInSlots: string;
  enableDurationChoice: boolean;
  reservationTaxes: string;
  taxes: VenueTaxDto[];
  venueTax: number;
  serviceFee: string;
  serviceFeeName: string;
  serviceFeeType: string;
  taxForServiceFee: string;
  goTabLocation?: string;
  goTabProductId: string;
  squareItemIds: string[];
  squareLocationId?: string;
}
export class MembershipPlanAddonRelationsDto {
  id: string;
  membershipPlanId: string;
  addonId: string;
  membershipPlan?: MembershipPlanDto
  addon?: AddonSettingDto
}