import { ActionTypeEnum, AppThunk } from ".";
import { getWithAuth, postWithAuth, putWithAuth, deleteWithAuth, TENANTS_URL, USERS_URL } from "./api";
import { CreateTenantDto, TenantSettingsDto, TempTenantUserDto, TenantPlan, VenueSettingDto, TenantUserDto, NewTenantUserDto, PackageSettingDto, PartnerDto } from "../store/types";
import { addErrorAction } from "./auth-actions";
import { setWebConfigUIAction } from "./ui-actions";
import { webConfigUI } from "../constants/webConfigUI";
import { selectTenant } from "../reducers/tenants";

export const getTenantsAction = (filter: string): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetTenants });
    const response = await getWithAuth(`${TENANTS_URL}/?query=${filter}`);
    dispatch({
      type: ActionTypeEnum.GetTenantsSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetTenantsFailure,
      payload: "error getting tenants",
    });
    dispatch(addErrorAction("Get tenants failure"));
  }
};

export const createTenantAction = (data: CreateTenantDto): AppThunk => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypeEnum.CreateTenant });
    const response = await postWithAuth(TENANTS_URL, data);
    dispatch({
      type: ActionTypeEnum.CreateTenantSuccess,
      payload: response.data,
    });
  } catch (e) {
    let errorMessage = 'error create tenant';
    if (!!e?.response?.data?.message) {
      errorMessage = e?.response?.data?.message;
    }
    dispatch({
      type: ActionTypeEnum.CreateTenantFailure,
      payload: errorMessage,
    });
    dispatch(addErrorAction(errorMessage));
  }
};
export const getTenantAction = (tenantId: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypeEnum.GetTenant });
    const response = await getWithAuth(`${TENANTS_URL}/${tenantId}`);
    dispatch({
      type: ActionTypeEnum.GetTenantSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetTenantFailure,
      payload: "error get tenant",
    });
    dispatch(addErrorAction("Get tenant failure"));
  }
};
export const createUserAction = (data: TempTenantUserDto): AppThunk => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypeEnum.CreateUser });
    const response = await postWithAuth(USERS_URL, data);
    dispatch({
      type: ActionTypeEnum.CreateUserSuccess,
      payload: { user: response.data, tenantId: data.tenantId },
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.CreateUserFailure,
      payload: "error create user",
    });
    dispatch(addErrorAction("Create user failure"));
  }
};
export const updateTenantAction = ({
  id,
  name,
  domain,
  plan,
  isHideDemoMessage,
  hasMemberships,
  membershipFee,
  bookingFee,
  isOnboarding,
  mixpanelToken,
  partner
}: {
  id: string;
  name: string,
  domain: string,
  plan: TenantPlan,
  isHideDemoMessage: boolean,
  isOnboarding: boolean,
  hasMemberships: boolean,
  membershipFee: number,
  bookingFee: number,
  mixpanelToken: string,
  partner?: PartnerDto,
}): AppThunk => async (
  dispatch
) => {
    try {
      dispatch({ type: ActionTypeEnum.UpdateTenant });
      const response = await postWithAuth(`${TENANTS_URL}/${id}`, { domain, name, plan, isHideDemoMessage, isOnboarding, hasMemberships, membershipFee, bookingFee, mixpanelToken, partner });
      dispatch({
        type: ActionTypeEnum.UpdateTenantSuccess,
        payload: response.data,
      });
      dispatch(getTenantAction(id))
    } catch (e) {
      dispatch({
        type: ActionTypeEnum.UpdateTenantFailure,
        payload: "error update tenant",
      });
      dispatch(addErrorAction("Update tenant failure"));
    }
  };
export const cloneTenantAction = ({
  name,
  domain,
  email,
  password,
  tenantId,
}: {
  name: string;
  domain: string;
  email: string;
  password: string;
  tenantId: string;
}): AppThunk => async (
  dispatch
) => {
    try {
      dispatch({ type: ActionTypeEnum.CloneTenant });
      const response = await postWithAuth(`${TENANTS_URL}/clone/${tenantId}`, { name, domain, email, password });
      dispatch({
        type: ActionTypeEnum.CloneTenantSuccess,
        payload: response.data,
      });
      dispatch(setWebConfigUIAction(webConfigUI.TENANTS))
    } catch (e) {
      let errorMessage = 'error clone tenant';
      if (!!e?.response?.data?.message) {
        errorMessage = e?.response?.data?.message;
      }
      dispatch({
        type: ActionTypeEnum.CloneTenantFailure,
        payload: errorMessage,
      });
      dispatch(addErrorAction(errorMessage));
    }
  };
export const removeTenantAction = (id: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypeEnum.DeleteTenant });
    const response = await deleteWithAuth(`${TENANTS_URL}/${id}`);
    dispatch({
      type: ActionTypeEnum.DeleteTenantSuccess,
      payload: id,
    });
    dispatch(setWebConfigUIAction(webConfigUI.TENANTS))
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.DeleteTenantFailure,
      payload: "error update tenant",
    });
    dispatch(addErrorAction("Update tenant failure"));
  }
};
export const updateUserAction = (data: TempTenantUserDto): AppThunk => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypeEnum.UpdateUser });
    const response = await putWithAuth(USERS_URL, data);
    dispatch({
      type: ActionTypeEnum.UpdateUserSuccess,
      payload: { user: response.data, tenantId: data.tenantId },
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.UpdateUserFailure,
      payload: "error update user",
    });
    dispatch(addErrorAction("Update user failure"));
  }
};
export const removeUserAction = (id: string, tenantId: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypeEnum.RemoveUser });
    await deleteWithAuth(`${USERS_URL}/${tenantId}/${id}`);
    dispatch({
      type: ActionTypeEnum.RemoveUserSuccess,
      payload: { id, tenantId },
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.RemoveUserFailure,
      payload: "error remove user",
    });
    dispatch(addErrorAction("Remove user failure"));
  }
};

export const selectTenantAction = (tenant: TenantSettingsDto) => ({ type: ActionTypeEnum.SetTenant, payload: tenant })

export const openTenantUrlAction = (venue: VenueSettingDto): AppThunk => async (dispatch, getState) => {
  const tenant = selectTenant(getState());
  let domain = tenant?.domain;
  if (!domain) {
    try {
      const response = await getWithAuth(`${TENANTS_URL}/domain`);
      domain = response.data;
    } catch (e) {
      dispatch(addErrorAction("Get tenant domain failure"));
    }
  }
  const protocol = ['localhost', '127.0.0.1'].includes(domain?.split(':')[0] || '') ? 'http' : 'https';
  window.open(`${protocol}://${domain}/package?venue=${encodeURIComponent(venue.name)}`, '_blank');
  dispatch({ type: 'blank' })
}

export const openTenantUrlWithPackageAction = ({ p, venue }: { venue: VenueSettingDto, p: PackageSettingDto }): AppThunk => async (dispatch, getState) => {
  const tenant = selectTenant(getState());
  let domain = tenant?.domain;
  if (!domain) {
    try {
      const response = await getWithAuth(`${TENANTS_URL}/domain`);
      domain = response.data;
    } catch (e) {
      dispatch(addErrorAction("Get tenant domain failure"));
    }
  }

  let params = `venue=${encodeURIComponent(venue.name)}`;

  if (p.isHidden) {
    params += `&sp=${encodeURIComponent(p.id)}`
  } else {
    params += `&currentPackage=${encodeURIComponent(p.name)}`
  }

  window.open(`http://${domain}/package?${params}`, '_blank');
  dispatch({ type: 'blank' })
}

export const setTenantErrorAction = (error: string) => ({ type: ActionTypeEnum.SetTenantError, payload: error })

export const getUsersAction = (): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetUsers });
    const response = await getWithAuth(`${USERS_URL}/users`);
    dispatch({
      type: ActionTypeEnum.GetUsersSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetUsersFailure,
      payload: "error getting users",
    });
    dispatch(addErrorAction("Get users failure"));
  }
};

export const selectUserAction = (user?: TenantUserDto) => ({
  type: ActionTypeEnum.SelectUser,
  payload: user,
});

export const createTenantUserAction = (data: NewTenantUserDto): AppThunk => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypeEnum.CreateTenantUser });
    const response = await postWithAuth(`${USERS_URL}/create-user`, data);
    dispatch({
      type: ActionTypeEnum.CreateTenantUserSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.CreateTenantUserFailure,
      payload: "error create user",
    });
    dispatch(addErrorAction("Create user failure"));
  }
};

export const updateTenantUserAction = (data: NewTenantUserDto): AppThunk => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypeEnum.UpdateTenantUser });
    const response = await postWithAuth(`${USERS_URL}/update-user`, data);
    dispatch({
      type: ActionTypeEnum.UpdateTenantUserSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.UpdateTenantUserFailure,
      payload: "error update user",
    });
    dispatch(addErrorAction("Update user failure"));
  }
};

export const removeTenantUserAction = (id: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypeEnum.RemoveTenantUser });
    await postWithAuth(`${USERS_URL}/remove-user/${id}`);
    dispatch({
      type: ActionTypeEnum.RemoveTenantUserSuccess,
      payload: id,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.RemoveTenantUserFailure,
      payload: "error remove user",
    });
    dispatch(addErrorAction("Remove user failure"));
  }
};

export const downloadUsersAction = (): AppThunk => async (
  dispatch
) => {
  dispatch({ type: ActionTypeEnum.UsersDownloadRequest });
  try {
    const response = await getWithAuth(`${USERS_URL}/download-users`);
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "users.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
    dispatch({ type: ActionTypeEnum.UsersDownloadSuccess });
  } catch (e) {
    console.log("download users error", e);
    dispatch({
      type: ActionTypeEnum.UsersDownloadFailure,
      payload: "DownloadUsers failure",
    });
    dispatch(addErrorAction("DownloadUsers failure"));
  }
};