import {
  IDropdownOption,
  Icon,
  IconButton,
  Toggle,
} from "office-ui-fabric-react";
import React, { useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import ColorButton from "../../../../../common/components/ColorButton";
import FormSelectField from "../../../../../common/components/FormSelectField";
import FormTextField from "../../../../../common/components/FormTextField";
import LinkButton from "../../../../../common/components/LinkButton";
import { updateVenueAction } from "../../../actions/venue-actions";
import TrashIcon from "../../../assets/trash-icon.svgr";
import { selectReadonly } from "../../../reducers/auth-reducer";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import { selectVenueError } from "../../../reducers/venues";
import { State, VenueSettingDto } from "../../../store/types";
import {
  durationSlotsToTime,
  formatTimeForCalendar,
  formatTimeWindowForCalendar,
  getBookingDuration,
} from "../../../utils/formats";
import Panel from "../../common/Panel";
import VenueMainActions from "../VenueMainActions";
import EditAdminReservationsSettings from "./EditAdminReservationsSettings/EditAdminReservationsSettings";
import EditCalendarRangeSettings from "./EditCalendarRangeSettings/EditCalendarRangeSettings";
import EditSkipGuestDetailsSettings from "./EditSkipGuestDetailsSettings/EditSkipGuestDetailsSettings";
import "./hostSettings.scss";

export const timeIntervals: IDropdownOption[] = [
  { key: 5, text: "5 minutes" },
  { key: 10, text: "10 minutes" },
  { key: 15, text: "15 minutes" },
  { key: 20, text: "20 minutes" },
  { key: 25, text: "25 minutes" },
  { key: 30, text: "30 minutes" },
  { key: 35, text: "35 minutes" },
  { key: 40, text: "40 minutes" },
  { key: 45, text: "45 minutes" },
  { key: 50, text: "50 minutes" },
  { key: 55, text: "55 minutes" },
  { key: 60, text: "60 minutes" },
];
export const defaultZoomOptions: IDropdownOption[] = [
  { key: 1, text: "Compact" },
  { key: 2, text: "Normal" },
  { key: 3, text: "Tall" },
];

interface VenueItem {
  name: string;
  value: string;
}

const mapStateToProps = (state: State) => ({
  error: selectVenueError(state),
  uiConfig: selectUIConfig(state),
  readonly: selectReadonly(state),
});

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & {
  isMiddleScreen: boolean;
  venue?: VenueSettingDto;
  editVenue: () => void;
};

const renderListItem = (item: VenueItem, index: number) => (
  <div key={index} className="venue-item">
    <div className="venue-item-name subtitle2">{item.name}</div>
    <div className="venue-item-value body2">{item.value}</div>
  </div>
);

const HostSettings = ({
  isMiddleScreen,
  venue,
  uiConfig,
  readonly,
  updateVenue,
}: Props) => {
  const actionText = uiConfig?.actionText || "Lane";
  const twelveHourClockFormat = !!uiConfig?.twelveHourClockFormat;
  const [
    isOpenEditAdminReservationsSettings,
    setIsOpenEditAdminReservationsSettings,
  ] = useState(false);
  const [isOpenEditCalendarRangeSettings, setIsOpenEditCalendarRangeSettings] =
    useState(false);
  const [
    isOpenEditSkipGuestDetailsSettings,
    setIsOpenEditSkipGuestDetailsSettings,
  ] = useState(false);
  const [groupResourcesSideBySide, setGroupResourcesSideBySide] =
    useState<boolean>(!!venue?.groupResourcesSideBySide);
  const [autoCompleteReservations, setAutoCompleteReservations] =
    useState<boolean>(!!venue?.autoCompleteReservations);
  const [autoNoShowReservations, setAutoNoShowReservations] =
    useState<boolean>(!!venue?.autoNoShowReservations);
  const [showSkipPaymentInHost, setShowSkipPaymentInHost] = useState<boolean>(
    venue?.showSkipPaymentInHost || false
  );
  const [showPaymentLinkInHost, setShowPaymentLinkInHost] = useState<boolean>(
    venue?.showPaymentLinkInHost || false
  );
  const [showRecordCashInHost, setShowRecordCashInHost] = useState<boolean>(
    venue?.showRecordCashInHost || false
  );
  const [showGiftCardInHost, setShowGiftCardInHost] = useState<boolean>(
    venue?.showGiftCardInHost || false
  );
  const [showManuallyEnterCardInHost, setShowManuallyEnterCardInHost] =
    useState<boolean>(venue?.showManuallyEnterCardInHost || false);

  const [showManagersCompInHost, setShowMangersCompInHost] =
    useState<boolean>(venue?.showManagersCompInHost || false);
  const [showCardReaderInHost, setShowCardReaderInHost] =
    useState<boolean>(venue?.showCardReaderInHost || false);

  const [showStripeInvoiceInHost, setShowStripeInvoiceInHost] =
    useState<boolean>(venue?.showStripeInvoiceInHost || false);

  const [enableReservationTimer, setEnableReservationTimer] = useState(
    venue?.enableReservationTimer
  );
  const [reservationTimerAlert, setReservationTimerAlert] = useState(
    venue?.reservationTimerAlert
  );
  const [reservationTimerInterval, setReservationTimerInterval] = useState(
    venue?.reservationTimerInterval
  );
  const [calendarZoomLevel, setCalendarZoomLevel] = useState(
    venue?.calendarZoomLevel
  );
  const [quickChatText, setQuickChatText] = useState(
    venue?.quickChatText || ""
  );
  const durationTimesForAdmin =
    durationSlotsToTime(
      venue?.durationInSlotsForAdmin,
      venue?.timeSlotDuration
    ) || [];
  const durationsForAdmin = `${venue?.durationInSlotsForAdmin
    } (${durationTimesForAdmin.join(",")})`;
  const weeksForBookingForAdmin = getBookingDuration(
    venue?.daysForBookingForAdmin
  );

  const [markAsPaidSourceOptions, setMarkAsPaidSourceOptions] = useState<string[]>(
    venue?.markAsPaidSourceOptions ? venue.markAsPaidSourceOptions.split(",") : []
  )
  const [stripeTerminalOptions, setStripeTerminalOptions] = useState<{ name: string, id: string }[]>(
    venue?.stripeTerminalOptions
      ? venue.stripeTerminalOptions.split(";").map(option => {
        const [name, id] = option.split(",");
        return { name, id };
      })
      : []
  );


  const adminReservationSettingsTitle = (
    <>
      <div className="h6">Admin Reservation Settings</div>
      <LinkButton onClick={() => setIsOpenEditAdminReservationsSettings(true)}>
        Edit
      </LinkButton>
    </>
  );
  const adminReservationSettings: VenueItem[] = [
    {
      name: "Duration Options for admin",
      value: venue?.durationInSlotsForAdmin ? durationsForAdmin : "-",
    },
    {
      name: `Max number of ${actionText.toLowerCase()}s`,
      value: venue?.maxNumberOfLanes ? venue.maxNumberOfLanes.toString() : "-",
    },
    {
      name: "Max guests per reservation for admin",
      value: venue?.maxGuestsForAdmin
        ? venue.maxGuestsForAdmin.toString()
        : "-",
    },
    {
      name: "How far in advance can admins book?",
      value: weeksForBookingForAdmin || "-",
    },
    {
      name: "Cut off time for admin",
      value: venue?.allowedReservationIntervalForAdmin
        ? venue.allowedReservationIntervalForAdmin.toString()
        : "-",
    },
    {
      name: "Ignore Staff Capacity",
      value: !!venue?.ignoreStaffCapacityForAdmin ? "on" : "off",
    },
  ];
  const calendarRangeSettingsTitle = (
    <>
      <div className="h6">Calendar Range Settings</div>
      <LinkButton onClick={() => setIsOpenEditCalendarRangeSettings(true)}>
        Edit
      </LinkButton>
    </>
  );
  const calendarRangeSettings: VenueItem[] = [
    {
      name: "Calendar Start Time",
      value: formatTimeForCalendar(
        twelveHourClockFormat,
        venue?.calendarStartTime
      ),
    },
    {
      name: "Calendar End Time",
      value: formatTimeForCalendar(
        twelveHourClockFormat,
        venue?.calendarEndTime
      ),
    },
    {
      name: "Calendar Time Window",
      value: formatTimeWindowForCalendar(venue?.calendarTimeWindow || 0),
    },
    {
      name: "Calendar Zoom Level",
      value: `${venue?.calendarZoomLevel || 1}`,
    },
  ];
  const skipGuestDetailsTitle = (
    <>
      <div className="h6">Skip Guest Details</div>
      <LinkButton onClick={() => setIsOpenEditSkipGuestDetailsSettings(true)}>
        Edit
      </LinkButton>
    </>
  );
  const skipGuestDetailsSettings: VenueItem[] = [
    { name: "Enable Skip", value: venue?.skipGuestName ? "on" : "off" },
    { name: "Email", value: venue?.defaultEmailForSkip || "-" },
    { name: "Phone Number", value: venue?.defaultPhoneForSkip || "-" },
    { name: "First Name", value: venue?.defaultFirstNameForSkip || "-" },
    { name: "Last Name", value: venue?.defaultLastNameForSkip || "-" },
  ];
  const assignLogicTitle = (
    <>
      <div className="h6">Assign Logic</div>
    </>
  );

  const timerAlertTitle = <div className="h6">Timer Alert</div>;
  const quickTextsTitle = <div className="h6">Quick Texts</div>;
  const defaultZoomText = <div className="h6">Default Zoom</div>;
  const paymentOptionsTitle = (
    <>
      <div className="h6">Payment Options</div>
    </>
  );

  interface Tag {
    tag: string;
    button: string;
    priority: number;
  }
  const toTags = (data: string): Tag[] =>
    data.split(";").map((d) => {
      const [tag, button, priority] = d.split(":");
      return { tag, button, priority: priority ? +priority : 0 };
    });
  const toQuick = (data: Tag[]) =>
    data
      .map(({ tag, button, priority }) => `${tag}:${button}:${priority}`)
      .join(";");

  const onRemoveTag = (index: number) => {
    let tags = toTags(quickChatText);
    tags.splice(index, 1);
    setQuickChatText(toQuick(tags));
  };

  const onUpdateTag = (index: number, tag: Tag) => {
    const tags = toTags(quickChatText);
    setQuickChatText(
      toQuick(tags.map((item, i) => (i === index ? tag : item)))
    );
  };

  const onUpdateAssignLogic = () => {
    if (venue) {
      updateVenue({
        ...venue,
        groupResourcesSideBySide,
        autoCompleteReservations,
        autoNoShowReservations,
      });
    }
  };

  const onUpdateEnableTimer = () => {
    if (venue) {
      updateVenue({
        ...venue,
        enableReservationTimer,
        reservationTimerAlert,
        reservationTimerInterval,
      });
    }
  };
  const onUpdateQuickMessage = () => {
    if (venue) {
      updateVenue({ ...venue, quickChatText });
    }
  };

  const onUpdatePaymentOptions = () => {
    if (venue) {
      updateVenue({
        ...venue,
        showSkipPaymentInHost,
        showPaymentLinkInHost,
        showRecordCashInHost,
        showManagersCompInHost,
        showGiftCardInHost,
        showManuallyEnterCardInHost,
        showCardReaderInHost,
        showStripeInvoiceInHost
      });
    }
  };
  const onUpdateZoomLevel = () => {
    if (venue) {
      updateVenue({
        ...venue,
        calendarZoomLevel,
      });
    }
  };
  const onRemoveMarkAsPaidSource = (index: number) => () => {
    const newOptions = [...markAsPaidSourceOptions];
    newOptions.splice(index, 1);
    setMarkAsPaidSourceOptions(newOptions);
  }
  const onUpdateMarkAsPaidSource = () => {

    if (venue) {
      updateVenue({
        ...venue,
        markAsPaidSourceOptions: markAsPaidSourceOptions
          .filter(option => option !== '')
          .join(","),
      });
    }
  }
  const onAddMarkAsPaidSource = () => {
    setMarkAsPaidSourceOptions([...markAsPaidSourceOptions, ""])

  }

  const onRemoveReaderOption = (index: number) => () => {
    const newOptions = [...stripeTerminalOptions];
    newOptions.splice(index, 1);
    setStripeTerminalOptions(newOptions);
  };
  const onUpdateReaderOptions = () => {
    if (venue) {
      updateVenue({
        ...venue,
        stripeTerminalOptions: stripeTerminalOptions.map(({ name, id }) => `${name},${id}`).join(";"),
      });
    }
  };
  const onAddReaderOption = () => {
    setStripeTerminalOptions([...stripeTerminalOptions, { name: "", id: "" }]);
  };

  const onChangeReaderOptionName = (index: number, value: string) => {
    const newOptions = [...stripeTerminalOptions];
    newOptions[index] = { ...newOptions[index], name: value };
    setStripeTerminalOptions(newOptions);
  };

  const onChangeReaderOptionId = (index: number, value: string) => {
    const newOptions = [...stripeTerminalOptions];
    newOptions[index] = { ...newOptions[index], id: value };
    setStripeTerminalOptions(newOptions);
  };
  return (
    <div className={`host-settings ${isMiddleScreen ? "mobile" : ""}`}>
      <div className="left-panel">
        {isMiddleScreen && <VenueMainActions />}
        <Panel
          className={`panel-shift ${isMiddleScreen ? "mobile" : "panel-with-two-columns"
            }`}
          title={adminReservationSettingsTitle}
        >
          {adminReservationSettings.map((item, index) =>
            renderListItem(item, index)
          )}
        </Panel>
        <Panel
          className={`panel-shift ${isMiddleScreen ? "mobile" : "panel-with-two-columns"
            }`}
          title={calendarRangeSettingsTitle}
        >
          {calendarRangeSettings.map((item, index) =>
            renderListItem(item, index)
          )}
        </Panel>
        <Panel
          className={`panel-shift ${isMiddleScreen ? "mobile" : "panel-with-two-columns"
            }`}
          title={skipGuestDetailsTitle}
        >
          {skipGuestDetailsSettings.map((item, index) =>
            renderListItem(item, index)
          )}
        </Panel>
        <Panel className="panel-shift" title={paymentOptionsTitle}>
          <div className={`fields-container ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              label="Show Manually Enter Card"
              className="row"
              checked={showManuallyEnterCardInHost}
              onChange={(_: any, value?: boolean) =>
                setShowManuallyEnterCardInHost(value || false)
              }
              inlineLabel
              disabled={readonly}
            />
            <Toggle
              label="Show Mark as Paid"
              className="row"
              checked={showRecordCashInHost}
              onChange={(_: any, value?: boolean) =>
                setShowRecordCashInHost(value || false)
              }
              inlineLabel
              disabled={readonly}
            />


            {showRecordCashInHost ? <Panel className="panel-shift" >
              <div className="fields-container">

                {markAsPaidSourceOptions.map((item, index) => (
                  <div
                    className={`fields-mapping ${isMiddleScreen ? "mobile" : ""
                      }`}
                    key={index}
                  >
                    <FormTextField
                      label="Mark as Paid Source"
                      className="field"
                      value={item}
                      onChange={(_: any, value?: string) => {
                        const newOptions = [...markAsPaidSourceOptions];
                        newOptions[index] = value || "";
                        setMarkAsPaidSourceOptions(newOptions);
                      }}
                      autoComplete="off"
                      disabled={readonly}
                    />

                    <IconButton
                      className="delete-button single-button"
                      ariaLabel="Remove mark as paid source"
                      onClick={
                        onRemoveMarkAsPaidSource(index)
                      }
                    >
                      <TrashIcon />
                    </IconButton>
                  </div>
                ))}
                <div className={isMiddleScreen ? "mobile" : ""}>
                  <LinkButton
                    className="add-button"
                    onClick={onAddMarkAsPaidSource}
                  >
                    <Icon iconName="Add" className="add" />
                    Add source
                  </LinkButton>
                </div>
                <div className={isMiddleScreen ? "mobile" : ""}>
                  <ColorButton
                    className="save-button"
                    onClick={onUpdateMarkAsPaidSource}
                    disabled={readonly}
                  >
                    Save Changes
                  </ColorButton>
                </div>
              </div>

            </Panel> : null}

            <Toggle
              label="Show Gift Card or Promo Code"
              className="row"
              checked={showGiftCardInHost}
              onChange={(_: any, value?: boolean) =>
                setShowGiftCardInHost(value || false)
              }
              inlineLabel
              disabled={readonly}
            />
            <Toggle
              label="Show Payment Link"
              className="row"
              checked={showPaymentLinkInHost}
              onChange={(_: any, value?: boolean) =>
                setShowPaymentLinkInHost(value || false)
              }
              inlineLabel
              disabled={readonly}
            />
            <Toggle
              label="Show Skip Payment"
              className="row"
              checked={showSkipPaymentInHost}
              onChange={(_: any, value?: boolean) =>
                setShowSkipPaymentInHost(value || false)
              }
              inlineLabel
              disabled={readonly}
            />

            <Toggle
              label="Show Managers Comp"
              className="row"
              checked={showManagersCompInHost}
              onChange={(_: any, value?: boolean) =>
                setShowMangersCompInHost(value || false)
              }
              inlineLabel
              disabled={readonly}
            />
            <Toggle
              label="Show Card Reader"
              className="row"
              checked={showCardReaderInHost}
              onChange={(_: any, value?: boolean) =>
                setShowCardReaderInHost(value || false)
              }
              inlineLabel
              disabled={readonly}
            />
            {showCardReaderInHost ? (
              <Panel className="panel-shift">
                <div className="fields-container">
                  {stripeTerminalOptions.map((item, index) => (
                    <div
                      className={`fields-mapping ${isMiddleScreen ? "mobile" : ""
                        }`}
                      key={index}
                    >
                      <FormTextField
                        label="Reader Name"
                        className="field"
                        value={item.name}
                        onChange={(_: any, value?: string) =>
                          onChangeReaderOptionName(index, value || "")
                        }
                        autoComplete="off"
                        disabled={readonly}
                      />
                      <FormTextField
                        label="Reader ID"
                        className="field"
                        value={item.id}
                        onChange={(_: any, value?: string) =>
                          onChangeReaderOptionId(index, value || "")
                        }
                        autoComplete="off"
                        disabled={readonly}
                      />

                      <IconButton
                        className="delete-button single-button"
                        ariaLabel="Remove reader option"
                        onClick={onRemoveReaderOption(index)}
                      >
                        <TrashIcon />
                      </IconButton>
                    </div>
                  ))}
                  <div className={isMiddleScreen ? "mobile" : ""}>
                    <LinkButton
                      className="add-button"
                      onClick={onAddReaderOption}
                    >
                      <Icon iconName="Add" className="add" />
                      Add Reader
                    </LinkButton>
                  </div>
                  <div className={isMiddleScreen ? "mobile" : ""}>
                    <ColorButton
                      className="save-button"
                      onClick={onUpdateReaderOptions}
                      disabled={readonly}
                    >
                      Save Changes
                    </ColorButton>
                  </div>
                </div>
              </Panel>
            ) : null}
            <Toggle
              label="Show Stripe Invoice"
              className="row"
              checked={showStripeInvoiceInHost}
              onChange={(_: any, value?: boolean) =>
                setShowStripeInvoiceInHost(value || false)
              }
              inlineLabel
              disabled={readonly}
            />
            <div className={isMiddleScreen ? "mobile" : ""}>
              <ColorButton
                className="save-button"
                onClick={onUpdatePaymentOptions}
                disabled={readonly}
              >
                Save Changes
              </ColorButton>
            </div>
          </div>
        </Panel>
        <Panel className="panel-shift" title={assignLogicTitle}>
          <div className={`fields-container ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              label="Group Side by Side"
              className="row"
              checked={groupResourcesSideBySide}
              onChange={(_: any, value?: boolean) =>
                setGroupResourcesSideBySide(value || false)
              }
              inlineLabel
              disabled={readonly}
            />
            <Toggle
              label="Auto Complete Reservations"
              className="row"
              checked={autoCompleteReservations}
              onChange={(_: any, value?: boolean) =>
                setAutoCompleteReservations(value || false)
              }
              inlineLabel
              disabled={readonly}
            />
            <Toggle
              label="Auto No Show Reservations"
              className="row"
              checked={autoNoShowReservations}
              onChange={(_: any, value?: boolean) =>
                setAutoNoShowReservations(value || false)
              }
              inlineLabel
              disabled={readonly}
            />
            <div className={isMiddleScreen ? "mobile" : ""}>
              <ColorButton
                className="save-button"
                onClick={onUpdateAssignLogic}
                disabled={readonly}
              >
                Save Changes
              </ColorButton>
            </div>
          </div>
        </Panel>
        <Panel className="panel-shift panel-timer" title={timerAlertTitle}>
          <div className={`fields-container ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              label="Enable Timer"
              className="row"
              checked={enableReservationTimer}
              onChange={(_: any, value?: boolean) =>
                setEnableReservationTimer(value || false)
              }
              inlineLabel
              disabled={readonly}
            />
            <FormSelectField
              options={timeIntervals}
              selectedKey={reservationTimerInterval || "Timer Alert"}
              onChange={(_: any, option) => {
                setReservationTimerInterval(option?.key as number);
              }}
              disabled={!enableReservationTimer}
            />
            <FormTextField
              label="Timer alert"
              className="field"
              value={reservationTimerAlert}
              onChange={(_: any, value?: string) =>
                setReservationTimerAlert(value || "")
              }
              autoComplete="off"
              disabled={!enableReservationTimer}
            />

            <div className={isMiddleScreen ? "mobile" : ""}>
              <ColorButton
                className="save-button"
                onClick={onUpdateEnableTimer}
                disabled={readonly}
              >
                Save Changes
              </ColorButton>
            </div>
          </div>
        </Panel>
        <Panel
          className="panel-shift panel-info panel-texts"
          title={quickTextsTitle}
        >
          {toTags(quickChatText).map((item, index) => (
            <div
              className={`fields-mapping ${isMiddleScreen ? "mobile" : ""}`}
              key={index}
            >
              <FormTextField
                label="Button name"
                className="field"
                value={item.button}
                onChange={(_: any, value?: string) =>
                  onUpdateTag(index, { ...item, button: value || "" })
                }
                autoComplete="off"
                disabled={readonly}
              />
              <FormTextField
                label="Text Copy"
                className="field"
                value={item.tag}
                onChange={(_: any, value?: string) =>
                  onUpdateTag(index, { ...item, tag: value || "" })
                }
                autoComplete="off"
                disabled={readonly}
              />
              <FormTextField
                className="field priority-field"
                label="Priority"
                type="number"
                value={item.priority + ""}
                onChange={(_: any, value?: string) =>
                  onUpdateTag(index, { ...item, priority: value ? +value : 0 })
                }
                autoComplete="off"
                disabled={readonly}
              />
              <IconButton
                className="delete-button"
                ariaLabel="Remove Text Message"
                onClick={() => onRemoveTag(index)}
              >
                <TrashIcon />
              </IconButton>
            </div>
          ))}
          <div className={isMiddleScreen ? "mobile" : ""}>
            <LinkButton
              className="add-button"
              onClick={() => setQuickChatText(`${quickChatText};::1`)}
            >
              <Icon iconName="Add" className="add" />
              Add new message
            </LinkButton>
          </div>
          <div className={isMiddleScreen ? "mobile" : ""}>
            <ColorButton
              className="save-button"
              onClick={onUpdateQuickMessage}
              disabled={readonly}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
        <Panel className="panel-shift" title={defaultZoomText}>
          <div className={`fields-container ${isMiddleScreen ? "mobile" : ""}`}>
            <FormSelectField
              placeholder="Select default zoom level"
              options={defaultZoomOptions}
              selectedKey={calendarZoomLevel || 1}
              onChange={(_: any, option) => {
                setCalendarZoomLevel(option?.key as number);
              }}
              className="panel-select-text-field"
            />

            <div className={isMiddleScreen ? "mobile" : ""}>
              <ColorButton
                className="save-button"
                onClick={onUpdateZoomLevel}
                disabled={readonly}
              >
                Save Changes
              </ColorButton>
            </div>
          </div>
        </Panel>
      </div>
      <div className={`right-panel ${isMiddleScreen ? "mobile" : ""}`}>
        {!isMiddleScreen && <VenueMainActions />}
      </div>
      {isOpenEditAdminReservationsSettings && (
        <EditAdminReservationsSettings
          onClose={() => setIsOpenEditAdminReservationsSettings(false)}
        />
      )}
      {isOpenEditCalendarRangeSettings && (
        <EditCalendarRangeSettings
          onClose={() => setIsOpenEditCalendarRangeSettings(false)}
        />
      )}
      {isOpenEditSkipGuestDetailsSettings && (
        <EditSkipGuestDetailsSettings
          onClose={() => setIsOpenEditSkipGuestDetailsSettings(false)}
        />
      )}
    </div>
  );
};

export default connector(HostSettings);
