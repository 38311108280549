import { IDropdownOption } from "office-ui-fabric-react";
import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import ColorButton from "../../../../common/components/ColorButton";
import FormTextField from "../../../../common/components/FormTextField";
import { updateConfigsAction } from "../../actions/configs-actions";
import { getPartnersAction } from "../../actions/partners-actions";
import { getTenantsAction, selectTenantAction } from "../../actions/tenants-actions";
import useTransferSync from "../../hooks/useTransferSync";
import { selectConfigs } from "../../reducers/configs";
import { selectIsTenantRequestInProgress, selectTenants } from "../../reducers/tenants";
import { State } from "../../store/types";
import Panel from "../common/Panel";
import "./superAdminSettings.scss";

const mapDispatchToProps = {
  updateConfigs: updateConfigsAction,
  getTenants: getTenantsAction,
  setTenant: selectTenantAction,
  getPartners: getPartnersAction,
};

const mapStateToProps = (state: State) => ({
  config: selectConfigs(state),
  tenants: selectTenants(state),
  isTenantRequestInProgress: selectIsTenantRequestInProgress(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const SuperAdminSettings = ({ config, updateConfigs, tenants }: Props) => {
  const [settingsForm, setSettingsForm] = useState({
    aboutGolfUrl: config?.aboutGolfUrl || "",
    aboutGolfApiVersion: config?.aboutGolfApiVersion || "",
    aboutGolfApiKey: config?.aboutGolfApiKey || "",
    aboutGolfSubscriptionKey: config?.aboutGolfSubscriptionKey || "",
    goTabApiAccessId: config?.goTabApiAccessId || "",
    goTabApiAccessSecret: config?.goTabApiAccessSecret || "",
    mchClientId: config?.mchClientId || "",
    mchClientSecret: config?.mchClientSecret || "",
    squareClientId: config?.squareClientId || "",
    squareClientSecret: config?.squareClientSecret || "",
    squareAccessToken: config?.squareAccessToken || "",
    stripePublicKey: config?.stripePublicKey || "",
    stripeSecretKey: config?.stripeSecretKey || "",
    stripeWebhookSecret: config?.stripeWebhookSecret || "",
    sandboxStripePublicKey: config?.sandboxStripePublicKey || "",
    sandboxStripeSecretKey: config?.sandboxStripeSecretKey || "",
    stripeClientId: config?.stripeClientId || "",
    sandboxStripeClientId: config?.sandboxStripeClientId || "",
    atomicGolfUrl: config?.atomicGolfUrl || "",
    atomicGolfClientId: config?.atomicGolfClientId || "",
    atomicGolfClientSecret: config?.atomicGolfClientSecret || "",
    atomicGolfTenantId: config?.atomicGolfTenantId || "",
    atomicGolfCustomFieldForCheckIn: config?.atomicGolfCustomFieldForCheckIn || "",
  });

  const onUpdateSettingsForm = (e: any, newValue?: string) => {
    setSettingsForm({
      ...settingsForm,
      [e.target.name]: newValue,
    });
  };

  const onSaveSettings = () => updateConfigs(settingsForm);
  const [open, setOpen] = useState(false);
  const [tenantId, setTenantId] = useState<string | number>('')
  const [isProd, setIsProd] = useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { sync, isSyncLoading, getHistory, syncLog } = useTransferSync({
    successFunc: () => { setOpen(false); },
    errorFunc: () => setOpen(false)
  })
  const handleConfirm = async () => {
    sync(tenantId)
  };


  const tenantsForDropdown = tenants.map((item) => ({ key: item.id, text: item.name }));
  const domain = tenants.find((item) => item.id === tenantId)?.domain || ''
  const onTenantChange = (event: any, option: IDropdownOption | undefined) => {
    if (!option || !option.key) return null;
    const tenantId = String(option.key);
    setTenantId(tenantId)
    getHistory(tenantId)
  };

  return (
    <div className="super-admin-settings-container">
      <div className="h4 super-admin-settings-title">Settings</div>
      <div className="super-admin-settings">
        <div className="super-admin-settings-information-container">
          <div className="super-admin-settings-information">
            <Panel
              className="panel-shift"
              title={<div className="h6">About Golf</div>}
            >
              <div className="row">
                <FormTextField
                  label="Url"
                  className="super-admin-field"
                  value={settingsForm.aboutGolfUrl}
                  name="aboutGolfUrl"
                  onChange={() => onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <FormTextField
                  label="Api Version"
                  className="super-admin-field"
                  value={settingsForm.aboutGolfApiVersion}
                  name="aboutGolfApiVersion"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <FormTextField
                  label="Api Key"
                  className="super-admin-field"
                  value={settingsForm.aboutGolfApiKey}
                  name="aboutGolfApiKey"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <FormTextField
                  label="Subscription Key"
                  className="super-admin-field"
                  value={settingsForm.aboutGolfSubscriptionKey}
                  name="aboutGolfSubscriptionKey"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <ColorButton className="save-button" onClick={onSaveSettings}>
                  Save Changes
                </ColorButton>
              </div>
            </Panel>
            <Panel
              className="panel-shift"
              title={<div className="h6">GoTab</div>}
            >
              <div className="row">
                <FormTextField
                  label="Api Access ID"
                  className="super-admin-field"
                  value={settingsForm.goTabApiAccessId}
                  name="goTabApiAccessId"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <FormTextField
                  label="Api Access Secret"
                  className="super-admin-field"
                  value={settingsForm.goTabApiAccessSecret}
                  name="goTabApiAccessSecret"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <ColorButton className="save-button" onClick={onSaveSettings}>
                  Save Changes
                </ColorButton>
              </div>
            </Panel>
            <Panel
              className="panel-shift"
              title={<div className="h6">Mailchimp</div>}
            >
              <div className="row">
                <FormTextField
                  label="Client ID"
                  className="super-admin-field"
                  value={settingsForm.mchClientId}
                  name="mchClientId"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <FormTextField
                  label="Client Secret"
                  className="super-admin-field"
                  value={settingsForm.mchClientSecret}
                  name="mchClientSecret"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <ColorButton className="save-button" onClick={onSaveSettings}>
                  Save Changes
                </ColorButton>
              </div>
            </Panel>
            <Panel
              className="panel-shift"
              title={<div className="h6">Square</div>}
            >
              <div className="row">
                <FormTextField
                  label="Access Token"
                  className="super-admin-field"
                  value={settingsForm.squareAccessToken}
                  name="squareAccessToken"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <FormTextField
                  label="Client Id"
                  className="super-admin-field"
                  value={settingsForm.squareClientId}
                  name="squareClientId"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <FormTextField
                  label="Client Secret"
                  className="super-admin-field"
                  value={settingsForm.squareClientSecret}
                  name="squareClientSecret"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <ColorButton className="save-button" onClick={onSaveSettings}>
                  Save Changes
                </ColorButton>
              </div>
            </Panel>
            <Panel
              className="panel-shift"
              title={<div className="h6">Stripe</div>}
            >
              <div className="row">
                <FormTextField
                  label="Stripe Public Key"
                  className="super-admin-field"
                  value={settingsForm.stripePublicKey}
                  name="stripePublicKey"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <FormTextField
                  label="Stripe Secret Key"
                  className="super-admin-field"
                  value={settingsForm.stripeSecretKey}
                  name="stripeSecretKey"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <FormTextField
                  label="Stripe Webhook Secret"
                  className="super-admin-field"
                  value={settingsForm.stripeWebhookSecret}
                  name="stripeWebhookSecret"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <FormTextField
                  label="Stripe Client Id"
                  className="super-admin-field"
                  value={settingsForm.stripeClientId}
                  name="stripeClientId"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <ColorButton className="save-button" onClick={onSaveSettings}>
                  Save Changes
                </ColorButton>
              </div>
            </Panel>
            <Panel
              className="panel-shift"
              title={<div className="h6">Stripe Sandbox</div>}
            >
              <div className="row">
                <FormTextField
                  label="Stripe Public Key"
                  className="super-admin-field"
                  value={settingsForm.sandboxStripePublicKey}
                  name="sandboxStripePublicKey"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <FormTextField
                  label="Stripe Secret Key"
                  className="super-admin-field"
                  value={settingsForm.sandboxStripeSecretKey}
                  name="sandboxStripeSecretKey"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <FormTextField
                  label="Stripe Client Id"
                  className="super-admin-field"
                  value={settingsForm.sandboxStripeClientId}
                  name="sandboxStripeClientId"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <ColorButton className="save-button" onClick={onSaveSettings}>
                  Save Changes
                </ColorButton>
              </div>
            </Panel>
            <Panel
              className="panel-shift"
              title={<div className="h6">Atomic Golf</div>}
            >
              <div className="row">
                <FormTextField
                  label="Url"
                  className="super-admin-field"
                  value={settingsForm.atomicGolfUrl}
                  name="atomicGolfUrl"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <FormTextField
                  label="Client Id"
                  className="super-admin-field"
                  value={settingsForm.atomicGolfClientId}
                  name="atomicGolfClientId"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <FormTextField
                  label="Client Secret"
                  className="super-admin-field"
                  value={settingsForm.atomicGolfClientSecret}
                  name="atomicGolfClientSecret"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <FormTextField
                  label="Tenant Id"
                  className="super-admin-field"
                  value={settingsForm.atomicGolfTenantId}
                  name="atomicGolfTenantId"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <FormTextField
                  label="Tripleseat Custom Field for Check In"
                  className="super-admin-field"
                  value={settingsForm.atomicGolfCustomFieldForCheckIn}
                  name="atomicGolfCustomFieldForCheckIn"
                  onChange={onUpdateSettingsForm}
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <ColorButton className="save-button" onClick={onSaveSettings}>
                  Save Changes
                </ColorButton>
              </div>
            </Panel>

          </div>
        </div>
      </div >

    </div >
  );
};

export default connector(SuperAdminSettings);
