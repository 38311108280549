import React from "react";
import { Modal } from "office-ui-fabric-react";
import ColorButton from "../../../../../../common/components/ColorButton";
import LinkButton from "../../../../../../common/components/LinkButton";
import SettingsImage from "../../../common/Image";
import CancelButton from "../../../../../../common/components/CancelButton";
import "./editImages.scss";

type Props = {
  homeBackgroundImage: string,
  homeLogo: string,
  homeEmailLogo: string,
  homeFavicon: string,
  isImageLoadInProgress: boolean;
  isLogoLoadInProgress: boolean;
  isEmailLogoLoadInProgress: boolean;
  isFaviconLoadInProgress: boolean;
  uploadImageFile: (files: FileList | null) => void;
  uploadLogoFile: (files: FileList | null) => void;
  uploadEmailLogoFile: (files: FileList | null) => void;
  uploadFaviconFile: (files: FileList | null) => void;
  onClose: (email?: string) => void;
};

const EditImages = ({
  isImageLoadInProgress,
  isLogoLoadInProgress,
  isEmailLogoLoadInProgress,
  isFaviconLoadInProgress,
  uploadImageFile,
  uploadLogoFile,
  uploadEmailLogoFile,
  uploadFaviconFile,
  onClose,
  homeBackgroundImage,
  homeLogo,
  homeEmailLogo,
  homeFavicon,
}: Props) => {
  
  const onCancel = () => {
    onClose();
    return;
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={() => onClose()}
      isBlocking={false}
      containerClassName="images"
    >
      <div className="container">
        <div className="header h4">
          <div>Images</div>
          <CancelButton onClick={() => onClose()} />
        </div>
        <div className="image-fields-container">
          <div className="image-field">
            <div className="subtitle2">
              Homepage Banner
            </div>
            <SettingsImage image={homeBackgroundImage} loading={isImageLoadInProgress} uploadImage={uploadImageFile} subTitle="1440x820 pixels"/>
          </div>
          <div className="image-field">
            <div className="subtitle2">
              Logo
            </div>
            <SettingsImage image={homeLogo} loading={isLogoLoadInProgress} uploadImage={uploadLogoFile} subTitle="70px height (.png)"/>
          </div>
          <div className="image-field">
            <div className="subtitle2">
              Logo for Emails
            </div>
            <SettingsImage image={homeEmailLogo} loading={isEmailLogoLoadInProgress} uploadImage={uploadEmailLogoFile} subTitle="70px height (.png)"/>
          </div>
          <div className="image-field">
            <div className="subtitle2">
              Favicon
            </div>
            <SettingsImage image={homeFavicon} loading={isFaviconLoadInProgress} uploadImage={uploadFaviconFile} subTitle="200x200 pixels"/>
          </div>
        </div>
        <div className="buttons-container">
          <LinkButton onClick={() => onClose()}>Cancel</LinkButton>
          <ColorButton onClick={() => onCancel()}>Add Variant</ColorButton>
        </div>
      </div>
    </Modal>
  );
};

export default EditImages;
