import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  ActionButton,
  Checkbox,
  Toggle,
} from 'office-ui-fabric-react';
import { State, TimeSlotSettingDto, InventoryMethodTypes } from '../../../../store/types';
import { WeekDays } from '../../../../constants/timedate';
import { TimeSlotRow } from './TimeSlotRow/TimeSlotRow';
import { selectUIConfig } from '../../../../reducers/ui-reducer';
import { formatTimeFromIndex } from '../../../../utils/formats';
import { HOURS_NUMBER_FOR_DAY_SLOTS } from '../../../../constants/timeSlots';
import CopyTimeSlots from './CopyTimeSlots';
import './timeSlot.scss';
import StartTimeSettings from './StartTimeSettings';
import { GenerateSlotsParams } from '../../Packages/PackageDetails/EditSchedulePackage/TimeSlotPackage/TimeSlotPackage';

const mapStateToProps = (state: State) => ({
  uiConfig: selectUIConfig(state),
});

const connector = connect(mapStateToProps);

interface OwnProps {
  day: number;
  slots: TimeSlotSettingDto[];
  addSlot: () => void;
  updateSlot: (slot: TimeSlotSettingDto) => void;
  removeSlot: (slotId: string) => void;
  timeSlotDuration: number;
  timeShifting: number;
  inventoryMethod: InventoryMethodTypes;
  ageForPolicy: string;
  startTimeOnly: string;
  updateStartTimeOnly: (value: boolean, dayIndex: number) => void;
  twelveHourClockFormat: boolean;
  onCopy: (selectedDays: string[]) => void;
  onGenerateSchedule: (data: GenerateSlotsParams) => void;
}
type Props = ConnectedProps<typeof connector> & OwnProps;

const TimeSlot: React.FC<Props> = ({
  day,
  slots = [],
  addSlot,
  updateSlot,
  removeSlot,
  timeSlotDuration,
  uiConfig,
  timeShifting,
  inventoryMethod,
  ageForPolicy,
  startTimeOnly,
  updateStartTimeOnly,
  twelveHourClockFormat,
  onCopy,
  onGenerateSchedule // Get the prop here
}) => {
  const actionText = (uiConfig?.actionText || "lane").toLowerCase();

  const slotsInHour = 60 / timeSlotDuration;
  const hoursShifting = Math.floor(timeShifting / 60);
  const OPEN_SLOTS = Math.floor((HOURS_NUMBER_FOR_DAY_SLOTS - hoursShifting) * slotsInHour) + 1;

  const times = Array.from({ length: OPEN_SLOTS }, (_, index) => ({
    key: index,
    text: formatTimeFromIndex(index, timeSlotDuration, timeShifting, twelveHourClockFormat),
  }));


  const handleGenerateClick = (data: GenerateSlotsParams) => {
    onGenerateSchedule({
      ...data,
      day: WeekDays[day],
    });
  };

  return (
    <div className="time-slot">
      <div className="time-slot-header h6">
        <div className="time-slot-head">
          <div className="time-slot-heading">
            {WeekDays[day]}
          </div>
          <CopyTimeSlots currentDay={WeekDays[day]} onCopy={onCopy} />
        </div>
        <div className="time-slot-header-right-container">
          <Checkbox
            className="weekday-checkbox"
            label='Start time only'
            defaultChecked={startTimeOnly === 'true'}
            onChange={(_, isChecked) =>
              updateStartTimeOnly(!!isChecked, day)
            }
          />
          <StartTimeSettings
            times={times}
            timeSlotDuration={timeSlotDuration}
            inventoryMethod={inventoryMethod}
            currentDay={WeekDays[day]}
            onGenerateSchedule={handleGenerateClick} // Pass the function
          />
          <Toggle
            className="slots-toggle"
            checked={slots.length > 0}
            onChange={() => (slots.length === 0 ? addSlot() : slots.forEach(slot => removeSlot(slot.id)))}
          />
        </div>
      </div>
      {slots.length > 0 && (
        <div className="time-slot-rows">
          {slots.map(item => (
            <div key={item.id}>
              <TimeSlotRow
                slot={item}
                times={times}
                timeSlotDuration={timeSlotDuration}
                onChange={(slot, field, value) => updateSlot({ ...slot, [field]: value })}
                removeSlot={removeSlot}
                inventoryMethod={inventoryMethod}
                ageForPolicy={ageForPolicy}
              />
            </div>
          ))}
          <div className="time-slot-button">
            <ActionButton allowDisabledFocus onClick={addSlot}>
              + Add item
            </ActionButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default connector(TimeSlot);
