import React, { useState } from "react";
import { settingsConfigUI } from "../../constants/webConfigUI";
import ThemeDesign from "./ThemeDesign";
import GlobalSettings from "./GlobalSettings";
import Integrations from "./IntegrationsSettings";
import "./settings.scss";


const VenueDetails = () => {
  const [settingsPage, setSettingsPage] = useState<settingsConfigUI>(settingsConfigUI.THEME_DESIGN);
  
  const renderContent = () => {
    switch (settingsPage) {
      case settingsConfigUI.THEME_DESIGN:
        return (
          <ThemeDesign/>
        );

      case settingsConfigUI.GLOBAL:
        return (
          <GlobalSettings />
        );

      case settingsConfigUI.INTEGRATIONS:
        return (
          <Integrations />
        );
      default:
        return (
          <div />
        );
    }
  }
  const content = renderContent()

  return (
    <div className="settings-container">
      <div className="h4 settings-title">
          Settings
      </div>
      <div className="settings">
        <div className="settings-information-container">
          <div className="settings-toggle">
            <div
              className={`settings-toggle-label body2 ${settingsPage === settingsConfigUI.THEME_DESIGN ? "active" : ""}`}
              onClick={() => setSettingsPage(settingsConfigUI.THEME_DESIGN)}
            >
              Theme Design
            </div>
            <div
              className={`settings-toggle-label body2 ${settingsPage === settingsConfigUI.GLOBAL ? "active" : ""}`}
              onClick={() => setSettingsPage(settingsConfigUI.GLOBAL)}
            >
              Global
            </div>
            <div
              className={`settings-toggle-label body2 ${settingsPage === settingsConfigUI.INTEGRATIONS ? "active" : ""}`}
              onClick={() => setSettingsPage(settingsConfigUI.INTEGRATIONS)}
            >
              Integrations
            </div>
          </div>
          {content}
        </div>
      </div>
    </div>
  );
};

export default VenueDetails;
