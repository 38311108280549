import React, { useState, useEffect, useRef } from "react";
import { Modal } from "office-ui-fabric-react";
import CancelButton from "../../../../../common/components/CancelButton";
import FormTextField from "../../../../../common/components/FormTextField";
import LinkButton from "../../../../../common/components/LinkButton";
import ColorButton from "../../../../../common/components/ColorButton";
import {
  updatePartnerAction,
  setPartnerErrorAction,
} from "../../../actions/partners-actions";
import {
  selectIsPartnerRequestInProgress,
  selectPartnerError,
} from "../../../reducers/partners";
import { connect, ConnectedProps } from "react-redux";
import "./editPartner.scss";
import { State, PartnerDto } from "../../../store/types";

const mapDispatchToProps = {
  updatePartner: updatePartnerAction,
  setPartnerError: setPartnerErrorAction,
};
const mapStateToProps = (state: State) => ({
  isLoadingPartner: selectIsPartnerRequestInProgress(state),
  error: selectPartnerError(state),
});

interface OwnProps {
  onClose: () => void;
  partner?: PartnerDto;
}
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & OwnProps;

const EditPartner = ({
  updatePartner,
  onClose,
  setPartnerError,
  partner,
  isLoadingPartner,
  error,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    setPartnerError("");
  }, []);
  useEffect(() => {
    if (!isLoadingPartner && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingPartner]);
  useEffect(() => {
    setName(partner?.name || "");
  }, []);

  const [name, setName] = useState(partner?.name || "");

  const onSavePartner = (e: React.FormEvent) => {
    e.preventDefault();
    if (partner) {
      //todo: send patch only
      saving.current = true;
      updatePartner({ name, id: partner?.id });
    } else {
      setPartnerError("Update partner failure");
    }
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-partner"
    >
      <div className="title h4">
        Edit Partner
        <CancelButton onClick={onClose} />
      </div>
      <form className="form-partner" onSubmit={onSavePartner}>
        <FormTextField
          label="Name"
          className="row"
          value={name}
          onChange={(_: any, text?: string) => setName(text || "")}
          autoComplete="off"
          required={true}
        />

        {error && <div className="error">{error}</div>}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit">Save Change</ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditPartner);
