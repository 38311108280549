import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Modal,
  IDropdownOption,
  Toggle,
} from "office-ui-fabric-react";
import { setVenueErrorAction, updateVenueAction } from "../../../../actions/venue-actions";
import { selectVenue, selectIsLoadingVenue, selectVenueError } from "../../../../reducers/venues";
import { selectUIConfig } from "../../../../reducers/ui-reducer";
import { State } from "../../../../store/types";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import { bufferSlots } from "../../../../utils/formats";
import FormTextField from "../../../../../../common/components/FormTextField";
import FormSelectField from "../../../common/FormSelectField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import { regEmail } from "../../../../utils/validate";
import { bookingDurations } from "../../../../constants/bookingDurationOptions";
import { slotToTime } from "../../../../../../common/utils/formats";
import "./editReservationsSettings.scss";

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
  setVenueError: setVenueErrorAction,
};
const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  readonly: selectReadonly(state),
  error: selectVenueError(state),
  uiConfig: selectUIConfig(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditReservationsSettings = ({
  updateVenue,
  venue,
  readonly,
  error,
  isLoadingVenue,
  uiConfig,
  onClose,
  setVenueError,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    setVenueError("");
  }, []);
  useEffect(() => {
    if (!isLoadingVenue && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingVenue]);
  useEffect(() => {
    setAllowedReservationInterval(venue?.allowedReservationInterval);
    setDurationInSlots(venue?.durationInSlots);
    setGuestSplit(venue?.guestSplit);
    setMaxGuests(venue?.maxGuests);
    setDaysForBooking(venue?.daysForBooking);
    setTotalCountOfLanes(venue?.totalCountOfLanes);
    setEnableDayReport(venue?.enableDayReport);
    setDayReportEmails(venue?.dayReportEmails);
    setDayReportTime(venue?.dayReportTime);
    setBufferTimeForReservation(venue?.bufferTimeForReservation);
    setPreBufferTimeForReservation(venue?.preBufferTimeForReservation);
    setShiftingForReservationStart(venue?.shiftingForReservationStart);
    setTax(venue?.tax.toString() || "0.00");
    setErrorNotificationEmails(venue?.errorNotificationEmails);
  }, [venue]);
  const actionText = uiConfig?.actionText || "Lane";
  const [allowedReservationInterval, setAllowedReservationInterval] = useState(venue?.allowedReservationInterval);
  const [durationInSlots, setDurationInSlots] = useState(venue?.durationInSlots);
  const [guestSplit, setGuestSplit] = useState(venue?.guestSplit);
  const [maxGuests, setMaxGuests] = useState(venue?.maxGuests);
  const [daysForBooking, setDaysForBooking] = useState(venue?.daysForBooking);
  const [totalCountOfLanes, setTotalCountOfLanes] = useState(venue?.totalCountOfLanes);
  const [enableDayReport, setEnableDayReport] = useState(venue?.enableDayReport);
  const [dayReportEmails, setDayReportEmails] = useState(venue?.dayReportEmails);
  const [dayReportTime, setDayReportTime] = useState(venue?.dayReportTime);
  const [errorNotificationEmails, setErrorNotificationEmails] = useState(venue?.errorNotificationEmails);
  const [bufferTimeForReservation, setBufferTimeForReservation] = useState(venue?.bufferTimeForReservation);
  const [preBufferTimeForReservation, setPreBufferTimeForReservation] = useState(venue?.preBufferTimeForReservation);
  const [shiftingForReservationStart, setShiftingForReservationStart] = useState(venue?.shiftingForReservationStart);
  const [tax, setTax] = useState(venue?.tax.toString() || "0.00");

  const closed = venue?.closed || false;

  const bufferTimes = bufferSlots(venue?.timeSlotDuration);
  const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
  const dayReportTimes = Array(24).fill(null).map((_, key) => ({ key, text: slotToTime(key, 60, twelveHourClockFormat) }));

  const getPatch = () => {
    const newTax = tax ? parseFloat(tax) : 0;
    return {
      allowedReservationInterval: allowedReservationInterval || 0,
      durationInSlots: durationInSlots || `2, 3, 4`,
      totalCountOfLanes: totalCountOfLanes || 10,
      guestSplit: guestSplit || 1,
      maxGuests: maxGuests || 1,
      daysForBooking: daysForBooking || 0,
      enableDayReport: enableDayReport || false,
      dayReportEmails: dayReportEmails ? dayReportEmails.trim() : '',
      dayReportTime: dayReportTime || 0,
      bufferTimeForReservation: bufferTimeForReservation || 0,
      preBufferTimeForReservation: preBufferTimeForReservation || 0,
      shiftingForReservationStart: shiftingForReservationStart || 0,
      tax: newTax,
      errorNotificationEmails: errorNotificationEmails || '',
    };
  }

  const regEmailList = (emailList: string): boolean => {
    const separator = ',';
    const arrayOfEmails = emailList.split(separator);
    let isValid = true;
    arrayOfEmails.map(email => {
      const trimEmail = email.trim();
      if (regEmail.test(trimEmail) == false) {
        isValid = false;
      }
    });
    return isValid;
  }

  const onUpdateReservationsSettings = (e: React.FormEvent) => {
    e.preventDefault();
    if (dayReportEmails && !regEmailList(dayReportEmails)) {
      setVenueError("Please, enter valid email address");
      return;
    }
    if (venue &&
      guestSplit && guestSplit > 0 &&
      maxGuests && maxGuests > 0 &&
      durationInSlots &&
      (shiftingForReservationStart != undefined || shiftingForReservationStart != null)
      && shiftingForReservationStart >= 0
      && totalCountOfLanes && totalCountOfLanes > 0
    ) {
      saving.current = true;
      const patch = getPatch();
      updateVenue({ ...venue, ...patch });
    }
    if (!durationInSlots) {
      setVenueError("Duration options must be greater than 0");
    } else if (!guestSplit || guestSplit <= 0) {
      setVenueError(`Max guests on one ${actionText.toLowerCase()} must be greater than 0`);
    } else if (!maxGuests || maxGuests <= 0) {
      setVenueError("Max guests per reservation must be greater than 0");
    } else if (shiftingForReservationStart == undefined || shiftingForReservationStart == null || shiftingForReservationStart < 0) {
      setVenueError("Shifting for reservation start must be greater than or equal to 0");
    } else if (!totalCountOfLanes || totalCountOfLanes <= 0) {
      setVenueError(`Total # of ${actionText}s must be greater than 0`);
    }
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-reservations-settings"
    >
      <div className="title h4">
        Reservation Settings
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="reservations-settings" onSubmit={onUpdateReservationsSettings}>
        <div className="edit-reservations-fields">
          <FormTextField
            label="Cut off time"
            type="number"
            className="row"
            value={allowedReservationInterval?.toString()}
            onChange={(_: any, text?: string) => setAllowedReservationInterval(Number(text))}
            autoComplete="off"
            required={!closed}
            disabled={readonly}
          />
          <FormTextField
            label="Duration options (in slots)"
            className="row"
            value={durationInSlots}
            onChange={(_: any, text?: string) => setDurationInSlots(text)}
            autoComplete="off"
            required={!closed}
            disabled={readonly}
          />
          <FormTextField
            label={`Max guests on one ${actionText.toLowerCase()}`}
            type="number"
            className="row"
            value={guestSplit?.toString()}
            onChange={(_: any, text?: string) => setGuestSplit(Number(text))}
            autoComplete="off"
            required={!closed}
            disabled={readonly}
          />
          <FormTextField
            label="Max guests per reservation"
            type="number"
            className="row"
            value={maxGuests?.toString()}
            onChange={(_: any, text?: string) => setMaxGuests(Number(text))}
            autoComplete="off"
            required={!closed}
            disabled={readonly}
          />
          <FormTextField
            label={`Total # of ${actionText}s`}
            type="number"
            className="row"
            value={totalCountOfLanes?.toString()}
            onChange={(_: any, text?: string) => setTotalCountOfLanes(Number(text))}
            autoComplete="off"
            required={!closed}
            disabled={readonly}
          />
          <FormSelectField
            label="How far in advance can users book?"
            className="row"
            options={bookingDurations}
            selectedKey={daysForBooking}
            onChange={(
              _: React.FormEvent<HTMLDivElement>,
              option?: IDropdownOption
            ) => setDaysForBooking(!!option ? +option.key : 56)}
          />
          <FormSelectField
            label="Buffer time before reservation"
            className="row"
            options={bufferTimes}
            selectedKey={preBufferTimeForReservation}
            onChange={(
              _: React.FormEvent<HTMLDivElement>,
              option?: IDropdownOption
            ) => setPreBufferTimeForReservation(option?.key ? +option?.key : 0)}
          />
          <FormSelectField
            label="Buffer time after reservation"
            className="row"
            options={bufferTimes}
            selectedKey={bufferTimeForReservation}
            onChange={(
              _: React.FormEvent<HTMLDivElement>,
              option?: IDropdownOption
            ) => setBufferTimeForReservation(option?.key ? +option?.key : 0)}
          />
          <Toggle
            label="Daily Report"
            className="row"
            checked={enableDayReport}
            onChange={(_: any, value?: boolean) => setEnableDayReport(value)}
            inlineLabel
            disabled={readonly}
          />
          <FormTextField
            label="Daily Report Emails"
            className="row"
            value={dayReportEmails}
            onChange={(_: any, value?: string) =>
              setDayReportEmails(value || "")
            }
            autoComplete="off"
            disabled={!enableDayReport}
          />
          <FormSelectField
            label="Daily Report Time"
            className="row"
            options={dayReportTimes}
            selectedKey={dayReportTime}
            onChange={(
              _: React.FormEvent<HTMLDivElement>,
              item?: IDropdownOption
            ) => setDayReportTime(item?.key as number)}
            required={!closed}
            disabled={!enableDayReport}
          />

          <FormTextField
            label="Error notification emails"
            className="row"
            value={errorNotificationEmails?.toString()}
            onChange={(_: any, text?: string) => setErrorNotificationEmails(text || "")}
            autoComplete="off"
            required={!closed}
            disabled={readonly}
          />
          <FormTextField
            label="Link skip ahead (in days)"
            type="number"
            className="row"
            value={shiftingForReservationStart?.toString()}
            onChange={(_: any, text?: string) => setShiftingForReservationStart(Number(text))}
            autoComplete="off"
            required={!closed}
            disabled={readonly}
          />
          <FormTextField
            label="Sales Tax"
            type="number"
            className="row"
            value={tax}
            onChange={(_: any, text?: string) => setTax(text || "0.00")}
            autoComplete="off"
            max={100}
            required={!closed}
            disabled={readonly}
          />
        </div>
        {error && <div className="error">{error}</div>}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditReservationsSettings);
