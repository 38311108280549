import { capitalize, flatten, isEmpty } from "lodash";
import {
  ActionButton,
  Checkbox,
  Icon,
  IDropdownOption,
  Spinner,
  SpinnerSize,
  Toggle
} from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import ColorButton from "../../../../../common/components/ColorButton";
import FormTextField from "../../../../../common/components/FormTextField";
import LinkButton from "../../../../../common/components/LinkButton";
import {
  CreateMailchimpMappingDto,
  MailchimpMappingDto,
} from "../../../../../server/src/dto/mailchimpMapping.dto";
import { MchColumnMapping } from "../../../../../server/src/services/types/mailchimp";
import { addErrorAction } from "../../../actions/auth-actions";
import { getQuestionsAction } from "../../../actions/question-action";
import {
  changeTripleSeatStatus,
  checkStripeVenueAction,
  connectStripeVenueAction,
  createMailchimpMappingAction,
  createMailchimpTagsAction,
  disconnectStripeVenueAction,
  getGoTabProductsAndSpotsForVenueAction,
  getMailchimpListsInfoAction,
  getMailchimpMergeFieldsAction,
  getMailchimpTagsAction,
  getSquareLocationsAction,
  tripleSeatStatusToUIModel,
  uiModelToTripleSeatStatus,
  updateMailchimpMappingAction,
  updateMailchimpTagsAction,
  updateVenueAction
} from "../../../actions/venue-actions";
import TrashIcon from "../../../assets/trash-icon.svgr";
import { useViewport } from "../../../hooks/responsive";
import { selectIsFromSuperAdmin, selectReadonly } from "../../../reducers/auth-reducer";
import { selectQuestions } from "../../../reducers/questions";
import { selectSettingsState } from "../../../reducers/settings";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import {
  selectGoTabProducts,
  selectGoTabSpots,
  selectIsRequestInProgress,
  selectMailchimp,
  selectSquare,
  selectStripeConnectionStatus,
  selectVenue,
} from "../../../reducers/venues";
import {
  customFields,
  GoTabWorkflowType,
  MergeField,
  PaymentType,
  State,
  TripleSeatBlockedStatus,
  VenueSettingDto,
} from "../../../store/types";
import FormSelectField from "../../common/FormSelectField";
import Panel from "../../common/Panel";
import ConnectVenueStripeComponent from "../../Memberships/ConnectVenueStripeComponent";
import StripeVenueFields from "../../Memberships/StripeVenueFields";
import VenueMainActions from "../VenueMainActions";
import "./venueIntegrations.scss";
import { MailchimpTagDto } from "../../../../../server/src/dto/mailchimpTag.dto";



const mchMergeFieldAddress = {
  addr1: "Street Address",
  addr2: "Address Line 2",
  city: "City",
  state: "State/Prov/Region",
  zip: "ZIP/Postal",
  country: "Country",
};

const mchDefaultMembersField: IDropdownOption[] = [
  { key: "email_address", text: "Email Address" },
  { key: "status", text: "Status" },
  { key: "ip_signup", text: "Ip Signup" },
  { key: "timestamp_signup", text: "Timestamp Signup" },
  { key: "ip_opt", text: "Ip Opt" },
  { key: "timestamp_opt", text: "Timestamp Opt" },
  { key: "language", text: "Language" },
  { key: "vip", text: "Vip" },
  { key: "tags", text: "Tags" },
];

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
  addError: addErrorAction,
  getGoTabProductsAndSpotsForVenue: getGoTabProductsAndSpotsForVenueAction,
  getMailchimpListsInfo: getMailchimpListsInfoAction,
  getMailchimpMergeFields: getMailchimpMergeFieldsAction,
  getMailchimpTags: getMailchimpTagsAction,
  createMailchimpMapping: createMailchimpMappingAction,
  updateMailchimpMapping: updateMailchimpMappingAction,
  getSquareLocations: getSquareLocationsAction,
  connectStripe: connectStripeVenueAction,
  disConnectStripe: disconnectStripeVenueAction,
  checkStripe: checkStripeVenueAction,
  getQuestions: getQuestionsAction,
  createMailchimpTags: createMailchimpTagsAction,
  updateMailchimpTags: updateMailchimpTagsAction,
};

const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  readonly: selectReadonly(state),
  goTabProducts: selectGoTabProducts(state),
  goTabSpots: selectGoTabSpots(state),
  settings: selectSettingsState(state),
  mailchimp: selectMailchimp(state),
  isRequestInProgress: selectIsRequestInProgress(state),
  square: selectSquare(state),
  stripeConnectionStatus: selectStripeConnectionStatus(state),
  uiConfig: selectUIConfig(state),
  isSuperAdmin: selectIsFromSuperAdmin(state),
  questions: selectQuestions(state),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const tsStatus = [
  TripleSeatBlockedStatus.Definite,
  TripleSeatBlockedStatus.Prospect,
  TripleSeatBlockedStatus.Tentative,
];

const payments: IDropdownOption[] = [
  { key: PaymentType.heartland, text: capitalize(PaymentType.heartland) },
  { key: PaymentType.stripe, text: capitalize(PaymentType.stripe) },
  { key: PaymentType.stripeV2, text: 'Stripe V2' },
  { key: PaymentType.fortis, text: capitalize(PaymentType.fortis) },
  { key: PaymentType.goTab, text: capitalize(PaymentType.goTab) },
  { key: PaymentType.square, text: capitalize(PaymentType.square) },
];
const goTabWorkflows: IDropdownOption[] = [
  { key: GoTabWorkflowType.ReservationDeposit, text: "Reservation Deposit" },
  { key: GoTabWorkflowType.PaidInFull, text: "Paid In Full" },
];

const getPaymentTypeDefault = (venue?: VenueSettingDto): string => {
  if (venue?.stripeSecretKey) {
    return PaymentType.stripe;
  } else if (venue?.fortisUrl) {
    return PaymentType.fortis;
  } else if (venue?.paymentType === PaymentType.goTab) {
    return PaymentType.goTab;
  } else if (venue?.paymentType === PaymentType.square) {
    return PaymentType.square;
  } else if (venue?.paymentType === PaymentType.stripeV2) {
    return PaymentType.stripeV2;
  } else {
    return PaymentType.heartland;
  }
};

const VenueIntegrations = ({
  venue,
  readonly,
  goTabProducts,
  goTabSpots,
  settings,
  isRequestInProgress,
  mailchimp,
  square,
  stripeConnectionStatus,
  getMailchimpListsInfo,
  getMailchimpMergeFields,
  createMailchimpMapping,
  updateMailchimpMapping,
  updateVenue,
  addError,
  getGoTabProductsAndSpotsForVenue,
  getSquareLocations,
  checkStripe,
  connectStripe,
  disConnectStripe,
  uiConfig,
  isSuperAdmin,
  getQuestions,
  questions,
  createMailchimpTags,
  updateMailchimpTags,
  getMailchimpTags
}: Props) => {
  const { isMiddleScreen } = useViewport();
  const [enableQSR, setEnableQSR] = useState(venue?.enableQSR);
  const [qsrSiteId, setQsrSiteId] = useState(venue?.qsrSiteId);
  const [qsrCompanyId, setQsrCompanyId] = useState(venue?.qsrCompanyId);
  const [enableSevenRooms, setEnableSevenRooms] = useState(
    venue?.enableSevenRooms
  );
  const [sevenRoomsVenueId, setSevenRoomsVenueId] = useState(
    venue?.sevenRoomsVenueId
  );
  const [enableTripleSeats, setEnableTripleSeats] = useState(
    venue?.enableTripleSeats
  );
  const [enableTripleSeatsScheduleUpdate, setEnableTripleSeatsScheduleUpdate] =
    useState(venue?.enableTripleSeatsScheduleUpdate);
  const [tripleSeatRoomId, setTripleSeatRoomId] = useState(
    venue?.tripleSeatRoomId
  );
  const [tripleSeatLocationId, setTripleSeatLocationId] = useState(
    venue?.tripleSeatLocationId
  );
  const [tripleTimezoneOffset, setTripleTimezoneOffset] = useState(
    `${venue?.tripleTimezoneOffset || 0}`
  );
  const [tripleSeatBlockedStatus, setTripleSeatBlockedStatus] = useState<
    string[]
  >(tripleSeatStatusToUIModel(venue?.tripleSeatBlockedStatus || ""));
  const [enablePOS, setEnablePOS] = useState(venue?.enablePOS);
  const [posLocationId, setPosLocationId] = useState(venue?.posLocationId);
  const [enableSteltronic, setEnableSteltronic] = useState(
    venue?.enableSteltronic
  );
  const [steltronicUrl, setSteltronicUrl] = useState(venue?.steltronicUrl);
  const [steltronicUsername, setSteltronicUsername] = useState(
    venue?.steltronicUsername
  );
  const [steltronicPassword, setSteltronicPassword] = useState(
    venue?.steltronicPassword
  );
  const [enableMailChimp, setEnableMailChimp] = useState(
    venue?.enableMailChimp
  );
  const [salesForceLocation, setSalesForceLocation] = useState(
    venue?.salesForceLocation
  );
  const [sandBoxPayment, setSandBoxPayment] = useState(venue?.sandBoxPayment);
  const [paymentUrl, setPaymentUrl] = useState(venue?.paymentUrl);
  const [mid, setMid] = useState(venue?.mid);
  const [paymentKey, setPaymentKey] = useState(venue?.paymentKey);
  const [paymentSecret, setPaymentSecret] = useState(venue?.paymentSecret);
  const [stripePublishableKey, setStripePublishableKey] = useState(
    venue?.stripePublishableKey
  );
  const [stripeSecretKey, setStripeSecretKey] = useState(
    venue?.stripeSecretKey
  );
  const [enableCaptureStripePayment, setEnableCaptureStripePayment] = useState(
    venue?.enableCaptureStripePayment
  );
  const [isStripeSandboxPayment, setIsStripeSandboxPayment] = useState(venue?.isStripeSandboxPayment);
  const [isStripeV2OtherMethodsEnable, setIsStripeV2OtherMethodsEnable] = useState(venue?.isStripeV2OtherMethodsEnable);
  const [stripeAdditionalMethods, setStripeAdditionalMethods] = useState(venue?.stripeAdditionalMethods);
  const [stripeTerminal, setStripeTerminal] = useState(venue?.stripeTerminal || '');
  const [stripeAccountId, setStripeAccountId] = useState(venue?.stripeAccountId || '');

  const [fortisUrl, setFortisUrl] = useState(venue?.fortisUrl);
  const [fortisDeveloperId, setFortisDeveloperId] = useState(
    venue?.fortisDeveloperId
  );
  const [fortisUserId, setFortisUserId] = useState(venue?.fortisUserId);
  const [fortisUserApiKey, setFortisUserApiKey] = useState(
    venue?.fortisUserApiKey
  );
  const [fortisLocationId, setFortisLocationId] = useState(
    venue?.fortisLocationId
  );
  const [sezzlePublishableKey, setSezzlePublishableKey] = useState(
    venue?.sezzlePublishableKey
  );
  const [sezzleSecretKey, setSezzleSecretKey] = useState(
    venue?.sezzleSecretKey
  );
  const [sezzleUrl, setSezzleUrl] = useState(venue?.sezzleUrl);
  const [lightScript, setLightScript] = useState(venue?.lightScript);
  const [enableSalesForce, setEnableSalesForce] = useState(
    venue?.enableSalesForce
  );
  const [enableTripleSeatsParty, setEnableTripleSeatsParty] = useState(
    venue?.enableTripleSeatsParty
  );
  const [enableMailChimpParty, setEnableMailChimpParty] = useState(
    venue?.enableMailChimpParty
  );
  const [paymentType, setPaymentType] = useState(getPaymentTypeDefault(venue));
  const [useGiftCard, setUseGiftCard] = useState(venue?.useGiftCard);
  const [giftCardClientKey, setGiftCardClientKey] = useState(
    venue?.giftCardClientKey
  );
  const [giftCardTerminalId, setGiftCardTerminalId] = useState(
    venue?.giftCardTerminalId
  );
  const [giftCardWsdlUrl, setGiftCardWsdlUrl] = useState(
    venue?.giftCardWsdlUrl
  );
  const [enableSalesForceReservation, setEnableSalesForceReservation] =
    useState(venue?.enableSalesForceReservation);
  const [salesForceReservationUrl, setSalesForceReservationUrl] = useState(
    venue?.salesForceReservationUrl
  );
  const [salesForceReservationClientId, setSalesForceReservationClientId] =
    useState(venue?.salesForceReservationClientId);
  const [
    salesForceReservationClientSecret,
    setSalesForceReservationClientSecret,
  ] = useState(venue?.salesForceReservationClientSecret);
  const [salesForceReservationUsername, setSalesForceReservationUsername] =
    useState(venue?.salesForceReservationUsername);
  const [salesForceReservationPassword, setSalesForceReservationPassword] =
    useState(venue?.salesForceReservationPassword);
  const [enableAboutGolfIntegration, setEnableAboutGolfIntegration] = useState(
    venue?.enableAboutGolfIntegration
  );
  const [aboutGolfFacilityId, setAboutGolfFacilityId] = useState(
    venue?.aboutGolfFacilityId
  );
  const [enableGoTabIntegration, setEnableGoTabIntegration] = useState(
    venue?.enableGoTabIntegration
  );
  const [goTabLocation, setGoTabLocation] = useState(venue?.goTabLocation);
  const [goTabProductId, setGoTabProductId] = useState(venue?.goTabProductId);
  const [goTabProductList, setGoTabProductList] = useState<IDropdownOption[]>(
    []
  );
  const [goTabSpotUuid, setGoTabSpotUuid] = useState(venue?.goTabSpotUuid);
  const [goTabWorkflowType, setGoTabWorkflowType] = useState(
    venue?.goTabWorkflowType
  );
  const [goTabCreateTabOnCheckIn, setGoTabCreateTabOnCheckIn] = useState(
    venue?.goTabCreateTabOnCheckIn
  );
  const [goTabChargeTaxesFeesOnDeposit, setGoTabChargeTaxesFeesOnDeposit] =
    useState(venue?.goTabChargeTaxesFeesOnDeposit);
  const [goTabSpotList, setGoTabSpotList] = useState<IDropdownOption[]>([]);
  const [enableTwilio, setEnableTwilio] = useState(venue?.enableTwilio);
  const [enableTwilioConfirmationText, setEnableTwilioConfirmationText] = useState(venue?.enableTwilioConfirmationText);
  const [enableTwilioReminderText, setEnableTwilioReminderText] = useState(venue?.enableTwilioReminderText);
  const [twilioConfirmationText, setTwilioConfirmationText] = useState(venue?.twilioConfirmationText);
  const [twilioReminderText, setTwilioReminderText] = useState(venue?.twilioReminderText);
  const [enableTwilioPhoneVerification, setEnableTwilioPhoneVerification] =
    useState(venue?.enableTwilioPhoneVerification);
  const [twilioPhoneNumber, setTwilioPhoneNumber] = useState(
    venue?.twilioPhoneNumber
  );
  const presetQuestions: string[] = Object.keys(customFields).map(key => customFields[key].fieldsName);
  const customQuestions: string[] = questions.map((question) => question.displayName);
  const allQuestions = [...presetQuestions, ...customQuestions];
  const [customMappings, setCustomMappings] = useState<Array<{
    question: string;
    mailchimpField: string;
    isIgnored: boolean;
  }>>([]);

  const [mchActiveList, setMchActiveList] =
    useState<MailchimpMappingDto | null>(null);
  const [mchActiveTags, setMchActiveTags] = useState<MailchimpTagDto | null>(null);

  const allColumnMappings = venue?.mchMappings.find(mch => mch.listId === mchActiveList?.listId)?.columnMapping ?? []
  const rexFieldNames = allColumnMappings?.map(mapping => mapping.rexFieldName) || [];
  const presetFieldMappingKeys = [
    "Email",
    "First Name",
    "Last Name",
    "Phone Number",
    "Venue",
    "Reservation Number",
    "Reservation Date",
  ]

  const [fieldMappingKeys, setFieldMappingKeys] = useState(presetFieldMappingKeys)

  const [tags, setTags] = useState<Array<{ id: string; name: string; customText: string }>>([]);
  const [existingTags, setExistingTags] = useState<Array<{ id: string; name: string }>>([]);
  const [isTagsUpdate, setIsTagsUpdate] = useState(false);
  const [errorQsrSiteId, setErrorQsrSiteId] = useState("");
  const [errorQsrCompanyId, setErrorQsrCompanyId] = useState("");
  const [errorSevenRoomsVenueId, setErrorSevenRoomsVenueId] = useState("");
  const [errorTripleSeatRoomId, setErrorTripleSeatRoomId] = useState("");
  const [errorTripleSeatLocationId, setErrorTripleSeatLocationId] =
    useState("");
  const [errorTripleTimezoneOffset, setErrorTripleTimezoneOffset] =
    useState("");
  const [errorPosLocationId, setErrorPosLocationId] = useState("");
  const [errorSteltronicUrl, setErrorSteltronicUrl] = useState("");
  const [errorSteltronicUsername, setErrorSteltronicUsername] = useState("");
  const [errorSteltronicPassword, setErrorSteltronicPassword] = useState("");
  const [errorSalesForceLocation, setErrorSalesForceLocation] = useState("");
  const [errorPaymentUrl, setErrorPaymentUrl] = useState("");
  const [errorMid, setErrorMid] = useState("");
  const [errorPaymentKey, setErrorPaymentKey] = useState("");
  const [errorPaymentSecret, setErrorPaymentSecret] = useState("");
  const [errorStripePublishableKey, setErrorStripePublishableKey] =
    useState("");
  const [errorStripeSecretKey, setErrorStripeSecretKey] = useState("");
  const [errorFortisUrl, setErrorFortisUrl] = useState("");
  const [errorFortisDeveloperId, setErrorFortisDeveloperId] = useState("");
  const [errorFortisUserId, setErrorFortisUserId] = useState("");
  const [errorFortisUserApiKey, setErrorFortisUserApiKey] = useState("");
  const [errorFortisLocationId, setErrorFortisLocationId] = useState("");
  const [errorGiftCardClientKey, setErrorGiftCardClientKey] = useState("");
  const [errorGiftCardTerminalId, setErrorGiftCardTerminalId] = useState("");
  const [errorGiftCardWsdlUrl, setErrorGiftCardWsdlUrl] = useState("");
  const [errorSalesForceReservationUrl, setErrorSalesForceReservationUrl] =
    useState("");
  const [
    errorSalesForceReservationClientId,
    setErrorSalesForceReservationClientId,
  ] = useState("");
  const [
    errorSalesForceReservationClientSecret,
    setErrorSalesForceReservationClientSecret,
  ] = useState("");
  const [
    errorSalesForceReservationUsername,
    setErrorSalesForceReservationUsername,
  ] = useState("");
  const [
    errorSalesForceReservationPassword,
    setErrorSalesForceReservationPassword,
  ] = useState("");
  const [errorAboutGolfFacilityId, setErrorAboutGolfFacilityId] = useState("");
  const [errorGoTabLocation, setErrorGoTabLocation] = useState("");
  const [errorGoTabProductId, setErrorGoTabProductId] = useState("");

  const isMchConnected = settings.mchIsEnable;

  const [errorMchMapping, setErrorMchMapping] = useState<
    Record<string, string | undefined>
  >({});

  const [isExistMchMapping, setIsExistMchMapping] = useState(false);
  const [mchMembersFieldOptions, setMchMembersFieldOptions] = useState<
    IDropdownOption[]
  >([]);
  const [mchColumnMappingIgnore, setMchColumnMappingIgnore] = useState<
    Record<string, boolean>
  >({});
  const [mchColumnMappingField, setMchColumnMappingField] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    if (venue?.stripeAccountId && paymentType === PaymentType.stripeV2) {
      checkStripe(venue?.id || '')
    }
  }, [venue])

  useEffect(() => {
    setFieldMappingKeys(Array.from(new Set([...presetFieldMappingKeys, ...rexFieldNames])))

  }, [mchActiveList?.listId, venue?.mchMappings])

  useEffect(() => {
    if (isMchConnected) {
      getMailchimpListsInfo();
      getQuestions();
      getMailchimpTags(venue?.id || '')
    }
  }, [venue?.id, isMchConnected]);

  useEffect(() => {
    if (isMchConnected && mchActiveList?.listId) {
      getMailchimpMergeFields(mchActiveList.listId);
    }
  }, [mchActiveList?.listId]);

  useEffect(() => {
    if (!mchActiveList) return;
    const mergeFieldsOptions = getMergeFieldsOptions(mailchimp.mergeFields);
    setMchMembersFieldOptions([
      ...mchDefaultMembersField,
      ...mergeFieldsOptions,
    ]);
  }, [mchActiveList, mailchimp.mergeFields]);

  useEffect(() => {
    updateMappingFields()
  }, [mchActiveList]);

  useEffect(() => {
    getMchFields()
    getMchTags()
  }, [mailchimp.listsInfo]);

  useEffect(() => {
    getMchTags()

    setTags(mailchimp.tags.map(tag => {
      const isInQuestions = allQuestions.includes(tag.tagName);
      return {
        id: tag.tagId,
        name: isInQuestions ? tag.tagName : `Custom`,
        customText: isInQuestions ? '' : tag.tagName
      }
    }))

    setIsTagsUpdate(mailchimp.tags && mailchimp.tags.length > 0);
  }, [mailchimp.tags]);


  const getMchFields = () => {
    if (!venue || !isMchConnected) return;
    const activeList = venue.mchMappings.find(({ isActive }) => isActive);
    if (!activeList) return;

    setMchActiveList(activeList);
  }
  const getMchTags = () => {
    if (!venue || !isMchConnected) return;
    const activeList = venue.mchTags.find(({ isActive }) => isActive);
    if (!activeList) return;

    setMchActiveTags(activeList);
  }
  const updateMappingFields = () => {
    if (!mchActiveList) return;
    const cmIgnore = getColumnMappingIgnore(mchActiveList.columnMapping);
    const cmFieldName = getColumnMappingField(mchActiveList.columnMapping);
    setMchColumnMappingIgnore(cmIgnore);
    setMchColumnMappingField(cmFieldName);
  }


  const isSquareConnected = settings.squareIsEnable;
  const isStripeConnected = !!settings.stripeAccountId;
  const [squareIsEnable, setSquareIsEnable] = useState(venue?.squareIsEnable);
  const [squareLocationName, setSquareLocationName] = useState<string>(
    venue?.squareLocationName || ""
  );
  const [squareLocationId, setSquareLocationId] = useState<string>(
    venue?.squareLocationId || ""
  );
  const [isSquareCashAppEnable, setIsSquareCashAppEnable] = useState<boolean>(
    Boolean(venue?.isSquareCashAppEnable)
  );
  const [isSquareAfterPayEnable, setIsSquareAfterPayEnable] = useState<boolean>(
    Boolean(venue?.isSquareAfterPayEnable)
  );
  const [errorSquareLocation, setErrorSquareLocation] = useState("");

  const [paymentOptions, setPaymentOptions] = useState(
    payments.map((payment) => {
      let disabled = false;
      if (payment.key === PaymentType.square) {
        disabled = !isSquareConnected;
      }
      if (payment.key === PaymentType.stripeV2) {
        disabled = !isStripeConnected;
      }
      return {
        ...payment,
        disabled,
      };
    })
  );

  useEffect(() => {
    if (goTabProducts.length > 0) {
      const productList = goTabProducts.map((item) => ({
        key: item.productUuid,
        text: item.name,
      }));
      setGoTabProductList(productList);
    }
    if (goTabSpots.length > 0) {
      const spotList = goTabSpots.map((item) => ({
        key: item.spotUuid,
        text: item.name,
      }));
      setGoTabSpotList(spotList);
    }
    if (!venue?.enableGoTabIntegration || !venue?.goTabLocation) {
      return;
    }
    if (
      (goTabProducts.length === 0 && venue?.goTabProductId) ||
      (goTabSpots.length === 0 && venue?.goTabSpotUuid)
    ) {
      getGoTabProductsAndSpotsForVenue(venue.goTabLocation);
    }
  }, [goTabProducts, goTabSpots]);

  useEffect(() => {
    if (
      errorQsrSiteId ||
      errorQsrCompanyId ||
      errorSevenRoomsVenueId ||
      errorTripleSeatRoomId ||
      errorTripleTimezoneOffset ||
      errorTripleSeatLocationId ||
      errorPosLocationId ||
      errorSteltronicUrl ||
      errorSteltronicUsername ||
      errorSteltronicPassword ||
      errorSalesForceLocation ||
      errorPaymentUrl ||
      errorMid ||
      errorPaymentKey ||
      errorPaymentSecret ||
      errorStripePublishableKey ||
      errorStripeSecretKey ||
      errorFortisUrl ||
      errorFortisDeveloperId ||
      errorFortisUserId ||
      errorFortisUserApiKey ||
      errorFortisLocationId ||
      errorGiftCardClientKey ||
      errorGiftCardTerminalId ||
      errorGiftCardWsdlUrl ||
      errorSalesForceReservationUrl ||
      errorSalesForceReservationClientId ||
      errorSalesForceReservationClientSecret ||
      errorSalesForceReservationUsername ||
      errorSalesForceReservationPassword ||
      errorAboutGolfFacilityId ||
      errorGoTabLocation ||
      errorGoTabProductId
    ) {
      addError("Update venue integrations failure");
    }
  }, [
    errorQsrSiteId,
    errorQsrCompanyId,
    errorSevenRoomsVenueId,
    errorTripleSeatRoomId,
    errorTripleSeatLocationId,
    errorTripleTimezoneOffset,
    errorPosLocationId,
    errorSteltronicUrl,
    errorSteltronicUsername,
    errorSteltronicPassword,
    errorSalesForceLocation,
    errorPaymentUrl,
    errorMid,
    errorPaymentKey,
    errorPaymentSecret,
    errorStripePublishableKey,
    errorStripeSecretKey,
    errorFortisUrl,
    errorFortisDeveloperId,
    errorFortisUserId,
    errorFortisUserApiKey,
    errorFortisLocationId,
    errorGiftCardClientKey,
    errorGiftCardTerminalId,
    errorGiftCardWsdlUrl,
    errorSalesForceReservationUrl,
    errorSalesForceReservationClientId,
    errorSalesForceReservationClientSecret,
    errorSalesForceReservationUsername,
    errorSalesForceReservationPassword,
    errorAboutGolfFacilityId,
    errorGoTabLocation,
    errorGoTabProductId,
  ]);

  useEffect(() => {
    if (isSquareConnected) {
      getSquareLocations();
    }
  }, []);

  useEffect(() => {
    setPaymentOptions(
      paymentOptions.map((payment) => {
        return {
          ...payment,
          disabled:
            payment.key === PaymentType.square
              ? !(isSquareConnected && Boolean(squareIsEnable))
              : false,
        };
      })
    );
  }, [squareIsEnable]);

  useEffect(() => {
    const location = square.locations.find(
      (location) => location.name === squareLocationName
    );
    if (location) {
      setErrorSquareLocation(
        settings.currency.toLowerCase() !== "usd"
          ? `Please change currency to USD for use current location. Your Square location currency ${location.currency}`
          : ""
      );
    }
  }, [squareLocationName, square.locations]);

  const onClearErrors = () => {
    setErrorQsrCompanyId("");
    setErrorQsrSiteId("");
    setErrorSevenRoomsVenueId("");
    setErrorTripleSeatRoomId("");
    setErrorTripleSeatLocationId("");
    setErrorTripleTimezoneOffset("");
    setErrorPosLocationId("");
    setErrorSteltronicUrl("");
    setErrorSteltronicUsername("");
    setErrorSteltronicPassword("");
    setErrorSalesForceLocation("");
    setErrorPaymentUrl("");
    setErrorPaymentKey("");
    setErrorPaymentSecret("");
    setErrorMid("");
    setErrorStripePublishableKey("");
    setErrorStripeSecretKey("");
    setErrorFortisUrl("");
    setErrorFortisDeveloperId("");
    setErrorFortisUserId("");
    setErrorFortisUserApiKey("");
    setErrorFortisLocationId("");
    setErrorGiftCardClientKey("");
    setErrorGiftCardTerminalId("");
    setErrorGiftCardWsdlUrl("");
    setErrorSalesForceReservationUrl("");
    setErrorSalesForceReservationClientId("");
    setErrorSalesForceReservationClientSecret("");
    setErrorSalesForceReservationUsername("");
    setErrorSalesForceReservationPassword("");
    setErrorAboutGolfFacilityId("");
    setErrorGoTabLocation("");
    setErrorGoTabProductId("");
    setErrorMchMapping({});
  };

  const onUpdateQSR = () => {
    onClearErrors();
    if ((enableQSR && qsrCompanyId && qsrSiteId) || !enableQSR) {
      const patch = {
        enableQSR: !!enableQSR,
        qsrCompanyId: qsrCompanyId || "",
        qsrSiteId: qsrSiteId || "",
      };
      updateVenue({ ...venue, ...patch });
    }
    if (enableQSR && !qsrCompanyId) {
      setErrorQsrCompanyId("This field is required.");
    }
    if (enableQSR && !qsrSiteId) {
      setErrorQsrSiteId("This field is required.");
    }
  };
  const onUpdateSevenrooms = () => {
    onClearErrors();
    if ((enableSevenRooms && sevenRoomsVenueId) || !enableSevenRooms) {
      const patch = {
        enableSevenRooms: !!enableSevenRooms,
        sevenRoomsVenueId: sevenRoomsVenueId || "",
      };
      updateVenue({ ...venue, ...patch });
    }
    if (enableSevenRooms && !sevenRoomsVenueId) {
      setErrorSevenRoomsVenueId("This field is required.");
    }
  };
  const onUpdateTripleseat = () => {
    onClearErrors();
    const offset = parseInt(tripleTimezoneOffset || "0", 10);
    if (isNaN(offset)) {
      setErrorTripleTimezoneOffset("This field is number.");
    }
    if (enableTripleSeats && !tripleSeatRoomId) {
      setErrorTripleSeatRoomId("This field is required.");
    }
    if (enableTripleSeats && !tripleSeatLocationId) {
      setErrorTripleSeatLocationId("This field is required.");
    }
    if (
      (enableTripleSeats &&
        tripleSeatRoomId &&
        tripleSeatLocationId &&
        !isNaN(offset)) ||
      !enableTripleSeats
    ) {
      const patch = {
        enableTripleSeats: !!enableTripleSeats,
        enableTripleSeatsScheduleUpdate: !!enableTripleSeatsScheduleUpdate,
        tripleSeatRoomId: tripleSeatRoomId || null,
        tripleSeatLocationId: tripleSeatLocationId || null,
        tripleSeatBlockedStatus:
          uiModelToTripleSeatStatus(tripleSeatBlockedStatus) || "",
        tripleTimezoneOffset: offset || 0,
      };
      updateVenue({ ...venue, ...patch });
    }
  };
  const onUpdatePOS = () => {
    onClearErrors();
    if ((enablePOS && posLocationId) || !enablePOS) {
      const patch = {
        enablePOS: !!enablePOS,
        posLocationId: posLocationId || "",
      };
      updateVenue({ ...venue, ...patch });
    }
    if (enablePOS && !posLocationId) {
      setErrorPosLocationId("This field is required.");
    }
  };
  const onUpdateSteltronic = () => {
    onClearErrors();
    if (
      (enableSteltronic &&
        steltronicUrl &&
        steltronicUsername &&
        steltronicPassword) ||
      !enableSteltronic
    ) {
      const patch = {
        enableSteltronic: !!enableSteltronic,
        steltronicUrl: steltronicUrl || "",
        steltronicUsername: steltronicUsername || "",
        steltronicPassword: steltronicPassword || "",
      };
      updateVenue({ ...venue, ...patch });
    }
    if (enableSteltronic && !steltronicUrl) {
      setErrorSteltronicUrl("This field is required.");
    }
    if (enableSteltronic && !steltronicUsername) {
      setErrorSteltronicUsername("This field is required.");
    }
    if (enableSteltronic && !steltronicPassword) {
      setErrorSteltronicPassword("This field is required.");
    }
  };
  const onUpdateMailChimp = () => {
    onClearErrors();
    const patch = {
      enableMailChimp: !!enableMailChimp,
      salesForceLocation: salesForceLocation || "",
    };
    updateVenue({ ...venue, ...patch });
  };
  const onUpdatePayment = () => {
    onClearErrors();
    const isSelectHeartland = paymentType === PaymentType.heartland;
    const isSelectFortis = paymentType === PaymentType.fortis;
    const isSelectGoTab = paymentType === PaymentType.goTab;
    const isSelectSquare = paymentType === PaymentType.square;
    const isStripeV2 = paymentType === PaymentType.stripeV2;

    const defaultPatch = {
      sandBoxPayment: !!sandBoxPayment,
      paymentUrl: "",
      mid: "",
      paymentKey: "",
      paymentSecret,
      stripePublishableKey: "",
      stripeSecretKey: "",
      enableCaptureStripePayment: true,
      fortisUrl: "",
      fortisDeveloperId: "",
      fortisUserId: "",
      fortisUserApiKey: "",
      fortisLocationId: "",
      paymentType: "",
      isStripeSandboxPayment,
    };

    if (isSelectHeartland) {
      if (
        !!paymentUrl?.trim() &&
        !!mid?.trim() &&
        !!paymentKey?.trim() &&
        !!paymentSecret?.trim()
      ) {
        const patch = {
          ...defaultPatch,
          paymentUrl,
          mid,
          paymentKey,
          paymentSecret,
          paymentType: PaymentType.heartland,
        };
        updateVenue({ ...venue, ...patch });
      }
      if (!paymentUrl?.trim()) {
        setErrorPaymentUrl("This field is required.");
      }
      if (!mid?.trim()) {
        setErrorMid("This field is required.");
      }
      if (!paymentKey?.trim()) {
        setErrorPaymentKey("This field is required.");
      }
      if (!paymentSecret?.trim()) {
        setErrorPaymentSecret("This field is required.");
      }
    } else if (isSelectFortis) {
      if (
        !!fortisUrl?.trim() &&
        !!fortisDeveloperId?.trim() &&
        !!fortisUserId?.trim() &&
        !!fortisUserApiKey?.trim() &&
        !!fortisLocationId?.trim()
      ) {
        const patch = {
          ...defaultPatch,
          fortisUrl,
          fortisDeveloperId,
          fortisUserId,
          fortisUserApiKey,
          fortisLocationId,
          paymentType: PaymentType.fortis,
        };
        updateVenue({ ...venue, ...patch });
      }
      if (!fortisUrl?.trim()) {
        setErrorFortisUrl("This field is required.");
      }
      if (!fortisDeveloperId?.trim()) {
        setErrorFortisDeveloperId("This field is required.");
      }
      if (!fortisUserId?.trim()) {
        setErrorFortisUserId("This field is required.");
      }
      if (!fortisUserApiKey?.trim()) {
        setErrorFortisUserApiKey("This field is required.");
      }
      if (!fortisLocationId?.trim()) {
        setErrorFortisLocationId("This field is required");
      }
    } else if (isSelectGoTab) {
      const patch = {
        ...defaultPatch,
        paymentType: PaymentType.goTab,
      };
      updateVenue({ ...venue, ...patch });
    } else if (isSelectSquare) {
      const patch = {
        ...defaultPatch,
        paymentType: PaymentType.square,
      };
      updateVenue({ ...venue, ...patch });
    } else if (isStripeV2) {
      const patch = {
        ...defaultPatch,
        isStripeV2OtherMethodsEnable: !!isStripeV2OtherMethodsEnable,
        stripeTerminal,
        stripeAccountId,
        paymentType: PaymentType.stripeV2,
        stripeAdditionalMethods: stripeAdditionalMethods || '',
      };
      updateVenue({ ...venue, ...patch });
    } else {
      if (!!stripePublishableKey?.trim() && !!stripeSecretKey?.trim()) {
        const patch = {
          ...defaultPatch,
          stripePublishableKey,
          stripeSecretKey,
          enableCaptureStripePayment: !!enableCaptureStripePayment,
          paymentType: PaymentType.stripe,
        };
        updateVenue({ ...venue, ...patch });
      }
      if (!stripePublishableKey?.trim()) {
        setErrorStripePublishableKey("This field is required.");
      }
      if (!stripeSecretKey?.trim()) {
        setErrorStripeSecretKey("This field is required.");
      }
    }
  };
  const onUpdateSezzle = () => {
    onClearErrors();
    const patch = {
      sezzlePublishableKey: sezzlePublishableKey || "",
      sezzleSecretKey: sezzleSecretKey || "",
      sezzleUrl: sezzleUrl || "",
    };
    updateVenue({ ...venue, ...patch });
  };
  const onUpdateLightScript = () => {
    onClearErrors();
    const patch = {
      lightScript: lightScript || "",
    };
    updateVenue({ ...venue, ...patch });
  };
  const onUpdateSalesforce = () => {
    onClearErrors();
    if ((enableSalesForce && salesForceLocation) || !enableSalesForce) {
      const patch = {
        enableSalesForce: !!enableSalesForce,
        salesForceLocation: salesForceLocation || "",
      };
      updateVenue({ ...venue, ...patch });
    }
    if (enableSalesForce && !salesForceLocation) {
      setErrorSalesForceLocation("This field is required.");
    }
  };
  const onUpdateTripleseatParty = () => {
    onClearErrors();
    const patch = {
      enableTripleSeatsParty: !!enableTripleSeatsParty,
    };
    updateVenue({ ...venue, ...patch });
  };
  const onUpdateMailChimpParty = () => {
    onClearErrors();
    if ((enableMailChimpParty && salesForceLocation) || !enableMailChimpParty) {
      const patch = {
        enableMailChimpParty: !!enableMailChimpParty,
        salesForceLocation: salesForceLocation || "",
      };
      updateVenue({ ...venue, ...patch });
    }
    if (enableMailChimpParty && !salesForceLocation) {
      setErrorSalesForceLocation("This field is required.");
    }
  };
  
  const onUpdateGiftCard = () => {
    onClearErrors();
    if (
      (useGiftCard &&
        giftCardClientKey &&
        giftCardTerminalId &&
        giftCardWsdlUrl) ||
      !useGiftCard
    ) {
      const patch = {
        useGiftCard: !!useGiftCard,
        giftCardClientKey: giftCardClientKey || "",
        giftCardTerminalId: giftCardTerminalId || "",
        giftCardWsdlUrl: giftCardWsdlUrl || "",
      };
      updateVenue({ ...venue, ...patch });
    }
    if (useGiftCard && !giftCardClientKey) {
      setErrorGiftCardClientKey("This field is required.");
    }
    if (useGiftCard && !giftCardTerminalId) {
      setErrorGiftCardTerminalId("This field is required.");
    }
    if (useGiftCard && !giftCardWsdlUrl) {
      setErrorGiftCardWsdlUrl("This field is required.");
    }
  };

  const onUpdateSalesforceReservation = () => {
    onClearErrors();
    if (
      (enableSalesForceReservation &&
        salesForceReservationUrl &&
        salesForceReservationClientId &&
        salesForceReservationClientSecret &&
        salesForceReservationUsername &&
        salesForceReservationPassword) ||
      !enableSalesForceReservation
    ) {
      const patch = {
        enableSalesForceReservation: !!enableSalesForceReservation,
        salesForceReservationUrl: salesForceReservationUrl || "",
        salesForceReservationClientId: salesForceReservationClientId || "",
        salesForceReservationClientSecret:
          salesForceReservationClientSecret || "",
        salesForceReservationUsername: salesForceReservationUsername || "",
        salesForceReservationPassword: salesForceReservationPassword || "",
      };
      updateVenue({ ...venue, ...patch });
    }
    if (enableSalesForceReservation && !salesForceReservationUrl) {
      setErrorSalesForceReservationUrl("This field is required.");
    }
    if (enableSalesForceReservation && !salesForceReservationClientId) {
      setErrorSalesForceReservationClientId("This field is required.");
    }
    if (enableSalesForceReservation && !salesForceReservationClientSecret) {
      setErrorSalesForceReservationClientSecret("This field is required.");
    }
    if (enableSalesForceReservation && !salesForceReservationUsername) {
      setErrorSalesForceReservationUsername("This field is required.");
    }
    if (enableSalesForceReservation && !salesForceReservationPassword) {
      setErrorSalesForceReservationPassword("This field is required.");
    }
  };

  const onUpdateAboutGolfIntegration = () => {
    onClearErrors();
    const patch = {
      enableAboutGolfIntegration: !!enableAboutGolfIntegration,
      aboutGolfFacilityId: aboutGolfFacilityId || "",
    };
    updateVenue({ ...venue, ...patch });
  };

  const onGetGoTabProductAndSpotList = () => {
    setErrorGoTabLocation("");
    if (!goTabLocation) {
      setErrorGoTabLocation("This field is required.");
    } else {
      getGoTabProductsAndSpotsForVenue(goTabLocation);
    }
  };

  const onUpdateGoTabIntegration = () => {
    onClearErrors();
    if (
      (enableGoTabIntegration &&
        goTabLocation &&
        goTabProductId &&
        goTabSpotUuid) ||
      !enableGoTabIntegration
    ) {
      const patch = {
        enableGoTabIntegration: !!enableGoTabIntegration,
        goTabLocation: goTabLocation || "",
        goTabProductId: goTabProductId || "",
        goTabSpotUuid: goTabSpotUuid || "",
        goTabWorkflowType,
        goTabCreateTabOnCheckIn,
        goTabChargeTaxesFeesOnDeposit,
      };
      updateVenue({ ...venue, ...patch });
    }
    if (enableGoTabIntegration && !goTabLocation) {
      setErrorGoTabLocation("This field is required.");
    }
    if (enableGoTabIntegration && !goTabProductId) {
      setErrorGoTabProductId("This field is required.");
    }
  };
  const onUpdateTwilioIntegration = () => {
    onClearErrors();
    if ((enableTwilio && twilioPhoneNumber) || !enableTwilio) {
      const patch = {
        enableTwilio,
        enableTwilioPhoneVerification,
        twilioPhoneNumber: twilioPhoneNumber || "",
        enableTwilioConfirmationText,
        twilioConfirmationText: twilioConfirmationText || "",
        enableTwilioReminderText,
        twilioReminderText: twilioReminderText || "",
      };
      updateVenue({ ...venue, ...patch });
    }
  };

  const onUpdateSquare = async () => {
    if (isSquareConnected) {
      updateVenue({
        ...venue,
        squareIsEnable,
        squareLocationName,
        squareLocationId,
        isSquareCashAppEnable,
        isSquareAfterPayEnable,
      });
    }
  };

  const onSandBoxChange = (value?: boolean) => {
    setSandBoxPayment(!!value);

    if (!!value) {
      setPaymentType(PaymentType.heartland);
      setPaymentUrl("https://cert.api2.heartlandportico.com");
      setPaymentKey("pkapi_cert_j0TFsUMpjPz8fJcuzf");
      setPaymentSecret("skapi_cert_MRE2AgCnzGEAiXqoukaN_Oley73lOktSHcdXUF_85A");
      setMid("test");
    } else {
      setPaymentUrl("");
      setPaymentKey("");
      setPaymentSecret("");
      setMid("");
    }
  };

  const checkMchErrors = (): boolean => {
    const errors = fieldMappingKeys.reduce((acc, key) => {
      const mch = mchColumnMappingField[key];
      const ignore = mchColumnMappingIgnore[key];
      if (mch === undefined && ignore === undefined) {
        return { ...acc, [key]: "This Field is required" };
      }
      if (ignore === false && mch === undefined) {
        return { ...acc, [key]: "This Field is required" };
      }
      return acc;
    }, {});
    setErrorMchMapping(errors);
    return !isEmpty(errors);
  };

  const onSaveUpdateMchMapping = async (e: any) => {
    onClearErrors();
    e.preventDefault();
    if (checkMchErrors()) return;

    if (!venue) return;

    const listId = mchActiveList?.listId;

    // Existing field mappings
    const existingMappings = fieldMappingKeys.map((key) => ({
      rexFieldName: key,
      mchFieldName: mchColumnMappingField[key]?.includes("option")
        ? mchColumnMappingField[key].split(":")[0]
        : mchColumnMappingField[key] || "",
      isIgnore: Boolean(mchColumnMappingIgnore[key]),
      optionValue: mchColumnMappingField[key]?.includes("option")
        ? mchColumnMappingField[key].split(":")[1]
        : undefined,
    }));

    // Custom field mappings
    const customFieldMappings = customMappings
      .filter(mapping => mapping.question !== '')
      .map(mapping => ({
        rexFieldName: mapping.question,
        mchFieldName: mapping.mailchimpField,
        isIgnore: mapping.isIgnored,
      }));

    // Combine existing and custom mappings
    const body: CreateMailchimpMappingDto = {
      listId: listId || "",
      venueId: venue.id,
      isActive: true,
      columnMapping: [...existingMappings, ...customFieldMappings],
    };

    // Determine whether to create or update the mapping
    isExistMchMapping
      ? createMailchimpMapping(venue.id, body)
      : updateMailchimpMapping({
        listId: listId || "",
        venueId: venue.id,
        mchMapping: body,
        onSuccess: () => {
          getMchFields();
          setMchColumnMappingIgnore({
            ...mchColumnMappingIgnore,
            ...customFieldMappings.reduce(
              (acc, { rexFieldName, isIgnore }) => ({
                ...acc,
                [rexFieldName]: isIgnore,
              }),
              {}
            ),
          });
          setMchColumnMappingField(
            {
              ...mchColumnMappingField,
              ...customFieldMappings.reduce(
                (acc, { rexFieldName, mchFieldName }) => ({
                  ...acc,
                  [rexFieldName]: mchFieldName,
                }),
                {}
              ),
            }
          );
          setCustomMappings([]);
        }
      });

    setIsExistMchMapping(false);
  };

  const onSaveUpdateMchTags = async () => {
    onClearErrors();
    if (!venue) return;

    const tagsPayload = tags.map(tag => ({
      tagId: tag.id,
      tagName: tag.name === "Custom" ? tag.customText : tag.name,
      venueId: venue.id,
      isActive: true,
    }));

    if (isTagsUpdate) {
      updateMailchimpTags(venue.id, tagsPayload); // Update existing tags
    } else {
      createMailchimpTags(venue.id, tagsPayload); // Create new tags
    }

    // Reload tags after save/update
    const updatedTags = venue.mchTags.map(tag => ({
      id: tag.tagId,
      name: tag.tagName
    }));
    setTags(updatedTags.map(tag => ({ ...tag, customText: "" })));
    setIsTagsUpdate(updatedTags && updatedTags.length > 0);
  };


  const onMchListsInfoChange = (_: any, option?: IDropdownOption) => {
    if (!option || !venue) return;
    const choshenListId = option.key;
    const activeList = venue.mchMappings.find(
      ({ listId }) => listId === choshenListId
    );
    const isAlreadyCreated = Boolean(activeList);
    const emptyList = {
      columnMapping: [],
      isActive: true,
      listId: choshenListId.toString(),
      venueId: venue.id,
    };

    if (isAlreadyCreated) {
      updateMailchimpMapping({
        listId: option.key.toString(),
        venueId: venue.id,
        mchMapping: { isActive: true },
      });
    }

    setIsExistMchMapping(!isAlreadyCreated);
    setMchActiveList(!activeList ? emptyList : activeList);
  };

  const addNewMapping = () => {
    setCustomMappings([...customMappings, { question: "", mailchimpField: "", isIgnored: false }]);
  };

  const removeMapping = (field: string) => {
    setFieldMappingKeys((prevKeys) =>
      prevKeys.filter((key) => key !== field)
    );

    setMchColumnMappingIgnore((prev) => {
      const updated = { ...prev };
      delete updated[field];
      return updated;
    });

    setMchColumnMappingField((prev) => {
      const updated = { ...prev };
      delete updated[field];
      return updated;
    });
  };

  const handleQuestionChange = (index: number, value: string) => {
    const updatedMappings = customMappings.map((mapping, i) =>
      i === index ? { ...mapping, question: value } : mapping
    );
    setCustomMappings(updatedMappings);
  };

  const handleRemoveCustomMapping = (index: number) => {
    return setCustomMappings((prevMappings) =>
      prevMappings.filter((_, i) => i !== index)
    );
  };


  const handleMailchimpFieldChange = (index: number, value: string) => {
    const updatedMappings = customMappings.map((mapping, i) =>
      i === index ? { ...mapping, mailchimpField: value } : mapping
    );
    setCustomMappings(updatedMappings);
  };


  const handleIgnoreChange = (index: number, value: boolean) => {
    const updatedMappings = customMappings.map((mapping, i) =>
      i === index ? { ...mapping, isIgnored: value } : mapping
    );
    setCustomMappings(updatedMappings);
  };
  const onAddTag = () => {
    setTags([...tags, { id: `tag-${Date.now()}`, name: "", customText: "" }]);
  };

  const onUpdateTag = (updatedTag: { id: string; name: string; customText: string }) => {
    setTags(tags.map(tag => tag.id === updatedTag.id ? updatedTag : tag));
  };
  const onRemoveTag = (tagToRemove: { id: string }) => {
    setTags(tags.filter(tag => tag.id !== tagToRemove.id));
  };


  const qsrTitle = (
    <>
      <div className="h6">QSR</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={enableQSR}
          onChange={(_, value) => setEnableQSR(value)}
          disabled={readonly}
        />
        <LinkButton onClick={onUpdateQSR}>Save</LinkButton>
      </div>
    </>
  );
  const squareTitle = (
    <>
      <div className="h6">Square</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={squareIsEnable}
          onChange={(_, value) => setSquareIsEnable(value)}
          disabled={readonly}
        />
        <LinkButton
          disabled={!isSquareConnected || Boolean(errorSquareLocation)}
          onClick={onUpdateSquare}
        >
          Save
        </LinkButton>
      </div>
    </>
  );
  const sevenRoomsTitle = (
    <>
      <div className="h6">Sevenrooms</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={enableSevenRooms}
          onChange={(_, value) => setEnableSevenRooms(value)}
          disabled={readonly}
        />
        <LinkButton onClick={onUpdateSevenrooms}>Save</LinkButton>
      </div>
    </>
  );

  const tripleSeatsTitle = (
    <>
      <div className="h6">Tripleseat</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={enableTripleSeats}
          onChange={(_, value) => setEnableTripleSeats(value)}
          disabled={readonly}
        />
        <LinkButton onClick={onUpdateTripleseat}>Save</LinkButton>
      </div>
    </>
  );
  const posTitle = (
    <>
      <div className="h6">POS</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={enablePOS}
          onChange={(_, value) => setEnablePOS(value)}
          disabled={readonly}
        />
        <LinkButton onClick={onUpdatePOS}>Save</LinkButton>
      </div>
    </>
  );
  const mailChimpTitle = (
    <>
      <div className="h6">MailChimp</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={enableMailChimp}
          onChange={(_, value) => setEnableMailChimp(value)}
          disabled={readonly}
        />
        <LinkButton onClick={onUpdateMailChimp}>Save</LinkButton>
      </div>
    </>
  );
  const steltronicTitle = (
    <>
      <div className="h6">Steltronic</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={enableSteltronic}
          onChange={(_, value) => setEnableSteltronic(value)}
          disabled={readonly}
        />
        <LinkButton onClick={onUpdateSteltronic}>Save</LinkButton>
      </div>
    </>
  );
  const aboutGolfIntegrationTitle = (
    <>
      <div className="h6">About Golf</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={enableAboutGolfIntegration}
          onChange={(_, value) => setEnableAboutGolfIntegration(value)}
          disabled={readonly}
        />
        <LinkButton onClick={onUpdateAboutGolfIntegration}>Save</LinkButton>
      </div>
    </>
  );
  const goTabIntegrationTitle = (
    <>
      <div className="h6">GoTab</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={enableGoTabIntegration}
          onChange={(_, value) => setEnableGoTabIntegration(value)}
          disabled={readonly}
        />
        <LinkButton onClick={onUpdateGoTabIntegration}>Save</LinkButton>
      </div>
    </>
  );
  const twilioIntegrationTitle = (
    <>
      <div className="h6">Twilio</div>
      <div className="title-buttons">
        <LinkButton onClick={onUpdateTwilioIntegration}>Save</LinkButton>
      </div>
    </>
  );
  const paymentTitle = (
    <>
      <div className="h6">Payment</div>
      <div className="integrations-sandbox-header">
        <Toggle
          label="Sandbox mode"
          className="integrations-field"
          checked={sandBoxPayment}
          onChange={(_, value) => onSandBoxChange(value)}
          inlineLabel
          disabled={readonly}
        />
        <LinkButton onClick={onUpdatePayment}>Save</LinkButton>
      </div>
    </>
  );
  const sezzleTitle = (
    <>
      <div className="h6">Sezzle</div>
      <LinkButton onClick={onUpdateSezzle}>Save</LinkButton>
    </>
  );
  const lightScriptTitle = (
    <>
      <div className="h6">Light Script</div>
      <LinkButton onClick={onUpdateLightScript}>Save</LinkButton>
    </>
  );
  const salesForceTitle = (
    <>
      <div className="h6">Salesforce</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={enableSalesForce}
          onChange={(_, value) => setEnableSalesForce(value)}
          disabled={readonly}
        />
        <LinkButton onClick={onUpdateSalesforce}>Save</LinkButton>
      </div>
    </>
  );
  const tripleSeatsPartyTitle = (
    <>
      <div className="h6">Tripleseat Party</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={enableTripleSeatsParty}
          onChange={(_, value) => setEnableTripleSeatsParty(value)}
          disabled={readonly}
        />
        <LinkButton onClick={onUpdateTripleseatParty}>Save</LinkButton>
      </div>
    </>
  );
  const mailChimpPartyTitle = (
    <>
      <div className="h6">MailChimp Party</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={enableMailChimpParty}
          onChange={(_, value) => setEnableMailChimpParty(value)}
          disabled={readonly}
        />
        <LinkButton onClick={onUpdateMailChimpParty}>Save</LinkButton>
      </div>
    </>
  );

  const tripleSeatStatusList = tsStatus.map((status) => {
    const isSelected = tripleSeatBlockedStatus.includes(status);
    return (
      <Checkbox
        key={status}
        className="ts-status"
        label={status.charAt(0) + status.slice(1).toLowerCase()}
        defaultChecked={isSelected}
        onChange={(_, checked) =>
          setTripleSeatBlockedStatus(
            changeTripleSeatStatus(status, tripleSeatBlockedStatus, checked)
          )
        }
        disabled={readonly || !enableTripleSeatsScheduleUpdate}
      />
    );
  });

  const giftCardTitle = (
    <>
      <div className="h6">Gift Card</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={useGiftCard}
          onChange={(_, value) => setUseGiftCard(value)}
          disabled={readonly}
        />
        <LinkButton onClick={onUpdateGiftCard}>Save</LinkButton>
      </div>
    </>
  );
  const salesForceReservationTitle = (
    <>
      <div className="h6">Salesforce Reservation</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={enableSalesForceReservation}
          onChange={(_, value) => setEnableSalesForceReservation(value)}
          disabled={readonly}
        />
        <LinkButton onClick={onUpdateSalesforceReservation}>Save</LinkButton>
      </div>
    </>
  );

  return (
    <div className={`venue-integrations ${isMiddleScreen ? "mobile" : ""}`}>
      <div className="left-panel">
        {isRequestInProgress && (
          <div className="loading">
            <Spinner size={SpinnerSize.large} />
          </div>
        )}
        <Panel className="panel-shift" title={squareTitle}>
          <div className="connect-square-location">
            <FormSelectField
              className="field"
              options={square.locations.map((location) => ({
                key: location.id,
                text: location.name || "",
              }))}
              defaultSelectedKey={squareLocationId}
              placeholder="Select Location"
              onChange={(_, option) => {
                setSquareLocationId(option?.key.toString() || "");
                setSquareLocationName(option?.text || "");
              }}
              errorMessage={errorSquareLocation}
              disabled={!squareIsEnable || readonly}
            />
          </div>
          <Toggle
            label="CashApp"
            className="panel-item"
            checked={isSquareCashAppEnable}
            onChange={(_, value) => setIsSquareCashAppEnable(Boolean(value))}
            inlineLabel
            onText="On"
            offText="Off"
            disabled={!squareIsEnable || readonly}
          />
          <Toggle
            label="Afterpay"
            className="panel-item"
            checked={isSquareAfterPayEnable}
            onChange={(_, value) => setIsSquareAfterPayEnable(Boolean(value))}
            inlineLabel
            onText="On"
            offText="Off"
            disabled={!squareIsEnable || readonly}
          />
        </Panel>
        <Panel className="panel-shift" title={qsrTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Site ID"
              className="integrations-field"
              value={qsrSiteId}
              errorMessage={errorQsrSiteId}
              onChange={(_, text) => setQsrSiteId(text)}
              autoComplete="off"
              required={!!enableQSR}
              disabled={readonly}
            />
            <FormTextField
              label="Company ID"
              className="integrations-field"
              value={qsrCompanyId}
              errorMessage={errorQsrCompanyId}
              onChange={(_, text) => setQsrCompanyId(text)}
              autoComplete="off"
              required={!!enableQSR}
              disabled={readonly}
            />
          </div>
        </Panel>
        <Panel className="panel-shift" title={sevenRoomsTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Venue ID"
              className="integrations-field"
              value={sevenRoomsVenueId}
              errorMessage={errorSevenRoomsVenueId}
              onChange={(_, text) => setSevenRoomsVenueId(text)}
              autoComplete="off"
              required={!!enableSevenRooms}
              disabled={readonly}
            />
          </div>
        </Panel>
        <Panel className="panel-shift" title={tripleSeatsTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Location ID"
              type="number"
              className="integrations-field"
              value={tripleSeatLocationId?.toString() || ""}
              errorMessage={errorTripleSeatLocationId}
              onChange={(_, text) =>
                setTripleSeatLocationId(text ? parseInt(text) : null)
              }
              autoComplete="off"
              required={!!(enableTripleSeats || enableTripleSeatsParty)}
              disabled={readonly}
            />
            <FormTextField
              label="Room ID"
              type="number"
              className="integrations-field"
              value={tripleSeatRoomId?.toString() || ""}
              errorMessage={errorTripleSeatRoomId}
              onChange={(_, text) =>
                setTripleSeatRoomId(text ? parseInt(text) : null)
              }
              autoComplete="off"
              required={!!(enableTripleSeats || enableTripleSeatsParty)}
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Timezone Offset (hours)"
              type="number"
              errorMessage={errorTripleTimezoneOffset}
              className="integrations-field"
              value={tripleTimezoneOffset || ""}
              onChange={(_, text) => setTripleTimezoneOffset(text || "")}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              label="Pull events from Tripleseat"
              className="integrations-field"
              checked={enableTripleSeatsScheduleUpdate}
              onChange={(_, value) => setEnableTripleSeatsScheduleUpdate(value)}
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <div className="select-group-status">
              <div className="integrations-field group-title">Statuses</div>
              <div className="integrations-field">{tripleSeatStatusList}</div>
            </div>
          </div>
        </Panel>
        <Panel className="panel-shift" title={posTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Location ID"
              className="integrations-field"
              value={posLocationId}
              errorMessage={errorPosLocationId}
              onChange={(_, text) => setPosLocationId(text)}
              autoComplete="off"
              required={!!enablePOS}
              disabled={readonly}
            />
          </div>
        </Panel>
        <Panel className="panel-shift" title={mailChimpTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormSelectField
              label="List of Info"
              className="integrations-field"
              options={mailchimp.listsInfo.map((l) => ({
                key: l.id,
                text: l.name,
              }))}
              selectedKey={mchActiveList?.listId}
              onChange={onMchListsInfoChange}
              disabled={!isMchConnected || !Boolean(mailchimp.listsInfo.length)}
            />
          </div>
          {isRequestInProgress && (
            <div className="loading">
              <Spinner size={SpinnerSize.large} />
            </div>
          )}
          {mchActiveList?.listId && (
            <>
              <Panel className="panel-mch-mapping" title="Edit Field Mappings">
                {fieldMappingKeys.map((formField) => {
                  const isRemovable = !presetFieldMappingKeys.includes(formField);
                  return (
                    <div
                      className={`row ${isMiddleScreen ? "mobile" : ""}`}
                      key={formField}
                    >
                      <div className="row">{formField}</div>
                      <Checkbox
                        className="row ignore-mch-mapping"
                        label="Ignore"
                        name="ignore-mch-mapping"
                        onChange={(_, isChecked) => {
                          setErrorMchMapping((prev) => ({
                            ...prev,
                            [formField]: isChecked
                              ? ""
                              : "This Field is required",
                          }));

                          setMchColumnMappingIgnore((prev) => ({
                            ...prev,
                            [formField]: Boolean(isChecked),
                          }));
                        }}
                        disabled={formField === "Email"}
                        checked={Boolean(mchColumnMappingIgnore[formField])}
                      />
                      <div className="row">
                        <FormSelectField
                          options={mchMembersFieldOptions}
                          selectedKey={mchColumnMappingField[formField] || ""}
                          required={!mchColumnMappingIgnore[formField]}
                          errorMessage={errorMchMapping[formField] || ""}
                          onChange={(_, option) => {
                            if (option?.key) {
                              setMchColumnMappingField((prev) => ({
                                ...prev,
                                [formField]: option?.key.toString(),
                              }));
                            }
                          }}
                          disabled={readonly}
                        />
                      </div>
                      <div className="row remove">
                        {isRemovable && (
                          <ActionButton
                            onClick={() => removeMapping(formField)}
                            styles={{ root: { height: 20, marginBottom: "10px" } }}
                          >
                            <TrashIcon />
                          </ActionButton>
                        )}
                      </div>
                    </div>
                  );
                })}
                {customMappings.map((mapping, index) => (
                  <div key={index} className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                    <div className="row">
                      <FormSelectField
                        options={allQuestions.map(question => ({ key: question, text: question }))}
                        selectedKey={mapping.question}
                        onChange={(_, option) => handleQuestionChange(index, option?.key as string)}
                        required={!mapping.isIgnored}
                      />
                    </div>
                    <Checkbox
                      label="Ignore"
                      checked={mapping.isIgnored}
                      onChange={(_, isChecked) => handleIgnoreChange(index, isChecked || false)}
                      className="row ignore-mch-mapping"
                    />
                    <div className="row">
                      <FormSelectField
                        options={mchMembersFieldOptions}
                        selectedKey={mapping.mailchimpField}
                        onChange={(_, option) => handleMailchimpFieldChange(index, option?.key as string)}
                        required={!mapping.isIgnored}
                      />
                    </div>
                    <div className="row remove">
                      <ActionButton onClick={() => handleRemoveCustomMapping(index)} styles={{ root: { height: 20, marginBottom: "10px" } }}><TrashIcon /></ActionButton>
                    </div>
                  </div>
                ))}
                <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                  <LinkButton className="add-button" onClick={addNewMapping}>
                    <Icon iconName="Add" className="add" />
                    Add item
                  </LinkButton>
                </div>
              </Panel>
            </>
          )}
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onSaveUpdateMchMapping}
              disabled={!isMchConnected}
            >
              {isExistMchMapping ? "Save Mapping" : "Update Mapping"}
            </ColorButton>
          </div>
          <Panel className="panel-mch-mapping" title="Tags">
            {tags.map(tag => (
              <div
                className={`row ${isMiddleScreen ? "mobile" : ""}`}
                key={tag.id}
              >
                <div className={`row ${tag.name === 'Custom' ? 'medium' : 'large'}`}>
                  <FormSelectField
                    className="field"
                    label="Tag Source"
                    options={[...allQuestions.map(question => ({ key: question, text: question })), { key: "Custom", text: "Custom Text" }]}
                    selectedKey={tag.name}
                    onChange={(_: any, option) => {
                      onUpdateTag({
                        ...tag,
                        name: option?.key ? option?.key.toString() : "",
                        customText: option?.key === "Custom" ? "" : tag.customText,
                      });
                    }}
                    disabled={readonly}
                  />
                </div>
                {tag.name === 'Custom' && (
                  <div className="row medium">
                    <FormTextField
                      label="Custom Text"
                      className="field"
                      value={tag.customText || ''}
                      onChange={(_: any, value?: string) =>
                        onUpdateTag({
                          ...tag,
                          customText: value || "",
                        })
                      }
                      autoComplete="off"
                      disabled={readonly}
                    />
                  </div>
                )}
                <div className="row remove low">
                  <ActionButton onClick={() => onRemoveTag(tag)} styles={{ root: { height: 20, marginBottom: "10px" } }}>
                    <TrashIcon />
                  </ActionButton>
                </div>
              </div>

            ))}
            <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
              <LinkButton className="add-button" onClick={onAddTag}>
                <Icon iconName="Add" className="add" />
                Add tag
              </LinkButton>
            </div>

          </Panel>

          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onSaveUpdateMchTags}
              disabled={!isMchConnected}
            >
              {isTagsUpdate ? "Update Tags" : "Save Tags"}
            </ColorButton>
          </div>
        </Panel>
        <Panel className="panel-shift" title={steltronicTitle}>
          <div className="row">
            <FormTextField
              label="Url"
              className="integrations-field"
              value={steltronicUrl}
              errorMessage={errorSteltronicUrl}
              onChange={(_, text) => setSteltronicUrl(text)}
              autoComplete="off"
              required={!!enableSteltronic}
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Username"
              className="integrations-field"
              value={steltronicUsername}
              errorMessage={errorSteltronicUsername}
              onChange={(_, text) => setSteltronicUsername(text)}
              autoComplete="off"
              required={!!enableSteltronic}
              disabled={readonly}
            />
            <FormTextField
              label="Password"
              className="integrations-field"
              value={steltronicPassword}
              errorMessage={errorSteltronicPassword}
              onChange={(_, text) => setSteltronicPassword(text)}
              autoComplete="off"
              required={!!enableSteltronic}
              disabled={readonly}
            />
          </div>
        </Panel>
        <Panel className="panel-shift" title={salesForceReservationTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Url"
              className="integrations-field"
              value={salesForceReservationUrl || ""}
              errorMessage={errorSalesForceReservationUrl}
              onChange={(_, text) => setSalesForceReservationUrl(text || "")}
              autoComplete="off"
              required={!!enableSalesForceReservation}
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Client ID"
              className="integrations-field"
              value={salesForceReservationClientId || ""}
              errorMessage={errorSalesForceReservationClientId}
              onChange={(_, text) =>
                setSalesForceReservationClientId(text || "")
              }
              autoComplete="off"
              required={!!enableSalesForceReservation}
              disabled={readonly}
            />
            <FormTextField
              label="Client Secret Key"
              className="integrations-field"
              value={salesForceReservationClientSecret || ""}
              errorMessage={errorSalesForceReservationClientSecret}
              onChange={(_, text) =>
                setSalesForceReservationClientSecret(text || "")
              }
              autoComplete="off"
              required={!!enableSalesForceReservation}
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Username"
              className="integrations-field"
              value={salesForceReservationUsername || ""}
              errorMessage={errorSalesForceReservationUsername}
              onChange={(_, text) =>
                setSalesForceReservationUsername(text || "")
              }
              autoComplete="off"
              required={!!enableSalesForceReservation}
              disabled={readonly}
            />
            <FormTextField
              label="Password"
              className="integrations-field"
              value={salesForceReservationPassword || ""}
              errorMessage={errorSalesForceReservationPassword}
              onChange={(_, text) =>
                setSalesForceReservationPassword(text || "")
              }
              autoComplete="off"
              required={!!enableSalesForceReservation}
              disabled={readonly}
            />
          </div>
        </Panel>
        <Panel className="panel-shift" title={aboutGolfIntegrationTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Facility ID"
              className="integrations-field"
              value={aboutGolfFacilityId || ""}
              errorMessage={errorAboutGolfFacilityId}
              onChange={(_, text) => setAboutGolfFacilityId(text || "")}
              autoComplete="off"
              required={!!enableAboutGolfIntegration}
              disabled={readonly}
            />
          </div>
        </Panel>
        <Panel className="panel-shift" title={goTabIntegrationTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Location"
              className="integrations-field"
              value={goTabLocation || ""}
              errorMessage={errorGoTabLocation}
              onChange={(_, text) => setGoTabLocation(text || "")}
              autoComplete="off"
              required={!!enableGoTabIntegration}
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onGetGoTabProductAndSpotList}
              disabled={readonly || !goTabLocation}
            >
              Get Product and Spot Lists
            </ColorButton>
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormSelectField
              label="Product"
              className="integrations-field"
              options={goTabProductList}
              selectedKey={goTabProductId}
              onChange={(_, item) => setGoTabProductId(item?.key as string)}
              disabled={readonly || goTabProductList.length === 0}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormSelectField
              label="Spot"
              className="integrations-field"
              options={goTabSpotList}
              selectedKey={goTabSpotUuid}
              onChange={(_, item) => setGoTabSpotUuid(item?.key as string)}
              disabled={readonly || goTabSpotList.length === 0}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormSelectField
              label="Workflow"
              className="integrations-field"
              options={goTabWorkflows}
              selectedKey={goTabWorkflowType}
              onChange={(_, item) =>
                setGoTabWorkflowType(item?.key as GoTabWorkflowType)
              }
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              label="Create open tab on check in"
              className="integratios-field"
              checked={goTabCreateTabOnCheckIn}
              onChange={(_, value) => setGoTabCreateTabOnCheckIn(value)}
              disabled={goTabWorkflowType !== GoTabWorkflowType.PaidInFull}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              label="Charge taxes & fees on deposit"
              className="integratios-field"
              checked={goTabChargeTaxesFeesOnDeposit}
              onChange={(_, value) => setGoTabChargeTaxesFeesOnDeposit(value)}
              disabled={goTabWorkflowType === GoTabWorkflowType.PaidInFull}
            />
          </div>
        </Panel>
        <Panel className="panel-shift" title={twilioIntegrationTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              className="integrations-field"
              label="Enable Twilio phone validation"
              checked={enableTwilioPhoneVerification}
              onChange={(_, value) => setEnableTwilioPhoneVerification(value)}
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              className="integrations-field"
              label="Enable Twilio SMS chat"
              checked={enableTwilio}
              onChange={(_, value) => setEnableTwilio(value)}
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              className="integrations-field"
              label="Enable auto confirmation text"
              checked={enableTwilioConfirmationText}
              onChange={(_, value) => setEnableTwilioConfirmationText(value)}
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              className="integrations-field"
              label="Enable auto reminder text (10 minutes)"
              checked={enableTwilioReminderText}
              onChange={(_, value) => setEnableTwilioReminderText(value)}
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Phone Number"
              className="integrations-field"
              value={twilioPhoneNumber}
              onChange={(_, text) => setTwilioPhoneNumber(text)}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Confirmation message"
              className="integrations-field"
              value={twilioConfirmationText}
              onChange={(_, text) => setTwilioConfirmationText(text)}
              autoComplete="off"
              disabled={readonly || !enableTwilioConfirmationText}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Reminder message"
              className="integrations-field"
              value={twilioReminderText}
              onChange={(_, text) => setTwilioReminderText(text)}
              autoComplete="off"
              disabled={readonly || !enableTwilioReminderText}
            />
          </div>
        </Panel>
        <Panel className="panel-shift" title={giftCardTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Client key"
              className="integrations-field"
              value={giftCardClientKey}
              errorMessage={errorGiftCardClientKey}
              onChange={(_, text) => setGiftCardClientKey(text)}
              autoComplete="off"
              required={!!useGiftCard}
              disabled={readonly}
            />
            <FormTextField
              label="Terminal ID"
              className="integrations-field"
              value={giftCardTerminalId}
              errorMessage={errorGiftCardTerminalId}
              onChange={(_, text) => setGiftCardTerminalId(text)}
              autoComplete="off"
              required={!!useGiftCard}
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Url"
              className="integrations-field"
              value={giftCardWsdlUrl}
              errorMessage={errorGiftCardWsdlUrl}
              onChange={(_, text) => setGiftCardWsdlUrl(text)}
              autoComplete="off"
              required={!!useGiftCard}
              disabled={readonly}
            />
          </div>
        </Panel>
        <Panel className="panel-shift" title={paymentTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormSelectField
              label="Payments"
              className="integrations-field"
              options={paymentOptions}
              selectedKey={paymentType}
              onChange={(_, item) => setPaymentType(item?.key as string)}
              disabled={readonly || sandBoxPayment}
            />
          </div>
          {paymentType === PaymentType.stripe ? (
            <>
              <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                <Toggle
                  label="Capture Payment"
                  className="integrations-field toggle-button"
                  checked={enableCaptureStripePayment}
                  onChange={(_, value) => setEnableCaptureStripePayment(value)}
                  inlineLabel
                  disabled={readonly}
                />
              </div>
              <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                <FormTextField
                  label="Stripe public"
                  className="integrations-field"
                  value={stripePublishableKey}
                  errorMessage={errorStripePublishableKey}
                  onChange={(_, text) => setStripePublishableKey(text)}
                  autoComplete="off"
                  disabled={readonly}
                  required={true}
                />
                <FormTextField
                  label="Stripe secret"
                  className="integrations-field"
                  value={stripeSecretKey}
                  errorMessage={errorStripeSecretKey}
                  onChange={(_, text) => setStripeSecretKey(text)}
                  autoComplete="off"
                  disabled={readonly}
                  required={true}
                />
              </div>
            </>
          ) : paymentType === PaymentType.stripeV2 ? (
            <>
              <div className={`panel-connection-venue ${isMiddleScreen ? 'mobile' : ''}`}>
                <ConnectVenueStripeComponent
                  isSandboxMode={isStripeSandboxPayment || false}
                  status={stripeConnectionStatus}
                  onConnect={() => connectStripe(venue?.id || '')}
                  onDisconnect={() => disConnectStripe(venue?.id || '')}
                  disabledConnect={stripeConnectionStatus === 'pending' || stripeConnectionStatus === 'ok'}
                  disabledDisconnect={!(stripeConnectionStatus === 'pending' || stripeConnectionStatus === 'ok')}
                  isRequestInProgress={isRequestInProgress}
                />
                <StripeVenueFields
                  stripeAccountId={stripeAccountId}
                  onCheckStripe={() => checkStripe(venue?.id || '')}
                  currency={uiConfig?.currency}
                  disabledCheck={!(stripeConnectionStatus === 'pending' || stripeConnectionStatus === 'ok')}
                  isStripeSandboxPayment={isStripeSandboxPayment || false}
                  setIsStripeSandboxPayment={(value) => setIsStripeSandboxPayment(value)}
                  isStripeV2OtherMethodsEnable={isStripeV2OtherMethodsEnable || false}
                  setIsStripeV2ApplePayEnable={(value) => setIsStripeV2OtherMethodsEnable(value)}
                  status={stripeConnectionStatus}
                  stripeTerminal={stripeTerminal}
                  setStripeTerminal={setStripeTerminal}
                  setStripeAccountId={isSuperAdmin ? setStripeAccountId : undefined}
                  setStripeAdditionalMethods={(value) => setStripeAdditionalMethods(value)}
                  stripeAdditionalMethods={stripeAdditionalMethods || ''}
                />
              </div>
            </>
          ) : paymentType === PaymentType.fortis ? (
            <>
              <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                <FormTextField
                  label="Fortis url"
                  className="integrations-field"
                  value={fortisUrl}
                  errorMessage={errorFortisUrl}
                  onChange={(_, text) => setFortisUrl(text)}
                  autoComplete="off"
                  disabled={readonly}
                  required={true}
                />
              </div>
              <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                <FormTextField
                  label="Fortis location id"
                  className="integrations-field"
                  value={fortisLocationId}
                  errorMessage={errorFortisLocationId}
                  onChange={(_, text) => setFortisLocationId(text)}
                  autoComplete="off"
                  disabled={readonly}
                  required={true}
                />
                <FormTextField
                  label="Fortis developer id"
                  className="integrations-field"
                  value={fortisDeveloperId}
                  errorMessage={errorFortisDeveloperId}
                  onChange={(_, text) => setFortisDeveloperId(text)}
                  autoComplete="off"
                  disabled={readonly}
                  required={true}
                />
              </div>
              <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                <FormTextField
                  label="Fortis user id"
                  className="integrations-field"
                  value={fortisUserId}
                  errorMessage={errorFortisUserId}
                  onChange={(_, text) => setFortisUserId(text)}
                  autoComplete="off"
                  disabled={readonly}
                  required={true}
                />
                <FormTextField
                  label="Fortis user key"
                  className="integrations-field"
                  value={fortisUserApiKey}
                  errorMessage={errorFortisUserApiKey}
                  onChange={(_, text) => setFortisUserApiKey(text)}
                  autoComplete="off"
                  disabled={readonly}
                  required={true}
                />
              </div>
            </>
          ) : [PaymentType.goTab, PaymentType.square, PaymentType.stripeV2].includes(
            paymentType as PaymentType
          ) ? (
            <></>
          ) : (
            <>
              <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                <FormTextField
                  label="Payment key"
                  className="integrations-field"
                  value={paymentKey}
                  errorMessage={errorPaymentKey}
                  onChange={(_, text) => setPaymentKey(text)}
                  autoComplete="off"
                  disabled={readonly}
                  required={true}
                />
                <FormTextField
                  label="Payment secret"
                  className="integrations-field"
                  value={paymentSecret}
                  errorMessage={errorPaymentSecret}
                  onChange={(_, text) => setPaymentSecret(text)}
                  autoComplete="off"
                  disabled={readonly}
                  required={true}
                />
              </div>
              <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                <FormTextField
                  label="Payment URL"
                  className="integrations-field"
                  value={paymentUrl}
                  errorMessage={errorPaymentUrl}
                  onChange={(_, text) => setPaymentUrl(text)}
                  autoComplete="off"
                  disabled={readonly}
                  required={true}
                />
                <FormTextField
                  label="Payment MID"
                  className="integrations-field"
                  value={mid}
                  errorMessage={errorMid}
                  onChange={(_, text) => setMid(text)}
                  autoComplete="off"
                  disabled={readonly}
                  required={true}
                />
              </div>
            </>
          )}
        </Panel>
        <Panel className="panel-shift" title={sezzleTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Public"
              className="integrations-field"
              value={sezzlePublishableKey}
              onChange={(_, text) => setSezzlePublishableKey(text)}
              autoComplete="off"
              disabled={readonly}
            />
            <FormTextField
              label="Secret"
              className="integrations-field"
              value={sezzleSecretKey}
              onChange={(_, text) => setSezzleSecretKey(text)}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="URL"
              className="integrations-field"
              value={sezzleUrl}
              onChange={(_, text) => setSezzleUrl(text)}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
        </Panel>
        <Panel className="panel-shift" title={lightScriptTitle}>
          <div className="row">
            <FormTextField
              className="integrations-field"
              multiline
              rows={7}
              label="Light Script"
              value={lightScript}
              onChange={(_, text) => setLightScript(text ? text : "")}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
        </Panel>
        <Panel className="panel-shift empty" title={salesForceTitle}></Panel>
        <Panel
          className="panel-shift empty"
          title={tripleSeatsPartyTitle}
        ></Panel>
        <Panel
          className="panel-shift empty"
          title={mailChimpPartyTitle}
        ></Panel>
      </div>
      <div className={`right-panel ${isMiddleScreen ? "mobile" : ""}`}>
        <VenueMainActions />
      </div>
    </div>
  );
};

export default connector(VenueIntegrations);

function getMergeFieldsOptions(mergeFields: MergeField[]): IDropdownOption[] {
  const mappedMergeFields = mergeFields.map((item) => {
    if (item.type === "address") {
      return Object.entries(mchMergeFieldAddress).map(([key, value]) => ({
        key: `merge_fields.${item.tag}.${key}`,
        text: `${item.name} - ${value}`,
      }));
    }
    if (["dropdown", "radio"].includes(item.type) && item.options) {
      return item.options.choices.map((value) => ({
        key: `merge_fields.${item.tag}:${value}:option`,
        text: `${item.name} - ${value}`,
      }));
    }
    return {
      key: `merge_fields.${item.tag}`,
      text: item.name,
    };
  });
  return flatten(mappedMergeFields);
}

function getColumnMappingIgnore(
  columnMapping: MchColumnMapping[]
): Record<string, boolean> {
  return columnMapping.reduce(
    (acc, { isIgnore, rexFieldName }) => ({
      ...acc,
      [rexFieldName]: isIgnore,
    }),
    {}
  );
}

function getColumnMappingField(
  columnMapping: MchColumnMapping[]
): Record<string, string> {
  return columnMapping.reduce(
    (acc, { mchFieldName, rexFieldName }) => ({
      ...acc,
      [rexFieldName]: mchFieldName,
    }),
    {}
  );
}
