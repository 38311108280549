import { Modal } from "office-ui-fabric-react";
import React from "react";
import TrashIcon from "../../assets/trash-icon.svgr";

import CancelButton from "../../../../common/components/CancelButton";
import { VenueSettingDto } from "../../store/types";
import VenueImage from "../common/Image";
import "../VenueDetails/VenueInfo/EditVenueImages/editVenueImages.scss";

type Props = {
    venue?: VenueSettingDto;
    isImageLoadInProgress: boolean;
    uploadSecondaryVenueImageFiles: (files: FileList | null) => void;
    deleteVenueImage: (id: string, imageUrl: string) => void;
    onClose: (email?: string) => void;
};

const EditMemebershipImage = ({
    isImageLoadInProgress,
    uploadSecondaryVenueImageFiles,
    venue,
    onClose,
    deleteVenueImage,
}: Props) => {

    return (
        <Modal
            isOpen={true}
            onDismiss={() => onClose()}
            isBlocking={false}
            containerClassName="venue-images membership-images"
        >
            <div className="container">
                <div className="header h4">
                    <div>Edit Image</div>
                    <CancelButton onClick={() => onClose()} />
                </div>
                <div className="venue-image-container">
                    <VenueImage
                        loading={isImageLoadInProgress}
                        uploadImage={uploadSecondaryVenueImageFiles}
                        subTitle="500x500 pixels"
                    />
                    {venue?.secondaryVenueImages.map((imageUrl, i) => (
                        <div className="delete-button" key={`${imageUrl}-${i}`}>
                            <img src={imageUrl} alt={`venue-image-${i}`} />
                            <TrashIcon
                                className="trash-icon"
                                onClick={() => deleteVenueImage(venue.id, imageUrl)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
};

export default EditMemebershipImage;
