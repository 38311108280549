import React, { FC } from 'react';
import Panel from '../../common/Panel';
import HistoryItem, { TransferDirection } from './HistoryItem';
import { SyncLogDto } from "../../../store/types";

interface Props {
    isMiddleScreen: boolean;
    items: SyncLogDto[]
    domain: string
}

const transferToSandboxHistoryTitle = (
    <div className="h6">Transfer History</div>
);

const TransferHistory: FC<Props> = ({ domain, items }) => (
    <Panel className="panel-shift panel-sandbox-history" title={transferToSandboxHistoryTitle}>
        <div className='history-items'>
            {items.map(({ id, info, createdAt }) => (<HistoryItem createdAt={createdAt.toString()} name={info} status={info.includes('error') ? 'Failure' : 'Success'} direction={TransferDirection.DevToDev} domain={domain} />))}
        </div>
    </Panel>)

export default TransferHistory