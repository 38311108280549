import React, { FC } from 'react';
import { HistoryStatus } from './HistoryItem';
import Checked from '../../../assets/checked.svg';
import Failure from '../../../assets/failure.svg';
import Warning from '../../../assets/warning.svg';

const SuccessInfo: FC = () => (
    <div className='history-item__transfer-info_checkbox'>
        <img src={Checked} className='history-item__transfer-info_checked' />
        <div className="h7">All configurations transferred successfully.</div>
    </div>
);

const FailureInfo: FC = () => (
    <div className='history-item__transfer-info_checkbox'>
        <img src={Failure} className='history-item__transfer-info_checked' />
        <div className="h7">All configurations weren't transferred.</div>
    </div>
);

const PartlyInfo: FC = () => (
    <div className='history-item__transfer-info_checkbox'>
        <img src={Warning} className='history-item__transfer-info_checked' />
        <div className="h7">Some of configurations weren't transferred.</div>
    </div>
);

interface Props {
    status: HistoryStatus
}


const TransferInfo: FC<Props> = ({ status }) => {
    switch (status) {
        case 'Success':
            return <SuccessInfo />;
        case 'Failure':
            return <FailureInfo />;
        case 'Partly':
            return <PartlyInfo />;
        default:
            return null; // Handle unexpected status values
    }
}

export default TransferInfo