import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import {
  Fabric,
  mergeStyles,
  IDropdownOption,
  DefaultButton,
} from "office-ui-fabric-react";
import {
  setTimeIntervalAction,
  setVenueFilterAction,
  downloadReportAction,
  setFilterParametersForReportAction,
} from "../../actions/reports-actions";
import { getVenuesAction } from "../../actions/venue-actions";
import {
  selectVenueIdFilterReport,
  selectTimeInterval,
  selectStartDate,
  selectEndDate,
  selectReport,
  selectReportFilterParameters,
} from "../../reducers/reports";
import { connect, ConnectedProps } from "react-redux";
import {
  State,
  VenueNameDto,
  TimeInterval,
  CurrencyType,
  FilterReservationParameters,
  VenueSettingDto,
} from "../../store/types";
import { selectVenues } from "../../reducers/venues";
import { setWebConfigUIAction } from "../../actions/ui-actions";
import { DATE_FORMAT, DATE_FORMAT_US } from "../../constants/timedate";
import { formatPrice } from "../../utils/formats";
import downloadIcon from "../../assets/download-icon.svg";
import ReservationsReportIcon from "../../assets/shoping-cart-icon.svgr";
import RevenueChargedIcon from "../../assets/cube-icon.svgr";
import RevenueBookedIcon from "../../assets/cards-icon.svgr";
import SettingsIcon from "../../assets/settings-icon.svgr";
import SelectDateRange from "../common/SelectDateRange/SelectDateRange";
import FormSelectField from "../common/FormSelectField";
import { webConfigUI } from "../../constants/webConfigUI";
import { useViewport } from "../../hooks/responsive";
import { selectUIConfig } from "../../reducers/ui-reducer";
import FilterReservations from "../Reservations/FilterReservations/FilterReservations";
import "./reports.scss";

const wrapperClassName = mergeStyles({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  selectors: {
    "& > *": { flexBasis: "43%" },
  },
});

const mapDispatchToProps = {
  setTimeFilterInterval: setTimeIntervalAction,
  setVenueFilter: setVenueFilterAction,
  getVenues: getVenuesAction,
  downloadReport: downloadReportAction,
  setWebConfigUI: setWebConfigUIAction,
  setFilterParametersForReport: setFilterParametersForReportAction,
};
const mapStateToProps = (state: State) => ({
  report: selectReport(state),
  venueId: selectVenueIdFilterReport(state),
  timeInterval: selectTimeInterval(state),
  startDate: selectStartDate(state),
  endDate: selectEndDate(state),
  venues: selectVenues(state),
  uiConfig: selectUIConfig(state),
  filterParameters: selectReportFilterParameters(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const Reports = ({
  report,
  venueId,
  timeInterval,
  startDate,
  endDate,
  venues,
  uiConfig,
  setTimeFilterInterval,
  setVenueFilter,
  getVenues,
  downloadReport,
  setWebConfigUI,
  filterParameters,
  setFilterParametersForReport,
}: Props) => {
  const { isMiddleScreen } = useViewport();
  useEffect(() => {
    if (venues && !venues.length) {
      getVenues();
    }
    setVenueFilter(venueId);
  }, []);
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const dropdownDismissTimer = useRef<any>(null);

  const tenantCurrency = uiConfig?.currency || CurrencyType.USD;

  useEffect(() => {
    return () => {
      if (dropdownDismissTimer.current) {
        clearTimeout(dropdownDismissTimer.current);
      }
    };
  }, []);

  const onDismissed = () => {
    dropdownDismissTimer.current = setTimeout(() => {
      if (timeInterval === TimeInterval.CUSTOM && !showRangePicker)
        setShowRangePicker(true);
    }, 200);
  };

  const composeVenuesForDropDown = (
    venues: VenueSettingDto[]
  ): IDropdownOption[] => {
    return [
      { key: "all", text: "All venues" },
      ...venues.map((item) => ({ key: item.id, text: item.name })),
    ];
  };
  const venuesForDropdown = composeVenuesForDropDown(venues);
  const custom =
    startDate && endDate
      ? `${dayjs(startDate).format(
          uiConfig?.dateFormat || DATE_FORMAT_US
        )}-${dayjs(endDate).format(uiConfig?.dateFormat || DATE_FORMAT_US)}`
      : "custom";
  const timeIntervalOptions = [
    { key: TimeInterval.TODAY, text: "Today" },
    { key: TimeInterval.THIS_MONTH, text: "This month" },
    { key: TimeInterval.LAST_MONTH, text: "Last month" },
    { key: TimeInterval.YEAR_TO_DATE, text: "Year to date" },
    { key: TimeInterval.ALL_TIME, text: "All" },
    { key: TimeInterval.CUSTOM, text: custom },
  ];

  const onVenueChange = (event: any, option: IDropdownOption | undefined) => {
    if (!option || !option.key) return null;
    const venueId =
      String(option.key) === "all" ? undefined : String(option.key);
    setVenueFilter(venueId);
  };

  const onIntervalChange = (
    event: any,
    option: IDropdownOption | undefined
  ) => {
    if (!option || !option.key) return null;
    if (dropdownDismissTimer.current) {
      clearTimeout(dropdownDismissTimer.current);
    }
    if (option.key === TimeInterval.CUSTOM) {
      setShowRangePicker(true);
      return;
    }
    setTimeFilterInterval({
      timeInterval: String(option.key) as TimeInterval,
      startDate: "",
      endDate: "",
    });
  };
  const onSetRange = (sDate?: Date | null, eDate?: Date | null) => {
    if (sDate && eDate) {
      setTimeFilterInterval({
        timeInterval: TimeInterval.CUSTOM,
        startDate: dayjs(sDate).format(DATE_FORMAT),
        endDate: dayjs(eDate).format(DATE_FORMAT),
      });
    }
    setShowRangePicker(false);
  };
  const onDownload = (e: any) => {
    e.preventDefault();
    downloadReport();
  };

  const onSetFilterParameters = (
    filterForReport: FilterReservationParameters[]
  ) => {
    setFilterParametersForReport(filterForReport);
  };

  return (
    <div className="reports">
      <div className="title h4">
        <div>Reports</div>
        <a href="" onClick={onDownload}>
          <img className="lanes-icon" src={downloadIcon} />
        </a>
      </div>
      <div className="inputs-block">
        <Fabric className={wrapperClassName}>
          <div className="filter-block">
            <div className="subtitle2">Filter by venue</div>
            <FormSelectField
              options={venuesForDropdown}
              onChange={onVenueChange}
              defaultSelectedKey={venueId || "all"}
            />
          </div>
          <div className="filter-block">
            <div className="subtitle2">Filter by date</div>
            <FormSelectField
              options={timeIntervalOptions}
              onChange={onIntervalChange}
              defaultSelectedKey={timeInterval}
              onDismiss={onDismissed}
            />
          </div>
          <DefaultButton
            className="filter-button"
            onClick={() => setShowFilter(true)}
          >
            <div className="filter-text">
              <SettingsIcon className="filter-icon" />
              Filter
            </div>
          </DefaultButton>
        </Fabric>
      </div>
      <div className={`report-list ${isMiddleScreen ? "mobile" : ""}`}>
        <div className={`report-block ${isMiddleScreen ? "mobile" : ""}`}>
          <div className="report-info">
            <div className="icon-container">
              <ReservationsReportIcon className="report-icon" />
            </div>
            <div className="report-text">
              <div className="label overline">RESERVATIONS</div>
              <div className="value h6">{report?.reservationCount}</div>
            </div>
          </div>
          <div className="report-link-container">
            <button
              className="report-link subtitle2"
              onClick={() => {
                setWebConfigUI(webConfigUI.RESERVATION);
              }}
            >
              <div className="link-text">Reservations</div>
              <div className="right-arrow">&#8594;</div>
            </button>
          </div>
        </div>
        <div className={`report-block ${isMiddleScreen ? "mobile" : ""}`}>
          <div className="report-info">
            <div className="icon-container">
              <RevenueChargedIcon className="report-icon" />
            </div>
            <div className="report-text">
              <div className="label overline">REVENUE CHARGED</div>
              <div className="value h6">
                {report
                  ? formatPrice(report.revenueCharged, tenantCurrency)
                  : ""}
              </div>
            </div>
          </div>
          <div className="report-link-container">
            <button
              className="report-link subtitle2"
              onClick={() => {
                setWebConfigUI(webConfigUI.RESERVATION);
              }}
            >
              <div className="link-text">Reservations</div>
              <div className="right-arrow">&#8594;</div>
            </button>
          </div>
        </div>
        <div className={`report-block ${isMiddleScreen ? "mobile" : ""}`}>
          <div className="report-info">
            <div className="icon-container">
              <RevenueBookedIcon className="report-icon" />
            </div>
            <div className="report-text">
              <div className="label overline">REVENUE BOOKED</div>
              <div className="value h6">
                {report
                  ? formatPrice(report.revenueBooked, tenantCurrency)
                  : ""}
              </div>
            </div>
          </div>
          <div className="report-link-container">
            <button
              className="report-link subtitle2"
              onClick={() => {
                setWebConfigUI(webConfigUI.RESERVATION);
              }}
            >
              <div className="link-text">Reservations</div>
              <div className="right-arrow">&#8594;</div>
            </button>
          </div>
        </div>
      </div>
      {showRangePicker && (
        <SelectDateRange onClose={onSetRange} uiConfig={uiConfig} />
      )}
      {showFilter && (
        <FilterReservations
          onClose={() => setShowFilter(false)}
          filterParametersForReservations={filterParameters}
          setFilterParametersForReservations={onSetFilterParameters}
        />
      )}
    </div>
  );
};

export default connector(Reports);
