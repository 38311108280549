import React from "react";
import { Modal } from "office-ui-fabric-react";
import LinkButton from "../../../../common/components/LinkButton";
import ColorButton from "../../../../common/components/ColorButton";
import CancelButton from "../../../../common/components/CancelButton";
import "./modalConfirm.scss";

type Props = {
  confirmationHeader: string;
  confirmationText: string;
  confirmationButtonText: string;
  onClose: (isConfirm: boolean) => void;
};

const ModalConfirm = ({
  confirmationHeader,
  confirmationText,
  confirmationButtonText,
  onClose,
}: Props) => {
  const onCancel = () => onClose(true);
  return (
    <Modal
      isOpen={true}
      onDismiss={() => onClose(false)}
      isBlocking={false}
      containerClassName="modal-confirm"
    >
      <div className="container">
        <div className="header h4">
          <div>{confirmationHeader}</div>
          <CancelButton onClick={() => onClose(false)} />
        </div>
        <div className="subtitle2 modal-confirm-text">
          {confirmationText}
        </div>
        <div className="buttons-container">
          <LinkButton onClick={() => onClose(false)}>Cancel</LinkButton>
          <ColorButton onClick={() => onCancel()}>
            {confirmationButtonText}
          </ColorButton>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirm;
