import React, {useEffect, useState} from 'react';
import './index.scss'
import deleteIcon from "../../../../assets/delete.svg"
import {Icon} from "office-ui-fabric-react";
import ColorButton from "../../../../../../common/components/ColorButton";
import {selectCategories} from "../../../../reducers/settings";
import {State} from "../../../../store/types";
import {connect, ConnectedProps} from "react-redux";
import {updateSettingsAction} from "../../../../actions/settings-actions";
import Panel from "../../../common/Panel";
import LinkButton from '../../../../../../common/components/LinkButton';
import FormTextField from "../../../../../../common/components/FormTextField";

interface RevenueCategory { id: number, name: string, score: number | string }

const mapStateToProps = (state: State) => ({
  categories: selectCategories(state)
})

const mapDispatchToProps = {
  updateSettings: updateSettingsAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>

const RevenueCategories = (
  {
    categories,
    updateSettings
  }: Props
) => {
  const [newCategories, setNewCategories] = useState<RevenueCategory[]>([]);

  useEffect(() => {
    const splitCategories = categories.split(';').filter(string => !!string);


    setNewCategories(splitCategories.map((category, index) => {
      const [name, score] = category.split(':');

      return {
        id: index,
        name,
        score: parseInt(score),
      }
    }))
  }, [categories])

  const onChangeName = (index: number) => (_: any, text?: string) => {
    const categories = [...newCategories]
    categories[index].name = text as string
    setNewCategories(categories)
  }

  const onChangePercentage = (index: number) => (_: any, text?: string) => {
    const categories = [...newCategories]
    if (!text) {
      categories[index].score = ''
    } else {
      let score = Number.isNaN(parseInt(text as string)) ? 0 : parseInt(text as string);

      score = Math.min(10, Math.max(score, 1))

      categories[index].score = score
    }
    setNewCategories(categories)
  }

  const onDeleteRow = (index: number) => () => {
    const categories = [...newCategories]
    categories.splice(index, 1)
    setNewCategories(categories)
  }

  const onAddItem = () => {
    setNewCategories(prevState => [...prevState, {name: '', score: 0, id: prevState.length}])
  }

  const onSaveCategories = () => {
    updateSettings({
      categories: newCategories.map(item => `${item.name}:${item.score}`).join(';')
    })
  }

  return (
    <Panel className='revenue-categories panel-shift' title={<span className='h6'>Revenue Categories</span>}>
      <div className='revenue-categories-body'>
        <div className='revenue-categories-inputs'>
          {
            newCategories.map((category, index) => (
              <div className='revenue-categories-inputs-row' key={category.id}>
                <div className='revenue-categories-input'>
                  <FormTextField value={category.name} onChange={onChangeName(index)}
                                 label='Name'/>
                </div>
                <div className='revenue-categories-input'>
                  <FormTextField value={String(category.score)} onChange={onChangePercentage(index)}
                                 label='Margin Score (1-10)'/>
                </div>
                <button className='delete-button' onClick={onDeleteRow(index)}>
                  <img src={deleteIcon} alt="Delete Row"/>
                </button>
              </div>
            ))
          }
        </div>
        <LinkButton className="revenue-categories-add-item" onClick={onAddItem}>
          <Icon iconName="Add" className="add-icon" />
          Add item
        </LinkButton>
        <ColorButton onClick={onSaveCategories}>Save Changes</ColorButton>
      </div>
    </Panel>
  );
};

export default connector(RevenueCategories);
