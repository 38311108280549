import React, { useState } from "react";
import { Modal } from "office-ui-fabric-react";
import { TempTenantUserDto, UserType } from "../../../store/types";
import CancelButton from "../../../../../common/components/CancelButton";
import "./editUser.scss";
import LinkButton from "../../../../../common/components/LinkButton";
import ColorButton from "../../../../../common/components/ColorButton";
import FormTextField from "../../../../../common/components/FormTextField";

interface Props {
  onClose: () => void;
  onSave: (user: TempTenantUserDto) => void;
  user: TempTenantUserDto;
  createMode?: boolean;
}

const EditUser = ({
  onClose,
  onSave,
  user,
  createMode = false,
}: Props) => {
  const [username, setUserName] = useState(user.username);
  const [password, setPassword] = useState('');


  const onSaveUser = (e: React.FormEvent) => {
    e.preventDefault();
    onSave({ ...user, username, password, type: UserType.partner });
    onClose();
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-user"
    >
      <div className="title h4">
        {createMode ? "Create User" : "Update User"}
        <CancelButton onClick={onClose} />
      </div>
      <form className="form-tenant" onSubmit={onSaveUser}>
        <FormTextField
          label="Email"
          className="row"
          value={username}
          onChange={(_: any, text?: string) => setUserName(text || "")}
          autoComplete="off"
          required={true}
          disabled={!createMode}
        />
        <FormTextField
          label="Password"
          className="row"
          type="password"
          value={password}
          onChange={(_: any, text?: string) => setPassword(text || "")}
          autoComplete="off"
          required={true}
        />
        
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit" >
            Save Change
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default EditUser;
