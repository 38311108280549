import React from "react";
import {
  Modal,
  DefaultButton,
} from "office-ui-fabric-react";
import linkIcon from "../../../../assets/link-icon.svg";
import helpIcon from "../../../../assets/help-icon.svg";
import exitIcon from "../../../../assets/exit-icon.svg";
import logoutIcon from "../../../../assets/logout-icon.svg";
import "./headerContextualMenu.scss";

type Props = {
  accountIconText: string;
  userName: string;
  logout: () => void;
  onClose: () => void;
  showExitButton: boolean;
};

const HeaderContextualMenu = ({ accountIconText, userName, logout, onClose, showExitButton }: Props) => {
  
  return (
    <Modal
      isOpen={true}
      onDismiss={() => onClose()}
      isBlocking={false}
      isDarkOverlay={false}
      overlay={{style: {backgroundColor: "transparent"}}}
      containerClassName="header-contextual-menu"
    >
      <div className="container">
        <div className="account-info">
          <div className="account-icon">
            <div className="account-icon-text">{accountIconText}</div>
          </div>
          <div className="account-text-container">
            <div className="account-title">Reserve with REX</div>
            <div className="name-block">{userName}</div>
          </div>
        </div>
        <div className="menu-button-container">
          <DefaultButton
            className="menu-button"
            href="https://announcekit.co/rex-reservations/product-updates/"
            target="_blank"
          >
            <img className="menu-icon" src={linkIcon} />
            <div className="menu-text">
              See What's New
            </div>
          </DefaultButton>
        </div>
        <div className="menu-button-container">
          <DefaultButton
            className="menu-button"
            href="https://help.reservewithrex.com"
            target="_blank"
          >
            <img className="menu-icon" src={helpIcon} />
            <div className="menu-text">
              Help Center
            </div>
          </DefaultButton>
        </div>
        {showExitButton && (
            <div className="menu-button-container">
            <DefaultButton
              className="menu-button"
              href="/settings"
            >
              <img className="menu-icon" src={exitIcon} />
              <div className="menu-text">
                Exit
              </div>
            </DefaultButton>
          </div>
        )}
        <div className="menu-button-container">
          <DefaultButton
            className="menu-button"
            onClick={logout}
          >
            <img className="menu-icon" src={logoutIcon} />
            <div className="menu-text">
              Log out
            </div>
          </DefaultButton>
        </div>
      </div>
    </Modal>
  );
};

export default HeaderContextualMenu;