import React, { useState, useEffect, useCallback } from "react";
import {
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  SelectionMode,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import {
  Fabric,
  mergeStyleSets,
  IListProps,
  ScrollablePane,
  DefaultButton,
  Callout,
  DirectionalHint,
} from "office-ui-fabric-react/lib";
import { IDropdownOption } from "office-ui-fabric-react/lib/Dropdown";
import {
  getReservationsAction,
  clearReservationsAction,
  setConfirmationNumberSearchAction,
  setLastLoadedPageAction,
  setPeriodOfReservationsAction,
  setVenueIdFilterAction,
  setColumnsAction,
  selectReservationAndTransactionsAction,
  setFilterParametersForReservationsAction,
  downloadReservationsReportAction,
  downloadReservationsTaxesReportAction,
  downloadReservationsRevenuesReportAction,
  downloadReservationsWithTransactionReportAction,
} from "../../actions/reservation-actions";
import { getVenuesAction } from "../../actions/venue-actions";
import { connect, ConnectedProps } from "react-redux";
import {
  State,
  ReservationSettingsDto,
  VenueNameDto,
  ReservationStatus,
  ReservationsPeriod,
  FilterReservationParameters,
  CurrencyType,
  VenueSettingDto,
} from "../../store/types";
import {
  selectReservations,
  selectReservationsTotalCount,
  selectLastLoadedPage,
  selectPeriodOfReservations,
  selectNumberOfSearchingReservation,
  selectVenueIdFilterReservations,
  selectColumns,
  selectFilterParameters,
  selectIsRequestInProgress,
} from "../../reducers/reservation";
import { selectVenues } from "../../reducers/venues";
import { selectUIConfig } from "../../reducers/ui-reducer";
import { formatSlotDate, formatDuration, formatName, formatTotal } from "../../utils/formats";
import { webConfigUI } from "../../constants/webConfigUI";
import { debounce } from "lodash";
import { DATE_FORMAT_US } from "../../constants/timedate";
import { useViewport } from "../../hooks/responsive";
import SettingsIcon from "../../assets/settings-icon.svgr";
import ChevronDown from "../../assets/custom-chevron-down.svgr";
import FormSelectField from "../common/FormSelectField";
import FormSearchField from "../common/FormSearchField";
import FilterReservations from "./FilterReservations/FilterReservations";
import ActionsButton from "../common/ActionsButton";
import { slotToTime } from "../../../../common/utils/formats";
import "./reservations.scss";

const styles = mergeStyleSets({
  callout: {
    maxWidth: "260px",
    minWidth: "260px",
    minHeight: "50px",
    maxHeight: "150px",
    backgroundColor: "white",
    border: "none",
    borderRadius: "6px",
  },
});

interface CellData {
  firstName: string;
  lastName: string;
  date: string;
  time: number;
  slots: number;
  duration: number;
  lanes: string;
  guests: string;
  timeSlotDuration: number;
  timeSlotShifting: number;
  price: number;
  tax: number;
  serviceFee: number;
  discount: number;
  discountAmount: number;
  currency: CurrencyType;
  addonsPrice: number;
}

interface VenueDropdown {
  key: string;
  text: string;
}

const listPropsForPageRender: IListProps = {
  renderedWindowsAhead: 1,
  renderedWindowsBehind: 1,
};

const wrapperClassName = mergeStyleSets({
  fields: {
    display: "flex",
    justifyContent: "space-between",
    selectors: {
      "& > *": { flexBasis: "43%" },
    },
  },
  mobileFields: {
    display: "flex",
    flexDirection: "column",
    selectors: {
      "& > *": { flexBasis: "43%" },
    },
  }
});

const detailsListStyle = {
  focusZone: {
    cursor: "pointer",
  },
};

interface OwnProps {
  setMainContainerUI: (view: webConfigUI) => void;
}

const mapDispatchToProps = {
  getReservations: getReservationsAction,
  clearReservations: clearReservationsAction,
  setLastLoadedPage: setLastLoadedPageAction,
  setPeriodOfReservations: setPeriodOfReservationsAction,
  setConfirmationNumberSearch: setConfirmationNumberSearchAction,
  getVenues: getVenuesAction,
  setVenueIdFilter: setVenueIdFilterAction,
  setColumns: setColumnsAction,
  selectReservationAndTransactions: selectReservationAndTransactionsAction,
  setFilterParametersForReservations: setFilterParametersForReservationsAction,
  downloadReservationsReport: downloadReservationsReportAction,
  downloadReservationsTaxesReport: downloadReservationsTaxesReportAction,
  downloadReservationsRevenuesReport: downloadReservationsRevenuesReportAction,
  downloadReservationsWithTransactionReport: downloadReservationsWithTransactionReportAction,
};
const mapStateToProps = (state: State) => ({
  reservations: selectReservations(state),
  totalCountOfReservations: selectReservationsTotalCount(state),
  lastLoadedPage: selectLastLoadedPage(state),
  periodOfReservations: selectPeriodOfReservations(state),
  searchedConfirmationNumber: selectNumberOfSearchingReservation(state),
  venues: selectVenues(state),
  selectedVenueId: selectVenueIdFilterReservations(state),
  columns: selectColumns(state),
  uiConfig: selectUIConfig(state),
  filterParameters: selectFilterParameters(state),
  isRequestInProgress: selectIsRequestInProgress(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & OwnProps;

const Reservations = ({
  getReservations,
  clearReservations,
  setConfirmationNumberSearch,
  setLastLoadedPage,
  setPeriodOfReservations,
  reservations,
  totalCountOfReservations,
  searchedConfirmationNumber,
  lastLoadedPage,
  periodOfReservations,
  getVenues,
  venues,
  selectedVenueId,
  setVenueIdFilter,
  columns,
  setColumns,
  selectReservationAndTransactions,
  uiConfig,
  setMainContainerUI,
  filterParameters,
  setFilterParametersForReservations,
  downloadReservationsReport,
  isRequestInProgress,
  downloadReservationsTaxesReport,
  downloadReservationsRevenuesReport,
  downloadReservationsWithTransactionReport,
}: Props) => {
  const { isMobile } = useViewport();
  const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
  const initialColumns: IColumn[] = [
    {
      key: "reservationNumber",
      name: "RESERVATION",
      fieldName: "number",
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
      isResizable: true,
      minWidth: 90,
      maxWidth: 150,
    },
    {
      key: "date",
      name: "DATE",
      fieldName: "date",
      className: "column body2",
      data: "string",
      isPadded: true,
      isResizable: true,
      minWidth: 80,
      maxWidth: 100,
      onRender: (item: CellData) => {
        return <span>{formatSlotDate(item.date, item.slots, item.timeSlotDuration, uiConfig?.dateFormat || DATE_FORMAT_US)}</span>;
      },
    },
    {
      key: "name",
      name: "NAME",
      fieldName: "name",
      className: "column body2",
      data: "string",
      isPadded: true,
      isResizable: true,
      minWidth: 70,
      maxWidth: 150,
      onRender: (item: CellData) => {
        return <span>{formatName(item.firstName, item.lastName)}</span>;
      },
    },
    {
      key: "time",
      name: "TIME",
      fieldName: "time",
      className: "column body2",
      minWidth: 70,
      maxWidth: 100,
      data: "number",
      isPadded: true,
      isResizable: true,
      onRender: (item: CellData) => {
        return <span>{slotToTime(item.slots, item.timeSlotDuration, twelveHourClockFormat, item.timeSlotShifting)}</span>;
      },
    },
    {
      key: "duration",
      name: "DURATION",
      fieldName: "duration",
      className: "column body2",
      minWidth: 80,
      maxWidth: 100,
      data: "string",
      isPadded: true,
      isResizable: true,
      onRender: (item: CellData) => {
        return <span>{formatDuration(item.duration, item.timeSlotDuration)}</span>;
      },
    },
    {
      key: "lanes",
      name: `${uiConfig?.actionText.toUpperCase() || 'LANE'}S`,
      fieldName: 'lanes',
      className: "column body2",
      minWidth: 60,
      maxWidth: 60,
      data: "string",
      isResizable: true,
    },
    {
      key: "guests",
      name: "GUESTS",
      fieldName: "guests",
      className: "column body2",
      minWidth: 60,
      maxWidth: 60,
      data: "string",
      isPadded: true,
      isResizable: true,
    },
    {
      key: "total",
      name: "TOTAL",
      fieldName: "total",
      className: "column body2",
      minWidth: 90,
      maxWidth: 110,
      data: "string",
      isPadded: true,
      isResizable: true,
      onRender: (item: CellData) => {
        return <span>{formatTotal(item.price, item.tax, item.serviceFee, item.addonsPrice, +item.discount+(+item.discountAmount), item.currency)}</span>;
      },
    },
  ];
  const [modifiedReservations, setModifiedReservations] = useState<
    (ReservationSettingsDto | null)[]
  >();
  const [venuesForDropdown, setVenuesForDropDown] = useState<VenueDropdown[]>(
    []
  );
  const [sortedColumn, setSortedColumn] = useState<IColumn>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [isShowActionsMenu, setIsShowActionsMenu] = useState<boolean>(false);

  const onChangePeriodOfReservations = (period: ReservationsPeriod) => {
    if (period !== periodOfReservations) {
      onSetPeriodOfReservations(period)
    }
  };

  const composeParams = ({
    page = lastLoadedPage,
    search = searchedConfirmationNumber,
    venueId = selectedVenueId,
    sort = (sortedColumn && sortedColumn.fieldName && sortedColumn.fieldName) || "",
    sortType = sortedColumn && sortedColumn.isSortedDescending ? "desc" : "asc",
    reservationsPeriod = periodOfReservations,
    filter = encodeURIComponent(JSON.stringify(filterParameters)),
  }: {
    page?: number;
    search?: string;
    venueId?: string;
    sort?: string;
    sortType?: string;
    reservationsPeriod?: ReservationsPeriod;
    filter?: string;
  }) => {
    return { page, search, venueId, sort, sortType, reservationsPeriod, filter };
  };
  const updateReservationsByParams = useCallback(
    ({ ...params }) => {
      clearReservations();
      setLastLoadedPage(0);
      getReservations(composeParams({ ...params, page: 0 }));
    },
    [lastLoadedPage, searchedConfirmationNumber, selectedVenueId, sortedColumn, periodOfReservations, filterParameters]
  );

  const skipColumn = (column: IColumn) => {
    return column.key === "total" || column.key === "name";
  };

  const onColumnClick = (ev: any, column: IColumn | undefined) => {
    if (!column || skipColumn(column)) return;
    const newColumns: IColumn[] = [...columns];
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        if (currColumn.isSortedDescending === undefined) {
          currColumn.isSortedDescending = false;
        } else {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
        }
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    newColumns.length && setColumns(newColumns);
    setSortedColumn(currColumn);
  };

  useEffect(() => {
    sortedColumn && sortedColumn.fieldName && updateReservationsByParams({});
  }, [columns]);

  const composeVenuesForDropDown = (
    venues: VenueSettingDto[]
  ): VenueDropdown[] => {
    return [
      { key: "all", text: "All venues" },
      ...venues.map((item) => ({ key: item.id, text: item.name })),
    ];
  };

  const onLoadNextPage = (page: number) => {
    if (modifiedReservations && modifiedReservations.length && !isRequestInProgress) {
      if (totalCountOfReservations > reservations.length) {
        setLastLoadedPage(page + 1);
        getReservations(composeParams({ page: page + 1 }));
      }
    }
    return null;
  };

  const onSetPeriodOfReservations = (value: ReservationsPeriod) => {
    clearReservations();
    setPeriodOfReservations(value);
    getReservations(composeParams({ reservationsPeriod: value, page: 0 }));
    updateReservationsByParams({ reservationsPeriod: value });
  };

  const onVenueChange = (event: any, option: IDropdownOption | undefined) => {
    if (!option || !option.key) return null;
    setVenueIdFilter(String(option.key));
    updateReservationsByParams({ venueId: option.key });
  };

  const onSearchBoxConfirm = (newValue: string) => {
    setConfirmationNumberSearch(newValue);
    updateReservationsByParams({ search: newValue });
  };

  const onSetFilterParameters = (filterForReservation: FilterReservationParameters[]) => {
    setFilterParametersForReservations(filterForReservation);
    updateReservationsByParams({filter: encodeURIComponent(JSON.stringify(filterForReservation))});
  }

  const onSearchBoxClear = () => {
    setConfirmationNumberSearch("");
  };

  useEffect(() => {
    if (venues && !venues.length) {
      getVenues();
    }
  }, []);

  useEffect(() => {
    if (reservations && reservations.length) {
      if (totalCountOfReservations > reservations.length) {
        setModifiedReservations([...reservations, null]);
      } else {
        setModifiedReservations(reservations);
      }
    } else {
      setModifiedReservations([]);
    }
  }, [reservations]);

  useEffect(() => {
    if (venues && venues.length) {
      setVenuesForDropDown(composeVenuesForDropDown(venues));
      if (
        (reservations &&
          !reservations.length &&
          !searchedConfirmationNumber &&
          !selectedVenueId &&
          !sortedColumn) ||
        !reservations
      ) {
        setColumns(initialColumns);
        setVenueIdFilter("all");
        getReservations(composeParams({ venueId: "all" }));
      }

      columns && setSortedColumn(columns.find((item) => item.isSorted));
    }
  }, [venues]);

  const onSearchChange = (ev: any, text: string | undefined = "") => {
    onSearchBoxConfirm(text);
  };

  const onTransactionClick = (reservation: ReservationSettingsDto) => {
    selectReservationAndTransactions(reservation.id);
  };

  const renderRow = (props: any) => (
    <DetailsRow
      {...props}
      onClick={() => onTransactionClick(props?.item)}
      className={
        props?.item.status === ReservationStatus.Cancelled ||
          props?.item.status === ReservationStatus.CancelledNoRefund || 
          props?.item.status === ReservationStatus.Delete
          ? "cancelled-row"
          : ""
      }
    />
  );

  const onDownload = (e:any)=>{
    e.preventDefault()
    downloadReservationsReport(periodOfReservations)
  }
  const onTaxesDownload = (e:any)=>{
    e.preventDefault()
    downloadReservationsTaxesReport(periodOfReservations)
  }
  const onRevenuesDownload = (e:any)=>{
    e.preventDefault()
    downloadReservationsRevenuesReport(periodOfReservations)
  }
  const onReservationsWithTransactionDownload = (e:any)=>{
    e.preventDefault()
    downloadReservationsWithTransactionReport(periodOfReservations)
  }
  return (
    <div className="reservations">
      <div className="reservations-header">
        <div className="h4 title">
          Reservations
        </div>
        <div className="actions-container">
          <ActionsButton
            id="actions-button"
            onClick={() => setIsShowActionsMenu(true)}
          >
            <div className="actions-button-text">
              Actions
            </div>
            <ChevronDown className="chevron" />
          </ActionsButton>
          {isShowActionsMenu && (
            <Callout
              className={styles.callout}
              gapSpace={0}
              target={`#actions-button`}
              onDismiss={() => setIsShowActionsMenu(false)}
              directionalHint={DirectionalHint.bottomRightEdge}
              isBeakVisible={false}
            >
              <div className="actions-button-container">
                <DefaultButton
                  className="actions-button"
                  onClick={onDownload}
                >
                  <div className="actions-text">
                    Manager's Export
                  </div>
                </DefaultButton>
              </div>
              <div className="actions-button-container">
                <DefaultButton
                  className="actions-button"
                  onClick={onTaxesDownload}
                >
                  <div className="actions-text">
                    Tax Export
                  </div>
                </DefaultButton>
              </div>
              <div className="actions-button-container">
                <DefaultButton
                  className="actions-button"
                  onClick={onRevenuesDownload}
                >
                  <div className="actions-text">
                    Revenue Export
                  </div>
                </DefaultButton>
              </div>
              <div className="actions-button-container">
                <DefaultButton
                  className="actions-button"
                  onClick={onReservationsWithTransactionDownload}
                >
                  <div className="actions-text">
                    Reservations with Transactions
                  </div>
                </DefaultButton>
              </div>
            </Callout>
          )}
        </div>
      </div>
      <div className="reservation-list-container">
        <div className="reservation-toggle">
          <div
            className={`reservation-toggle-label body2 ${periodOfReservations === ReservationsPeriod.all ? "active" : ""}`}
            onClick={() => onChangePeriodOfReservations(ReservationsPeriod.all)}
          >
            Show all
          </div>
          <div
            className={`reservation-toggle-label body2 ${periodOfReservations === ReservationsPeriod.day ? "active" : ""}`}
            onClick={() => onChangePeriodOfReservations(ReservationsPeriod.day)}
          >
            Today only
          </div>
          <div
            className={`reservation-toggle-label body2 ${periodOfReservations === ReservationsPeriod.week ? "active" : ""}`}
            onClick={() => onChangePeriodOfReservations(ReservationsPeriod.week)}
          >
            This week
          </div>
        </div>
        <div className="inputs-block">
          <Fabric className={!isMobile ? wrapperClassName.fields : wrapperClassName.mobileFields}>
            <div className={`filter-block ${!isMobile ? "" : "mobile"}`}>
              <FormSelectField
                options={venuesForDropdown}
                onChange={onVenueChange}
                defaultSelectedKey={selectedVenueId}
              />
            </div>
            <div className={`searchbox-block ${!isMobile ? "" : "mobile"}`}>
              <FormSearchField
                placeholder="Search..."
                onSearch={onSearchBoxConfirm}
                onChange={debounce(onSearchChange, 2000)}
                onClear={onSearchBoxClear}
                value={
                  searchedConfirmationNumber ? searchedConfirmationNumber : ""
                }
              />
            </div>
            <DefaultButton
              className="filter-button"
              onClick={() => setShowFilter(true)}
            >
              <div className="filter-text">
                <SettingsIcon className="filter-icon" />
                Filter
              </div>
            </DefaultButton>
          </Fabric>
        </div>
      </div>
      <div className="reservations-list">
        <ScrollablePane>
          {modifiedReservations && (
            <DetailsList
              items={modifiedReservations}
              columns={columns}
              selectionMode={SelectionMode.none}
              setKey="reservations"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onRenderMissingItem={() => onLoadNextPage(lastLoadedPage)}
              listProps={listPropsForPageRender}
              onColumnHeaderClick={onColumnClick}
              styles={detailsListStyle}
              onRenderRow={renderRow}
            />
          )}
        </ScrollablePane>
      </div>
      {showFilter && <FilterReservations
        onClose={() => setShowFilter(false)}
        filterParametersForReservations={filterParameters}
        setFilterParametersForReservations={onSetFilterParameters}
      />}
    </div>
  );
};
export default connector(Reservations);
