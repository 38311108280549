import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { updateVenueAction } from "../../../actions/venue-actions";
import { selectVenue } from "../../../reducers/venues";
import { State } from "../../../store/types";
import { useViewport } from "../../../hooks/responsive";
import Panel from '../../common/Panel';
import LinkButton from '../../../../../common/components/LinkButton';
import VenueMainActions from '../VenueMainActions';
import EditVenueInfoLanguage from './EditVenueInfoLanguage';
import EditReservationBoking from "./EditReservationBoking";
import EditEventInquiry from "./EditEventInquiry";
import EditConfirmation from "./EditConfirmation";
import EditNotes from "./EditNotes";
import { extractInnerText } from "../../../utils/extractInnerText";
import { Icon, IconButton } from "office-ui-fabric-react";
import FormTextField from "../../../../../common/components/FormTextField";
import TrashIcon from "../../../assets/trash-icon.svgr";
import FormHtmlTextField from "../../FormHtmlTextField/FormHtmlTextField";
import EditCustomTab from "./EditCustomTab";

import "./venueLanguage.scss";

const mapDispatchToProps = {
    updateVenue: updateVenueAction,
};

const mapStateToProps = (state: State) => ({
    venue: selectVenue(state),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>

interface LanguageItem {
    name: string;
    value: string;
    isHTML?: boolean
}

interface FaqItem {
    question: string;
    answer: string;
}
const FAQ_DELIMITER = "|";
const parseFaq = (faq: string): FaqItem[] => {
    const faqs: FaqItem[] = [];
    const faqArray = faq.split(FAQ_DELIMITER);
    faqArray.forEach((item) => {
        const question = item.split(">>")[0];
        const answer = item.split(">>")[1];
        faqs.push({ question, answer });
    });
    return faqs;
}
const parseFaqToString = (faqs: FaqItem[]) => {
    let faqString = faqs.map((item) => `${item.question}>>${item.answer}`).join(FAQ_DELIMITER);
    return faqString;
}


const renderListItem = (item: LanguageItem, index: number) => {
    return (
        <div key={index} className="language-item"><div className="language-item-name subtitle2">{item.name}</div><div className="language-item-value body2">{item.value}</div></div>
    )
};

const renderHTMLListItem = (item: LanguageItem, index: number) => {
    return (
        <div key={index} className="language-item">
            <div className="language-item-name subtitle2">{item.name}</div>
            <div className="language-item-value body2" dangerouslySetInnerHTML={{ __html: extractInnerText(item.value) }} />
        </div>
    )
};

const VenueLanguage = ({
    venue,
    updateVenue,
}: Props) => {
    const { isMiddleScreen } = useViewport();
    const [isOpenVenueInfoLanguage, setIsOpenVenueInfoLanguage] = useState(false);
    const [isOpenReservationBoking, setIsOpenReservationBoking] = useState(false);
    const [isOpenEventInquiry, setIsOpenEventInquiry] = useState(false);
    const [isOpenCustomTab, setIsOpenCustomTab] = useState(false);
    const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
    const [isOpenNotes, setIsOpenNotes] = useState(false);
    const [faqs, setFaq] = useState(venue?.faqs ? parseFaq(venue.faqs) : []);
    const [editIndex, setEditIndex] = useState<number | undefined>();
    const [editFaqTitle, setEditFaqTitle] = useState(false);
    const [faqTitle, setFaqTitle] = useState(venue?.faqTitle || '');

    const venueInfoLanguage: LanguageItem[] = [
        { name: 'Venue Description', value: venue?.description || '-' },
        { name: 'Venue Description Title', value: venue?.descriptionTitle || '-' },
        { name: 'Venue Policies', value: venue?.venueInfo || '-' },
        { name: 'Guest must agree to Venue Policies', value: venue?.showPolicy ? "Yes" : "No" },
        { name: 'Venue Closed', value: venue?.closedMessage || '-' },
        { name: 'Reservation Details (archived)', value: venue?.reservationDetails || '-' },
        { name: 'Reservations Unavailable (archived)', value: venue?.reservationIsNotAvailableText || '-' },
        { name: 'Keep me Updated', value: venue?.keepMeUpdatedText || '-' },
    ]

    const reservationBookingLanguage: LanguageItem[] = [
        { name: 'Page Title', value: venue?.packagesTitle || '-' },
        { name: 'Tab Name', value: venue?.packagesTabName || '-' },
        { name: 'Description', value: venue?.packagesDescription || '-', isHTML: true },
        { name: 'Choose your package', value: venue?.choosePackageTitle || '-' },
        { name: 'No packages available message', value: venue?.inactivePackageMessage || '-', isHTML: true },
        { name: 'Contact only button', value: venue?.contactUsButtonText || '-' },
        { name: 'Checkout Note', value: venue?.textBox || '-', isHTML: true },
        { name: 'Add Ons Title', value: venue?.addonsTitle || '-', isHTML: true },
        { name: 'Add Ons Description', value: venue?.addonsDescription || '-', isHTML: true },
        { name: 'Age Groups Pricing Note', value: venue?.ageGroupsPricingNote || '-' },
        { name: 'Activities Title', value: venue?.activitiesTitle || '-' },
        { name: 'Activities Description', value: venue?.activitiesDescription || '-' },
    ]

    const eventInquiryLanguage: LanguageItem[] = [
        { name: 'Event Inquiry Title', value: venue?.eventInquiryTitle || '-' },
        { name: 'Tab Name', value: venue?.eventInquiryTabName || '-' },
        { name: 'Description', value: venue?.descriptionParty || '-', isHTML: true },
        { name: 'Events Note', value: venue?.makePartyNoteText || '-', isHTML: true },
        { name: 'Min guests for inquiry', value: `${venue?.minGuestsForInquiry || '-'}` },
        { name: 'Max guests for inquiry', value: `${venue?.maxGuestsForInquiry || '-'}` },
        { name: 'Confirmation Page Title', value: venue?.eventConfirmationPageTitle || '-' },
        { name: 'Confirmation Page Copy', value: venue?.eventConfirmationPageCopy || '-', isHTML: true },
        { name: 'Events Redirect', value: venue?.partyUrl ? "Yes" : "No" },
        { name: 'Redirect URL', value: venue?.partyUrl || '-' },
    ]

    const customTabLanguage: LanguageItem[] = [
        { name: 'Custom Tab is Enabled', value: venue?.customTabEnable ? 'Yes' : 'No' },
        { name: 'Tab Name', value: venue?.customTabTabName || '-' },
        { name: 'Description', value: venue?.customTabDescription || '-', isHTML: true },
        { name: 'Custom Script', value: venue?.customTabScript || '-' },
    ]

    const emailsGuestLanguage: LanguageItem[] = [
        { name: 'Section 1 Title', value: venue?.section1Title || '-' },
        { name: 'Section 1 Paragraph', value: venue?.boldTextInAnEmail || '-' },
        { name: 'Section 2 Title', value: venue?.section2Title || '-' },
        { name: 'Section 2 Paragraph', value: venue?.section2Paragraph || '-' },
        { name: 'Email Note', value: venue?.delineationTextInAnEmail || '-' },
    ]
    const emailsInquiryLanguage: LanguageItem[] = [
        { name: 'Event Inquiry Email is Enabled', value: venue?.eventInquiryEmailEnable ? "Yes" : "No" },
        { name: 'Inquiry Email Title', value: venue?.eventEmailTitle || '-' },
        { name: 'Inquiry Email Copy', value: venue?.eventEmailCopy || '-' },
    ]
    const thankYouEmailLanguage: LanguageItem[] = [
        { name: 'Thank You Email is Enabled', value: venue?.thankYouEmailEnable ? "Yes" : "No" },
        { name: 'Send after X minute', value: venue?.thankYouEmailMinutes.toString() || '-' },
        { name: 'Subject', value: venue?.thankYouEmailSubject || '-' },
        { name: 'Header', value: venue?.thankYouEmailHeader || '-' },
        { name: 'Body', value: venue?.thankYouEmailBody || '-', isHTML: true },
    ]

    const notesLanguage: LanguageItem[] = [
        { name: 'Custom Notes', value: venue?.enableCustomNotes ? "Yes" : "No" },
        { name: 'Notes Required', value: venue?.enableRequiredCustomNotes ? "Yes" : "No" },
        { name: 'Notes Title', value: venue?.customNotesTitle || '-' },
        { name: 'Notes Explanation', value: venue?.customNotesExplanationText || '-' },
        { name: 'Notes Prefill', value: venue?.customNotesPrefilledText || '-' },
    ]

    const onUpdateFaq = (item: any, index: number) => {
        const updatedFaqs = [...faqs];
        updatedFaqs[index] = item;
        setFaq(updatedFaqs);
    }

    const onRemoveFaq = (index: number) => {
        const updatedFaqs = [...faqs];
        updatedFaqs.splice(index, 1);
        setFaq(updatedFaqs);
    }

    const onAddFaq = () => {
        const updatedFaqs = [...faqs];
        updatedFaqs.push({ question: '', answer: '' });
        setFaq(updatedFaqs);
        setEditIndex(updatedFaqs.length - 1);
    }

    const saveFaq = () => {
        if (venue) {
            const patch = { faqs: parseFaqToString(faqs) };
            updateVenue({ ...venue, ...patch });
        }
    }

    const onEditFaqTitle = () => {
        setFaqTitle(venue?.faqTitle || '')
        setEditFaqTitle(true)
    }

    const onSaveFaqTitle = () => {
        updateVenue({ ...venue, faqTitle });
        setEditFaqTitle(false)
    }

    const venueInfoTitle = (<><div className="h6">Venue Info</div><LinkButton onClick={() => setIsOpenVenueInfoLanguage(true)}>Edit</LinkButton></>);
    const reservationBookingTitle = (<><div className="h6">Reservation Booking Page</div><LinkButton onClick={() => setIsOpenReservationBoking(true)}>Edit</LinkButton></>);
    const eventInquiryTitle = (<><div className="h6">Event Inquiry Page</div><LinkButton onClick={() => setIsOpenEventInquiry(true)}>Edit</LinkButton></>);
    const customTabTitle = (<><div className="h6">Custom Tab Page</div><LinkButton onClick={() => setIsOpenCustomTab(true)}>Edit</LinkButton></>);
    const faqSectionTitle = (<><div className="h6">FAQ Accordion</div><LinkButton onClick={saveFaq}>Save</LinkButton></>);
    const emailsTitle = (<><div className="h6">Emails</div><LinkButton onClick={() => setIsOpenConfirmation(true)}>Edit</LinkButton></>);
    const notesTitle = (<><div className="h6">Custom Notes</div><LinkButton onClick={() => setIsOpenNotes(true)}>Edit</LinkButton></>);
    return (
        <div className={`venue-language ${isMiddleScreen ? "mobile" : ""}`}>
            <div className="left-panel">
                <Panel className="panel-shift" title={venueInfoTitle}>
                    {venueInfoLanguage.map((item, index) => renderHTMLListItem(item, index))}
                    <div className="panel-padding"></div>
                </Panel>
                <Panel className="panel-shift" title={reservationBookingTitle}>
                    {reservationBookingLanguage.map((item, index) => item.isHTML ? renderHTMLListItem(item, index) : renderListItem(item, index))}
                    <div className="panel-padding"></div>
                </Panel>
                <Panel className="panel-shift" title={eventInquiryTitle}>
                    {eventInquiryLanguage.map((item, index) => item.isHTML ? renderHTMLListItem(item, index) : renderListItem(item, index))}
                    <div className="panel-padding"></div>
                </Panel>
                <Panel className="panel-shift" title={customTabTitle}>
                    {customTabLanguage.map((item, index) => item.isHTML ? renderHTMLListItem(item, index) : renderListItem(item, index))}
                    <div className="panel-padding"></div>
                </Panel>
                <Panel className="panel-shift" title={faqSectionTitle}>
                    {editFaqTitle ? <div className="inline-edit">
                        <FormTextField
                            label="Section Ttitle"
                            className="row"
                            value={faqTitle}
                            onChange={(_: any, value?: string) =>
                                setFaqTitle(value || "")
                            }
                        /> <div style={{ display: 'flex' }}><LinkButton onClick={onSaveFaqTitle}>Save</LinkButton><IconButton
                            iconProps={{ iconName: "Cancel" }}
                            ariaLabel="Cancel"
                            onClick={() => setEditFaqTitle(false)}
                        >
                        </IconButton></div>
                    </div> :
                        <div className="inline-edit"><div className="">
                            <div className="language-item-name subtitle2">Section Title</div>
                            <div className="language-item-value body2">{venue?.faqTitle || ''}</div>
                        </div>
                            <IconButton
                                iconProps={{ iconName: "Edit" }}
                                ariaLabel="Edit"
                                onClick={onEditFaqTitle}
                            >
                            </IconButton>
                        </div>
                    }
                    {faqs.length > 0 && faqs.map((item: FaqItem, index: number) => (
                        index === editIndex ?
                            <div className='faq-item' key={index}>
                                <div className="faq-block">
                                    <FormTextField
                                        label="Question"
                                        className="field fee-field"
                                        value={item?.question || ""}
                                        onChange={(_: any, value?: string) =>
                                            onUpdateFaq({ ...item, question: value || "" }, index)
                                        }
                                        autoComplete="off"
                                    />
                                    <FormHtmlTextField
                                        label="Answer"
                                        className="row"
                                        value={item?.answer || ""}
                                        setValue={(value?: string) =>
                                            onUpdateFaq({ ...item, answer: value || "" }, index)
                                        }
                                    />
                                </div>
                                <IconButton
                                    iconProps={{ iconName: "Cancel" }}
                                    ariaLabel="Cancel"
                                    onClick={() => setEditIndex(undefined)}
                                >
                                </IconButton>
                            </div> : <div className="faq-item" key={index}>
                                <div className="faq-block">
                                    <div className="faq-question"><b>Q:</b><span>{item?.question || ""}</span></div>
                                    <div className="faq-question"><b>A:</b><span dangerouslySetInnerHTML={{ __html: extractInnerText(item?.answer || "") }} /></div>
                                </div>
                                <div className="faq-actions">
                                    <IconButton
                                        iconProps={{ iconName: "Edit" }}
                                        ariaLabel="Edit"
                                        onClick={() => setEditIndex(index)}
                                    >
                                    </IconButton>

                                    <IconButton
                                        className="delete-button"
                                        ariaLabel="Remove Service Fee"
                                        onClick={() => { onRemoveFaq(index); setEditIndex(undefined) }}
                                    >
                                        <TrashIcon />
                                    </IconButton>
                                </div>
                            </div>
                    ))}
                    <div className="">
                        <LinkButton className="add-button" onClick={() => onAddFaq()}>
                            <Icon iconName="Add" className="add" />
                            Add Question
                        </LinkButton>
                    </div>
                    <div className="panel-padding"></div>
                </Panel>
                <Panel className="panel-shift" title={emailsTitle}>
                    <div className="panel-subtitle">Guest Email Confirmation</div>
                    {emailsGuestLanguage.map((item, index) => renderHTMLListItem(item, index))}
                    <div className="panel-subtitle">Inquiry Email</div>
                    {emailsInquiryLanguage.map((item, index) => renderHTMLListItem(item, index))}
                    <div className="panel-padding"></div>
                    <div className="panel-subtitle">Thank You Email</div>
                    {thankYouEmailLanguage.map((item, index) => renderHTMLListItem(item, index))}
                    <div className="panel-padding"></div>
                </Panel>
                <Panel className="panel-shift" title={notesTitle}>
                    {notesLanguage.map((item, index) => renderListItem(item, index))}
                    <div className="panel-padding"></div>
                </Panel>
            </div>
            <div className={`right-panel ${isMiddleScreen ? "mobile" : ""}`}>
                <VenueMainActions />
            </div>
            {isOpenVenueInfoLanguage && <EditVenueInfoLanguage onClose={() => setIsOpenVenueInfoLanguage(false)} />}
            {isOpenReservationBoking && <EditReservationBoking onClose={() => setIsOpenReservationBoking(false)} />}
            {isOpenEventInquiry && <EditEventInquiry onClose={() => setIsOpenEventInquiry(false)} />}
            {isOpenCustomTab && <EditCustomTab onClose={() => setIsOpenCustomTab(false)} />}
            {isOpenConfirmation && <EditConfirmation onClose={() => setIsOpenConfirmation(false)} />}
            {isOpenNotes && <EditNotes onClose={() => setIsOpenNotes(false)} />}
        </div>
    );
};

export default connector(VenueLanguage);