import React, {useEffect, useRef, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {Modal} from "office-ui-fabric-react";
import {setErrorAction, updateSettingsAction} from "../../../../actions/settings-actions";
import {
  selectError,
  selectIsSettingsRequestInProgress,
  selectPrivacyPolicy,
  selectTermsOfUse,
} from "../../../../reducers/settings";
import {selectReadonly} from "../../../../reducers/auth-reducer";
import {State} from "../../../../store/types";
import FormTextField from "../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import "./editPrivacyLinks.scss";

const mapDispatchToProps = {
  updateSettings: updateSettingsAction,
  setError: setErrorAction,
};
const mapStateToProps = (state: State) => ({
  error: selectError(state),
  readonly: selectReadonly(state),
  isSettingsInProgress: selectIsSettingsRequestInProgress(state),
  privacyPolicy: selectPrivacyPolicy(state),
  termsOfUse: selectTermsOfUse(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditSocialMedia = (
  {
    updateSettings,
    onClose,
    setError,
    error,
    readonly,
    isSettingsInProgress,
    privacyPolicy,
    termsOfUse,
  }: Props
) => {
  const saving = useRef(false);
  useEffect(() => {
    setError("");
  }, []);
  useEffect(() => {
    if (!isSettingsInProgress && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isSettingsInProgress]);

  const [formValues, setFormValues] = useState({
    privacyPolicy,
    termsOfUse,
  })

  const onChange = (key: string, value: string) => {
    setFormValues(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  const onUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    saving.current = true;
    updateSettings(formValues);
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-privacy-links"
    >
      <div className="title h4">
        Privacy Links
        <CancelButton onClick={() => onClose()}/>
      </div>
      <form className="main-form" onSubmit={onUpdate}>
        <FormTextField
          label="Privacy Policy"
          className="row"
          value={formValues.privacyPolicy}
          onChange={(_: any, text?: string) => onChange("privacyPolicy", text || "")}
          autoComplete="off"
          disabled={readonly}
        />
        <FormTextField
          label="Terms of Use"
          className="row"
          value={formValues.termsOfUse}
          onChange={(_: any, text?: string) => onChange("termsOfUse", text || "")}
          autoComplete="off"
          disabled={readonly}
        />
        {error && <div className="error">{error}</div>}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditSocialMedia);
