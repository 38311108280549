import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  DefaultButton,
  Callout,
  DirectionalHint,
  mergeStyleSets,
  Modal,
} from "office-ui-fabric-react";
import { capitalize, orderBy } from "lodash";
import {
  State,
  ReservationSettingsDto,
  TransactionSettingDto,
  Pricing,
  PaymentType,
  ReservationStatus,
  PaymentStatus,
  customFields,
  CancelType,
  ReservationIntegrationDto,
  FeeType,
  AddonType,
} from "../../store/types";
import {
  selectReservation,
  selectTransactions,
} from "../../reducers/reservation";
import {
  resyncIntegrationAction,
  refundTransactionAction,
  cancelReservationAction,
  resendConfirmationEmailAction,
  setTransactionAndReservationClearAction,
  removeReservationAction,
} from "../../actions/reservation-actions";
import { setWebConfigUIAction } from "../../actions/ui-actions";
import { getVenuesAction } from "../../actions/venue-actions";
import dayjs from "dayjs";
import {
  formatGuests,
  formatDuration,
  formatPrice,
  formatReservationDate,
  formatGiftCardNumbers,
} from "../../utils/formats";
import {
  formatLanes,
  getDepositAmountWithCurrency,
  slotToTime,
  formatCustomFees,
  getReservationOccasion,
} from "../../../../common/utils/formats";
import { useViewport } from "../../hooks/responsive";
import ConfirmResyncIntegration from "./ConfirmResyncIntegration/ConfirmResyncIntegration";
import ConfirmResend from "./ConfirmResend/ConfirmResend";
import ConfirmRefund from "./ConfirmRefund/ConfirmRefund";
import ConfirmCancellation from "./ConfirmCancellation/ConfirmCancellation";
import ActionsButton from "../common/ActionsButton";
import LocationIcon from "../../assets/locations-icon.svgr";
import ChevronDown from "../../assets/custom-chevron-down.svgr";
import BackButton from "../common/BackButton/BackButton";
import Panel from "../common/Panel";
import { selectUIConfig } from "../../reducers/ui-reducer";
import { selectVenues } from "../../reducers/venues";
import { webConfigUI } from "../../constants/webConfigUI";
import "./reservationDetails.scss";
import CancelButton from "../../../../common/components/CancelButton";
import LinkButton from "../../../../common/components/LinkButton";

interface ReservationItem {
  name: string;
  value: string;
}

const RESERVATION_DATE_FORMAT = "MMM DD, YYYY";
const RESERVATION_TIME_FORMAT = "HH:mm";

const styles = mergeStyleSets({
  callout: {
    maxWidth: "260px",
    minWidth: "260px",
    minHeight: "50px",
    maxHeight: "192px",
    backgroundColor: "white",
    border: "none",
    borderRadius: "6px",
  },
});

const composeStartDate = (reservation: ReservationSettingsDto) => {
  const date = formatReservationDate(reservation, RESERVATION_DATE_FORMAT);
  return date;
};
const composeStartTime = (
  reservation: ReservationSettingsDto,
  twelveHourClockFormat: boolean
) => {
  const time = slotToTime(
    reservation.startSlot,
    reservation.timeSlotDuration,
    twelveHourClockFormat,
    reservation.timeSlotShifting
  );
  return time;
};

const SeeMoreModal = ({
  title,
  text,
  onClose,
}: {
  title: string;
  text: string;
  onClose: () => void;
}) => {
  return (
    <Modal
      isOpen={true}
      onDismiss={() => onClose()}
      isBlocking={false}
      containerClassName="confirm-cancellation"
    >
      <div className="container">
        <div className="header h4">
          <div>{title}</div>
          <CancelButton onClick={() => onClose()} />
        </div>
        {text}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Close</LinkButton>
        </div>
      </div>
    </Modal>
  );
};

const TransactionItem = ({
  t,
  r,
}: {
  t: TransactionSettingDto;
  r: ReservationSettingsDto;
}) => {
  const date = dayjs(
    new Date(t.date).toLocaleString("en-US", {
      timeZone: r.venueTimeZone,
    })
  );
  const content = getContent(t, r);
  const paymentStatus = getPaymentStatus(t);

  return (
    <div className="transaction" key={t.id}>
      {content}
      <div className="tdate">{`${date.format(
        RESERVATION_DATE_FORMAT
      )} at ${date.format(RESERVATION_TIME_FORMAT)}`}</div>
      <div className="tstatus">{paymentStatus}</div>
      {t.isFailed && <div className="t-error">{t.card}</div>}
    </div>
  );
};

const IntegrationItem = ({
  integration,
  r,
}: {
  integration: ReservationIntegrationDto;
  r: ReservationSettingsDto;
}) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const date = dayjs(
    new Date(integration.date).toLocaleString("en-US", {
      timeZone: r.venueTimeZone,
    })
  );
  let textInformation: any = integration.info || integration.message;
  if (integration.status !== "Succeeded" && textInformation?.length > 200) {
    textInformation = (
      <div className="tstatus">
        Failed: &nbsp;
        <span className="see-more" onClick={() => setIsShowMore(true)}>
          See more
        </span>
      </div>
    );
  }
  return (
    <div className="integration-item" key={integration.id}>
      <div className="integration-line">
        <div
          className={
            integration.status === "Succeeded"
              ? "item-circle blue"
              : "item-circle red"
          }
        />
        <div className="tdate">{`${date.format(
          RESERVATION_DATE_FORMAT
        )} at ${date.format(RESERVATION_TIME_FORMAT)}`}</div>
      </div>
      <div className="integration-line">
        <div className="border-line"></div>
        <div>
          <div className="status">{integration.integrationName}</div>

          <div className="tstatus">{textInformation}</div>
          <div className="tstatus">{`By ${integration.email} (${integration.role})`}</div>
        </div>
      </div>
      {isShowMore && (
        <SeeMoreModal
          title={integration.integrationName}
          text={integration.info || integration.message}
          onClose={() => setIsShowMore(false)}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = {
  resyncIntegration: resyncIntegrationAction,
  refundTransaction: refundTransactionAction,
  cancelReservation: cancelReservationAction,
  removeReservation: removeReservationAction,
  resendConfirmationEmail: resendConfirmationEmailAction,
  setWebConfigUI: setWebConfigUIAction,
  setTransactionAndReservationClear: setTransactionAndReservationClearAction,
  getVenues: getVenuesAction,
};

const mapStateToProps = (state: State) => ({
  transactions: selectTransactions(state),
  reservation: selectReservation(state),
  uiConfig: selectUIConfig(state),
  venues: selectVenues(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const renderListItem = (item: ReservationItem, index: number) => (
  <div key={index} className="detail">
    <div className="detail-title subtitle2">{item.name}</div>
    <div className="detail-text body2">{item.value}</div>
  </div>
);

const ReservationDetails = ({
  transactions,
  reservation,
  uiConfig,
  venues,
  getVenues,
  resyncIntegration,
  refundTransaction,
  cancelReservation,
  removeReservation,
  resendConfirmationEmail,
  setWebConfigUI,
  setTransactionAndReservationClear,
}: Props) => {
  useEffect(() => {
    if (venues && !venues.length) {
      getVenues();
    }
  }, []);
  const { isMiddleScreen } = useViewport();
  const actionText = uiConfig?.actionText || "Lane";
  const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
  const [isShowActionsMenu, setIsShowActionsMenu] = useState<boolean>(false);
  const [showSyncIntegration, setShowSyncIntegration] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [showRefund, setShowRefund] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const venue = venues.find((v) => v.id === reservation?.venueId);
  let integrations: ReservationIntegrationDto[] = [];
  if (reservation?.integrations && reservation?.integrations?.length > 0) {
    if (
      reservation.integrations[0].counter > 0 &&
      reservation.integrations[reservation.integrations.length - 1].counter > 0
    ) {
      integrations = orderBy(reservation.integrations, "counter", "desc");
    } else {
      integrations = orderBy(reservation.integrations, "data", "desc");
    }
  }

  const doSyncIntegration = (integration?: string) => {
    setShowSyncIntegration(false);
    if (reservation && integration !== undefined && integration !== "") {
      resyncIntegration(reservation, integration);
    }
  };

  const doResend = (email?: string) => {
    setShowResend(false);
    if (reservation && email !== undefined && email !== "") {
      resendConfirmationEmail(reservation, email);
    }
  };

  const doRefund = (refund?: number) => {
    setShowRefund(false);
    if (reservation && refund !== undefined && refund) {
      refundTransaction(reservation, refund);
    }
  };

  const doCancel = (cancelCondition?: CancelType) => {
    setShowCancel(false);
    if (reservation && cancelCondition !== undefined) {
      if (cancelCondition === CancelType.deleteReservation) {
        removeReservation(reservation);
      } else {
        cancelReservation(reservation, cancelCondition);
      }
    }
  };

  if (!reservation) {
    return null;
  }
  const createdDate = dayjs(
    new Date(reservation.createdAt).toLocaleString("en-US", {
      timeZone: reservation.venueTimeZone,
    })
  );
  const reservationDate = `${createdDate.format(
    RESERVATION_DATE_FORMAT
  )} at ${createdDate.format(RESERVATION_TIME_FORMAT)} (${
    reservation.venueTimeZone
  })`;
  const isInactiveReservation =
    reservation.status === ReservationStatus.Delete ||
    reservation.status === ReservationStatus.Pending;
  const isDisableResendAndCancelActions =
    isInactiveReservation ||
    reservation.status === ReservationStatus.Cancelled ||
    reservation.status === ReservationStatus.CancelledNoRefund;
  const isDisableRefundAction =
    reservation.status === ReservationStatus.Unpaid ||
    reservation.status === ReservationStatus.Uncaptured;

  const slotsInHour = 60 / reservation.timeSlotDuration;
  const rate =
    reservation.pricing === Pricing.flatRate
      ? (reservation.price * slotsInHour) / reservation.duration
      : reservation.pricing === Pricing.perPersonFlat
      ? reservation.price / reservation.guests
      : reservation.pricing === Pricing.perPerson
      ? (reservation.price * slotsInHour) /
        reservation.duration /
        reservation.guests
      : (reservation.price * slotsInHour) /
        reservation.duration /
        reservation.lanes;
  const summary =
    reservation.pricing === Pricing.flatRate
      ? `${formatPrice(rate, reservation.currency)} / ${formatDuration(
          reservation.duration,
          reservation.timeSlotDuration
        )}`
      : reservation.pricing === Pricing.perPersonFlat
      ? `${formatGuests(reservation.guests)} x ${formatPrice(
          rate,
          reservation.currency
        )}`
      : reservation.pricing === Pricing.perPerson
      ? `${formatGuests(reservation.guests)} x ${formatPrice(
          rate,
          reservation.currency
        )} / ${formatDuration(
          reservation.duration,
          reservation.timeSlotDuration
        )}`
      : `${formatLanes(reservation.lanes, actionText)} x ${formatPrice(
          rate,
          reservation.currency
        )} / ${formatDuration(
          reservation.duration,
          reservation.timeSlotDuration
        )}`;

  const addonPriceElements =
    reservation.addons && reservation.addons.length > 0
      ? reservation.addons.map((addon, index) => {
          const addonInfo = `${addon.name} x ${addon.quantity}`;
          let addonPrice = +addon.price;
          if (addon.options && addon.options.length > 0) {
            addonPrice =
              addonPrice +
              addon.options.reduce((acc, option) => {
                return acc + +option.price;
              }, 0);
          }
          addonPrice = Math.round(addonPrice * addon.quantity * 100) / 100;
          return (
            <div className="order-summary-row" key={index}>
              <div className="order-summary-title">{addonInfo}</div>
              <div className="order-summary-amount">
                {formatPrice(addonPrice, reservation.currency, true)}
              </div>
            </div>
          );
        })
      : null;

  const reservationSubtotal = (
    +reservation.price +
    +reservation.tax +
    +reservation.addonsPrice
  ).toFixed(2);

  const customFees = formatCustomFees(
    reservation.customFees,
    reservation.currency
  );
  let customFeeElements = null;
  if (customFees.length > 0) {
    customFeeElements = customFees.map(({ name, value }) => (
      <div className="order-summary-row" key={name}>
        <div className="order-summary-title">{name}</div>
        <div className="order-summary-amount">{value}</div>
      </div>
    ));
  }

  const total =
    Math.round(
      (((+reservation.price + +reservation.tax) * 100) / 100 +
        +reservation.serviceFee +
        +reservation.addonsPrice -
        +reservation.giftCardPaid +
        +reservation.modificationFee -
        +reservation.discountAmount) *
        100
    ) / 100;
  const totalDue = Math.round((total - reservation.payed) * 100) / 100;
  const depositParamValue =
    +reservation.deposit <= +reservation.depositParam
      ? +reservation.deposit
      : +reservation.depositParam;
  const depositParamText =
    reservation.depositType === FeeType.PERCENT
      ? `${+reservation.depositParam}%`
      : `${formatPrice(depositParamValue, reservation.currency)}`;

  const customerDetailsTitle = (
    <>
      <div className="h6 details-header">Customer Details</div>
    </>
  );
  const reservationDetailsTitle = (
    <>
      <div className="h6 details-header">Reservation Details</div>
    </>
  );
  const customFieldsTitle = (
    <>
      <div className="h6 details-header">Custom Fields</div>
    </>
  );
  const orderSummaryTitle = (
    <>
      <div className="h6 order-summary-header">Payment</div>
    </>
  );
  const paymentHistoryTitle = (
    <>
      <div className="h6 details-header">Payment History</div>
    </>
  );
  const integrationsTitle = (
    <>
      <div className="h6 details-header">Timeline</div>
    </>
  );

  const customerDetailText: ReservationItem[] = [
    { name: "First Name", value: reservation.firstName || "-" },
    { name: "Last Name", value: reservation.lastName || "-" },
    { name: "Email", value: reservation.email || "-" },
    { name: "Phone", value: reservation.phone || "-" },
  ];
  let guestCount = reservation.guests;
  if (reservation.addons && reservation.addons.length > 0) {
    reservation.addons.forEach((addon) => {
      if (addon.addonType === AddonType.guestCount) {
        guestCount += addon.quantity;
      }
    });
  }
  const reservationDetailsText: ReservationItem[] = [
    { name: "Package Name", value: reservation.packageName || "-" },
    {
      name: "Time",
      value: composeStartTime(reservation, twelveHourClockFormat) || "-",
    },
    { name: "Date", value: composeStartDate(reservation) || "-" },
    { name: "Guests", value: guestCount.toString() || "-" },
    { name: "Occasion", value: getReservationOccasion(reservation) || "-" },
    {
      name: "Duration",
      value:
        formatDuration(reservation.duration, reservation.timeSlotDuration) ||
        "-",
    },
    { name: `${actionText}s`, value: reservation.lanes.toString() || "-" },
    { name: "Notes", value: reservation.customNotes || "-" },
    { name: "Transaction ID", value: transactions[0]?.referenceNumber || "-" },
    { name: "Status", value: reservation.status || "-" },
    {
      name: "Gift Card Numbers",
      value: formatGiftCardNumbers(reservation.giftCardNumbers) || "-",
    },
    { name: "Admin Notes", value: reservation.adminNotes || "-" },
    { name: "Reservation Token", value: reservation.token || "-" },
  ];
  let customFieldsText: ReservationItem[] = [];
  if (reservation.customFieldsInformation) {
    reservation.customFieldsInformation.split(";").forEach((item) => {
      const customParams = item.split(/:(.*)/s);
      if (
        customParams[0] !== "playerDetailsForSteltronic" &&
        !!customFields[customParams[0]]
      ) {
        customFieldsText = [
          ...customFieldsText,
          {
            name: customFields[customParams[0]]?.fieldsName,
            value: customParams[1] || "-",
          },
        ];
      }
    });
  }
  let maxRefund = 0;
  transactions.forEach((transaction) => {
    if (
      transaction.isRefund ||
      transaction.isModified ||
      transaction.isCancelled
    ) {
      maxRefund = maxRefund - +transaction.amount;
    } else if (
      !(
        transaction.isCancelled ||
        transaction.isIncomplete ||
        transaction.isUncaptured ||
        transaction.isFailed
      )
    ) {
      maxRefund = maxRefund + +transaction.amount;
    }
  });
  const discountAmount = +reservation.discount + +reservation.discountAmount;
  const discount =
    discountAmount > 0
      ? `-${formatPrice(discountAmount, reservation.currency)}`
      : formatPrice(discountAmount, reservation.currency);

  return (
    <div className="reservation-container">
      <div className="reservation">
        <BackButton
          text={"Reservations"}
          onBack={() => {
            setTransactionAndReservationClear();
            setWebConfigUI(webConfigUI.RESERVATION);
          }}
        />
        <div className="reservation-information-container">
          <div className="reservation-information-header">
            <div className="reservation-number h4">
              {`#${reservation.number}`}
            </div>
            <div className="actions-container">
              <ActionsButton
                id="actions-button"
                onClick={() => setIsShowActionsMenu(true)}
              >
                <div className="actions-button-text">Actions</div>
                <ChevronDown className="chevron" />
              </ActionsButton>
              {isShowActionsMenu && (
                <Callout
                  className={styles.callout}
                  gapSpace={0}
                  target={`#actions-button`}
                  onDismiss={() => setIsShowActionsMenu(false)}
                  directionalHint={DirectionalHint.bottomRightEdge}
                  isBeakVisible={false}
                >
                  <div className="actions-button-container">
                    <DefaultButton
                      className="actions-button"
                      onClick={() => setShowSyncIntegration(true)}
                    >
                      <div className="actions-text">Manual Sync</div>
                    </DefaultButton>
                    <DefaultButton
                      className="actions-button"
                      onClick={() => setShowResend(true)}
                      disabled={isDisableResendAndCancelActions}
                    >
                      <div className="actions-text">Resend email</div>
                    </DefaultButton>
                    <DefaultButton
                      className="actions-button"
                      onClick={() => setShowRefund(true)}
                      disabled={!!reservation.goTabId}
                    >
                      <div className="actions-text">Refund</div>
                    </DefaultButton>
                    <DefaultButton
                      className="actions-button"
                      onClick={() => setShowCancel(true)}
                    >
                      <div className="actions-text">Cancel Reservation</div>
                    </DefaultButton>
                  </div>
                </Callout>
              )}
            </div>
          </div>
          <div className="reservation-information-subtitle">
            <div className="parameter">
              <div className="text">{reservationDate}</div>
            </div>
            <div className="parameter">
              <LocationIcon className="icon" />
              <div className="text address">{reservation.venueName}</div>
            </div>
          </div>
          <div
            className={`reservation-information-main-container ${
              isMiddleScreen ? "mobile" : ""
            }`}
          >
            <div className="reservation-info">
              <Panel
                className={`panel-shift details-container ${
                  isMiddleScreen ? "mobile" : "panel-with-two-columns"
                }`}
                title={customerDetailsTitle}
              >
                {customerDetailText.map((item, index) =>
                  renderListItem(item, index)
                )}
              </Panel>
              <Panel
                className={`panel-shift details-container ${
                  isMiddleScreen ? "mobile" : "panel-with-two-columns"
                }`}
                title={reservationDetailsTitle}
              >
                {reservationDetailsText.map((item, index) =>
                  renderListItem(item, index)
                )}
              </Panel>
              {reservation.customFieldsInformation && (
                <Panel
                  className={`panel-shift details-container ${
                    isMiddleScreen ? "mobile" : "panel-with-two-columns"
                  }`}
                  title={customFieldsTitle}
                >
                  {customFieldsText.map((item, index) =>
                    renderListItem(item, index)
                  )}
                </Panel>
              )}
            </div>
            <div className="payment-info">
              <div className="order-summary-container">
                {orderSummaryTitle}
                <div className="order-summary-data">
                  <div className="order-summary-row">
                    <div className="order-summary-title">{summary}</div>
                    <div className="order-summary-amount">
                      {formatPrice(reservation.price, reservation.currency)}
                    </div>
                  </div>
                  {addonPriceElements}
                  {reservation.giftCardPaid && reservation.giftCardPaid > 0 ? (
                    <div className="order-summary-row">
                      <div className="order-summary-title">Gift Card</div>
                      <div className="order-summary-amount">
                        -
                        {formatPrice(
                          reservation.giftCardPaid,
                          reservation.currency,
                          true
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div className="order-summary-row">
                    <div className="order-summary-title">Tax</div>
                    <div className="order-summary-amount">
                      {formatPrice(reservation.tax, reservation.currency)}
                    </div>
                  </div>
                  <div className="order-summary-row">
                    <div className="order-summary-title">Subtotal</div>
                    <div className="order-summary-amount">
                      {formatPrice(+reservationSubtotal, reservation.currency)}
                    </div>
                  </div>
                  <div className="order-summary-row">
                    <div className="order-summary-title">Discount</div>
                    <div className="order-summary-amount">{discount}</div>
                  </div>
                  <div className="order-summary-row">
                    <div className="order-summary-title">Deposit</div>
                    <div className="order-summary-amount">
                      {depositParamText}
                    </div>
                  </div>
                  {customFeeElements}
                  {!(+reservation.modificationFee || +reservation.refunded) && (
                    <div className="order-summary-row">
                      <div className="order-summary-title bold">Total</div>
                      <div className="order-summary-amount bold">
                        {formatPrice(+total, reservation.currency)}
                      </div>
                    </div>
                  )}
                </div>
                {!!(+reservation.modificationFee || +reservation.refunded) && (
                  <div className="order-summary-data block">
                    {!!reservation.modificationFee && (
                      <div className="order-summary-row">
                        <div className="order-summary-title">
                          Modification Fee
                        </div>
                        <div className="order-summary-amount">
                          {formatPrice(
                            +reservation.modificationFee,
                            reservation.currency
                          )}
                        </div>
                      </div>
                    )}
                    {!!reservation.refunded && (
                      <div className="order-summary-row">
                        <div className="order-summary-title">Refunded</div>
                        <div className="order-summary-amount">
                          -{" "}
                          {formatPrice(
                            +reservation.refunded,
                            reservation.currency
                          )}
                        </div>
                      </div>
                    )}
                    <div className="order-summary-row">
                      <div className="order-summary-title bold">Total</div>
                      <div className="order-summary-amount bold">
                        {formatPrice(+total, reservation.currency)}
                      </div>
                    </div>
                  </div>
                )}
                <div className="order-summary-data block">
                  <div className="order-summary-row">
                    <div className="order-summary-title">Paid by customer</div>
                    <div className="order-summary-amount">
                      {formatPrice(+reservation.payed, reservation.currency)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-summary-total">
                <div className="order-summary-title bold">Total Due</div>
                <div className="order-summary-amount bold">
                  {formatPrice(+totalDue, reservation.currency)}
                </div>
              </div>
              <Panel
                className="panel-shift details-container history"
                title={paymentHistoryTitle}
              >
                <div className="history-data">
                  <div className="transactions-list">
                    {transactions.map((t, index) => (
                      <div key={index}>
                        {TransactionItem({ t, r: reservation })}
                        {index < transactions.length - 1 && (
                          <div className="line" />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </Panel>
              <Panel
                className="panel-shift details-container history"
                title={integrationsTitle}
              >
                <div className="history-data">
                  <div className="transactions-list">
                    {integrations.map((integration, index) =>
                      IntegrationItem({ integration, r: reservation })
                    )}
                  </div>
                </div>
              </Panel>
            </div>
          </div>
        </div>
      </div>
      {showSyncIntegration && (
        <ConfirmResyncIntegration
          venue={venue}
          onClose={(integration?: string) => {
            doSyncIntegration(integration);
          }}
        />
      )}
      {showCancel && (
        <ConfirmCancellation
          onClose={(cancelCondition?: CancelType) => {
            doCancel(cancelCondition);
          }}
          isHideCancelWithRefund={isDisableRefundAction}
          isDisableResendAndCancelActions={isDisableResendAndCancelActions}
        />
      )}
      {showResend && (
        <ConfirmResend
          clientEmail={reservation.email}
          onClose={(email?: string) => {
            doResend(email);
          }}
        />
      )}
      {showRefund && (
        <ConfirmRefund
          onClose={(refund?: number) => {
            doRefund(refund);
          }}
          deposit={maxRefund}
          currency={reservation.currency}
        />
      )}
    </div>
  );
};

export default connector(ReservationDetails);

function getContent(t: TransactionSettingDto, r: ReservationSettingsDto) {
  const amount = getDepositAmountWithCurrency(+t.amount, r.currency);
  const isRefund = t.isRefund || t.isCancelled || t.isModified;
  const isFailed = t.isFailed;
  const cardInfo = isFailed
    ? ""
    : t.paymentType === PaymentType.heartland
    ? `on ${t.cardType} ending in ${t.card}`
    : `via ${capitalize(t.paymentType)} on card ${t.card}`;

  if (r.status === ReservationStatus.Unpaid) {
    return <div>{`Charge for ${amount} made by walk-in`}</div>;
  }

  if (t.paymentType === PaymentType.heartland) {
    return (
      <div>{`${isRefund || isFailed ? "Refund" : "Charge"} for ${formatPrice(
        t.amount,
        r.currency
      )} ${cardInfo}`}</div>
    );
  }

  if (t.paymentType === PaymentType.square) {
    const isSquareRefund = t.referenceNumber === "refund";
    return (
      <div>{`${
        isRefund || isFailed || isSquareRefund ? "Refund" : "Charge"
      } for ${formatPrice(t.amount, r.currency)} ${cardInfo}`}</div>
    );
  }

  return (
    <div>{`${isRefund || isFailed ? "Refund" : "Charge"} for ${formatPrice(
      t.amount,
      r.currency
    )} ${cardInfo}`}</div>
  );
}

function getPaymentStatus(t: TransactionSettingDto): string {
  if (t.isIncomplete) {
    if (t.paymentType === PaymentType.square) {
      return "pending";
    }
    return PaymentStatus.Incomplete;
  }
  if (t.isCancelled && t.referenceNumber !== PaymentStatus.Refund) {
    return PaymentStatus.Cancelled;
  }
  if (t.isUncaptured) {
    return PaymentStatus.Uncaptured;
  }
  if (t.isFailed) {
    return PaymentStatus.Failed;
  }
  return PaymentStatus.Succeeded;
}
