import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Modal, Toggle } from "office-ui-fabric-react";
import {
  updateVenueAction,
  setVenueErrorAction,
} from "../../../../actions/venue-actions";
import {
  selectVenue,
  selectIsLoadingVenue,
  selectVenueError,
} from "../../../../reducers/venues";
import { State } from "../../../../store/types";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import FormTextField from "../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import { isValidUrl } from "../../../../utils/validate";
import CancelButton from "../../../../../../common/components/CancelButton";
import FormHtmlTextField from "../../../FormHtmlTextField/FormHtmlTextField";

import "./editEventInquiry.scss";

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
  setVenueError: setVenueErrorAction,
};
const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  error: selectVenueError(state),
  readonly: selectReadonly(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditEventInquiry = ({
  updateVenue,
  venue,
  readonly,
  isLoadingVenue,
  error,
  onClose,
  setVenueError,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    setVenueError("");
  }, []);
  useEffect(() => {
    if (!isLoadingVenue && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingVenue]);
  const [eventInquiryTitle, setEventInquiryTitle] = useState(
    venue?.eventInquiryTitle
  );
  const [eventInquiryTabName, setEventInquiryTabName] = useState(
    venue?.eventInquiryTabName
  );
  const [descriptionParty, setDescriptionParty] = useState(
    venue?.descriptionParty
  );
  const [partyNoteText, setPartyNoteText] = useState(venue?.makePartyNoteText);
  const [minGuestsForInquiry, setMinGuestsForInquiry] = useState(
    venue?.minGuestsForInquiry ? `${venue?.minGuestsForInquiry}` : "0"
  );
  const [maxGuestsForInquiry, setMaxGuestsForInquiry] = useState(
    venue?.maxGuestsForInquiry ? `${venue?.maxGuestsForInquiry}` : "0"
  );
  const [eventConfirmationPageTitle, setEventConfirmationPageTitle] = useState(
    venue?.eventConfirmationPageTitle
  );
  const [eventConfirmationPageCopy, setEventConfirmationPageCopy] = useState(
    venue?.eventConfirmationPageCopy
  );
  const [enableRequiredPartyUrl, setEnableRequiredPartyUrl] = useState(
    venue?.partyUrl ? true : false
  );
  const [partyUrl, setPartyUrl] = useState(venue?.partyUrl);
  const closed = venue?.closed || false;

  const getPatch = () => {
    return {
      eventInquiryTitle: eventInquiryTitle || "Event Inquiry",
      eventInquiryTabName: eventInquiryTabName || "Event Inquiry",
      descriptionParty,
      makePartyNoteText: partyNoteText || "",
      minGuestsForInquiry: minGuestsForInquiry ? +minGuestsForInquiry : 0,
      maxGuestsForInquiry: maxGuestsForInquiry ? +maxGuestsForInquiry : 0,
      eventConfirmationPageTitle: eventConfirmationPageTitle || "",
      eventConfirmationPageCopy: eventConfirmationPageCopy || "",
      partyUrl: enableRequiredPartyUrl ? partyUrl : "",
    };
  };

  const onUpdateEventInquiries = (e: React.FormEvent) => {
    e.preventDefault();
    if (enableRequiredPartyUrl && partyUrl && !isValidUrl(partyUrl)) {
      setVenueError("Please enter a valid URL");
      saving.current = false;
    }

    const emptyHTML = "<p><br></p>";

    if (
      !enableRequiredPartyUrl &&
      !closed &&
      (!descriptionParty || descriptionParty === emptyHTML)
    ) {
      setVenueError("Please enter an Events description");
      saving.current = false;
      return;
    }

    if (
      venue &&
      ((!enableRequiredPartyUrl && descriptionParty) ||
        (enableRequiredPartyUrl && partyUrl && isValidUrl(partyUrl)))
    ) {
      const patch = getPatch();
      //todo: send patch only
      saving.current = true;
      updateVenue({ ...venue, ...patch });
    }
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-event-inquiry"
    >
      <div className="title h4">
        Event Inquiry Page
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="main-form" onSubmit={onUpdateEventInquiries}>
        <FormTextField
          label="Event Inquiry Title"
          className="row"
          value={eventInquiryTitle}
          onChange={(_, text) => setEventInquiryTitle(text)}
          autoComplete="off"
          disabled={readonly}
        />
        <FormTextField
          label="Tab Name"
          className="row"
          value={eventInquiryTabName}
          onChange={(_, text) => setEventInquiryTabName(text)}
          autoComplete="off"
          disabled={readonly}
        />
        <FormHtmlTextField
          label="Description"
          className="row"
          value={descriptionParty}
          setValue={setDescriptionParty}
          disabled={readonly}
        />
        <FormHtmlTextField
          label="Events Note"
          className="row"
          value={partyNoteText}
          setValue={setPartyNoteText}
          disabled={readonly}
        />
        <FormTextField
          label="Min guests for inquiry"
          className="row"
          type="number"
          value={minGuestsForInquiry || "0"}
          onChange={(_, value) => setMinGuestsForInquiry(value || "0")}
          autoComplete="off"
          disabled={readonly}
        />
        <FormTextField
          label="Max guests for inquiry"
          className="row"
          type="number"
          value={maxGuestsForInquiry || "0"}
          onChange={(_, value) => setMaxGuestsForInquiry(value || "0")}
          autoComplete="off"
          disabled={readonly}
        />
        <FormTextField
          label="Confirmation Page Title"
          className="row"
          value={eventConfirmationPageTitle}
          onChange={(_, text) => setEventConfirmationPageTitle(text)}
          autoComplete="off"
          disabled={readonly}
        />
        <FormHtmlTextField
          label="Confirmation Page Copy"
          className="row"
          value={eventConfirmationPageCopy}
          setValue={setEventConfirmationPageCopy}
          disabled={readonly}
        />
        <Toggle
          label="Events Redirect"
          className="row toggle"
          checked={enableRequiredPartyUrl}
          onChange={() => setEnableRequiredPartyUrl(!enableRequiredPartyUrl)}
          inlineLabel
          disabled={readonly}
        />
        <FormTextField
          label="Redirect URL"
          className="row"
          value={partyUrl}
          onChange={(_, text) => setPartyUrl(text)}
          autoComplete="off"
          required={enableRequiredPartyUrl}
          disabled={readonly || !enableRequiredPartyUrl}
        />
        {error && <div className="error">{error}</div>}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit" disabled={readonly}>
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditEventInquiry);
