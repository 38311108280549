import {
    Checkbox,
    Modal,
    Toggle
} from "office-ui-fabric-react";
import React, { useEffect, useRef, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import ColorButton from "../../../../../common/components/ColorButton";
import LinkButton from "../../../../../common/components/LinkButton";
import { rem } from "../../../../../common/utils/formats";
import { updateAddonAction } from "../../../actions/addon-action";
import { updatePackageAndNavigatePageAction } from "../../../actions/package-action";
import { selectAddonError, selectCurrentAddon, selectIsLoadingAddon } from "../../../reducers/addons";
import { selectReadonly, selectVenuePage } from "../../../reducers/auth-reducer";
import { selectPlans } from "../../../reducers/membership";
import { State } from "../../../store/types";
import '../../VenueDetails/Packages/PackageDetails/EditMembershipSettings/editMembershipSettings.scss';

const checkboxStyle = {
    root: {
        margin: "0 0 20px",
    },
    checkbox: {
        width: rem(20),
        height: rem(20),
    },
    text: {
        fontSize: rem(13),
        lineHeight: "1.5",
    },
    checkmark: {
        fontSize: rem(13),
    },
};

const mapDispatchToProps = {
    updateAddon: updateAddonAction,
    updatePackageAndNavigatePage: updatePackageAndNavigatePageAction,
};
const mapStateToProps = (state: State) => ({
    currentAddon: selectCurrentAddon(state),
    readonly: selectReadonly(state),
    venuePage: selectVenuePage(state),
    isLoadingAddon: selectIsLoadingAddon(state),
    error: selectAddonError(state),
    plans: selectPlans(state),

});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
    onClose: () => void;
};

const EditMembershipsSettings = ({
    readonly,
    onClose,
    updateAddon,
    currentAddon,
    isLoadingAddon,
    error,
    plans
}: Props) => {
    const saving = useRef(false);
    useEffect(() => {
        if (!isLoadingAddon && !error && saving.current) {
            saving.current = false;
            onClose();
        }
    }, [isLoadingAddon]);


    const [isMemberOnlyAddon, setIsMemberOnlyAddon] = useState<boolean>(currentAddon?.isMemberOnlyAddon || false);
    const [assignedPlans, setAssignedPlans] = useState<string[]>(currentAddon?.membershipPlanIds || []);


    const handlePlanChange = (planId: string, isChecked: boolean) => {
        setAssignedPlans(prevPlans =>
            isChecked ? [...prevPlans, planId] : prevPlans.filter(id => id !== planId)
        );
    };

    const getPatch = () => {
        return {
            isMemberOnlyAddon,
            membershipPlanIds: assignedPlans
        };
    }

    const onUpdate = (e: React.FormEvent) => {
        e.preventDefault();

        saving.current = true;
        const patch = getPatch();
        updateAddon({ ...currentAddon, ...patch })
    };

    return (
        <Modal
            isOpen={true}
            onDismiss={onClose}
            isBlocking={false}
            containerClassName="edit-package"
        >
            <div className="title h4">
                Membership Settings
            </div>
            <form className="package" onSubmit={onUpdate}>
                <div className="row">
                    <Toggle
                        inlineLabel
                        checked={isMemberOnlyAddon}
                        onChange={(_, checked) => setIsMemberOnlyAddon(checked || false)}
                        disabled={readonly}
                        label={
                            <span className='body2 semibold'>Member Only Addon</span>
                        }
                    />
                </div>
                {plans?.length > 0 ?
                    <div className="row row-plan">
                        <span className="body2 semibold">Assign Plans</span>
                        <div className="assign-plans">
                            {plans.map((plan) => (
                                <Checkbox
                                    key={plan.id}
                                    label={plan.name}
                                    checked={assignedPlans?.includes(plan.id)}
                                    onChange={(_, checked) => handlePlanChange(plan.id, checked || false)}
                                    styles={checkboxStyle}
                                />
                            ))}

                        </div>
                    </div>
                    : null}

                <div className="buttons-container">
                    <LinkButton onClick={onClose}>Cancel</LinkButton>
                    <ColorButton
                        type="submit"
                        disabled={readonly}
                    >
                        Save Changes
                    </ColorButton>
                </div>
            </form>
        </Modal>
    );
};

export default connector(EditMembershipsSettings);
