import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Modal, Toggle } from "office-ui-fabric-react";
import {
  updateVenueAction,
  setVenueErrorAction,
} from "../../../../actions/venue-actions";
import {
  selectVenue,
  selectIsLoadingVenue,
  selectVenueError,
} from "../../../../reducers/venues";
import { State } from "../../../../store/types";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import FormTextField from "../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import FormHtmlTextField from "../../../FormHtmlTextField/FormHtmlTextField";

import "./editCustomTab.scss";

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
  setVenueError: setVenueErrorAction,
};
const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  error: selectVenueError(state),
  readonly: selectReadonly(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditCustomTab = ({
  updateVenue,
  venue,
  readonly,
  isLoadingVenue,
  error,
  onClose,
  setVenueError,
}: Props) => {
  const saving = useRef(false);

  useEffect(() => {
    setVenueError("");
  }, []);

  useEffect(() => {
    if (!isLoadingVenue && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingVenue]);

  const [customTabEnable, setCustomTabEnable] = useState(
    venue?.customTabEnable
  );
  const [customTabTabName, setCustomTabTabName] = useState(
    venue?.customTabTabName
  );
  const [customTabDescription, setCustomTabDescription] = useState(
    venue?.customTabDescription
  );
  const [customTabScript, setCustomTabScript] = useState(
    venue?.customTabScript
  );

  const getPatch = () => {
    return {
      customTabEnable,
      customTabTabName: customTabTabName || "Custom Tab",
      customTabDescription,
      customTabScript,
    };
  };

  const onUpdateCustomTab = (e: React.FormEvent) => {
    e.preventDefault();
    const patch = getPatch();
    saving.current = true;
    updateVenue({ ...venue, ...patch });
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-custom-tab"
    >
      <div className="title h4">
        Custom Tab Page
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="main-form" onSubmit={onUpdateCustomTab}>
        <Toggle
          label="Enable/Disable"
          className="row toggle"
          checked={customTabEnable}
          onChange={() => setCustomTabEnable(prev => !prev)}
          inlineLabel
          disabled={readonly}
        />
        <FormTextField
          label="Tab Name"
          className="row"
          value={customTabTabName}
          onChange={(_, text) => setCustomTabTabName(text)}
          autoComplete="off"
          disabled={readonly}
        />
        <FormHtmlTextField
          label="Description"
          className="row"
          value={customTabDescription}
          setValue={setCustomTabDescription}
          disabled={readonly}
        />
        <FormTextField
          label="Custom Script"
          className="row"
          value={customTabScript}
          onChange={(_, text) => setCustomTabScript(text)}
          multiline
          autoAdjustHeight
          disabled={readonly}
        />
        {error && <div className="error">{error}</div>}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit" disabled={readonly}>
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditCustomTab);
