import React, { useState, useCallback } from "react";
import {
  Modal,
  ChoiceGroup,
  IChoiceGroupOption,
} from "office-ui-fabric-react";
import { color } from "../../../constants/styles";
import CancelButton from "../../../../../common/components/CancelButton";
import LinkButton from "../../../../../common/components/LinkButton";
import ColorButton from "../../../../../common/components/ColorButton";
import { CancelType } from "../../../store/types";
import "./confirmCancellation.scss";

const choiceGroupStyle = {
  root: {
    marginBottom: "20px",
  },
  field: {
    color: color.dark,
    fontSize: "16px",
    fontFamily: "Inter, sans-serif",
  }
}

type Props = {
  onClose: (cancelCondition?: CancelType) => void;
  isHideCancelWithRefund: boolean;
  isDisableResendAndCancelActions: boolean;
};

const ConfirmCancellation = ({ onClose, isHideCancelWithRefund, isDisableResendAndCancelActions }: Props) => {
  const [cancelCondition, setCancelCondition] = useState(isDisableResendAndCancelActions ? CancelType.deleteReservation : isHideCancelWithRefund ? CancelType.cancelWithoutRefound : CancelType.cancelWithRefound);

  const cancelOptions: IChoiceGroupOption[] = [
    {
      key: CancelType.cancelWithRefound,
      text: CancelType.cancelWithRefound,
      styles: choiceGroupStyle,
      disabled: isHideCancelWithRefund || isDisableResendAndCancelActions
    },
    {
      key: CancelType.cancelWithoutRefound,
      text: CancelType.cancelWithoutRefound,
      styles: choiceGroupStyle,
      disabled: isDisableResendAndCancelActions
    },
    {
      key: CancelType.deleteReservation,
      text: CancelType.deleteReservation,
      styles: choiceGroupStyle,
    },
  ];
  const onChange = useCallback(
    (
      ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
      option?: IChoiceGroupOption
    ) => {
      setCancelCondition(option?.key as CancelType || CancelType.cancelWithRefound);
    },
    []
  );
  const onCancel = () => {
    onClose(cancelCondition);
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={() => onClose()}
      isBlocking={false}
      containerClassName="confirm-cancellation"
    >
      <div className="container">
        <div className="header h4">
          <div>Cancel Reservation</div>
          <CancelButton onClick={() => onClose()} />
        </div>
        <ChoiceGroup
          selectedKey={cancelCondition}
          options={cancelOptions}
          onChange={onChange}
        />
        <div className="buttons-container">
          <LinkButton onClick={() => onClose()}>Cancel</LinkButton>
          <ColorButton onClick={() => onCancel()}>Cancel Reservation</ColorButton>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmCancellation;
