import React, {useState} from "react";
import { DefaultButton } from "office-ui-fabric-react";
import HeaderContextualMenu from "./HeaderContextualMenu/HeaderContextualMenu";
import HeaderLeftMenu from "./HeaderLeftMenu/HeaderLeftMenu";
import ChevronDown from "../../../assets/custom-chevron-down.svgr";
import { useViewport } from "../../../hooks/responsive";
import { webConfigUI } from "../../../constants/webConfigUI";
import { LeftPanelList } from "../../Home/Home";
import "./header.scss";

interface HeaderProps {
  logout: () => void;
  setMainContainerUI: (MainContainerUI: webConfigUI) => void;
  userName: string;
  homeLogo?: string;
  showExitButton: boolean;
  mainContainerUI: webConfigUI;
  leftPanelList: LeftPanelList[];
  goToHost: () => void;
}

const Header = ({
  logout,
  userName,
  homeLogo = '',
  showExitButton,
  mainContainerUI,
  setMainContainerUI,
  leftPanelList,
  goToHost
}: HeaderProps) => {
  const { isMiddleScreen } = useViewport();
  const [isShowContextualMenu, setIsShowContextualMenu] = useState<boolean>(false);
  const [isShowLeftMenu, setIsShowLeftMenu] = useState<boolean>(false);

  const getInitials = (userName: string) => {
    return userName.slice(0, 1).toUpperCase();
  };

  const accountIconText = getInitials(userName);

  return (
    <>
      <div className="common-header">
        <div className="top-section">
        <img className="header-top-image" src={homeLogo} />
        {isMiddleScreen && (
          <DefaultButton
            className="header-button"
            onClick={() => setIsShowLeftMenu(true)}
          >
            Menu
            <ChevronDown className="chevron" />
          </DefaultButton>
        )}
        </div>
        <div
          className={`account ${isMiddleScreen ? "mobile" : ""}`}
          onClick={() => setIsShowContextualMenu(true)}
        >
          <div className="account-button">
            <div className="account-icon">
              <div className="account-icon-text">{accountIconText}</div>
            </div>
            {!isMiddleScreen && (
              <>
                <div className="account-text-container">
                  <div className="account-title">Reserve with REX</div>
                  <div className="name-block">{userName}</div>
                </div>
                <ChevronDown className="chevron" />
              </>
            )}
          </div>
        </div>
      </div>
      {isShowContextualMenu && <HeaderContextualMenu
        accountIconText={accountIconText}
        userName={userName}
        showExitButton={showExitButton}
        logout={logout}
        onClose={() => {
          setIsShowContextualMenu(false);
        }}
      />}
      {isMiddleScreen && (
        <HeaderLeftMenu
          isOpen={isShowLeftMenu}
          mainContainerUI={mainContainerUI}
          setMainContainerUI={setMainContainerUI}
          leftPanelList={leftPanelList}
          onClose={() => {
            setIsShowLeftMenu(false);
          }}
          goToHost={goToHost}
        />
      )}
    </>
  );
};

export default Header;
