import React, { ChangeEvent, useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import {
  Checkbox,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDropdownOption,
  ScrollablePane,
  SelectionMode,
} from "office-ui-fabric-react";
import LinkButton from "../../../../common/components/LinkButton";
import {
  deletePlanImageAction,
  updatePlanAction,
  updatePlanDiscountRelationsAction,
  uploadPlanImageAction,
} from "../../actions/membership-action";
import { useViewport } from "../../hooks/responsive";
import { selectPlan } from "../../reducers/membership";
import { selectUIConfig } from "../../reducers/ui-reducer";
import {
  selectIsImageLoadInProgress,
  selectVenueError,
} from "../../reducers/venues";
import {
  FeeType,
  ScheduleSettingDto,
  State,
  VenueSettingDto,
} from "../../store/types";
import FormSearchField from "../common/FormSearchField";
import VenueImage from "../common/Image";
import Panel from "../common/Panel";
import EditPlan from "./Edit/EditPlan";
import EditMemebershipImage from "./EditMembershipImage";
import MembershipsVenueActions from "./MembershipsVenueActions";
import "./plans.scss";
import { selectDiscounts } from "../../reducers/discounts";
import "../VenueDetails/VenueInfo/venueInfo.scss";

interface PlanItem {
  name: string;
  value: string;
}

export const feeTypeOptions: IDropdownOption[] = [
  {
    key: FeeType.PERCENT,
    text: FeeType.PERCENT,
  },
  {
    key: FeeType.FLAT_RATE,
    text: FeeType.FLAT_RATE,
  },
];

export const cardColorTypeOptions: IDropdownOption[] = [
  { key: "#FF9C72", text: "Coral", data: { background: "#FF9C72" } },
  { key: "#10D8AE", text: "Emerald", data: { background: "#10D8AE" } },
  { key: "#BFB5FF", text: "Lavender", data: { background: "#BFB5FF" } },
  { key: "#FE6D79", text: "Punch", data: { background: "#FE6D79" } },
  { key: "#5FAAFF", text: "Azure", data: { background: "#5FAAFF" } },
  { key: "#FFA2C6", text: "Bubblegum", data: { background: "#FFA2C6" } },
  { key: "#FFD669", text: "Sunshine", data: { background: "#FFD669" } },
  { key: "#E7EFFF", text: "Cloud", data: { background: "#E7EFFF" } },
];

const mapStateToProps = (state: State) => ({
  error: selectVenueError(state),
  isImageLoadInProgress: selectIsImageLoadInProgress(state),
  uiConfig: selectUIConfig(state),
  currentPlan: selectPlan(state),
  discounts: selectDiscounts(state),
});

const mapDispatchToProps = {
  uploadPlanImage: uploadPlanImageAction,
  deletePlanImage: deletePlanImageAction,
  updatePlan: updatePlanAction,
  updatePlanDiscountRelations: updatePlanDiscountRelationsAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & {
  isMiddleScreen: boolean;
  readonly: boolean;
  venue?: VenueSettingDto;
  editVenue: () => void;
  addSchedule: () => void;
  editSchedule: (schedule: ScheduleSettingDto) => void;
  cloneSchedule: (schedule: ScheduleSettingDto) => void;
  removeVenueSchedule: (scheduleId: string) => void;
};

const renderListItem = (item: PlanItem, index: number) => (
  <div key={index} className="venue-item">
    <div className="venue-item-name subtitle2">{item.name}</div>
    <div className="venue-item-value body2">{item.value}</div>
  </div>
);

const MembershipsInfo = ({
  isMiddleScreen,
  isImageLoadInProgress,
  discounts,
  uploadPlanImage,
  deletePlanImage,
  currentPlan,
  updatePlan,
  updatePlanDiscountRelations,
}: Props) => {
  useEffect(() => {
    setSelectedDiscountIds(currentPlan?.discounts || []);
  }, [currentPlan]);

  const { isMobile } = useViewport();
  const [isOpenEditImages, setIsOpenEditImages] = useState(false);
  const [isEditPlan, setIsEditPlan] = useState(false);
  const [searchBenefits, setSearchBenefits] = useState("");
  const [selectedDiscountIds, setSelectedDiscountIds] = useState(
    currentPlan?.discounts || []
  );

  const benefitsColumns: IColumn[] = [
    {
      key: "action",
      fieldName: "action",
      name: "",
      minWidth: 15,
      maxWidth: 15,
      isResizable: true,
      className: "column body2",
      styles: {
        root: {
          width: 30,
        },
      },
      data: "action",
      isPadded: false,
      onRender: (item) => {
        const checked = selectedDiscountIds.includes(item.id);
        const onCheckboxChange = () => {
          if (checked) {
            setSelectedDiscountIds(
              selectedDiscountIds.filter((id) => id !== item.id)
            );
          } else {
            setSelectedDiscountIds([...selectedDiscountIds, item.id]);
          }
        };
        return (
          <div className="checkbox">
            <Checkbox checked={checked} onChange={onCheckboxChange} />
          </div>
        );
      },
    },
    {
      key: "discountName",
      name: "DISCOUNT NAME",
      fieldName: "name",
      minWidth: 120,
      maxWidth: 180,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
    },
    {
      key: "type",
      name: "TYPE",
      fieldName: "type",
      minWidth: 60,
      maxWidth: 100,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
    },
    {
      key: "amount",
      name: "AMOUNT",
      fieldName: "amount",
      minWidth: 60,
      maxWidth: 100,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
    },
    {
      key: "status",
      name: "STATUS",
      fieldName: "status",
      minWidth: 60,
      maxWidth: 100,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      onRender: (item) => {
        const isActive = item.status === "active";
        const statusText = isActive ? "Active" : "Inactive";

        return (
          <div className="status-container">
            <div className={`status-color ${isActive ? "green" : "red"}`} />
            <div className={`status-text body2 ${isActive ? "green" : "red"}`}>
              {statusText}
            </div>
          </div>
        );
      },
    },
  ];

  const onItemInvoked = (item: any): void => {
    console.log(item);
  };
  const uploadImageFile = (files: FileList | null) => {
    if (files && currentPlan?.id) {
      uploadPlanImage(files[0], currentPlan?.id);
    }
  };

  const handleSearchBenefitsChange = (
    event?: ChangeEvent<HTMLInputElement> | undefined,
    newValue?: string | undefined
  ) => {
    setSearchBenefits(newValue || "");
  };

  const handleEditPlan = () => {
    if (currentPlan) updatePlan(currentPlan);
    setIsEditPlan(true);
  };

  const planDetailsTitle = (
    <>
      <div className="h6">Plan Details</div>
      <LinkButton onClick={handleEditPlan}>Edit</LinkButton>
    </>
  );
  const benefitsTitle = (
    <>
      <div className="h6">Benefits</div>
    </>
  );

  const getPlanPrice = () => {
    if (currentPlan?.annualRate && currentPlan?.monthlyRate)
      return `$${currentPlan?.monthlyRate}/month, $${currentPlan?.annualRate}/year`;

    if (currentPlan?.annualRate) return `$${currentPlan?.annualRate}/year`;

    if (currentPlan?.monthlyRate) return `$${currentPlan?.monthlyRate}/month`;
    else return "-";
  };

  const planDetailsSettings: PlanItem[] = [
    { name: "Plan name", value: currentPlan?.name || "-" },
    { name: `Priority`, value: currentPlan?.priority.toString() || "-" },
    { name: "Short Description", value: currentPlan?.shortDescription || "-" },
    { name: "Long Description", value: currentPlan?.longDescription || "-" },
    { name: `One-time setup fee`, value: "No" },
    { name: "Free Trial", value: currentPlan?.trialPeriod?.toString() || "-" },
    { name: "Price", value: getPlanPrice() },
    { name: "Set up fee", value: currentPlan?.setUpFee?.toString() || "-" },
  ];

  let filteredDiscounts = discounts;
  if (searchBenefits) {
    filteredDiscounts = discounts.filter((discount) =>
      discount.name.toLowerCase().includes(searchBenefits.toLowerCase())
    );
  }

  return (
    <div
      className={`venue-info memberships-info ${isMiddleScreen ? "mobile" : ""
        }`}
    >
      <div className="left-panel">
        {isMiddleScreen && <MembershipsVenueActions />}
        <Panel
          className={`panel-shift ${isMiddleScreen ? "mobile" : "panel-with-two-columns"
            }`}
          title={planDetailsTitle}
        >
          {planDetailsSettings.map((item, index) =>
            renderListItem(item, index)
          )}
        </Panel>

        <Panel className={`plans benefits`} title={benefitsTitle}>
          <div className="controls-block">
            <FormSearchField
              placeholder="Search..."
              className={`control-filed ${isMobile ? "mobile" : ""}`}
              value={searchBenefits}
              onChange={handleSearchBenefitsChange}
            />
            <LinkButton
              className="save-link"
              onClick={() =>
                updatePlanDiscountRelations({
                  planId: currentPlan?.id || "",
                  discountIds: selectedDiscountIds,
                })
              }
            >
              Save
            </LinkButton>
          </div>

          <div className="plans-list min-height-extended benefits">
            <ScrollablePane>
              <DetailsList
                items={filteredDiscounts}
                styles={{ focusZone: { cursor: "pointer" } }}
                columns={benefitsColumns}
                selectionMode={SelectionMode.none}
                getKey={(item) => item.id}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onActiveItemChanged={onItemInvoked}
                onRenderDetailsHeader={(props, defaultRender) => {
                  if (!props) {
                    return null;
                  }
                  return <div className="header">{defaultRender!(props)}</div>;
                }}
              />
            </ScrollablePane>
          </div>
        </Panel>
      </div>
      <div className={`right-panel ${isMiddleScreen ? "mobile" : ""}`}>
        {!isMiddleScreen && (
          <>
            <MembershipsVenueActions />
          </>
        )}
        <Panel
          className="panel-shift"
          title={
            <>
              <div className="h6">Image</div>
              <LinkButton onClick={() => setIsOpenEditImages(true)}>
                Edit
              </LinkButton>
            </>
          }
        >
          <div
            className={`image-fields-container  image-fields-container-memberships ${isMiddleScreen ? "mobile" : ""
              }`}
          >
            <div className="image-field">
              <VenueImage
                image={currentPlan?.image}
                loading={isImageLoadInProgress}
                uploadImage={uploadImageFile}
                subTitle="500x500 pixels"
              />
            </div>
          </div>
        </Panel>
      </div>
      {isOpenEditImages && (
        <EditMemebershipImage
          venue={{ id: currentPlan?.id, secondaryVenueImages: [currentPlan?.image] } as VenueSettingDto}
          isImageLoadInProgress={isImageLoadInProgress}
          uploadSecondaryVenueImageFiles={uploadImageFile}
          onClose={() => setIsOpenEditImages(false)}
          deleteVenueImage={deletePlanImage}
        />
      )}

      {isEditPlan && (
        <EditPlan onClose={() => setIsEditPlan(false)} plan={currentPlan} />
      )}
    </div>
  );
};

export default connector(MembershipsInfo);
