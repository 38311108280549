import React, { useEffect } from "react";
import { Modal } from "office-ui-fabric-react";
import { webConfigUI } from "../../../../constants/webConfigUI";
import RexIcon from "../../../../assets/rex-black.png";
import { LeftPanelList } from "../../../Home/Home";
import { menuIcons } from "../../../Home/LeftSidePanel";
import "./headerLeftMenu.scss";

type Props = {
  isOpen: boolean;
  mainContainerUI: webConfigUI;
  setMainContainerUI: (MainContainerUI: webConfigUI) => void;
  leftPanelList: LeftPanelList[];
  onClose: () => void;
  goToHost: () => void
};

const HeaderLeftMenu = ({ isOpen, mainContainerUI, setMainContainerUI, leftPanelList, onClose, goToHost }: Props) => {
  useEffect(() => {
    onClose();
  }, [mainContainerUI]);
  const onChangeMainContainerUI = (newMainContainerUI: webConfigUI) => {
    if (mainContainerUI !== newMainContainerUI) {
      setMainContainerUI(newMainContainerUI);
    }
  }
  const menuPanel = leftPanelList.map(item => {
    const menuIcon = menuIcons[item.icon];
    return <button
      key={item.key}
      className={`content ${mainContainerUI === item.key ? "active" : ""
        }`}
      onClick={() => {
        if(item.key === webConfigUI.HOST_APP){
          goToHost();
        }else{
          onChangeMainContainerUI(item.key);
        }
      }}
    >
      <div className="inner">
        <div className="icon-container">
          {menuIcon}
        </div>
        <div className={`text ${mainContainerUI === item.key ? "active" : ""
          }`}>{item.text}</div>
      </div>
    </button>
  })
  return (
    <Modal
      isOpen={isOpen}
      onDismiss={() => onClose()}
      isBlocking={false}
      isDarkOverlay={false}
      overlay={{ style: { backgroundColor: "transparent" } }}
      containerClassName="header-left-menu-container"
    >
      <div className="header-left-menu">
        <div className="header-left-menu-inner">
          {menuPanel}
        </div>
        <div className="rex-logo">
          <img className="rex" src={RexIcon} />
        </div>
      </div>
    </Modal>
  );
};

export default HeaderLeftMenu;