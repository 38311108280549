import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Modal } from "office-ui-fabric-react";
import {
  updateVenueAction,
  setVenueErrorAction,
} from "../../../../actions/venue-actions";
import {
  selectVenue,
  selectIsLoadingVenue,
  selectVenueError,
} from "../../../../reducers/venues";
import { State } from "../../../../store/types";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import FormTextField from "../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import FormHtmlTextField from "../../../FormHtmlTextField/FormHtmlTextField";

import "./editReservationBoking.scss";

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
  setVenueError: setVenueErrorAction,
};
const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  error: selectVenueError(state),
  readonly: selectReadonly(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditReservationBoking = ({
  updateVenue,
  venue,
  readonly,
  isLoadingVenue,
  error,
  onClose,
  setVenueError,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    setVenueError("");
  }, []);
  useEffect(() => {
    if (!isLoadingVenue && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingVenue]);
  const [packagesTitle, setPackagesTitle] = useState(venue?.packagesTitle);
  const [packagesTabName, setPackagesTabName] = useState(
    venue?.packagesTabName
  );
  const [packagesDescription, setPackagesDescription] = useState(
    venue?.packagesDescription
  );
  const [choosePackageTitle, setChoosePackageTitle] = useState(
    venue?.choosePackageTitle
  );
  const [inactivePackageMessage, setInactivePackageMessage] = useState(
    venue?.inactivePackageMessage
  );
  const [contactUsButtonText, setContactUsButtonText] = useState(
    venue?.contactUsButtonText
  );
  const [textBox, setTextBox] = useState(venue?.textBox);
  const [addonsTitle, setAddonsTitle] = useState(venue?.addonsTitle);
  const [addonsDescription, setAddonsDescription] = useState(
    venue?.addonsDescription
  );
  const [ageGroupsPricingNote, setAgeGroupsPricingNote] = useState(
    venue?.ageGroupsPricingNote
  );
  const [activitiesTitle, setActivitiesTitle] = useState(venue?.activitiesTitle);
  const [activitiesDescription, setActivitiesDescription] = useState(venue?.activitiesDescription);

  const closed = venue?.closed || false;

  const getPatch = () => {
    const emptyHTML = "<p><br></p>";

    return {
      packagesTitle: packagesTitle || "Event Packages",
      packagesTabName: packagesTabName || "Event Packages",
      packagesDescription,
      choosePackageTitle: choosePackageTitle || "Choose your package",
      inactivePackageMessage,
      contactUsButtonText,
      textBox,
      addonsTitle,
      addonsDescription,
      ageGroupsPricingNote,
      activitiesTitle: activitiesTitle === emptyHTML ? "" : activitiesTitle,
      activitiesDescription: activitiesDescription === emptyHTML ? "" : activitiesDescription,
    };
  };

  const onUpdateEventInquiries = (e: React.FormEvent) => {
    e.preventDefault();
    const emptyHTML = "<p><br></p>";

    if (
      !closed &&
      (!packagesDescription || packagesDescription === emptyHTML)
    ) {
      setVenueError("Please enter a Packages Description");
      saving.current = false;
      return;
    }

    const patch = getPatch();
    //todo: send patch only
    saving.current = true;
    updateVenue({ ...venue, ...patch });
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-reservation-booking"
    >
      <div className="title h4">
        Reservation Booking Page
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="main-form" onSubmit={onUpdateEventInquiries}>
        <FormTextField
          label="Page Title"
          className="row"
          value={packagesTitle}
          onChange={(_, text) => setPackagesTitle(text)}
          autoComplete="off"
          disabled={readonly}
        />
        <FormTextField
          label="Tab Name"
          className="row"
          value={packagesTabName}
          onChange={(_, text) => setPackagesTabName(text)}
          autoComplete="off"
          disabled={readonly}
        />
        <FormHtmlTextField
          label="Description"
          className="row"
          value={packagesDescription}
          setValue={setPackagesDescription}
          disabled={readonly}
        />
        <FormTextField
          label="Choose your package"
          className="row"
          value={choosePackageTitle}
          onChange={(_, text) => setChoosePackageTitle(text)}
          autoComplete="off"
          disabled={readonly}
        />
        <FormHtmlTextField
          label="No packages available message"
          className="row"
          value={inactivePackageMessage}
          setValue={setInactivePackageMessage}
          disabled={readonly}
        />
        <FormTextField
          label="Contact only button"
          className="row"
          value={contactUsButtonText}
          onChange={(_, text) => setContactUsButtonText(text || "")}
          autoComplete="off"
          disabled={readonly}
        />
        <FormHtmlTextField
          setValue={setTextBox}
          value={textBox}
          label="Checkout Note"
          className="row"
          readOnly={readonly}
        />
        <FormHtmlTextField
          label="Add Ons Title"
          className="row"
          value={addonsTitle}
          setValue={setAddonsTitle}
          disabled={readonly}
        />
        <FormHtmlTextField
          label="Add Ons Description"
          className="row"
          value={addonsDescription}
          setValue={setAddonsDescription}
          disabled={readonly}
        />
        <FormTextField
          label="Age Groups Pricing Note"
          className="row"
          value={ageGroupsPricingNote}
          onChange={(_, text) => setAgeGroupsPricingNote(text || "")}
          autoComplete="off"
          disabled={readonly}
        />
        <FormHtmlTextField
          label="Activities Title"
          className="row"
          value={activitiesTitle}
          setValue={setActivitiesTitle}
          disabled={readonly}
        />
        <FormHtmlTextField
          label="Activities Description"
          className="row"
          value={activitiesDescription}
          setValue={setActivitiesDescription}
          disabled={readonly}
        />
        {error && <div className="error">{error}</div>}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit" disabled={readonly}>
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditReservationBoking);
