import React, { useState } from "react";
import { IDropdownOption, Modal } from "office-ui-fabric-react";
import { TempTenantUserDto, UserType } from "../../../store/types";
import CancelButton from "../../../../../common/components/CancelButton";
import "./editUser.scss";
import LinkButton from "../../../../../common/components/LinkButton";
import ColorButton from "../../../../../common/components/ColorButton";
import FormTextField from "../../../../../common/components/FormTextField";
import FormSelectField from "../../common/FormSelectField";
import { userTypes } from "../../../constants/userTypes";
import { VenueSettingDto } from "../../../store/types/venues";
import { getIsVenueUser } from "../../../utils/formats";

interface Props {
  onClose: () => void;
  onSave: (user: TempTenantUserDto) => void;
  user: TempTenantUserDto;
  createMode?: boolean;
  isSuperAdmin: boolean;
  venues: VenueSettingDto[];
}

const EditUser = ({
  onClose,
  onSave,
  user,
  createMode = false,
  isSuperAdmin,
  venues,
}: Props) => {
  const [username, setUserName] = useState(user.username);
  const [password, setPassword] = useState('');
  const [type, setType] = useState<UserType | undefined>(user.type);
  const [venueId, setVenueId] = useState<string | undefined>(user.venueId || undefined);

  const venueList = venues ? venues.map(venue => ({
    key: venue.id,
    text: venue.name,
  })) : [];

  const onSaveUser = (e: React.FormEvent) => {
    e.preventDefault();
    let newVenueId = undefined;
    if (type && getIsVenueUser(type)) {
      newVenueId = venueId;
    }
    onSave({ ...user, username, password, type, venueId: newVenueId });
    onClose();
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-user"
    >
      <div className="title h4">
        {createMode ? "Create User" : "Update User"}
        <CancelButton onClick={onClose} />
      </div>
      <form className="form-tenant" onSubmit={onSaveUser}>
        <FormTextField
          label="Email"
          className="row"
          value={username}
          onChange={(_: any, text?: string) => setUserName(text || "")}
          autoComplete="off"
          required={true}
          disabled={!createMode}
        />
        <FormTextField
          label="Password"
          className="row"
          type="password"
          value={password}
          onChange={(_: any, text?: string) => setPassword(text || "")}
          autoComplete="off"
          required={true}
        />
        {isSuperAdmin && (
          <>
          <FormSelectField
            label="Role"
            options={userTypes}
            selectedKey={type}
            onChange={(
              _: React.FormEvent<HTMLDivElement>,
              item?: IDropdownOption
            ) => setType(item?.key as UserType)}
            disabled={!isSuperAdmin}
          />
          {(type && getIsVenueUser(type)) && (
              <FormSelectField
                label="Venue"
                options={venueList}
                selectedKey={venueId}
                onChange={(
                  _: React.FormEvent<HTMLDivElement>,
                  item?: IDropdownOption
                ) => setVenueId(item?.key as string)}
                required={getIsVenueUser(type)}
                disabled={!isSuperAdmin}
              />
          )}
          </>
        )}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit" >
            Save Change
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default EditUser;
