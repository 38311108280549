import React from "react";
import {Link} from "office-ui-fabric-react";
import "./demoLink.scss";

interface DemoLinkProps {
  link: string
}

const DemoLink = ({
  link
}: DemoLinkProps) => {

  
  return (
      <Link href={link} className="demo-link">
      ⓘ This is a demo. Click here to upgrade your plan.
      </Link>
  );
};

export default DemoLink;
