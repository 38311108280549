import { Toggle } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import FormTextField from "../../../../common/components/FormTextField";
import { Accordion, AccordionSummary, Typography, AccordionDetails } from "@material-ui/core";
import { CurrencyType } from "../../store/types";
import ChevronIcon from '../../assets/chevron.svgr'
interface Props {
  stripeAccountId?: string;
  setStripeAccountId?: (value: string) => void;
  currency?: string;
  onCheckStripe: () => void;
  disabledCheck?: boolean;
  isStripeSandboxPayment: boolean;
  setIsStripeSandboxPayment: (value?: boolean) => void;
  readonly?: boolean;
  isStripeV2OtherMethodsEnable: boolean;
  setIsStripeV2ApplePayEnable: (value?: boolean) => void;
  stripeTerminal: string;
  setStripeTerminal: (value: string) => void;
  status: string;
  stripeAdditionalMethods: string;
  setStripeAdditionalMethods: (value: string) => void;
}

const defaultMethods = [
  'Afterpay',
  'Amazon',
  'Cash App',
  'Affirm',
  'Klarna',
  'Bank ACH',
]

const StripeVenueFields = ({
  currency,
  stripeAccountId,
  onCheckStripe,
  disabledCheck,
  isStripeSandboxPayment,
  setIsStripeSandboxPayment,
  readonly,
  isStripeV2OtherMethodsEnable,
  setIsStripeV2ApplePayEnable,
  status,
  stripeTerminal,
  setStripeTerminal,
  setStripeAccountId,
  stripeAdditionalMethods,
  setStripeAdditionalMethods,
}: Props) => {
  const [methodsState, setMethodsState] = useState<{ [key: string]: boolean }>({});
  const [availableMethods, setAvailableMethods] = useState(defaultMethods);

  useEffect(() => {
    // Conditionally add iDEAL only once based on the initial currency check
    if (currency === CurrencyType.EUR && !availableMethods.includes('iDeal')) {
      setAvailableMethods(prevMethods => [...prevMethods, 'iDeal']);
    }
  }, [currency]);
  useEffect(() => {
    // Initialize methods state from the existing stripeAdditionalMethods string
    const initialMethodsState = availableMethods.reduce((acc, method) => {
      const regex = new RegExp(`${method}:\\s*(true|false)`, 'i');
      const match = stripeAdditionalMethods.match(regex);
      acc[method] = match ? match[1] === 'true' : false;
      return acc;
    }, {} as { [key: string]: boolean });

    setMethodsState(initialMethodsState);

  }, [stripeAdditionalMethods]);

  const handleMethodToggle = (method: string, enabled: boolean) => {
    const updatedMethodsState = {
      ...methodsState,
      [method]: enabled,
    };

    setMethodsState(updatedMethodsState);

    const methodsString = availableMethods
      .map(method => `${method}:${updatedMethodsState[method]}`)
      .join(';');

    setStripeAdditionalMethods(methodsString);
  };


  let stripeState = "";

  if (status === "pending") {
    stripeState = "pending";
  } else if (status === "ok") {
    stripeState = "connected";
  }
  const tagStyles = {
    backgroundColor:
      stripeState === "connected"
        ? "#EDF7ED"
        : stripeState === "pending"
          ? "#E3F4FD"
          : "#FBE3E2",
    color:
      stripeState === "connected"
        ? "#27AB6E"
        : stripeState === "pending"
          ? "#4A90E2"
          : "#EC4C47",
    cursor: "default",
    padding: "6px 12px",
  };

  return (
    <div className="stripe-field">
      <div className="row">
        <FormTextField
          value={stripeAccountId}
          placeholder="Stripe account ID"
          disabled={!setStripeAccountId}
          onChange={(_, text) => setStripeAccountId && setStripeAccountId(text || '')}
          className={!stripeAccountId ? "empty-field" : ""}
        />
      </div>
      <div className="row">
        <FormTextField
          value={currency || "$USD"}
          disabled
          className={!currency ? "empty-field" : ""}
        />
      </div>

      <Toggle
        label="Stripe Test mode"
        checked={isStripeSandboxPayment}
        onChange={(_, value) => setIsStripeSandboxPayment(value)}
        inlineLabel
        disabled={readonly}
      />

      <Toggle
        label="Other payment methods"
        checked={isStripeV2OtherMethodsEnable}
        onChange={(_, value) => setIsStripeV2ApplePayEnable(value)}
        inlineLabel
        disabled={readonly}
      />
      {isStripeV2OtherMethodsEnable && (
        <Accordion elevation={1} variant="outlined" style={{ borderRadius: '8px' }} >
          <AccordionSummary expandIcon={<ChevronIcon />}
          >
            <div>Choose Payment Methods</div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {availableMethods.map(method => (
                <Toggle
                  key={method}
                  label={method}
                  checked={methodsState[method]}
                  onChange={(_, value) => handleMethodToggle(method, value || false)}
                  inlineLabel
                  disabled={readonly}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      )}


      <div className="statuses-container">
        <div className="stripe-connect-status" style={tagStyles}>
          {stripeState || "Not Connected"}
        </div>
        <button
          onClick={onCheckStripe}
          disabled={disabledCheck}
          className="button-check"
        >
          Check Status
        </button>
      </div>
    </div>
  );
};

export default StripeVenueFields;
