import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Modal, Toggle } from "office-ui-fabric-react";
import { updateSettingsAction, setErrorAction } from "../../../../actions/settings-actions";
import {
  selectError,
  selectIsSettingsRequestInProgress,
  selectHtmlTitle,
  selectHomeTitle,
  selectHomeLink,
  selectCompanyName,
  selectNewDesign,
} from "../../../../reducers/settings";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import { State } from "../../../../store/types";
import FormTextField from "../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import "./editReservationSettings.scss";

const mapDispatchToProps = {
  updateSettings: updateSettingsAction,
  setError: setErrorAction,
};
const mapStateToProps = (state: State) => ({
  error: selectError(state),
  readonly: selectReadonly(state),
  isSettingsInProgress: selectIsSettingsRequestInProgress(state),
  htmlTitle: selectHtmlTitle(state),
  homeTitle: selectHomeTitle(state),
  homeLink: selectHomeLink(state),
  companyName: selectCompanyName(state),
  newDesign: selectNewDesign(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditReservationSettings = ({
  updateSettings,
  onClose,
  setError,
  error,
  readonly,
  isSettingsInProgress,
  htmlTitle,
  homeTitle,
  homeLink,
  companyName,
  newDesign,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    setError("");
  }, []);
  useEffect(() => {
    if (!isSettingsInProgress && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isSettingsInProgress]);
  const [tabTitle, setTabTitle] = useState(htmlTitle);
  const [title, setTitle] = useState(homeTitle);
  const [logoLink, setLogoLink] = useState(homeLink);
  const [company, setCompany] = useState(companyName);
  const [isNewDesign, setIsNewDesign] = useState(newDesign);

  const onUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    saving.current = true;
    updateSettings({
      htmlTitle: tabTitle,
      homeTitle: title,
      homeLink: logoLink,
      companyName: company,
      newDesign: isNewDesign,
    });
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-reservation-setting"
    >
      <div className="title h4">
        Page Setting
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="main-form" onSubmit={onUpdate}>
        <FormTextField
          label="HTML Title"
          className="row"
          value={tabTitle}
          onChange={(_: any, text?: string) => setTabTitle(text || "")}
          autoComplete="off"
          required={true}
          disabled={readonly}
        />
        <FormTextField
          label="Homepage Title"
          className="row"
          value={title}
          onChange={(_: any, text?: string) => setTitle(text || "")}
          autoComplete="off"
          required={true}
          disabled={readonly}
        />
        <FormTextField
          label="Home Link"
          className="row"
          value={logoLink}
          onChange={(_: any, text?: string) => setLogoLink(text || "")}
          autoComplete="off"
          disabled={readonly}
        />
        <FormTextField
          label="Company Name"
          className="row"
          value={company}
          onChange={(_: any, text?: string) => setCompany(text || "")}
          autoComplete="off"
          required={true}
          disabled={readonly}
        />
        <Toggle
          label="Use new design (please do not touch it)"
          className="integrations-field"
          checked={isNewDesign}
          onChange={(_: any, value?: boolean) => setIsNewDesign(!!value)
          }
          inlineLabel
          disabled={readonly}
        />
        {error && <div className="error">{error}</div>}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditReservationSettings);
