import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { FilterRow } from "./FilterRow/FilterRow";
import { FilterReservationParameters } from "../../../../store/types";
import "./filter.scss";

const connector = connect(null);

interface OwnProps {
  filterParameter: FilterReservationParameters[];
  addFilterParameter: () => void;
  updateFilterParameter: (filterParameter: FilterReservationParameters) => void;
  removeFilterParameter: (filterParameterId: string) => void;
}
type Props = ConnectedProps<typeof connector> & OwnProps;

const Filter = ({
  filterParameter,
  addFilterParameter,
  updateFilterParameter,
  removeFilterParameter,
}: Props) => {

  const onChange = (
    filterParameter: FilterReservationParameters,
    field: string,
    value: string | number | null
  ) => {
    updateFilterParameter({ ...filterParameter, [field]: value });
  };
  const onChangeFilterParameter = (
    filterParameter: FilterReservationParameters,
    field: string,
    value: string | number | null
  ) => {
    updateFilterParameter({
      ...filterParameter,
      [field]: value,
      filterCondition: null,
      filterValue: "",
    });
  };

  return (
    <div className="filter">
      {filterParameter &&
        filterParameter.map((item, index) => (
          <div className="filter-element" key={item.id}>
            <FilterRow
              parameter={item}
              onChange={onChange}
              onChangeFilterParameter={onChangeFilterParameter}
              addFilterParameter={addFilterParameter}
              removeFilterParameter={removeFilterParameter}
            />
            {index < filterParameter.length - 1 && (
              <div
                className="filter-delimiter"
              >
                <div className="and-block">
                  AND
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default connector(Filter)