import React, { useState } from "react";
import { Modal, IDropdownOption } from "office-ui-fabric-react";
import { ResyncIntegrationType, VenueSettingDto } from "../../../store/types";
import FormSelectField from "../../../../../common/components/FormSelectField";
import CancelButton from "../../../../../common/components/CancelButton";
import LinkButton from "../../../../../common/components/LinkButton";
import ColorButton from "../../../../../common/components/ColorButton";
import "./confirmResyncIntegration.scss";

type Props = {
  venue?: VenueSettingDto;
  onClose: (integration?: string) => void;
};

const ConfirmResyncIntegration = ({ onClose, venue }: Props) => {

  const [integration, setIntegration] = useState<string>("");
  const [errorIntegration, setErrorIntegration] = useState<string>("");
  let integrationOptions = [];
  if (venue && venue.enableTripleSeats) {
    integrationOptions.push({
      key: ResyncIntegrationType.Tripleseat,
      text: ResyncIntegrationType.Tripleseat,
    })
  }
  if (venue && venue.enableSteltronic) {
    integrationOptions.push({
      key: ResyncIntegrationType.Steltronic,
      text: ResyncIntegrationType.Steltronic,
    })
  }

  const onCancel = () => {
    if (integration) {
      onClose(integration);
      setIntegration("");
      return;
    }
    if (!integration) {
      setErrorIntegration("This field is required.");
    }
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={() => onClose()}
      isBlocking={false}
      containerClassName="confirm-resync"
    >
      <div className="container">
        <div className="header h4">
          <div>Manual Sync</div>
          <CancelButton onClick={() => onClose()} />
        </div>
        <div className="subtitle2">Select your integration</div>
        <FormSelectField
          options={integrationOptions}
          selectedKey={integration}
          onChange={(
            _: React.FormEvent<HTMLDivElement>,
            item?: IDropdownOption
          ) => setIntegration(item?.key as ResyncIntegrationType)}
        />
        <div className="error">{errorIntegration}</div>
        <div className="buttons-container">
          <LinkButton onClick={() => onClose()}>Cancel</LinkButton>
          <ColorButton onClick={() => onCancel()}>Sync</ColorButton>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmResyncIntegration;
