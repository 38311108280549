import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
  Icon,
  ScrollablePane,
} from "office-ui-fabric-react";
import {
  getVenuesAction,
  selectVenueAction,
} from "../../actions/venue-actions";
import { selectVenues, selectFilteredVenues } from "../../reducers/venues";
import { State, VenueSettingDto } from "../../store/types";
import { setFilteredVenuesAction } from "../../actions/venue-actions";
import { webConfigUI } from "../../constants/webConfigUI";
import FormSearchField from "../common/FormSearchField";
import LocationIcon from "../../assets/locations-icon.svgr";
import ColorButton from "../../../../common/components/ColorButton";
import ActionsButton from "../common/ActionsButton";
import EditVenueInfo from "../VenueDetails/EditVenueInfo/EditVenueInfo";
import { selectReadonly } from "../../reducers/auth-reducer";
import "./venues.scss";

interface CellData {
  closed: boolean;
}

interface OwnProps {
  setMainContainerUI: (view: webConfigUI) => void;
}
const mapDispatchToProps = {
  getVenues: getVenuesAction,
  selectVenue: selectVenueAction,
  setFilteredVenues: setFilteredVenuesAction,
};
const mapStateToProps = (state: State) => ({
  venues: selectVenues(state),
  filteredVenues: selectFilteredVenues(state),
  readonly: selectReadonly(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & OwnProps;

const Venues = ({
  getVenues,
  selectVenue,
  venues,
  setFilteredVenues,
  filteredVenues,
  readonly,
  setMainContainerUI,
}: Props) => {

  const columns: IColumn[] = [
    {
      key: "name",
      name: "VENUE",
      fieldName: "name",
      minWidth: 130,
      maxWidth: 250,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
    },
    {
      key: "city",
      name: "CITY",
      fieldName: "city",
      minWidth: 130,
      maxWidth: 250,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
    },
    {
      key: "address",
      name: "ADDRESS",
      fieldName: "address",
      minWidth: 130,
      maxWidth: 250,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
    },
    {
      key: "status",
      name: "STATUS",
      fieldName: "closed",
      minWidth: 130,
      maxWidth: 250,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      onRender: (item: CellData) => {
        return <span>{getStatusSlot(item.closed)}</span>;
      },
    },
  ];

  useEffect(() => {
    getVenues();
  }, [getVenues]);

  useEffect(() => {
    venues && setFilteredVenues(venues);
  }, [venues]);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const getStatusSlot = (isClosed: boolean) => {
    const statusText = isClosed ? "Closed" : "Open";
    return (
      <div className="status-container">
        <div className={`status-color ${isClosed ? "red" : "green"}`}/>
        <div className={`status-text body2 ${isClosed ? "red" : "green"}`}>
          {statusText}
        </div>
      </div>
    );
  };

  const onItemInvoked = (item: any): void => {
    selectVenue(item as VenueSettingDto);
    setMainContainerUI(webConfigUI.VENUE_DETAILS);
  };

  const onFilter = (ev: any, searchText: string | undefined = ""): void => {
    setFilteredVenues(
      searchText
        ? venues.filter((item) =>
          item.name.toLowerCase().includes(searchText.toLowerCase())
        )
        : venues
    );
  };

  const addVenue = () => {
    selectVenue();
    setIsOpenEdit(true);
  };

  return (
    <div className="venues">
      <div className="venues-header">
        <div className="h4 title">
          Venues
        </div>
        <ActionsButton
          onClick={addVenue}
          disabled={readonly}
        >
          <Icon iconName="Add" className="add-icon"/>
          <div className="add-button-text">
            Add Venue
          </div>
        </ActionsButton>
      </div>
      <div className="venue-list-container">
        <div className="searchbox-block">
          <FormSearchField
            placeholder="Search..."
            onChange={onFilter}
          />
        </div>
      </div>
      {venues.length > 0 ? (
        <div className="venues-list">
          <ScrollablePane>
            <DetailsList
              items={filteredVenues}
              styles={{ focusZone: { cursor: "pointer" } }}
              columns={columns}
              selectionMode={SelectionMode.none}
              getKey={(item) => item.id}
              setKey="none"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onActiveItemChanged={onItemInvoked}
            />
          </ScrollablePane>
        </div>
      ) : (
        <div className="empty-venue-list-container">
          <div className="empty-venue-list">
            <LocationIcon className="location-icon" />
            <div className="empty-venue-list-message">
              Let’s get your first venue set up!
            </div>
            <ColorButton
              className="empty-venue-list-button"
              onClick={addVenue}
              disabled={readonly}
            >
              <Icon iconName="Add" className="add-icon"/>
              <div className="add-button-text">
                Add Venue
              </div>
            </ColorButton>
          </div>
        </div>
      )}
      {isOpenEdit && <EditVenueInfo isCloneVenue={false} onClose={() => setIsOpenEdit(false)} />}
    </div>
  );
};

export default connector(Venues);
