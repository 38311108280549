import React, { useState } from "react";
import { Modal } from "office-ui-fabric-react";
import { regEmail } from "../../../utils/validate";
import FormTextField from "../../../../../common/components/FormTextField";
import CancelButton from "../../../../../common/components/CancelButton";
import LinkButton from "../../../../../common/components/LinkButton";
import ColorButton from "../../../../../common/components/ColorButton";
import "./confirmResend.scss";

type Props = {
  clientEmail: string;
  onClose: (email?: string) => void;
};

const ConfirmResend = ({ clientEmail, onClose }: Props) => {
  
  const [email, setEmail] = useState<string | undefined>(clientEmail);
  const [errorEmail, setErrorEmail] = useState<string>("");

  const regEmailList = (emailList: string): boolean => {
    const separator = ',';
    const arrayOfEmails = emailList.split(separator);
    let isValid = true;
    arrayOfEmails.map(email => {
      const trimEmail = email.trim();
      if (regEmail.test(trimEmail) == false) {
        isValid = false;
      }
    });
    return isValid;
  }
  
  
  const onCancel = () => {
    if (email && regEmailList(email)) {
      onClose(email);
      return;
    }
    if (!email) {
        setErrorEmail("This field is required.");
    }
    if (email && !regEmailList(email)) {
        setErrorEmail("Please enter a valid email address.");
    }
  };
  const onEmailChange = (text?: string) => {
    setErrorEmail("");
    setEmail(text);
  };
  return (
    <Modal
      isOpen={true}
      onDismiss={() => onClose()}
      isBlocking={false}
      containerClassName="confirm-resend"
    >
      <div className="container">
        <div className="header h4">
          <div>Resend Email</div>
          <CancelButton onClick={() => onClose()} />
        </div>
        <div className="subtitle2">Email Address</div>
        <FormTextField
          type="email"
          value={email}
          onChange={(_: any, value?: string) =>
            onEmailChange(
              value ? value : "",
            )
          }
        />
        <div className="error">{errorEmail}</div>
        <div className="buttons-container">
          <LinkButton onClick={() => onClose()}>Cancel</LinkButton>
          <ColorButton onClick={() => onCancel()}>Send</ColorButton>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmResend;
