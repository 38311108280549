import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  ActionButton,
  DefaultButton,
  mergeStyleSets,
  Callout,
  DirectionalHint,
} from "office-ui-fabric-react";
import { startCase } from 'lodash';
import { State, TempTenantUserDto, UserType } from "../../store/types";
import { removeTenantAction, createUserAction, updateUserAction, removeUserAction } from "../../actions/tenants-actions";
import { switchTenantAction } from "../../actions/auth-actions";
import { setWebConfigUIAction } from "../../actions/ui-actions";
import { getVenuesForTenantAction } from "../../actions/venue-actions";
import {
  selectTenant,
  selectIsTenantRequestInProgress,
  selectIsUserRequestInProgress,
} from "../../reducers/tenants";
import { selectIsSuperAdmin } from "../../reducers/auth-reducer";
import { selectVenues } from "../../reducers/venues";
import { webConfigUI } from "../../constants/webConfigUI";
import { useViewport } from "../../hooks/responsive";
import ChevronDown from "../../assets/custom-chevron-down.svgr";
import LoginIcon from "../../assets/login-icon.svg";
import UserIcon from "../../assets/tenants-icon.svgr";
import VertIcon from "../../assets/more-vert.svgr";
import BackButton from "../common/BackButton/BackButton";
import Panel from "../common/Panel";
import ColorButton from "../../../../common/components/ColorButton";
import ActionsButton from "../common/ActionsButton";
import LinkButton from "../../../../common/components/LinkButton";
import EditUser from './EditUser/EditUser';
import CreateTenant from '../Tenants/CreateTenant/CreateTenant';
import DeleteConfirmation from "../common/DeleteConfirmation/DeleteConfirmation";
import EditTenant from "./EditTenant/EditTenant";
import "./tenantDetails.scss";

interface TenantItem {
  name: string;
  value: string;
}

const styles = mergeStyleSets({
  callout: {
    maxWidth: "260px",
    minWidth: "260px",
    minHeight: "100px",
    maxHeight: "150px",
    backgroundColor: "white",
    border: "none",
    borderRadius: "6px",
  },
});

const mapDispatchToProps = {
  removeTenant: removeTenantAction,
  createUser: createUserAction,
  updateUser: updateUserAction,
  removeUser: removeUserAction,
  switchTenant: switchTenantAction,
  setWebConfigUI: setWebConfigUIAction,
  getVenues: getVenuesForTenantAction,
};
const mapStateToProps = (state: State) => ({
  tenant: selectTenant(state),
  isTenantRequestInProgress: selectIsTenantRequestInProgress(state),
  isUserRequestInProgress: selectIsUserRequestInProgress(state),
  isSuperAdmin: selectIsSuperAdmin(state),
  venues: selectVenues(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const renderListItem = (item: TenantItem, index: number) => (<div key={index} className="tenant-item"><div className="tenant-item-name subtitle2">{item.name}</div><div className="tenant-item-value body2">{item.value}</div></div>);

const TenantDetails = ({
  tenant,
  isTenantRequestInProgress,
  isUserRequestInProgress,
  isSuperAdmin,
  venues,
  removeTenant,
  updateUser,
  createUser,
  removeUser,
  switchTenant,
  setWebConfigUI,
  getVenues,
}: Props) => {
  const { isMiddleScreen } = useViewport();
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isCloneVenue, setIsCloneVenue] = useState(false);
  const [isRemoveOpen, setIsRemoveOpen] = useState(false);
  const [isRemoveUserOpen, setIsRemoveUserOpen] = useState(false);
  const [isEditUserOpen, setIsEditUserOpen] = useState(false);
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [isShowActionsMenu, setIsShowActionsMenu] = useState<boolean>(false);
  const [tempUser, setTempUser] = useState<TempTenantUserDto | undefined>();
  const [removeId, setRemoveId] = useState<string | undefined>();

  useEffect(() => {
    if (!isTenantRequestInProgress) {
      setIsOpenEdit(false);
      setIsCloneVenue(false);
    }
  }, [isTenantRequestInProgress]);
  useEffect(() => {
    if (!isUserRequestInProgress) {
      setIsRemoveOpen(false);
      setIsRemoveUserOpen(false);
      setIsEditUserOpen(false);
      setIsAddUserOpen(false);
    }
  }, [isUserRequestInProgress]);
  useEffect(() => {
    if(tenant) {
      getVenues(tenant.id);
    }
  }, [tenant]);


  const editTenant = () => setIsOpenEdit(true);
  const onCloneTenant = () => {
    setIsCloneVenue(true);
  }
  const closeCloneWindow = () => {
    setIsCloneVenue(false);
  }
  const removeTenantOpen = () => {
    if (!tenant) {
      return
    }
    setIsRemoveOpen(true);
  };
  const confirmRemoveTenant = (isConfirm: boolean) => {
    if (isConfirm && tenant) {
      removeTenant(tenant.id)
    }
    setIsRemoveOpen(false);
  };
  const removeUserOpen = (index: number) => {
    if (!tenant || !tenant.users[index]) {
      return
    }
    setRemoveId(tenant.users[index].id)
    setIsRemoveUserOpen(true);
  };
  const confirmRemoveUser = (isConfirm: boolean) => {
    if (isConfirm && tenant && removeId) {
      removeUser(removeId, tenant.id)
    }
    setIsRemoveUserOpen(false);
  };

  const onAddUserOpen = () => {
    if (!tenant) {
      return
    }
    setTempUser({ tenantId: tenant?.id, username: '', password: '' })
    setIsAddUserOpen(true)
  }
  const onAddUser = (data: { username: string, password: string, type?: UserType, venueId?: string }) => {
    if (!tempUser) {
      return
    }
    createUser({ ...tempUser, ...data })
  }
  const onEditUserOpen = (index: number) => {
    if (!tenant) {
      return
    }
    const user = {
      id: tenant.users[index].id,
      tenantId: tenant?.id,
      username: tenant.users[index].username,
      password: '',
      type: tenant.users[index].type,
      venueId: tenant.users[index].venueId,
    }
    setTempUser(user)
    setIsEditUserOpen(true)
  }
  const onUpdateUser = (data: { username: string, password: string, type?: UserType }) => {
    if (!tempUser) {
      return
    }
    updateUser({ ...tempUser, ...data })
  }
  const onSwitchTenant = () => {
    if (!tenant?.id) {
      return
    }
    switchTenant(tenant.id)
  }

  const tenantSettingsTitle = (<><div className="h6">Tenant Settings</div><LinkButton onClick={editTenant}>Edit</LinkButton></>);
  const tenantLoginTitle = (<><div className="h6">Tenant Login</div></>);
  const UsersTitle = (<><div className="h6">Users</div><LinkButton onClick={onAddUserOpen}>Add User</LinkButton></>);

  const tenantSettings: TenantItem[] = [
    { name: 'Name', value: tenant?.name || '-' },
    { name: 'Venues', value: tenant?.venueCount?.toString() || '0' },
    { name: 'Domain', value: tenant?.domain || '-' },
    { name: 'Plan', value: startCase(tenant?.plan) || '-' },
    { name: 'Hide demo banner', value: tenant?.isHideDemoMessage ? "Yes" : "No" },
    { name: 'Onboarding', value: tenant?.isOnboarding ? "Yes" : "No" },
    { name: 'Mixpanel token', value: tenant?.mixpanelToken || '-'},
    { name: 'Partner', value: tenant?.partner?.name || '-'},
    { name: 'Memberships', value: tenant?.hasMemberships ? "Yes" : "No" },
    { name: 'Membership Fee', value: `${tenant?.membershipFee || 0}` },
    { name: 'Booking Fee', value: `${tenant?.bookingFee || 0}` },
  ];

  const userButton = (index: number) => {
    const menuProps = {
      shouldFocusOnMount: true,
      isBeakVisible: false,
      items: [
        { key: `editItem${index}`, text: 'Edit', onClick: () => onEditUserOpen(index) },
        { key: `deleteItem${index}`, text: 'Delete', onClick: () => removeUserOpen(index) },
      ],
    }
    return <ActionButton className="user-button" menuProps={menuProps}><VertIcon /></ActionButton>
  }

  return (
    <div className="tenant-details-container">
      <div className="tenant-details">
        <BackButton
          text={"Clients"}
          onBack={() => {
            setWebConfigUI(webConfigUI.TENANTS)
          }}
        />
        <div className="tenant-details-header">
          <div className="tenant-details-title h4">
            {tenant?.name}
          </div>
          <div className="actions-container">
            <ActionsButton
              id="actions-button"
              onClick={() => setIsShowActionsMenu(true)}
            >
              <div className="actions-button-text">
                Actions
              </div>
              <ChevronDown className="chevron" />
            </ActionsButton>
            {isShowActionsMenu && (
              <Callout
                className={styles.callout}
                gapSpace={0}
                target={`#actions-button`}
                onDismiss={() => setIsShowActionsMenu(false)}
                directionalHint={DirectionalHint.bottomRightEdge}
                isBeakVisible={false}
              >
                <div className="actions-button-container">
                  <DefaultButton
                    className="actions-button"
                    onClick={onCloneTenant}
                  >
                    <div className="actions-text">
                      Clone tenant
                    </div>
                  </DefaultButton>
                  <DefaultButton
                    className="actions-button"
                    onClick={removeTenantOpen}
                  >
                    <div className="actions-text">
                      Delete Tenant
                    </div>
                  </DefaultButton>
                </div>
              </Callout>
            )}
          </div>
        </div>
        <div className="tenant-details-line" />
        <div className={`tenant-info ${isMiddleScreen ? "mobile" : ""}`}>
          <div className="left-panel">
            {isMiddleScreen && (
              <Panel className="panel-shift panel-for-login" title={tenantLoginTitle}>
                <div
                  className="panel-button"
                  onClick={onSwitchTenant}
                >
                  <img src={LoginIcon} className="panel-icon" />
                  <div className="body2">
                    Login
                  </div>
                </div>
              </Panel>
            )}
            <Panel className={`panel-shift ${isMiddleScreen ? "mobile" : "panel-with-two-columns"}`} title={tenantSettingsTitle}>
              {tenantSettings.map((item, index) => renderListItem(item, index))}
              <div className="row">
                <ColorButton
                  className="stripe-button"
                  href={`https://dashboard.stripe.com/customers/${tenant?.stripeCustomerId}`}
                  target="_blank"
                >
                  Stripe
                </ColorButton>
              </div>
            </Panel>
          </div>
          <div className={`right-panel ${isMiddleScreen ? "mobile" : ""}`}>
            {!isMiddleScreen && (
              <Panel className="panel-shift panel-for-login" title={tenantLoginTitle}>
                <div
                  className="panel-button"
                  onClick={onSwitchTenant}
                >
                  <img src={LoginIcon} className="panel-icon" />
                  <div className="body2">
                    Login
                  </div>
                </div>
              </Panel>
            )}
            <Panel className="panel-shift user-list" title={UsersTitle}>
              {tenant && tenant?.users.length > 0
                ? tenant?.users.map((u, index) => (
                  <div key={u.id}>
                  <div className="user-list-row">
                    <div className="subtitle2">
                      <div>{u.username}</div>
                    </div>
                    {userButton(index)}
                  </div>
                  {index < tenant?.users.length - 1 && (<div className="line" />)}
                  </div>
                )) : (
                  <div className="empty-user-list-container">
                    <div className="empty-user-list">
                      <UserIcon className="user-icon" />
                      <div className="empty-user-list-message">
                        There are no users here yet.
                      </div>
                      <ColorButton
                        className="empty-user-list-button"
                        onClick={onAddUserOpen}
                      >
                        <div className="add-button-text">
                          Add User
                        </div>
                      </ColorButton>
                    </div>
                  </div>
                )}
            </Panel>
          </div>
        </div>
      </div>
      {isRemoveOpen && <DeleteConfirmation
        ConfirmationHeader={"Confirmation"}
        ConfirmationText={"Do you want to remove this tenant?"}
        ConfirmationButtonText={"Delete Tenant"}
        onClose={confirmRemoveTenant}
      />}
      {isRemoveUserOpen && <DeleteConfirmation
        ConfirmationHeader={"Confirmation"}
        ConfirmationText={"Do you want to remove this user?"}
        ConfirmationButtonText={"Delete User"}
        onClose={confirmRemoveUser}
      />}
      {isOpenEdit && <EditTenant tenant={tenant} onClose={() => setIsOpenEdit(false)} />}
      {isCloneVenue && <CreateTenant tenantId={tenant?.id} onClose={() => closeCloneWindow()} />}
      {isEditUserOpen && tempUser && <EditUser onClose={() => setIsEditUserOpen(false)} onSave={onUpdateUser} venues={venues} user={tempUser} isSuperAdmin={isSuperAdmin} />}
      {isAddUserOpen && tempUser && <EditUser onClose={() => setIsAddUserOpen(false)} onSave={onAddUser} venues={venues} user={tempUser} createMode isSuperAdmin={isSuperAdmin} />}
    </div>
  );
};

export default connector(TenantDetails);
