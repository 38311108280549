import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
  Icon,
  ScrollablePane,
  ActionButton,
  DetailsRow,
} from "office-ui-fabric-react";
import {
  getUsersAction,
  removeTenantUserAction,
  selectUserAction,
  downloadUsersAction,
} from "../../actions/tenants-actions";
import { selectUsers } from "../../reducers/tenants";
import { State, TenantUserDto, UserType } from "../../store/types";
import UsersIcon from "../../assets/users-icon.svgr";
import VertIcon from "../../assets/more-vert.svgr";
import downloadIcon from "../../assets/download-icon.svg";
import ColorButton from "../../../../common/components/ColorButton";
import ActionsButton from "../common/ActionsButton";
import EditUser from "./EditUser/EditUser";
import { selectReadonly } from "../../reducers/auth-reducer";
import { userTypes } from "../../constants/userTypes";
import "./users.scss";

interface CellData {
  id: string;
  username: string;
  type: UserType;
  venueId?: string;
}

const mapDispatchToProps = {
  getUsers: getUsersAction,
  selectUser: selectUserAction,
  removeUser: removeTenantUserAction,
  downloadUsers: downloadUsersAction,
};
const mapStateToProps = (state: State) => ({
  users: selectUsers(state),
  readonly: selectReadonly(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const Users = ({
  getUsers,
  selectUser,
  removeUser,
  users,
  readonly,
  downloadUsers,
}: Props) => {

  const columns: IColumn[] = [
    {
      key: "username",
      name: "Email",
      fieldName: "username",
      minWidth: 130,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
    },
    {
      key: "type",
      name: "Role",
      fieldName: "type",
      minWidth: 120,
      maxWidth: 220,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
      onRender: (item: CellData) => {
        const userRole = userTypes.find(type => type.key === item.type);
        return <span>{userRole?.text || "-"}</span>;
      },
    },
    {
      key: "action",
      name: "",
      minWidth: 25,
      maxWidth: 25,
      className: "column body2",
      onRender: (item: CellData) => {
        const menuProps = {
          shouldFocusOnMount: true,
          isBeakVisible: false,
          items: [
            { key: 'newItem', text: 'Edit', onClick: () => onItemInvoked(item as TenantUserDto) },
            { key: 'newItem', text: 'Delete', onClick: () => removeUser(item.id) },
          ],
        }
        return <ActionButton className="contextual-menu" menuProps={menuProps}> <VertIcon /></ActionButton>;
      },
      isPadded: true,
    },
  ];

  useEffect(() => {
    getUsers();
  }, [getUsers]);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const onItemInvoked = (item: any): void => {
    selectUser(item as TenantUserDto);
    setIsOpenEdit(true);
  };

  const addUser = () => {
    selectUser();
    setIsOpenEdit(true);
  };

  const renderRow = (props: any) => (
    <DetailsRow
      {...props}
      onClick={() => onItemInvoked(props?.item)}
    />
  );

  const onDownload = (e: any) => {
    e.preventDefault()
    downloadUsers()
  }

  return (
    <div className="users">
      <div className="users-header">
        <div className="title h4">
          <div>Users</div>
          <a href='' onClick={onDownload} >
            <img className="lanes-icon" src={downloadIcon} />
          </a>
        </div>
        <ActionsButton
          onClick={addUser}
          disabled={readonly}
        >
          <Icon iconName="Add" className="add-icon" />
          <div className="add-button-text">
            Add User
          </div>
        </ActionsButton>
      </div>
      {users.length > 0 ? (
        <div className="users-list">
          <ScrollablePane>
            <DetailsList
              items={users}
              styles={{ focusZone: { cursor: "pointer" } }}
              columns={columns}
              selectionMode={SelectionMode.none}
              getKey={(item) => item.id}
              setKey="none"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onRenderRow={renderRow}
            />
          </ScrollablePane>
        </div>
      ) : (
        <div className="empty-users-list-container">
          <div className="empty-users-list">
            <UsersIcon className="location-icon" />
            <div className="empty-users-list-message">
              There are no users here yet.
            </div>
            <ColorButton
              className="empty-user-list-button"
              onClick={addUser}
              disabled={readonly}
            >
              <Icon iconName="Add" className="add-icon" />
              <div className="add-button-text">
                Add User
              </div>
            </ColorButton>
          </div>
        </div>
      )}
      {isOpenEdit && <EditUser onClose={() => setIsOpenEdit(false)} />}
    </div>
  );
};

export default connector(Users);
