import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { IDropdownOption, Toggle } from "office-ui-fabric-react";
import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import ColorButton from "../../../../common/components/ColorButton";
import FormTextField from "../../../../common/components/FormTextField";
import { updateConfigsAction } from "../../actions/configs-actions";
import { getPartnersAction } from "../../actions/partners-actions";
import { setTenantFilterAction } from "../../actions/super-admin-reports-actions";
import { getTenantsAction, selectTenantAction } from "../../actions/tenants-actions";
import { useViewport } from "../../hooks/responsive";
import useTransferSync from "../../hooks/useTransferSync";
import { selectConfigs } from "../../reducers/configs";
import { selectIsTenantRequestInProgress, selectTenants } from "../../reducers/tenants";
import { State } from "../../store/types";
import FormSelectField from "../common/FormSelectField";
import Panel from "../common/Panel";
import TransferHistory from "../Settings/IntegrationsSettings/TransferHistory";
import "./superAdminSettings.scss";

const mapDispatchToProps = {
    updateConfigs: updateConfigsAction,
    getTenants: getTenantsAction,
    setTenant: selectTenantAction,
    getPartners: getPartnersAction,
};

const mapStateToProps = (state: State) => ({
    config: selectConfigs(state),
    tenants: selectTenants(state),
    isTenantRequestInProgress: selectIsTenantRequestInProgress(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const SyncSettings = ({ config, updateConfigs, tenants }: Props) => {
    const [settingsForm, setSettingsForm] = useState({
        aboutGolfUrl: config?.aboutGolfUrl || "",
        aboutGolfApiVersion: config?.aboutGolfApiVersion || "",
        aboutGolfApiKey: config?.aboutGolfApiKey || "",
        aboutGolfSubscriptionKey: config?.aboutGolfSubscriptionKey || "",
        goTabApiAccessId: config?.goTabApiAccessId || "",
        goTabApiAccessSecret: config?.goTabApiAccessSecret || "",
        mchClientId: config?.mchClientId || "",
        mchClientSecret: config?.mchClientSecret || "",
        squareClientId: config?.squareClientId || "",
        squareClientSecret: config?.squareClientSecret || "",
        squareAccessToken: config?.squareAccessToken || "",
    });
    const { isMiddleScreen } = useViewport();

    const onUpdateSettingsForm = (e: any, newValue?: string) => {
        setSettingsForm({
            ...settingsForm,
            [e.target.name]: newValue,
        });
    };

    const onSaveSettings = () => updateConfigs(settingsForm);
    const [open, setOpen] = useState(false);
    const [tenantId, setTenantId] = useState<string | number>('')
    const [isProd, setIsProd] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { sync, isSyncLoading, getHistory, syncLog } = useTransferSync({
        successFunc: () => { setOpen(false); },
        errorFunc: () => setOpen(false)
    })
    const handleConfirm = async () => {
        sync(tenantId)
    };


    const tenantsForDropdown = tenants.map((item) => ({ key: item.id, text: item.name }));
    const domain = tenants.find((item) => item.id === tenantId)?.domain || ''
    const onTenantChange = (event: any, option: IDropdownOption | undefined) => {
        if (!option || !option.key) return null;
        const tenantId = String(option.key);
        setTenantId(tenantId)
        getHistory(tenantId)
    };

    return (
        <div className="super-admin-settings-container">
            <div className="h4 super-admin-settings-title">Sync</div>
            <div className="super-admin-settings">
                <div className="super-admin-settings-information-container">
                    <div className="super-admin-settings-information">

                        <Panel className="panel-shift" title={
                            <div className="row panel-with-button"><div className="h6">Transfer Settings to Sandbox</div>
                                <div className="row sync-row">
                                    {/* <Toggle
                    inlineLabel
                    defaultChecked={false}
                    checked={isProd}
                    onChange={(e, checked?: boolean) => setIsProd(checked as boolean)}
                    label={
                      <div className="to">{isProd ? <>to <div className='badge red' >PROD</div></> : <>to <div className='badge green' >DEV</div></>}</div>
                    }
                  /> */}

                                    <ColorButton
                                        className="save-button"
                                        onClick={handleClickOpen}
                                        disabled={!tenantId}
                                    >
                                        Sync
                                    </ColorButton>
                                </div>
                            </div>} >
                            <div className="panel-transfer__search">
                                <FormSelectField
                                    className="field"
                                    options={tenantsForDropdown}
                                    selectedKey={tenantId}
                                    placeholder="Select tenant from the list"
                                    onChange={onTenantChange}
                                />

                            </div>
                            <TransferHistory isMiddleScreen={isMiddleScreen} items={syncLog} domain={domain} />
                        </Panel>
                    </div>
                </div>
            </div >
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '5px',
                        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
                    },
                }}
            >
                <DialogTitle >
                    Are you sure?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This action cannot be undone. Do you really want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ padding: '20px' }}>
                    <ColorButton
                        onClick={handleClose}
                        style={{ marginRight: '10px', borderColor: "rgb(161, 159, 157)" }}
                        textColor="rgb(161, 159, 157)"

                    >
                        Cancel
                    </ColorButton>
                    <ColorButton className="save-button" onClick={handleConfirm} disabled={isSyncLoading}>
                        Yes
                    </ColorButton>
                </DialogActions>
            </Dialog>
            {/* <Toast open={isSynced} message="Synced successfully!" handleClose={() => setIsSynced(false)} /> */}
        </div >
    );
};

export default connector(SyncSettings);
