import { Modal, Toggle } from "office-ui-fabric-react";
import React, { useEffect, useRef, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import CancelButton from "../../../../../../common/components/CancelButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import { FormField } from "../../../../../../common/components/FormField/FormField";
import FormTextField from "../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../common/components/LinkButton";
import { PhoneInput } from "../../../../../../common/components/PhoneInput/PhoneInput";
import {
  isValidEmail,
  isValidPhone,
} from "../../../../../../common/utils/validate";
import {
  setVenueErrorAction,
  updateVenueAction,
} from "../../../../actions/venue-actions";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import {
  selectIsLoadingVenue,
  selectVenue,
  selectVenueError,
} from "../../../../reducers/venues";
import { State } from "../../../../store/types";
import "./editSkipGuestDetailsSettings.scss";

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
  setVenueError: setVenueErrorAction,
};
const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  readonly: selectReadonly(state),
  error: selectVenueError(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditSkipGuestDetailsSettings = ({
  updateVenue,
  venue,
  readonly,
  error,
  isLoadingVenue,
  onClose,
  setVenueError,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    setVenueError("");
  }, []);
  useEffect(() => {
    if (!isLoadingVenue && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingVenue]);
  useEffect(() => {
    setDefaultEmailForSkip(venue?.defaultEmailForSkip);
    setDefaultPhoneForSkip(venue?.defaultPhoneForSkip);
    setDefaultFirstNameForSkip(venue?.defaultFirstNameForSkip);
    setDefaultLastNameForSkip(venue?.defaultLastNameForSkip);
    setSkipGuestName(venue?.skipGuestName);
  }, [venue]);
  const [defaultEmailForSkip, setDefaultEmailForSkip] = useState(
    venue?.defaultEmailForSkip
  );
  const [defaultPhoneForSkip, setDefaultPhoneForSkip] = useState(
    venue?.defaultPhoneForSkip
  );
  const [defaultFirstNameForSkip, setDefaultFirstNameForSkip] = useState(
    venue?.defaultFirstNameForSkip
  );
  const [defaultLastNameForSkip, setDefaultLastNameForSkip] = useState(
    venue?.defaultLastNameForSkip
  );
  const [enableSkip, setSkipGuestName] = useState(venue?.skipGuestName);

  const [errorDefaultEmailForSkip, setErrorDefaultEmailForSkip] = useState("");
  const [errorPhoneForSkip, setErrorDefaultPhoneForSkip] = useState("");
  const [errorNameForSkip, setErrorDefaultNameForSkip] = useState("");

  const onDefaultEmailForSkipChange = (_: any, text?: string) => {
    setErrorDefaultEmailForSkip("");
    setDefaultEmailForSkip(text);
  };

  const onDefaultPhoneForSkipChange = (text?: string) => {
    setErrorDefaultPhoneForSkip("");
    setDefaultPhoneForSkip(text);
  };

  const onDefaultFirstNameForSkipChange = (_: any, text?: string) => {
    setErrorDefaultNameForSkip("");
    setDefaultFirstNameForSkip(text);
  };

  const onDefaultLastNameForSkipChange = (_: any, text?: string) => {
    setErrorDefaultNameForSkip("");
    setDefaultLastNameForSkip(text);
  };

  const getPatch = () => {
    return {
      defaultEmailForSkip: defaultEmailForSkip || "",
      defaultPhoneForSkip: defaultPhoneForSkip || "",
      defaultFirstNameForSkip: defaultFirstNameForSkip || "",
      defaultLastNameForSkip: defaultLastNameForSkip || "",
      skipGuestName: !!enableSkip,
    };
  };

  const onUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    setErrorDefaultEmailForSkip("");
    setErrorDefaultPhoneForSkip("");
    if (venue) {
      if (!!defaultEmailForSkip && !isValidEmail(defaultEmailForSkip)) {
        setErrorDefaultEmailForSkip("Please enter a valid email address");
        return;
      }
      if (!!defaultPhoneForSkip && !isValidPhone(defaultPhoneForSkip)) {
        setErrorDefaultPhoneForSkip("Please enter a valid phone number.");
        return;
      }
      saving.current = true;
      const patch = getPatch();
      updateVenue({ ...venue, ...patch });
    }
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-skip-guest-details-settings"
    >
      <div className="title h4">
        Skip Guest Details Settings
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="admin-reservations-settings" onSubmit={onUpdate}>
        <FormField label="Enable skip">
          <Toggle
            checked={enableSkip}
            onChange={() => setSkipGuestName(!enableSkip)}
          />
        </FormField>
        <div className="edit-reservations-fields">
          <FormTextField
            label="Email"
            className="row"
            value={defaultEmailForSkip}
            onChange={onDefaultEmailForSkipChange}
            autoComplete="off"
            errorMessage={errorDefaultEmailForSkip}
            disabled={readonly || !enableSkip}
          />
          <FormField label="Phone number" error={errorPhoneForSkip}>
            <div className={(readonly || !enableSkip) ? 'is-disabled' : ''}>
              <PhoneInput
                className="row phone-field"
                placeholder="Phone number"
                defaultCountry="US"
                value={defaultPhoneForSkip || ""}
                onChange={onDefaultPhoneForSkipChange}
                limitMaxLength={true}
                disabled={readonly || !enableSkip}
              />
            </div>
          </FormField>
          <FormTextField
            label="Default First Name"
            className="row mt-2"
            value={defaultFirstNameForSkip}
            onChange={onDefaultFirstNameForSkipChange}
            autoComplete="off"
            disabled={readonly || !enableSkip}
          />
          <FormTextField
            label="Default Last Name"
            className="row mt-2"
            value={defaultLastNameForSkip}
            onChange={onDefaultLastNameForSkipChange}
            autoComplete="off"
            errorMessage={errorNameForSkip}
            disabled={readonly || !enableSkip}
          />
        </div>
        {error && <div className="error">{error}</div>}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit" disabled={readonly}>
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditSkipGuestDetailsSettings);
