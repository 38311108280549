import React, { FC } from 'react';
import TransferInfo from './TransferInfo';

function timeFromNow(targetDate: string): string {
    const targetDateTime = new Date(targetDate);

    const currentDateTime = new Date();

    const timeDifference = currentDateTime.getTime() - targetDateTime.getTime();

    const seconds = Math.floor(timeDifference / 1000);

    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    const month = day * 30; // Assuming a month is 30 days
    const year = day * 365; // Assuming a year is 365 days

    const yearsAgo = Math.floor(seconds / year);
    const monthsAgo = Math.floor(seconds / month);
    const weeksAgo = Math.floor(seconds / week);
    const daysAgo = Math.floor(seconds / day);
    const hoursAgo = Math.floor(seconds / hour);
    const minutesAgo = Math.floor(seconds / minute);

    if (yearsAgo > 0) {
        return `${yearsAgo} year${yearsAgo > 1 ? 's' : ''} ago`;
    } else if (monthsAgo > 0) {
        return `${monthsAgo} month${monthsAgo > 1 ? 's' : ''} ago`;
    } else if (weeksAgo > 0) {
        return `${weeksAgo} week${weeksAgo > 1 ? 's' : ''} ago`;
    } else if (daysAgo > 0) {
        return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
    } else if (hoursAgo > 0) {
        return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
    } else if (minutesAgo > 0) {
        return `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`;
    } else {
        return 'Just now';
    }
}
export type HistoryStatus = 'Success' | 'Failure' | 'Partly'

export enum TransferDirection {
    ProdToProd = "ProdToProd",
    ProdToDev = "ProdToDev",
    DevToDev = "DevToDev",
    DevToProd = "DevToProd",
}


interface Props {
    createdAt: string,
    name: string,
    status: HistoryStatus,
    direction: TransferDirection
    domain: string
}

const HistoryItem: FC<Props> = ({ createdAt, name, status, direction, domain }) => {
    return (
        <div className='history-item'>
            <div className='history-item__user-info'>
                <div className="h7">{timeFromNow(createdAt)}</div>
                <div className="h8">{name}</div>
            </div>
            <div className="history-item__transfer-info">
                <TransferInfo status={status} />
                <div className="history-item__badges">
                    <div className="badge-container">
                        <div className='badge red' >PROD</div>
                        <div className="h7">{domain}</div>
                    </div> 
                     <div className="badge-container">
                        <div className='badge green' >DEV</div>
                        <div className="h7">{`dev-${domain}`}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HistoryItem