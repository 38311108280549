import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  ActionButton,
  DefaultButton,
  mergeStyleSets,
  Callout,
  DirectionalHint,
} from "office-ui-fabric-react";
import { State, TempTenantUserDto, UserType } from "../../store/types";
import { removePartnerAction } from "../../actions/partners-actions";
import {
  createUserAction,
  updateUserAction,
  removeUserAction,
} from "../../actions/tenants-actions";
import {
  selectPartner,
  selectIsPartnerRequestInProgress,
} from "../../reducers/partners";
import { selectIsUserRequestInProgress } from "../../reducers/tenants";
import { webConfigUI } from "../../constants/webConfigUI";
import { useViewport } from "../../hooks/responsive";
import ChevronDown from "../../assets/custom-chevron-down.svgr";
import UserIcon from "../../assets/tenants-icon.svgr";
import VertIcon from "../../assets/more-vert.svgr";
import BackButton from "../common/BackButton/BackButton";
import Panel from "../common/Panel";
import ColorButton from "../../../../common/components/ColorButton";
import ActionsButton from "../common/ActionsButton";
import LinkButton from "../../../../common/components/LinkButton";
import EditUser from "./EditUser/EditUser";
import DeleteConfirmation from "../common/DeleteConfirmation/DeleteConfirmation";
import EditPartner from "./EditPartner/EditPartner";
import "./partnerDetails.scss";
import { setWebConfigUIAction } from "../../actions/ui-actions";

interface PartnerItem {
  name: string;
  value: string;
}

const styles = mergeStyleSets({
  callout: {
    maxWidth: "260px",
    minWidth: "260px",
    minHeight: "100px",
    maxHeight: "150px",
    backgroundColor: "white",
    border: "none",
    borderRadius: "6px",
  },
});

const mapDispatchToProps = {
  removePartner: removePartnerAction,
  createUser: createUserAction,
  updateUser: updateUserAction,
  removeUser: removeUserAction,
  setWebConfigUI: setWebConfigUIAction,
};
const mapStateToProps = (state: State) => ({
  partner: selectPartner(state),
  isPartnerRequestInProgress: selectIsPartnerRequestInProgress(state),
  isUserRequestInProgress: selectIsUserRequestInProgress(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const renderListItem = (item: PartnerItem, index: number) => (
  <div key={index} className="partner-item">
    <div className="partner-item-name subtitle2">{item.name}</div>
    <div className="partner-item-value body2">{item.value}</div>
  </div>
);

const PartnerDetails = ({
  partner,
  isPartnerRequestInProgress,
  isUserRequestInProgress,
  removePartner,
  updateUser,
  createUser,
  removeUser,
  setWebConfigUI,
}: Props) => {
  const { isMiddleScreen } = useViewport();
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isRemoveOpen, setIsRemoveOpen] = useState(false);
  const [isRemoveUserOpen, setIsRemoveUserOpen] = useState(false);
  const [isEditUserOpen, setIsEditUserOpen] = useState(false);
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [isShowActionsMenu, setIsShowActionsMenu] = useState<boolean>(false);
  const [tempUser, setTempUser] = useState<TempTenantUserDto | undefined>();
  const [removeId, setRemoveId] = useState<string | undefined>();

  useEffect(() => {
    if (!isPartnerRequestInProgress) {
      setIsOpenEdit(false);
    }
  }, [isPartnerRequestInProgress]);
  useEffect(() => {
    if (!isUserRequestInProgress) {
      setIsRemoveOpen(false);
      setIsRemoveUserOpen(false);
      setIsEditUserOpen(false);
      setIsAddUserOpen(false);
    }
  }, [isUserRequestInProgress]);

  const editPartner = () => setIsOpenEdit(true);

  const removePartnerOpen = () => {
    if (!partner) {
      return;
    }
    setIsRemoveOpen(true);
  };
  const confirmRemovePartner = (isConfirm: boolean) => {
    if (isConfirm && partner) {
      removePartner(partner.id);
    }
    setIsRemoveOpen(false);
  };
  const removeUserOpen = (index: number) => {
    if (!partner || !partner.users[index]) {
      return;
    }
    setRemoveId(partner.users[index].id);
    setIsRemoveUserOpen(true);
  };
  const confirmRemoveUser = (isConfirm: boolean) => {
    if (isConfirm && partner && removeId) {
      removeUser(removeId, partner.id);
    }
    setIsRemoveUserOpen(false);
  };

  const onAddUserOpen = () => {
    if (!partner) {
      return;
    }
    setTempUser({ partnerId: partner?.id, username: "", password: "", type: UserType.partner });
    setIsAddUserOpen(true);
  };
  const onAddUser = (data: {
    username: string;
    password: string;
    type?: UserType;
    venueId?: string;
  }) => {
    if (!tempUser) {
      return;
    }
    createUser({ ...tempUser, ...data });
  };
  const onEditUserOpen = (index: number) => {
    if (!partner) {
      return;
    }
    const user = {
      id: partner.users[index].id,
      partnerId: partner?.id,
      username: partner.users[index].username,
      password: "",
      type: partner.users[index].type,
    };
    setTempUser(user);
    setIsEditUserOpen(true);
  };
  const onUpdateUser = ({ username, password }: {
    username: string;
    password: string;
    type?: UserType;
  }) => {
    if (!tempUser) {
      return;
    }
    updateUser({ ...tempUser, username, password });
  };

  const partnerSettingsTitle = (
    <>
      <div className="h6">Partner Settings</div>
      <LinkButton onClick={editPartner}>Edit</LinkButton>
    </>
  );

  const UsersTitle = (
    <>
      <div className="h6">Users</div>
      <LinkButton onClick={onAddUserOpen}>Add User</LinkButton>
    </>
  );

  const partnerSettings: PartnerItem[] = [
    { name: "Name", value: partner?.name || "-" },
    {
      name: "Tenants",
      value: partner?.tenants?.map(({ name }) => name)?.join(",") || "-",
    },
  ];

  const userButton = (index: number) => {
    const menuProps = {
      shouldFocusOnMount: true,
      isBeakVisible: false,
      items: [
        {
          key: `editItem${index}`,
          text: "Edit",
          onClick: () => onEditUserOpen(index),
        },
        {
          key: `deleteItem${index}`,
          text: "Delete",
          onClick: () => removeUserOpen(index),
        },
      ],
    };
    return (
      <ActionButton className="user-button" menuProps={menuProps}>
        <VertIcon />
      </ActionButton>
    );
  };

  return (
    <div className="partner-details-container">
      <div className="partner-details">
        <BackButton
          text={"Partners"}
          onBack={() => {
            setWebConfigUI(webConfigUI.PARTNERS);
          }}
        />
        <div className="partner-details-header">
          <div className="partner-details-title h4">{partner?.name}</div>
          <div className="actions-container">
            <ActionsButton
              id="actions-button"
              onClick={() => setIsShowActionsMenu(true)}
            >
              <div className="actions-button-text">Actions</div>
              <ChevronDown className="chevron" />
            </ActionsButton>
            {isShowActionsMenu && (
              <Callout
                className={styles.callout}
                gapSpace={0}
                target={`#actions-button`}
                onDismiss={() => setIsShowActionsMenu(false)}
                directionalHint={DirectionalHint.bottomRightEdge}
                isBeakVisible={false}
              >
                <div className="actions-button-container">
                  <DefaultButton
                    className="actions-button"
                    onClick={removePartnerOpen}
                  >
                    <div className="actions-text">Delete Partner</div>
                  </DefaultButton>
                </div>
              </Callout>
            )}
          </div>
        </div>
        <div className="partner-details-line" />
        <div className={`partner-info ${isMiddleScreen ? "mobile" : ""}`}>
          <div className="left-panel">
            <Panel
              className={`panel-shift mobile`}
              title={partnerSettingsTitle}
            >
              {partnerSettings.map((item, index) =>
                renderListItem(item, index)
              )}
            </Panel>
          </div>
          <div className={`right-panel ${isMiddleScreen ? "mobile" : ""}`}>
            <Panel className="panel-shift user-list" title={UsersTitle}>
              {partner && partner?.users.length > 0 ? (
                partner?.users.map((u, index) => (
                  <div key={u.id}>
                    <div className="user-list-row">
                      <div className="subtitle2">
                        <div>{u.username}</div>
                      </div>
                      {userButton(index)}
                    </div>
                    {index < partner?.users.length - 1 && (
                      <div className="line" />
                    )}
                  </div>
                ))
              ) : (
                <div className="empty-user-list-container">
                  <div className="empty-user-list">
                    <UserIcon className="user-icon" />
                    <div className="empty-user-list-message">
                      There are no users here yet.
                    </div>
                    <ColorButton
                      className="empty-user-list-button"
                      onClick={onAddUserOpen}
                    >
                      <div className="add-button-text">Add User</div>
                    </ColorButton>
                  </div>
                </div>
              )}
            </Panel>
          </div>
        </div>
      </div>
      {isRemoveOpen && (
        <DeleteConfirmation
          ConfirmationHeader={"Confirmation"}
          ConfirmationText={"Do you want to remove this partner?"}
          ConfirmationButtonText={"Delete Partner"}
          onClose={confirmRemovePartner}
        />
      )}
      {isRemoveUserOpen && (
        <DeleteConfirmation
          ConfirmationHeader={"Confirmation"}
          ConfirmationText={"Do you want to remove this user?"}
          ConfirmationButtonText={"Delete User"}
          onClose={confirmRemoveUser}
        />
      )}
      {isOpenEdit && (
        <EditPartner partner={partner} onClose={() => setIsOpenEdit(false)} />
      )}

      {isAddUserOpen && tempUser && (
        <EditUser
          onClose={() => setIsAddUserOpen(false)}
          onSave={onAddUser}
          user={tempUser}
          createMode
        />
      )}
      {isEditUserOpen && tempUser && (
        <EditUser
          onClose={() => setIsEditUserOpen(false)}
          onSave={onUpdateUser}
          user={tempUser}
        />
      )}
    </div>
  );
};

export default connector(PartnerDetails);
