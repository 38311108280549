import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { Fabric, mergeStyles, IDropdownOption, Callout, mergeStyleSets, DirectionalHint, DefaultButton } from "office-ui-fabric-react";
import {
  setTimeIntervalAction,
  setFilterParametersForReportAction,
  getReportsV2Action,
  setVenueFilterV2Action,
  setTimeIntervalV2Action,
} from "../../actions/reports-actions";
import { getVenuesAction } from "../../actions/venue-actions";
import {
  selectTimeInterval,
  selectStartDate,
  selectEndDate,
  selectReportFilterParameters,
  selectReportV2,
  selectVenueIdsForFilterReportV2,
} from "../../reducers/reports";
import { connect, ConnectedProps } from "react-redux";
import {
  State,
  TimeInterval,
  FilterReservationParameters,
  ReportV2Dto,
  VenueSettingDto,
  ChartOptions,
} from "../../store/types";
import { selectVenues } from "../../reducers/venues";
import { setWebConfigUIAction } from "../../actions/ui-actions";
import { DATE_FORMAT, DATE_FORMAT_US } from "../../constants/timedate";
import SelectDateRange from "../common/SelectDateRange/SelectDateRange";
import FormSelectField from "../common/FormSelectField";
import { selectUIConfig } from "../../reducers/ui-reducer";
import FilterReservations from "../Reservations/FilterReservations/FilterReservations";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import "./reportsV2.scss";
import { ArrowUpward as ArrowUpwardIcon, ArrowDownward as ArrowDownwardIcon } from '@material-ui/icons';
import ActionsButton from "../common/ActionsButton";
import { downloadReservationsReportAction, downloadReservationsRevenuesReportAction, downloadReservationsTaxesReportAction, downloadReservationsWithTransactionReportAction } from "../../actions/reservation-actions";
import ChevronDown from "../../assets/custom-chevron-down.svgr";

const styles = mergeStyleSets({
  callout: {
    maxWidth: "260px",
    minWidth: "260px",
    minHeight: "50px",
    maxHeight: "150px",
    backgroundColor: "white",
    border: "none",
    borderRadius: "6px",
  },
});

const wrapperClassName = mergeStyles({
  display: "flex",
  alignItems: "flex-end",
  columnGap: "25px",
  selectors: {
    "& > *": { flexBasis: "43%" },
  },
});

const mapDispatchToProps = {
  setTimeFilterInterval: setTimeIntervalAction,
  setVenuesFilter: setVenueFilterV2Action,
  getVenues: getVenuesAction,
  setWebConfigUI: setWebConfigUIAction,
  setFilterParametersForReport: setFilterParametersForReportAction,
  getReportsV2: getReportsV2Action,
  setTimeIntervalV2: setTimeIntervalV2Action,
  downloadReservationsReport: downloadReservationsReportAction,
  downloadReservationsTaxesReport: downloadReservationsTaxesReportAction,
  downloadReservationsRevenuesReport: downloadReservationsRevenuesReportAction,
  downloadReservationsWithTransactionReport: downloadReservationsWithTransactionReportAction,
};

const mapStateToProps = (state: State) => ({
  reportV2: selectReportV2(state),
  venueIds: selectVenueIdsForFilterReportV2(state),
  timeInterval: selectTimeInterval(state),
  startDate: selectStartDate(state),
  endDate: selectEndDate(state),
  venues: selectVenues(state),
  uiConfig: selectUIConfig(state),
  filterParameters: selectReportFilterParameters(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const ReportsV2 = ({
  reportV2,
  venueIds,
  timeInterval,
  startDate,
  endDate,
  venues,
  uiConfig,
  setTimeFilterInterval,
  setVenuesFilter,
  getVenues,
  filterParameters,
  setFilterParametersForReport,
  getReportsV2,
  setTimeIntervalV2,
  downloadReservationsReport,
  downloadReservationsTaxesReport,
  downloadReservationsRevenuesReport,
  downloadReservationsWithTransactionReport,
}: Props) => {
  useEffect(() => {
    if (venues && !venues.length) {
      getVenues();
    }
    setVenuesFilter(venueIds);
    getReportsV2({
      timeInterval: timeInterval,
    });
  }, []);

  const [showRangePicker, setShowRangePicker] = useState(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const dropdownDismissTimer = useRef<any>(null);
  const [isShowActionsMenu, setIsShowActionsMenu] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      if (dropdownDismissTimer.current) {
        clearTimeout(dropdownDismissTimer.current);
      }
    };
  }, []);

  const onDismissed = () => {
    dropdownDismissTimer.current = setTimeout(() => {
      if (timeInterval === TimeInterval.CUSTOM && !showRangePicker)
        setShowRangePicker(true);
    }, 200);
  };

  const composeVenuesForDropDown = (
    venues: VenueSettingDto[]
  ): IDropdownOption[] => {
    return [
      { key: "all", text: "All venues" },
      ...venues.map((item) => ({ key: item.id, text: item.name })),
    ];
  };
  const venuesForDropdown = composeVenuesForDropDown(venues);
  const custom =
    startDate && endDate
      ? `${dayjs(startDate).format(
          uiConfig?.dateFormat || DATE_FORMAT_US
        )}-${dayjs(endDate).format(uiConfig?.dateFormat || DATE_FORMAT_US)}`
      : "custom";
  const timeIntervalOptions = [
    { key: TimeInterval.TODAY, text: "Today" },
    { key: TimeInterval.THIS_MONTH, text: "This month" },
    { key: TimeInterval.LAST_30_DAYS, text: "Last 30 days" },
    { key: TimeInterval.LAST_MONTH, text: "Last month" },
    { key: TimeInterval.LAST_3_MONTHS, text: "Last 3 months" },
    { key: TimeInterval.LAST_6_MONTHS, text: "Last 6 months" },
    { key: TimeInterval.YEAR_TO_DATE, text: "Year to date" },
    { key: TimeInterval.LAST_YEAR, text: "Last year" },
    { key: TimeInterval.ALL_TIME, text: "All" },
    { key: TimeInterval.CUSTOM, text: custom },
  ];

  const onVenueChange = (_: any, option: IDropdownOption | undefined) => {
    const newVenues = venueIds || [];
    if (!option || !option.key) return null;
    if (option.key === "all") {
      setVenuesFilter(["all"]);
      return;
    }
    if (option.selected) {
      newVenues.push(String(option.key));
      setVenuesFilter(newVenues.filter((v) => v !== "all"));
      return;
    }
    setVenuesFilter(newVenues.filter((v) => v !== String(option.key)));
  };

  const onIntervalChange = (_: any, option: IDropdownOption | undefined) => {
    if (!option || !option.key) return null;
    if (dropdownDismissTimer.current) {
      clearTimeout(dropdownDismissTimer.current);
    }
    if (option.key === TimeInterval.CUSTOM) {
      setShowRangePicker(true);
      return;
    }
    setTimeIntervalV2({
      timeInterval: String(option.key) as TimeInterval,
      startDate: "",
      endDate: "",
    });
  };

  const onSetRange = (sDate?: Date | null, eDate?: Date | null) => {
    if (sDate && eDate) {
      setTimeFilterInterval({
        timeInterval: TimeInterval.CUSTOM,
        startDate: dayjs(sDate).format(DATE_FORMAT),
        endDate: dayjs(eDate).format(DATE_FORMAT),
      });
      getReportsV2({
        timeInterval: TimeInterval.CUSTOM,
        startDate: dayjs(sDate).format(DATE_FORMAT),
        endDate: dayjs(eDate).format(DATE_FORMAT),
      });
    }
    setShowRangePicker(false);
  };

  const onSetFilterParameters = (
    filterForReport: FilterReservationParameters[]
  ) => {
    setFilterParametersForReport(filterForReport);
  };

  const onDownload = (e:any)=>{
    e.preventDefault()
    downloadReservationsReport(timeInterval, venueIds)
  }
  const onTaxesDownload = (e:any)=>{
    e.preventDefault()
    downloadReservationsTaxesReport(timeInterval, venueIds)
  }
  const onRevenuesDownload = (e:any)=>{
    e.preventDefault()
    downloadReservationsRevenuesReport(timeInterval, venueIds)
  }
  const onReservationsWithTransactionDownload = (e:any)=>{
    e.preventDefault()
    downloadReservationsWithTransactionReport(timeInterval, venueIds)
  }

  return (
    <div className="reports-v2">
      <div className="report-v2-header">
        <div className="title h4">
          <div>Reports V2</div>
        </div>
        <div className="actions-container">
          <ActionsButton
            id="actions-button"
            onClick={() => setIsShowActionsMenu(true)}
          >
            <div className="actions-button-text">
              Actions
            </div>
            <ChevronDown className="chevron" />
          </ActionsButton>
          {isShowActionsMenu && (
            <Callout
              className={styles.callout}
              gapSpace={0}
              target={`#actions-button`}
              onDismiss={() => setIsShowActionsMenu(false)}
              directionalHint={DirectionalHint.bottomRightEdge}
              isBeakVisible={false}
            >
              <div className="actions-button-container">
                <DefaultButton
                  className="actions-button"
                  onClick={onDownload}
                >
                  <div className="actions-text">
                    Manager's Export
                  </div>
                </DefaultButton>
              </div>
              <div className="actions-button-container">
                <DefaultButton
                  className="actions-button"
                  onClick={onTaxesDownload}
                >
                  <div className="actions-text">
                    Tax Export
                  </div>
                </DefaultButton>
              </div>
              <div className="actions-button-container">
                <DefaultButton
                  className="actions-button"
                  onClick={onRevenuesDownload}
                >
                  <div className="actions-text">
                    Revenue Export
                  </div>
                </DefaultButton>
              </div>
              <div className="actions-button-container">
                <DefaultButton
                  className="actions-button"
                  onClick={onReservationsWithTransactionDownload}
                >
                  <div className="actions-text">
                    Reservations with Transactions
                  </div>
                </DefaultButton>
              </div>
            </Callout>
          )}
        </div>
      </div>


      <div className="inputs-block">
        <Fabric className={wrapperClassName}>
          <div className="filter-block">
            <div className="subtitle2">Filter by venue</div>
            <FormSelectField
              options={venuesForDropdown}
              onChange={onVenueChange}
              multiSelect
              defaultSelectedKeys={venueIds?.length ? venueIds : ["all"]}
            />
          </div>
          <div className="filter-block">
            <div className="subtitle2">Filter by date</div>
            <FormSelectField
              options={timeIntervalOptions}
              onChange={onIntervalChange}
              defaultSelectedKey={timeInterval}
              onDismiss={onDismissed}
            />
          </div>
        </Fabric>
      </div>

      {showRangePicker && (
        <SelectDateRange onClose={onSetRange} uiConfig={uiConfig} />
      )}
      {showFilter && (
        <FilterReservations
          onClose={() => setShowFilter(false)}
          filterParametersForReservations={filterParameters}
          setFilterParametersForReservations={onSetFilterParameters}
        />
      )}

      <div
        className="charts-container"
        onClick={() => console.log("Click")}
      >
        {Object.keys(reportV2 || []).map(
          (key) => {
            const typedKey = key as keyof ReportV2Dto
            const reportOption = reportV2?.[typedKey];
            if (!reportOption) return null;
            const isBarByCategories = reportOption.categories;
            const formatterY = reportOption.formatter?.y === '$' ? currencyFormatter : defaultFormatter;
            const formatterX = reportOption.formatter?.x === '$' ? currencyFormatter : defaultFormatter;
            const options: ApexOptions = {
              chart: {
                id: "basic-bar",
                height: 350,
                zoom: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                },
                events: {
                  mouseMove: (e, chart, option) => {
                    const rect = chart.el.getBoundingClientRect();

                    const controlVLine = chart.el
                      .querySelector(".apexcharts-xcrosshairs")
                      .getBoundingClientRect();
                    const middleX = rect.left + rect.width / 2;

                    const r = chart.el.querySelector(".apexcharts-tooltip");
                    if (-1 !== option.seriesIndex) {
                      if (controlVLine.x >= middleX) {
                        r.style.transform = "translateY(-80%) translateX(60%)";
                      }
                      if (controlVLine.x < middleX) {
                        r.style.transform = "translateY(-80%) translateX(-60%)";
                      }
                    }
                  },
                },
              },
              xaxis: isBarByCategories ?
                {
                  categories: reportOption.categories,
                  labels: { formatter: formatterX },
                } :
                {
                  type: isBarByCategories ? 'category' : "datetime",
                  labels: {
                    style: {
                      colors: '#b8b8d9',
                      fontSize: '12px', 
                    },
                  },
                },
              plotOptions: !isBarByCategories ? {} : {
                bar: {
                  horizontal: true,
                  borderRadius: 4,
                },
              },
              yaxis: isBarByCategories ? {} : {
                labels: {
                  formatter: formatterY,
                  style: {
                    colors: '#b8b8d9',
                    fontSize: '12px',
                  },
                },
              },
              grid: {
                show: false,
              },
              tooltip: {
                enabled: true,
                theme: "dark",
                style: {
                  fontSize: "14px",
                },
                marker: {
                  fillColors: ["#5F7EFB"],
                },
                y: {
                  formatter: formatterY,
                },
                x: {
                  format: "MMM dd",
                },
              },
              markers: {
                size: 0,
                hover: {
                  size: 10,
                },
                shape: "circle",
                colors: ["#5F7EFB"],
              },
              dataLabels: {
                enabled: false,
              },
              legend: {
                show: false,
              },
              stroke: {
                colors: ["#5F7EFB"],
                curve: 'straight',
              },
            };
            return (
              <div className="chart-flex-item" key={key}>
                <div className="chart-title-row">{reportOption.title}</div>

                { areRequiredTotalFieldsDefined(reportOption) && (
                  <div className="chart-subtitle-row">
                    <div className="total">
                      <span className="current">{formatterY(reportOption.total)}</span>
                      <span className="previous-period">from {formatterY(reportOption.totalPrev)}</span>
                    </div>
                    <div className={`trend ${reportOption.trend.direction}`}>
                      <div className="trend-icon">
                        { reportOption.trend.direction === 'positive' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon /> }
                      </div>
                      <span>{Math.abs(reportOption.trend.value).toFixed(1)}%</span>
                    </div>
                  </div>
                )}
                
                <Chart
                  type={reportOption.chartType || "area"}
                  options={options}
                  series={[
                    {
                      data: reportOption.data || [],
                      name: "",
                    },
                  ]}
                  width="100%"
                />
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default connector(ReportsV2);


function currencyFormatter(val: number | string) {
  return `${val.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  })}`;
}

function defaultFormatter(val: number | string) {
  return Number(val).toFixed(0);
}

function areRequiredTotalFieldsDefined(options: ChartOptions): options is Required<ChartOptions> {
  return (
      options.total !== undefined &&
      options.totalPrev !== undefined &&
      options.trend !== undefined
  );
}