import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Modal,
  Toggle,
} from "office-ui-fabric-react";
import { updateVenueAction, setVenueErrorAction } from "../../../../actions/venue-actions";
import { selectVenue, selectIsLoadingVenue, selectVenueError } from "../../../../reducers/venues";
import { State } from "../../../../store/types";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import FormTextField from "../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import "./editNotes.scss";

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
  setVenueError: setVenueErrorAction,
};
const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  error: selectVenueError(state),
  readonly: selectReadonly(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditNotes = ({
  updateVenue,
  venue,
  readonly,
  isLoadingVenue,
  error,
  onClose,
  setVenueError,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    setVenueError("");
  }, []);
  useEffect(() => {
    if (!isLoadingVenue && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingVenue]);
  const [enableCustomNotes, setEnableCustomNotes] = useState(venue?.enableCustomNotes);
  const [enableRequiredCustomNotes, setEnableRequiredCustomNotes] = useState(venue?.enableRequiredCustomNotes);
  const [customNotesTitle, setCustomNotesTitle] = useState(venue?.customNotesTitle);
  const [customNotesExplanationText, setCustomNotesExplanationText] = useState(venue?.customNotesExplanationText);
  const [customNotesPrefilledText, setCustomNotesPrefilledText] = useState(venue?.customNotesPrefilledText);

  const getPatch = () => {
    return {
      enableCustomNotes,
      enableRequiredCustomNotes,
      customNotesTitle,
      customNotesExplanationText,
      customNotesPrefilledText,
    };
  }

  const onUpdateNotes = (e: React.FormEvent) => {
    e.preventDefault();
    if (venue) {
      const patch = getPatch();
      //todo: send patch only
      saving.current = true;
      updateVenue({ ...venue, ...patch });
    }
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-notes"
    >
      <div className="title h4">
        Custom Notes
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="main-form" onSubmit={onUpdateNotes}>
        <Toggle
          label="Custom Notes"
          className="row"
          checked={enableCustomNotes}
          onChange={(_: any, value?: boolean) => setEnableCustomNotes(value)}
          inlineLabel
          disabled={readonly}
        />
        <Toggle
          label="Notes Required"
          className="row"
          checked={enableRequiredCustomNotes}
          onChange={(_: any, value?: boolean) => setEnableRequiredCustomNotes(value)}
          inlineLabel
          disabled={!enableCustomNotes || readonly}
        />
        <FormTextField
          label="Notes Title"
          className="row"
          value={customNotesTitle}
          onChange={(_: any, text?: string) => setCustomNotesTitle(text)}
          autoComplete="off"
          required={enableCustomNotes}
          disabled={!enableCustomNotes || readonly}
        />
        <FormTextField
          label="Notes Explanation"
          className="row"
          value={customNotesExplanationText}
          onChange={(_: any, text?: string) => setCustomNotesExplanationText(text)}
          autoComplete="off"
          multiline rows={7}
          disabled={!enableCustomNotes || readonly}
        />
        <FormTextField
          label="Notes Prefill"
          className="row"
          value={customNotesPrefilledText}
          onChange={(_: any, text?: string) => setCustomNotesPrefilledText(text)}
          autoComplete="off"
          multiline rows={7}
          disabled={!enableCustomNotes || readonly}
        />
        {error && <div className="error">{error}</div>}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditNotes);
