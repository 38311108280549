import React, { useState, useEffect, useCallback } from "react";
import {
  Icon,
  DetailsList,
  ScrollablePane,
  Sticky,
  StickyPositionType,
  IRenderFunction,
  IDetailsHeaderProps,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
} from "office-ui-fabric-react";
import { debounce } from "lodash";
import {
  getTenantsAction,
  selectTenantAction,
} from "../../actions/tenants-actions";
import { getPartnersAction } from "../../actions/partners-actions";
import {
  selectTenants,
  selectIsTenantRequestInProgress,
} from "../../reducers/tenants";
import { connect, ConnectedProps } from "react-redux";
import { State, TenantPlan, TenantSettingsDto } from "../../store/types";
import CreateTenant from "./CreateTenant/CreateTenant";
import ActionsButton from "../common/ActionsButton";
import { webConfigUI } from "../../constants/webConfigUI";
import "./tenants.scss";
import FormSearchField from "../common/FormSearchField";
import ClientTabs from './ClientTabs'

const isDemoPlan = (plan: TenantPlan)=>{
  return plan === TenantPlan.demo
}
const isInactivePlan = (plan: TenantPlan)=>{
  return plan === TenantPlan.development || plan === TenantPlan.churned
}

const mapDispatchToProps = {
  getTenants: getTenantsAction,
  setTenant: selectTenantAction,
  getPartners: getPartnersAction,
};
const mapStateToProps = (state: State) => ({
  tenants: selectTenants(state),
  isTenantRequestInProgress: selectIsTenantRequestInProgress(state),
});

interface OwnProps {
  setMainContainerUI: (view: webConfigUI) => void;
}
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & OwnProps;

const Tenants = ({
  tenants,
  isTenantRequestInProgress,
  getTenants,
  getPartners,
  setTenant,
  setMainContainerUI,
}: Props) => {
  const onColumnClick = useCallback(
    (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
      const newColumns: IColumn[] = columns.slice();
      const currColumn: IColumn = newColumns.filter(
        (currCol) => column.key === currCol.key
      )[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
      setColumns(newColumns);
    },
    [tenants]
  );
  const getSortData = (): [string, boolean] => {
    for (let col of columns) {
      if (col.isSorted) {
        return [col.fieldName || "", !!col.isSortedDescending];
      }
    }
    return ["", false];
  };

  const [columns, setColumns] = useState<IColumn[]>([
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      className: "bold-column subtitle2",
      minWidth: 180,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      isSorted: true,
      data: "string",
      isPadded: true,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      onColumnClick,
    },
    {
      key: "domain",
      name: "Domain",
      fieldName: "domain",
      className: "column body2",
      minWidth: 210,
      isResizable: true,
      data: "string",
      isPadded: true,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      onColumnClick,
    },
    {
      key: "venueCount",
      name: "Venues",
      fieldName: "venueCount",
      className: "column body2",
      minWidth: 80,
      maxWidth: 90,
      isResizable: true,
      data: "number",
      isPadded: true,
      onColumnClick,
    },
    {
      key: "plan",
      name: "Plan",
      fieldName: "plan",
      className: "column body2",
      minWidth: 110,
      maxWidth: 120,
      isResizable: true,
      data: "string",
      isPadded: true,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      onColumnClick,
    },
  ]);

  useEffect(() => {
    if (tenants && !tenants.length) {
      getTenants("");
    }
    getPartners();
  }, []);
  useEffect(() => {
    if (!isTenantRequestInProgress) {
      setShowCreateForm(false);
    }
  }, [isTenantRequestInProgress]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [filter, setFilter] = useState("");

  const onItemInvoked = (item: any): void => {
    setTenant(item);
    setMainContainerUI(webConfigUI.TENANT_DETAILS);
  };

  const onSearchBoxConfirm = (newValue: string) => {
    setFilter(newValue);
    getTenants(newValue);
  };

  const onSearchBoxClear = () => {
    setFilter("");
  };

  const onSearchChange = (ev: any, text: string | undefined = "") => {
    onSearchBoxConfirm(text);
  };

  const _copyAndSort = (
    items: TenantSettingsDto[],
    columnKey: string,
    isSortedDescending?: boolean
  ): TenantSettingsDto[] => {
    const key = columnKey as keyof TenantSettingsDto;
    return [...items].sort((a: TenantSettingsDto, b: TenantSettingsDto) => {
      if (!a || !b) {
        return 1;
      }
      return (
        isSortedDescending
          ? (a[key] as any) < (b[key] as any)
          : (a[key] as any) > (b[key] as any)
      )
        ? 1
        : -1;
    });
  };
  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }

    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
        {defaultRender!(props)}
      </Sticky>
    );
  };
  let allTenants = [...tenants];
  let items = [...allTenants];

  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleFilterChange = (event: React.ChangeEvent<{}>, value: number) => {
    setSelectedTab(value);
  };

  switch (selectedTab) {
    case 0:
      items = allTenants.filter((item) => !isDemoPlan(item.plan) && !isInactivePlan(item.plan));
      break;
    case 1:
      items = allTenants.filter((item) => isDemoPlan(item.plan));
      break;
    case 2:
      items = allTenants.filter((item) => isInactivePlan(item.plan));
      break;
    case 3:
      items = allTenants.filter((item) => item.isOnboarding);
      break;
  }

  const [sortField, isDesc] = getSortData();

  if (filter) {
    items = allTenants.filter(
      (item) => item.name.includes(filter) || item.domain.includes(filter)
    );
  }

  if (sortField) {
    items = _copyAndSort(items, sortField, isDesc);
  }

  return (
    <div className="tenants">
      <div className="tenants-header">
        <div className="h4 title">Clients</div>
        <ActionsButton
          onClick={() => setShowCreateForm(true)}
          allowDisabledFocus
        >
          <Icon iconName="Add" className="add-icon" />
          <div className="add-button-text">Create New Tenant</div>
        </ActionsButton>
      </div>

      <ClientTabs
        filterValue={selectedTab}
        onFilterChange={handleFilterChange}
      />

      <div className="inputs-block">
        <div className="searchbox-block">
          <FormSearchField
            placeholder="Search..."
            onSearch={onSearchBoxConfirm}
            onChange={debounce(onSearchChange, 2000)}
            onClear={onSearchBoxClear}
            value={filter}
          />
        </div>
      </div>
      <div className="tenant-list">
        <ScrollablePane>
          <DetailsList
            items={items}
            styles={{ focusZone: { cursor: "pointer" } }}
            columns={columns}
            selectionMode={SelectionMode.none}
            getKey={(item) => item.id}
            setKey="none"
            onRenderDetailsHeader={onRenderDetailsHeader}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onActiveItemChanged={onItemInvoked}
          />
        </ScrollablePane>
      </div>
      {showCreateForm && (
        <CreateTenant onClose={() => setShowCreateForm(false)} />
      )}
    </div>
  );
};

export default connector(Tenants);