import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import React from "react";
import StripeSmIcon from "../../assets/stripe-sm.svgr";
import StripeIcon from "../../assets/stripe.svgr";
import ErrorMainIcon from "../../assets/error-main.svgr";



interface Props {
    status: string;
    onConnect: () => void;
    onDisconnect: () => void;
    disabledConnect: boolean;
    disabledDisconnect: boolean;
    isRequestInProgress: boolean,
    isSandboxMode: boolean;
}

const ConnectVenueStripeComponent = ({
    status,
    onConnect,
    onDisconnect,
    disabledConnect,
    disabledDisconnect,
    isRequestInProgress,
    isSandboxMode
}: Props) => {
    let stripeState = "";

    if (status === "pending") {
        stripeState = "pending";
    } else if (status === "ok") {
        stripeState = "connected";
    }
    const tagStyles = {
        backgroundColor:
            stripeState === "connected"
                ? "#EDF7ED"
                : stripeState === "pending"
                    ? "#E3F4FD"
                    : "#FBE3E2",
        color:
            stripeState === "connected"
                ? "#27AB6E"
                : stripeState === "pending"
                    ? "#4A90E2"
                    : "#EC4C47",
        cursor: "default",
    };


    return (
        <>

            {isRequestInProgress && (
                <div className="loading">
                    <Spinner size={SpinnerSize.large} />
                </div>
            )}

            <div className="stripe-connect-container v2">
                <div className="row-between stripe-connect-header">
                    <StripeIcon />
                </div>

                {isSandboxMode ? <div className="error-main">
                    <ErrorMainIcon />
                    Test Mode. Real money won't be charged.

                </div> : (

                    <div className="subtitle2">
                        Accept recurring payments witth funds going direcly into your linked
                        bank account.
                    </div>
                )
                }
                <div className="stripe-buttons-group">
                    <button
                        className="btn-stripe-connect"
                        onClick={onConnect}
                        disabled={disabledConnect}
                    >
                        Connect with <StripeSmIcon />
                    </button>
                    <button
                        className="btn-stripe-disconnect"
                        onClick={onDisconnect}
                        disabled={disabledDisconnect}
                    >
                        Disconnect
                    </button>
                </div>
            </div >
        </>
    );
};

export default ConnectVenueStripeComponent;
