import React, {useEffect} from 'react';
import './resources.scss'
import {
  getResourceCategoriesAction,
  setCurrentResourceCategoryAction,
  updateResourceCategoryAction
} from "../../../actions/resources-action";
import {PackageSettingDto, ResourceCategorySettingDto, State} from "../../../store/types";
import {selectResourceCategories} from "../../../reducers/resources";
import {connect, ConnectedProps} from "react-redux";
import EditResourceCategory from "./EditResourceCategory";
import EditResource from "./EditResource";
import ResourcesCategoryRow from "./ResourcesCategoryRow";
import {ActionButton, IColumn} from "office-ui-fabric-react";
import {formatDuration} from "../../../utils/formats";
import VertIcon from "../../../assets/more-vert.svgr";

const mapDispatchToProps = {
  getResourceCategories: getResourceCategoriesAction,

};

const mapStateToProps = (state: State) => ({
  resourceCategories: selectResourceCategories(state)
})

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & {
  venueId?: string;
};

const Resources = (
  {
    resourceCategories,
    getResourceCategories,
    venueId,
  }: Props
) => {
  useEffect(() => {
    if (venueId) {
      getResourceCategories(venueId)
    }
  }, [])


  return (
    <div className="resources">
      {
        resourceCategories.map((category, index) => (
          <ResourcesCategoryRow
            key={index}
            category={category}
          />
        ))
      }
    </div>
  );
};

export default connector(Resources);