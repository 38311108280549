import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  SelectionMode,
  IColumn,
  Icon,
  ActionButton,
} from "office-ui-fabric-react";
import {
  getPackagesAction,
  selectPackageAction,
  removePackageAction,
  selectPackageAndNavigatePageAction,
  setFilteredPackagesAction,
} from "../../../actions/package-action";
import {
  selectPackages,
  selectPackage,
  selectFilteredPackages,
} from "../../../reducers/packages";
import { selectReadonly } from "../../../reducers/auth-reducer";
import { selectTimeSlotDuration } from "../../../reducers/settings";
import { PackageSettingDto, State } from "../../../store/types";
import { useViewport } from "../../../hooks/responsive";
import { formatDuration } from "../../../utils/formats";
import FormSearchField from "../../common/FormSearchField";
import PackageIcon from "../../../assets/shoping-cart-icon.svgr";
import VertIcon from "../../../assets/more-vert.svgr";
import ColorButton from "../../../../../common/components/ColorButton";
import DeleteConfirmation from "../../common/DeleteConfirmation/DeleteConfirmation";
import Panel from "../../common/Panel";
import EditPackage from "./PackageDetails/EditPackage/EditPackage";
import "./packages.scss";

interface CellData extends PackageSettingDto {
  id: string;
  status: boolean;
  venueIds: string[];
  price: number;
  duration: number;
  priority: number;
  tags: string;
}

const mapDispatchToProps = {
  getPackages: getPackagesAction,
  selectPackage: selectPackageAction,
  removePackage: removePackageAction,
  selectPackageAndNavigatePage: selectPackageAndNavigatePageAction,
  setFilteredPackages: setFilteredPackagesAction,
};
const mapStateToProps = (state: State) => ({
  packages: selectPackages(state),
  currentPackage: selectPackage(state),
  readonly: selectReadonly(state),
  timeSlotDuration: selectTimeSlotDuration(state),
  filteredPackages: selectFilteredPackages(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  venueId?: string;
  timeSlotShifting?: number[];
};

const Packages = ({
  getPackages,
  selectPackage,
  removePackage,
  selectPackageAndNavigatePage,
  setFilteredPackages,
  packages,
  venueId,
  timeSlotDuration,
  filteredPackages,
}: Props) => {

  const columns: IColumn[] = [
    {
      key: "name",
      name: "NAME",
      fieldName: "name",
      minWidth: 220,
      maxWidth: 350,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
    },
    {
      key: "method",
      name: "PRICING METHOD",
      fieldName: "method",
      minWidth: 130,
      maxWidth: 250,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
    },
    {
      key: "minGuests",
      name: "MIN GUESTS",
      fieldName: "minGuests",
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
    },
    {
      key: "maxGuests",
      name: "MAX GUESTS",
      fieldName: "maxGuests",
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
    },
    {
      key: "numberOfLanes",
      name: "LANES",
      fieldName: 'numberOfLanes',
      className: "column body2",
      minWidth: 50,
      maxWidth: 50,
      data: "string",
      isResizable: true,
    },
    {
      key: "duration",
      name: "DURATION",
      fieldName: "duration",
      className: "column body2",
      minWidth: 80,
      maxWidth: 90,
      data: "string",
      isPadded: true,
      isResizable: true,
      onRender: (item: CellData) => {
        return <span>{formatDuration(item.duration, timeSlotDuration)}</span>;
      },
    },
    {
      key: "priority",
      name: "PRIORITY",
      fieldName: 'priority',
      className: "column body2",
      minWidth: 60,
      maxWidth: 60,
      data: "string",
      isResizable: true,
    },
    {
      key: "status",
      name: "STATUS",
      fieldName: "status",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      onRender: (item: CellData) => {
        return <span>{getStatusSlot(item.status)}</span>;
      },
    },
    {
      key: "tags",
      name: "TAGS",
      fieldName: "tags",
      minWidth: 100,
      maxWidth: 180,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      onRender: (item: CellData) => {
        return <span>{item.tags.replace(/\;/g, '; ')}</span>;
      },
    },
    {
      key: "action",
      name: "",
      minWidth: 25,
      maxWidth: 25,
      className: "column body2",
      onRender: (item: CellData) => {
        const menuProps = {
          shouldFocusOnMount: true,
          isBeakVisible: false,
          items: [
            { key: 'newItem', text: 'Edit', onClick: () => editPackage(item as PackageSettingDto) },
            { key: 'newItem', text: 'Clone', onClick: () => clonePackage(item as PackageSettingDto) },
            { key: 'newItem', text: 'Delete', onClick: () => onRemovePackage(item.id) },
          ],
        }
        return <ActionButton className="contextual-menu" menuProps={menuProps}><VertIcon /></ActionButton>;
      },
      isPadded: true,
    },
  ];

  useEffect(() => {
    if (venueId) {
      getPackages(venueId);
    }
  }, [getPackages]);

  useEffect(() => {
    packages && setFilteredPackages(packages);
  }, [packages]);

  const { isMiddleScreen } = useViewport();
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isClonePackage, setIsClonePackage] = useState(false);
  const [packageId, setPackageId] = useState("");
  const [isRemovePackageOpen, setIsRemovePackageOpen] = useState(false);

  const getStatusSlot = (isActive: boolean) => {
    const statusText = isActive ? "Active" : "Inactive";
    return (
      <div className="status-container">
        <div className={`status-color ${isActive ? "green" : "red"}`} />
        <div className={`status-text body2 ${isActive ? "green" : "red"}`}>
          {statusText}
        </div>
      </div>
    );
  };
  const renderRow = (props: any) => (
    <DetailsRow
      {...props}
      onClick={() => onItemInvoked(props?.item)}
    />
  );
  const onItemInvoked = (item: any): void => {
    selectPackageAndNavigatePage(item as PackageSettingDto);
  };
  const addPackage = () => {
    selectPackage();
    setIsOpenEdit(true);
  };
  const editPackage = (selectedPackage?: PackageSettingDto) => {
    if (selectedPackage) {
      setIsClonePackage(false);
      setIsOpenEdit(true);
      selectPackage(selectedPackage);
    }
  };
  const onRemovePackage = (id: string) => {
    setPackageId(id);
    setIsRemovePackageOpen(true);
  };
  const clonePackage = (selectedPackage: PackageSettingDto) => {
    setIsClonePackage(true);
    setIsOpenEdit(true);
    selectPackage(selectedPackage);
  };
  const closeRemovePackageDialog = () => {
    setPackageId("");
    setIsRemovePackageOpen(false);
  };
  const confirmedRemovePackage = (isConfirm: boolean) => {
    if (isConfirm && packageId) {
      removePackage(packageId);
    }
    closeRemovePackageDialog();
  };

  const onFilterByTags = (ev: any, searchText: string | undefined = ""): void => {
    setFilteredPackages(
      searchText
        ? packages.filter((item) =>
          item.name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.method.toLowerCase().includes(searchText.toLowerCase()) ||
          item.tags.toLowerCase().includes(searchText.toLowerCase())
        )
        : packages
    );
  };

  return (
    <>
      <div className={`packages ${isMiddleScreen ? "mobile" : ""}`}>
        <div className="left-panel">
          <Panel className="panel-shift panel-packages" title={<div className="h6">Packages</div>}>
            <div className="package-filter-container">
              <div className="searchbox-block">
                <FormSearchField
                  placeholder="Search by name, pricing method, and tags..."
                  onChange={onFilterByTags}
                />
              </div>
            </div>
            {packages && packages.length > 0 ? (
              <>
                <div className="package-list">
                  <DetailsList
                    items={filteredPackages}
                    styles={{ focusZone: { cursor: "pointer" } }}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    getKey={(item) => item.id}
                    setKey="none"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    onRenderRow={renderRow}
                  />
                </div>
                <div className="add-package-button-container">
                  <ColorButton
                    className="empty-package-list-button"
                    onClick={addPackage}
                  >
                    <Icon iconName="Add" className="add-icon" />
                    <div className="add-button-text">
                      Add Package
                    </div>
                  </ColorButton>
                </div>
              </>
            ) : (
              <div className="empty-package-list-container">
                <div className="empty-package-list">
                  <PackageIcon className="package-icon" />
                  <div className="empty-package-list-message">
                    There are no packages here yet.
                  </div>
                  <ColorButton
                    className="empty-package-list-button"
                    onClick={addPackage}
                  >
                    <Icon iconName="Add" className="add-icon" />
                    <div className="add-button-text">
                      Add Package
                    </div>
                  </ColorButton>
                </div>
              </div>
            )}
          </Panel>
        </div>
      </div>
      {isOpenEdit && <EditPackage isClonePackage={false} onClose={() => setIsOpenEdit(false)} />}
      {isOpenEdit && <EditPackage isClonePackage={isClonePackage} onClose={() => setIsOpenEdit(false)} />}
      {isRemovePackageOpen && <DeleteConfirmation
        ConfirmationHeader={"Confirmation"}
        ConfirmationText={"Do you want to remove this package?"}
        ConfirmationButtonText={"Delete Package"}
        onClose={confirmedRemovePackage}
      />}
    </>
  );
};

export default connector(Packages);
