import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Modal,
} from "office-ui-fabric-react";
import { updateVenueAction, setVenueErrorAction } from "../../../../actions/venue-actions";
import { selectVenue, selectIsLoadingVenue, selectVenueError } from "../../../../reducers/venues";
import { State } from "../../../../store/types";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import FormTextField from "../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import "./editTimeSlotShifting.scss";

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
  setVenueError: setVenueErrorAction,
};
const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  error: selectVenueError(state),
  readonly: selectReadonly(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditConfirmation = ({
  updateVenue,
  venue,
  readonly,
  isLoadingVenue,
  error,
  onClose,
  setVenueError,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    setVenueError("");
  }, []);
  useEffect(() => {
    if (!isLoadingVenue && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingVenue]);
  const getTimeSlotShifting = (timeSlotShifting: any) => {
    return [0, 0, 0, 0, 0, 0, 0].map((n: number, index: number) => timeSlotShifting && timeSlotShifting[index] ? +timeSlotShifting[index] : n)
  };
  const [timeSlotShifting, setTimeSlotShifting] = useState(getTimeSlotShifting(venue?.timeSlotShifting || []));

  const getPatch = () => {
    return { timeSlotShifting };
  }

  const onSaveTimeSlotShifting = (day: number, value: number) => {
    const updated = [...timeSlotShifting]
    updated[day] = value
    setTimeSlotShifting(updated)
  };

  const onUpdateTimeSlotShifting = (e: React.FormEvent) => {
    e.preventDefault();
    if (venue) {
      const patch = getPatch();
      //todo: send patch only
      saving.current = true;
      updateVenue({ ...venue, ...patch });
    }
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-timeSlotShifting"
    >
      <div className="title h4">
        Schedule Shifting (1  = 1 min shifting)
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="main-form" onSubmit={onUpdateTimeSlotShifting}>
          <FormTextField
            label="Monday"
            className="row"
            value={timeSlotShifting && timeSlotShifting[1] ? timeSlotShifting[1].toString() : "0"}
            onChange={(_: any, text?: string) =>
              onSaveTimeSlotShifting(1, text ? parseInt(text) : 0)
            }
            autoComplete="off"
          />
          <FormTextField
            label="Tuesday"
            className="row"
            value={timeSlotShifting && timeSlotShifting[2] ? timeSlotShifting[2].toString() : "0"}
            onChange={(_: any, text?: string) =>
              onSaveTimeSlotShifting(2, text ? parseInt(text) : 0)
            }
            autoComplete="off"
          />
          <FormTextField
            label="Wednesday"
            className="row"
            value={timeSlotShifting && timeSlotShifting[3] ? timeSlotShifting[3].toString() : "0"}
            onChange={(_: any, text?: string) =>
              onSaveTimeSlotShifting(3, text ? parseInt(text) : 0)
            }
            autoComplete="off"
          />
          <FormTextField
            label="Thursday"
            className="row"
            value={timeSlotShifting && timeSlotShifting[4] ? timeSlotShifting[4].toString() : "0"}
            onChange={(_: any, text?: string) =>
              onSaveTimeSlotShifting(4, text ? parseInt(text) : 0)
            }
            autoComplete="off"
          />
          <FormTextField
            label="Friday"
            className="row"
            value={timeSlotShifting && timeSlotShifting[5] ? timeSlotShifting[5].toString() : "0"}
            onChange={(_: any, text?: string) =>
              onSaveTimeSlotShifting(5, text ? parseInt(text) : 0)
            }
            autoComplete="off"
          />
          <FormTextField
            label="Saturday"
            className="row"
            value={timeSlotShifting && timeSlotShifting[6] ? timeSlotShifting[6].toString() : "0"}
            onChange={(_: any, text?: string) =>
              onSaveTimeSlotShifting(6, text ? parseInt(text) : 0)
            }
            autoComplete="off"
          />
          <FormTextField
            label="Sunday"
            className="row"
            value={timeSlotShifting && timeSlotShifting[0] ? timeSlotShifting[0].toString() : "0"}
            onChange={(_: any, text?: string) =>
              onSaveTimeSlotShifting(0, text ? parseInt(text) : 0)
            }
            autoComplete="off"
          />
        {error && <div className="error">{error}</div>}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditConfirmation);
