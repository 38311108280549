import { orderBy } from "lodash";
import {
  ActionButton,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  Icon,
  SelectionMode,
  Toggle
} from "office-ui-fabric-react";
import React, { useEffect } from 'react';
import { ConnectedProps, connect } from "react-redux";
import ColorButton from "../../../../../../common/components/ColorButton";
import LinkButton from '../../../../../../common/components/LinkButton';
import {
  deleteResourceAction,
  setCurrentResourceAction,
  setCurrentResourceCategoryAction, updateResourceAction,
  updateResourceCategoryAction
} from "../../../../actions/resources-action";
import VertIcon from "../../../../assets/more-vert.svgr";
import PackageIcon from "../../../../assets/shoping-cart-icon.svgr";
import { selectResourceCategories } from "../../../../reducers/resources";
import { ResourceCategorySettingDto, ResourceSettingDto, State } from "../../../../store/types";
import DeleteConfirmation from "../../../common/DeleteConfirmation/DeleteConfirmation";
import FormSearchField from "../../../common/FormSearchField";
import Panel from "../../../common/Panel";
import EditResource from "../EditResource";
import EditResourceCategory from "../EditResourceCategory";

const mapDispatchToProps = {
  updateResourceCategory: updateResourceCategoryAction,
  setCurrentResourceCategory: setCurrentResourceCategoryAction,
  setCurrentResource: setCurrentResourceAction,
  deleteResource: deleteResourceAction,
  updateResource: updateResourceAction
};

const mapStateToProps = (state: State) => ({
  resourceCategories: selectResourceCategories(state)
})

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & {
  category: ResourceCategorySettingDto,
};

const ResourcesCategoryRow = (
  {
    category,
    updateResourceCategory,
    setCurrentResourceCategory,
    setCurrentResource,
    deleteResource,
    updateResource
  }: Props
) => {
  const initialColumns: IColumn[] = [
    {
      key: "name",
      name: "NAME",
      fieldName: "name",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      className: "bold-column subtitle2 plan-name",
      data: "string",
      isPadded: true,
    },
    {
      key: "abbreviation",
      name: "ABBREVIATION",
      fieldName: "abbreviation",
      minWidth: 180,
      maxWidth: 200,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
    },
    {
      key: "priority",
      name: "PRIORITY",
      fieldName: "priority",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      isSorted: true,
      isSortedDescending: true
    },
    {
      key: "row",
      name: "ROW",
      fieldName: "row",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      isSorted: true,
      isSortedDescending: true
    },
    {
      key: "active",
      name: "STATUS",
      fieldName: "active",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      onRender: (item: ResourceSettingDto) => {
        const isActive = item.active;
        const statusText = isActive ? "Active" : "Inactive";
        return (
          <div className="status-container">
            <div className={`status-color ${isActive ? "green" : "red"}`} />
            <div className={`status-text body2 ${isActive ? "green" : "red"}`}>
              {statusText}
            </div>
          </div>
        );
      },
    },
    {
      key: "toggle",
      name: "ON/OFF",
      fieldName: "toggle",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      onRender: (item: ResourceSettingDto) => {
        return (
          <Toggle
            className="toggle"
            checked={item.active}
            onChange={(_: any, value?: boolean) => onToggleActiveResource({ resource: item, active: !!value })}
          />
        )
      },
    },
    {
      key: "adminOnly",
      name: "ONLINE AVAILABLE",
      fieldName: "toggle",
      minWidth: 90,
      maxWidth: 130,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      onRender: (item: ResourceSettingDto) => {
        return (
          <Toggle
            className="toggle"
            checked={!item.adminOnly}
            onChange={(_: any, value?: boolean) => onToggleAdminOnlyResource({ resource: item, adminOnly: !value })}
          />
        )
      },
    },
    {
      key: "action",
      name: "",
      minWidth: 25,
      maxWidth: 25,
      className: "column body2",
      onRender: (item: ResourceSettingDto) => {
        const menuProps = {
          shouldFocusOnMount: true,
          isBeakVisible: false,
          items: [
            { key: 'newItem', text: 'Edit', onClick: () => onEditResource(item) },
            { key: 'newItem', text: 'Delete', onClick: () => setRemoveResourceId(item.id) },
          ],
        }
        return <ActionButton className="contextual-menu" menuProps={menuProps}><VertIcon /></ActionButton>;
      },
      isPadded: true,
    },
  ];
  const [isOpenEdit, setIsOpenEdit] = React.useState(false);
  const [isOpenEditResource, setIsOpenEditResource] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [removeResourceId, setRemoveResourceId] = React.useState<string | null>("");

  const [columns, setColumns] = React.useState(initialColumns);
  const [resources, setResources] = React.useState<ResourceSettingDto[]>([]);

  const onToggleActive = ({ category, active }: { category: ResourceCategorySettingDto, active: boolean }) => {
    updateResourceCategory({ ...category, active })
  }

  const onToggleActiveResource = ({ resource, active }: { resource: ResourceSettingDto, active: boolean }) => {
    updateResource({ ...resource, active })
  }
  const onToggleAdminOnlyResource = ({ resource, adminOnly }: { resource: ResourceSettingDto, adminOnly: boolean }) => {
    updateResource({ ...resource, adminOnly })
  }

  const onEditCategory = (category: ResourceCategorySettingDto) => {
    setCurrentResourceCategory(category)
    setIsOpenEdit(true)
  }

  const onCloseEdit = () => {
    setCurrentResourceCategory(undefined)
    setIsOpenEdit(false)
  }

  const onCloseEditResource = () => {
    setCurrentResource(undefined)
    setIsOpenEditResource(false)
  }

  const onAddResource = () => {
    setIsOpenEditResource(true)
  }

  const onEditResource = (resource: ResourceSettingDto) => {
    setCurrentResource(resource)
    setIsOpenEditResource(true)
  }

  const onCloseConfirmation = (isConfirm: boolean) => {
    if (isConfirm && removeResourceId) {
      deleteResource(removeResourceId)
    }
    setRemoveResourceId(null)
  }

  useEffect(() => {
    const sortedResources = (orderBy(category.resources, ["priority"], ["desc"]) || [])
    setResources(sortedResources)
  }, [category])

  const onColumnClick = (ev?: React.MouseEvent<HTMLElement>, column?: IColumn) => {
    if (!column || column?.key === 'toggle' || column?.key === 'adminOnly') return;

    let sortDirection;

    const newColumns = columns.map(currentColumn => {
      if (currentColumn.key === column.key) {
        let isSortedDescending = false;

        if (currentColumn.isSortedDescending !== undefined) {
          isSortedDescending = !currentColumn.isSortedDescending;
        }

        sortDirection = isSortedDescending ? 'desc' : 'asc';
        return {
          ...currentColumn,
          isSorted: true,
          isSortedDescending,
        }
      }

      return {
        ...currentColumn,
        isSorted: false,
        isSortedDescending: true,
      }
    })


    if (sortDirection) {
      setColumns(newColumns);

      const fieldKey = column.fieldName as keyof ResourceSettingDto;
      const iteratee = !["priority", "active"].includes(column.key) ? [(resource: ResourceSettingDto) => String(resource[fieldKey]).toLowerCase()] : [(resource: ResourceSettingDto) => resource[fieldKey]];

      const sortedResources = (orderBy(category.resources, iteratee, [sortDirection]) || []) as ResourceSettingDto[]
      setResources(sortedResources)
    }
  }

  const renderTitle = (category: ResourceCategorySettingDto) => {
    return (
      <>
        <div className="h6">{category.name}</div>
        <div className="resource-category-buttons">
          <Toggle
            className="toggle"
            checked={category.active}
            onChange={(_: any, value?: boolean) => onToggleActive({ category, active: !!value })}
          />
          <LinkButton
            onClick={() => onEditCategory(category)}
          >
            Edit
          </LinkButton>
        </div>
      </>
    )
  }

  const filteredResources = resources.filter(resource => resource.name.toLowerCase().includes(searchValue.toLowerCase()))

  return (
    <>
      <Panel title={renderTitle(category)} className="resource-category">
        {
          category.active && (
            <div>
              <div className="resource-category-search">
                <FormSearchField
                  placeholder="Search by name..."
                  onChange={(ev: any, value?) => setSearchValue(value || "")}
                />
              </div>
              {
                resources.length > 0 ? (
                  <>
                    <div className="resource-list">
                      <DetailsList
                        items={filteredResources}
                        styles={{ focusZone: { cursor: "pointer" } }}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        getKey={(item) => item.id}
                        setKey="none"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        onColumnHeaderClick={onColumnClick}
                      />
                    </div>
                    <div className="add-resource-button-container">
                      <ColorButton
                        className="empty-package-list-button"
                        onClick={onAddResource}
                      >
                        <Icon iconName="Add" className="add-icon" />
                        <div className="add-button-text">
                          Add resource
                        </div>
                      </ColorButton>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="empty-resource-list-container">
                      <div className="empty-resource-list">
                        <PackageIcon className="resource-icon" />
                        <div className="empty-resource-list-message">
                          There are no resources here yet.
                        </div>
                        <ColorButton
                          className="empty-resource-list-button"
                          onClick={onAddResource}
                        >
                          <Icon iconName="Add" className="add-icon" />
                          <div className="add-button-text">
                            Add resource
                          </div>
                        </ColorButton>
                      </div>
                    </div>

                  </>
                )
              }
            </div>
          )
        }
      </Panel>
      {isOpenEdit && <EditResourceCategory onClose={onCloseEdit} />}
      {isOpenEditResource && <EditResource resourceCategoryId={category.id} onClose={onCloseEditResource} />}
      {!!removeResourceId && <DeleteConfirmation
        ConfirmationHeader={"Confirmation"}
        ConfirmationText={"Do you want to remove this resource?"}
        ConfirmationButtonText={"Delete Resource"}
        onClose={onCloseConfirmation}
      />}
    </>
  )
}

export default connector(ResourcesCategoryRow);