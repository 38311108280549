import React from "react";
import AddonsIcon from "../../../assets/addon-icon.svgr";
import AddonModifiersIcon from "../../../assets/addon-modifiers-icon.svgr";
import MonitoringIcon from "../../../assets/database.svgr";
import MonitoringIconColored from "../../../assets/database_colored.svgr";
import LocationIcon from "../../../assets/locations-icon.svgr";
import HostAppIcon from "../../../assets/monitor-icon.svgr";
import PartnersIcon from "../../../assets/partner_exchange.svgr";
import PartnersIconColored from "../../../assets/partner_exchange_colored.svgr";
import PromotionIcon from "../../../assets/promotions-icon.svgr";
import ReportsIcon from "../../../assets/reports-icon.svgr";
import ReservationIcon from "../../../assets/reservations-icon.svgr";
import RexIcon from "../../../assets/rex-black.png";
import SettingsIcon from "../../../assets/settings-icon.svgr";
import SyncIcon from "../../../assets/sync_alt.svgr";
import SyncIconColored from "../../../assets/sync_alt_colored.svgr";
import TenantIcon from "../../../assets/tenants-icon.svgr";
import UsersIcon from "../../../assets/users-icon.svgr";
import QuestionsIcon from "../../../assets/questions-icon.svgr";
import MembershipIcon from "../../../assets/membership-icon.svgr";
import { webConfigUI } from "../../../constants/webConfigUI";
import { LeftPanelList } from "../Home";
import "./leftSidePanel.scss";

export const menuIcons: any = {
  location: <LocationIcon />,
  setting: <SettingsIcon />,
  report: <ReportsIcon />,
  promotion: <PromotionIcon />,
  tenant: <TenantIcon />,
  reservation: <ReservationIcon />,
  users: <UsersIcon />,
  addons: <AddonsIcon />,
  discounts: <PromotionIcon />,
  hostApp: <HostAppIcon />,
  monitoring: <MonitoringIcon />,
  partners: <PartnersIcon />,
  sync: <SyncIcon />,
  membership: <MembershipIcon />,
  questions: <QuestionsIcon />,
  addonModifiers: <AddonModifiersIcon />,
};

interface Props {
  setMainContainerUI: (MainContainerUI: webConfigUI) => void;
  mainContainerUI: webConfigUI;
  leftPanelList: LeftPanelList[];
  goToHost: () => void;
  version?: string;
}


const coloredIconsMap: Record<webConfigUI | number, React.ReactNode> = {
  [webConfigUI.TRANSFERS]: <SyncIconColored />,
  [webConfigUI.PARTNERS]: <PartnersIconColored />,
  [webConfigUI.MONITORING_APP]: <MonitoringIconColored />,
};


const LeftSidePanel = ({ setMainContainerUI, mainContainerUI, leftPanelList, goToHost, version }: Props) => {
  const onChangeMainContainerUI = (newMainContainerUI: webConfigUI) => {
    if (newMainContainerUI === webConfigUI.HOST_APP) {
      goToHost();
    } else {
      if (mainContainerUI !== newMainContainerUI) {
        setMainContainerUI(newMainContainerUI);
      }
    }
  }
  const leftPanel = leftPanelList.map(item => {
    const menuIcon = menuIcons[item.icon];
    return <button
      key={item.key}
      className={`content ${(mainContainerUI === item.key) ? "active" : ""
        }`}
      onClick={() => {
        onChangeMainContainerUI(item.key);
      }}
    >
      <div className="inner">
        <div className="icon-container">
          {coloredIconsMap[item.key] && mainContainerUI === item.key ? coloredIconsMap[item.key] as React.ReactNode : menuIcon}

        </div>
        <div className={`text ${mainContainerUI === item.key ? "active" : ""
          }`}>{item.text}</div>
      </div>
    </button>
  })
  return (
    <div className="left-side-panel">
      <div className="left-side-panel-inner">
        {leftPanel}
      </div>
      <div className="left-version">
        {version ? `version: ${version}` : ''}
      </div>
      <div className="rex-logo">
        <img className="rex" src={RexIcon} />
      </div>
    </div>
  );
};

export default LeftSidePanel;
