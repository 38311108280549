import { startCase } from "lodash";
import { IDropdownOption, Modal, Toggle } from "office-ui-fabric-react";
import React, { useEffect, useRef, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import CancelButton from "../../../../../common/components/CancelButton";
import ColorButton from "../../../../../common/components/ColorButton";
import FormTextField from "../../../../../common/components/FormTextField";
import LinkButton from "../../../../../common/components/LinkButton";
import {
  setTenantErrorAction,
  updateTenantAction,
} from "../../../actions/tenants-actions";
import {
  selectIsBowlingMarketingAdmin,
  selectIsGoTabAdmin,
  selectIsPartnerAdmin,
  selectIsSteltronicAdmin,
} from "../../../reducers/auth-reducer";
import { selectPartners } from "../../../reducers/partners";
import {
  selectIsTenantRequestInProgress,
  selectTenantError,
} from "../../../reducers/tenants";
import { State, TenantPlan, TenantSettingsDto } from "../../../store/types";
import FormSelectField from "../../common/FormSelectField";
import "./editTenant.scss";

const mapDispatchToProps = {
  updateTenant: updateTenantAction,
  setTenantError: setTenantErrorAction,
};
const mapStateToProps = (state: State) => ({
  isLoadingTenant: selectIsTenantRequestInProgress(state),
  error: selectTenantError(state),
  isPartnerAdmin: selectIsPartnerAdmin(state),
  isSteltronicAdmin: selectIsSteltronicAdmin(state),
  isGoTabAdmin: selectIsGoTabAdmin(state),
  isBowlingMarketingAdmin: selectIsBowlingMarketingAdmin(state),
  partners: selectPartners(state),
});

interface OwnProps {
  onClose: () => void;
  tenant?: TenantSettingsDto;
}
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & OwnProps;

const Plans = Object.values(TenantPlan)
  .filter(
    (key) =>
      !(
        key === TenantPlan.spotonPartner ||
        key === TenantPlan.steltronic ||
        key === TenantPlan.bowlingMarketing ||
        key === TenantPlan.goTab
      )
  )
  .map((key) => ({
    key,
    text: startCase(key),
  }));

const EditTenant = ({
  updateTenant,
  onClose,
  setTenantError,
  tenant,
  isLoadingTenant,
  error,
  isPartnerAdmin,
  isSteltronicAdmin,
  isGoTabAdmin,
  isBowlingMarketingAdmin,
  partners,
}: Props) => {
  const saving = useRef(false);
  const partnersOptions = partners
    ? [
      { key: "id", text: "None" },
      ...partners.map(({ id, name }) => ({ key: id, text: name })),
    ]
    : [];
  useEffect(() => {
    setTenantError("");
  }, []);
  useEffect(() => {
    if (!isLoadingTenant && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingTenant]);
  useEffect(() => {
    setDomain(tenant?.domain || "");
    setPlan(getDefaultTenantPlan());
    setName(tenant?.name || "");
    setIsHideDemoMessage(tenant?.isHideDemoMessage || false);
    setIsOnboarding(tenant?.isOnboarding || false);
    setHasMemberships(tenant?.hasMemberships || false);
    setMixpanelToken(tenant?.mixpanelToken || "");
    setPartner(tenant?.partner);
  }, []);

  const getDefaultTenantPlan = () => {
    let defaultPlan = tenant?.plan || TenantPlan.demo;
    if (isSteltronicAdmin) {
      defaultPlan = TenantPlan.steltronic;
    } else if (isGoTabAdmin) {
      defaultPlan = TenantPlan.goTab;
    } else if (isBowlingMarketingAdmin) {
      defaultPlan = TenantPlan.bowlingMarketing;
    }
    return defaultPlan;
  };

  const [name, setName] = useState(tenant?.name || "");
  const [domain, setDomain] = useState(tenant?.domain || "");
  const [plan, setPlan] = useState<TenantPlan>(getDefaultTenantPlan());
  const [isHideDemoMessage, setIsHideDemoMessage] = useState<boolean>(false);
  const [isOnboarding, setIsOnboarding] = useState<boolean>(false);
  const [hasMemberships, setHasMemberships] = useState<boolean>(tenant?.hasMemberships || false );
  const [mixpanelToken, setMixpanelToken] = useState(
    tenant?.mixpanelToken || ""
  );
  const [membershipFee, setMembershipFee] = useState<string | undefined>(tenant?.membershipFee?.toString() || undefined);
  const [bookingFee, setBookingFee] = useState<string | undefined>(tenant?.bookingFee?.toString() || undefined);
  const [partner, setPartner] = useState(tenant?.partner);

  const onSaveTenant = (e: React.FormEvent) => {
    e.preventDefault();
    if (tenant) {
      console.log("saving tenant");
      //todo: send patch only
      saving.current = true;
      updateTenant({
        domain,
        plan,
        isHideDemoMessage,
        isOnboarding,
        hasMemberships,
        membershipFee: hasMemberships ? Number(membershipFee) : 0,
        bookingFee: hasMemberships ? Number(bookingFee) : 0,
        name,
        id: tenant?.id,
        mixpanelToken,
        partner,
      });
    } else {
      setTenantError("Update tenant failure");
    }
  };
  const onSelectPartner = (
    _: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => {
    const p = partners?.find(({ id }) => id === option?.key);
    setPartner(p);
  };

  const onToggleMembership = (_: any, value?: boolean) => {
    if (value && !membershipFee) setMembershipFee('5');
    setHasMemberships(value || false)
  }

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-tenant"
    >
      <div className="title h4">
        Edit Tenant
        <CancelButton onClick={onClose} />
      </div>
      <form className="form-tenant" onSubmit={onSaveTenant}>
        <FormTextField
          label="Name"
          className="row"
          value={name}
          onChange={(_: any, text?: string) => setName(text || "")}
          autoComplete="off"
          required={true}
        />
        <FormTextField
          label="Domain"
          className="row"
          value={domain}
          onChange={(_: any, text?: string) => setDomain(text || "")}
          autoComplete="off"
          required={true}
        />
        {!isPartnerAdmin && (
          <FormSelectField
            label="Plan"
            className="row"
            options={Plans}
            selectedKey={plan}
            required={true}
            onChange={(
              _: React.FormEvent<HTMLDivElement>,
              option?: IDropdownOption
            ) =>
              setPlan(
                option?.key ? (option?.key as TenantPlan) : TenantPlan.demo
              )
            }
          />
        )}
        <FormTextField
          label="Mixpanel Token"
          className="row"
          value={mixpanelToken}
          onChange={(_: any, text?: string) => setMixpanelToken(text || "")}
          autoComplete="off"
        />
        {!isPartnerAdmin && (
          <FormSelectField
            label="Partner"
            className="row"
            options={partnersOptions}
            selectedKey={partner?.id}
            required={false}
            onChange={onSelectPartner}
          />
        )}
        <Toggle
          label="Hide demo banner"
          className="row toggle"
          checked={isHideDemoMessage}
          onChange={(_: any, value?: boolean) =>
            setIsHideDemoMessage(value || false)
          }
          inlineLabel
        />
        <Toggle
          label="Onboarding"
          className="row toggle"
          checked={isOnboarding}
          onChange={(_: any, value?: boolean) =>
            setIsOnboarding(value || false)
          }
          inlineLabel
        />
        <Toggle
          label="Memberships"
          className="row toggle"
          checked={hasMemberships}
          onChange={onToggleMembership}
          inlineLabel
        />
        <FormTextField
          label="Membership fee %"
          className="row"
          value={membershipFee?.toString()}
          type="text"
          onChange={(_, text?: string) => {
            if (Number(text) > 100) {
              setMembershipFee('100');
              return;
            }
            if (Number(text) < 0) {
              setMembershipFee('0');
              return;
            }

            // Allow only numbers that are integers or decimals with up to 3 decimal places
            const regex = /^\d*\.?\d{0,3}$/;
            if (regex.test(text || '')) {
              setMembershipFee(text || '0');
            }
          }}
          autoComplete="off"
          min={0}
          disabled={!hasMemberships}
          placeholder="0"
        />
        <FormTextField
          label="Booking fee %"
          className="row"
          value={bookingFee?.toString()}
          type="text"
          onChange={(_, text?: string) => {
            if (Number(text) > 100) {
              setBookingFee('100');
              return;
            }
            if (Number(text) < 0) {
              setBookingFee('0');
              return;
            }

            // Allow only numbers that are integers or decimals with up to 3 decimal places
            const regex = /^\d*\.?\d{0,3}$/;
            if (regex.test(text || '')) {
              setBookingFee(text || '0');
            }
          }}
          autoComplete="off"
          min={0}
          placeholder="0"
        />

        {error && <div className="error">{error}</div>}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit">Save Change</ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditTenant);
