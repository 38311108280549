import React, { FC, ChangeEvent } from "react";
import { Tabs, Tab, TabProps } from "@material-ui/core";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const tabStyle: React.CSSProperties = {
    color: "#72757C",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.1px",
    minWidth: 0,
    marginLeft: "21px",
    textTransform: "none", // Fix textTransform issue
};

interface Props {
    filterValue: number;
    onFilterChange: (event: ChangeEvent<{}>, value: number) => void;
}

const ClientTabs: FC<Props> = ({ filterValue, onFilterChange }) => {
    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        onFilterChange(event, newValue);
    };

    return (
        <Tabs
            value={filterValue}
            onChange={handleChange}
            aria-label="Clients filter"
            TabIndicatorProps={{
                style: {
                    backgroundColor: 'rgb(6, 36, 255)',
                },
            }}
            style={{
                marginBottom: "15px",
                marginLeft: "-21px",
            }}
        >
            <Tab label="Active" {...a11yProps(0)} style={tabStyle as TabProps['style']} />
            <Tab label="Sales" {...a11yProps(1)} style={tabStyle as TabProps['style']} />
            <Tab label="Inactive" {...a11yProps(2)} style={tabStyle as TabProps['style']} />
            <Tab label="Onboarding" {...a11yProps(3)} style={tabStyle as TabProps['style']} />
        </Tabs>
    );
};

export default ClientTabs;
