import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Modal,
  IDropdownOption,
  Toggle,
  ITag,
} from "office-ui-fabric-react";
import {
  setVenueErrorAction,
  updateVenueAction,
} from "../../../../actions/venue-actions";
import {
  selectVenue,
  selectIsLoadingVenue,
  selectVenueError,
} from "../../../../reducers/venues";
import { selectUIConfig } from "../../../../reducers/ui-reducer";
import { State } from "../../../../store/types";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import FormTextField from "../../../../../../common/components/FormTextField";
import FormSelectField from "../../../common/FormSelectField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import FormTagPicker from "../../../../../../common/components/FormTagPicker";
import { bookingDurations } from "../../../../constants/bookingDurationOptions";
import "./editAdminReservationsSettings.scss";

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
  setVenueError: setVenueErrorAction,
};
const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  readonly: selectReadonly(state),
  error: selectVenueError(state),
  uiConfig: selectUIConfig(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditAdminReservationsSettings = ({
  updateVenue,
  venue,
  readonly,
  error,
  isLoadingVenue,
  uiConfig,
  onClose,
  setVenueError,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    setVenueError("");
  }, []);
  useEffect(() => {
    if (!isLoadingVenue && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingVenue]);
  useEffect(() => {
    setDurationInSlotsForAdmin(venue?.durationInSlotsForAdmin);
    setMaxGuestsForAdmin(venue?.maxGuestsForAdmin);
    setMaxNumberOfLanes(venue?.maxNumberOfLanes);
    setDaysForBookingForAdmin(venue?.daysForBookingForAdmin);
    setAllowedReservationIntervalForAdmin(
      venue?.allowedReservationIntervalForAdmin
    );
    setIgnoreStaffCapacityForAdmin(!!venue?.ignoreStaffCapacityForAdmin);
  }, [venue]);
  const actionText = uiConfig?.actionText || "Lane";
  const [durationInSlotsForAdmin, setDurationInSlotsForAdmin] = useState(
    venue?.durationInSlotsForAdmin
  );
  const [maxGuestsForAdmin, setMaxGuestsForAdmin] = useState(
    venue?.maxGuestsForAdmin
  );
  const [maxNumberOfLanes, setMaxNumberOfLanes] = useState(
    venue?.maxNumberOfLanes
  );
  const [daysForBookingForAdmin, setDaysForBookingForAdmin] = useState(
    venue?.daysForBookingForAdmin
  );
  const [
    allowedReservationIntervalForAdmin,
    setAllowedReservationIntervalForAdmin,
  ] = useState(venue?.allowedReservationIntervalForAdmin);
  const [ignoreStaffCapacityForAdmin, setIgnoreStaffCapacityForAdmin] =
    useState<boolean>(!!venue?.ignoreStaffCapacityForAdmin);

  const getPatch = () => {
    return {
      durationInSlotsForAdmin: (durationInSlotsForAdmin || "").replace(/\s+/g, ""), // remove all spaces
      maxNumberOfLanes: maxNumberOfLanes || undefined,
      maxGuestsForAdmin: maxGuestsForAdmin || undefined,
      daysForBookingForAdmin: daysForBookingForAdmin || undefined,
      allowedReservationIntervalForAdmin:
        allowedReservationIntervalForAdmin || undefined,
      ignoreStaffCapacityForAdmin,
    };
  };

  const onUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    if (venue) {
      saving.current = true;
      const patch = getPatch();
      updateVenue({ ...venue, ...patch });
    }
  };

  const toTags = (data: string) =>
    data.split(";").map((key) => ({ key, name: key }));
  const toQuick = (data: ITag[]) => data.map(({ key }) => key).join(";");


  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-admin-reservations-settings"
    >
      <div className="title h4">
        Admin Reservation Settings
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="admin-reservations-settings" onSubmit={onUpdate}>
        <div className="edit-reservations-fields">
          <FormTextField
            label="Duration options for admin (in slots)"
            className="row"
            value={durationInSlotsForAdmin}
            onChange={(_: any, text?: string) =>
              setDurationInSlotsForAdmin(text)
            }
            autoComplete="off"
            disabled={readonly}
          />
          <FormTextField
            label="Max number of lanes"
            type="number"
            className="row"
            value={maxNumberOfLanes?.toString()}
            onChange={(_: any, text?: string) =>
              setMaxNumberOfLanes(Number(text))
            }
            autoComplete="off"
            disabled={readonly}
          />
          <FormTextField
            label="Max guests per reservation for admin"
            type="number"
            className="row"
            value={maxGuestsForAdmin?.toString()}
            onChange={(_: any, text?: string) =>
              setMaxGuestsForAdmin(Number(text))
            }
            autoComplete="off"
            disabled={readonly}
          />
          <FormTextField
            label="Cut off time for admin"
            type="number"
            className="row"
            value={allowedReservationIntervalForAdmin?.toString()}
            onChange={(_: any, text?: string) =>
              setAllowedReservationIntervalForAdmin(Number(text))
            }
            autoComplete="off"
            disabled={readonly}
          />
          <FormSelectField
            label="How far in advance can admins book?"
            className="row"
            options={bookingDurations}
            selectedKey={daysForBookingForAdmin}
            onChange={(
              _: React.FormEvent<HTMLDivElement>,
              option?: IDropdownOption
            ) =>
              setDaysForBookingForAdmin(!!option ? +option?.key : undefined)
            }
          />
          <Toggle
            label="Ignore Staff Capacity"
            className="row"
            checked={ignoreStaffCapacityForAdmin}
            onChange={(_: any, value?: boolean) =>
              setIgnoreStaffCapacityForAdmin(value || false)
            }
            inlineLabel
            disabled={readonly}
          />
        </div>
        {error && <div className="error">{error}</div>}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit" disabled={readonly}>
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditAdminReservationsSettings);
