export class ResourceSettingDto {
    id: string;
    resourceCategoryId: string;
    name: string;
    abbreviation: string;
    priority: number;
    active: boolean;
    adminOnly: boolean;
    outOfOrder: boolean;
    bussing: boolean;
    inUse: boolean;
    deleted: boolean;
    resourceSpecificTripleSeatsIntegration: boolean;
    tripleSeatLocationId: number | null;
    tripleSeatRoomId: number | null;
    tripleSeatRoomPath: string;
    tripleSeatAccountId: number | null;
    tripleSeatContactId: number | null;
    tripleSeatBlockedStatus?: string;
    tripleSeatPublishType?: string;
    tripleSeatCustomFieldsMapping?: string | null;
    tripleSeatCustomEventName?: string | null;
    tripleSeatReadAvailability: boolean;
    tripleSeatAvailabilityType?: string | null;
    tripleSeatAvailabilityFieldId?: string | null;
    tripleSeatStatusesOfPulledEvents?: string;
    resourceSpecificSteltronicIntegration: boolean;
    steltronicUrl: string | null;
    steltronicUsername: string | null;
    steltronicPassword: string | null;
    steltronicNumber: number;
    aboutGolfSimId: string;
    atomicGolfBayId: string;
    goTabSpotUuid?: string;
    inUseCountdown?: number;
    textCardColor?: string;
    cardColor?: string;
    isTsResource?: boolean;
    row: number;
}

export class CreateResourceSettingDto {
    resourceCategoryId: string;
    name: string;
    abbreviation: string;
    priority: number;
    active: boolean;
    adminOnly: boolean;
    outOfOrder: boolean;
    bussing: boolean;
    deleted: boolean;
    resourceSpecificTripleSeatsIntegration: boolean;
    tripleSeatLocationId: number | null;
    tripleSeatRoomId: number | null;
    tripleSeatRoomPath: string;
    tripleSeatAccountId: number | null;
    tripleSeatContactId: number | null;
    tripleSeatBlockedStatus?: string;
    tripleSeatPublishType?: string;
    tripleSeatCustomFieldsMapping?: string | null;
    tripleSeatCustomEventName?: string | null;
    tripleSeatReadAvailability: boolean;
    tripleSeatAvailabilityType?: string | null;
    tripleSeatAvailabilityFieldId?: string | null;
    tripleSeatStatusesOfPulledEvents?: string;
    resourceSpecificSteltronicIntegration: boolean;
    steltronicUrl: string | null;
    steltronicUsername: string | null;
    steltronicPassword: string | null;
    steltronicNumber: number;
    aboutGolfSimId: string;
    atomicGolfBayId: string;
    goTabSpotUuid: string;
    inUseCountdown?: number;
    textCardColor?: string;
    cardColor?: string;
    row: number
}

export class AboutGolfSim {
    id: string;
    name: string;
}

export interface ResourceSettingQueryParams {
    isFloor?: boolean;
    statuses?: string;
    date?: string
}


export enum ResourceFloorStatus {
    InUse = 'inUse',
    OutOfOrder = 'outOfOrder',
    Disabled = 'disabled',
    AdminOnly = 'adminOnly',
    Bussing = 'bussing',
    Available = 'available',
}
