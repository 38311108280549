import React, { useState, useEffect } from "react";
import {
  Icon,
} from "office-ui-fabric-react";
import {
  DatePicker,
  TimePicker,
} from '@material-ui/pickers';
import dayjs from "dayjs";
import {
  FilterCondition,
  FilterConditionDate,
  FilterConditionDuration,
  FilterEqualCondition,
  FilterParameter,
  FilterReservationParameters,
} from "../../../../../store/types";
import {
  onFormatUSDate,
  onFormatUSTime,
} from "../../../../../constants/timedate";
import { color } from "../../../../../constants/styles";
import FormTextField from "../../../../../../../common/components/FormTextField";
import FormSelectField from "../../../../common/FormSelectField";
import LinkButton from "../../../../../../../common/components/LinkButton";
import "./filterRow.scss";

interface OwnProps {
  parameter: FilterReservationParameters;
  onChange: (filterParameter: FilterReservationParameters, field: string, value: string | number | null) => void;
  onChangeFilterParameter: (filterParameter: FilterReservationParameters, field: string, value: string | number | null) => void;
  addFilterParameter: () => void;
  removeFilterParameter: (filterParameterId: string) => void;
}

const filterParameterOptions = Object.values(FilterParameter).map(key => ({ key, text: key }))
const filterConditionOptions = Object.values(FilterCondition).map(key => ({ key, text: key }))
const filterEqualConditionOptions = Object.values(FilterEqualCondition).map(key => ({ key, text: key }))
const filterConditionDateOptions = Object.values(FilterConditionDate).map(key => ({ key, text: key }))
const filterConditionDurationOptions = Object.values(FilterConditionDuration).map(key => ({ key, text: key }))
const fieldConditions = {
  [FilterParameter.ReservationDate]: {
    condition: filterConditionDateOptions,
  },
  [FilterParameter.ReservationCreatedDate]: {
    condition: filterConditionDateOptions,
  },
  [FilterParameter.Guests]: {
    condition: filterConditionOptions,
  },
  [FilterParameter.Duration]: {
    condition: filterConditionDurationOptions,
  },
  [FilterParameter.StartTime]: {
    condition: filterConditionDateOptions,
  },
  [FilterParameter.ReservationNumber]: {
    condition: filterConditionOptions,
  },
  [FilterParameter.cardLast4]: {
    condition: filterEqualConditionOptions,
  }
}

export const FilterRow = ({
  parameter,
  onChange,
  onChangeFilterParameter,
  addFilterParameter,
  removeFilterParameter,
}: OwnProps) => {
  useEffect(() => {
    if (
      parameter.filterValue && (
        parameter.filterParameter === FilterParameter.ReservationCreatedDate ||
        parameter.filterParameter === FilterParameter.ReservationDate
      )
    ) {
      const day = dayjs(parameter.filterValue).toDate();
      setDate(day);
    }
    if (
      parameter.filterValue && parameter.filterParameter === FilterParameter.StartTime
    ) {
      const time = dayjs().startOf("day").add(+parameter.filterValue, 'minute').toDate();
      setTime(time);
    }
  }, [addFilterParameter, parameter.filterCondition]);
  const [date, setDate] = useState<Date | null>(null);
  const [time, setTime] = useState<Date | null>(null);

  const onChangeParameter = (value: string | number | null) => {
    onChangeFilterParameter(parameter, "filterParameter", value);
    setDate(null);
    setTime(null);
  }

  const onChangeDate = (value?: Date | null) => {
    const date = value ? onFormatUSDate(value) : "";
    onChange(parameter, "filterValue", date);
  }

  const onChangeTime = (value?: Date | null) => {
    const time = value ? onFormatUSTime(value) : "";
    onChange(parameter, "filterValue", time);
  }

  const onAddNewFilter = () => {
    if (parameter.filterParameter && parameter.filterCondition && parameter.filterValue) {
      addFilterParameter();
    }
  }

  return (
    <div className="filter-parameter-container">
      <div className="where-text">Where</div>
      <div className="row">
        <FormSelectField
          options={filterParameterOptions}
          selectedKey={parameter.filterParameter}
          onChange={(_: any, option) => {
            onChangeParameter(option?.key || null);
          }}
        />
        <FormSelectField
          options={parameter.filterParameter ? fieldConditions[parameter.filterParameter].condition : filterConditionOptions}
          selectedKey={parameter.filterCondition}
          onChange={(_: any, option) => {
            onChange(parameter, "filterCondition", option?.key || null);
          }}
        />
      </div>
      {(
        parameter.filterParameter === FilterParameter.ReservationCreatedDate ||
        parameter.filterParameter === FilterParameter.ReservationDate
      ) ? (
        <DatePicker
          autoOk
          value={date}
          variant="inline"
          inputVariant="outlined"
          onChange={data => onChangeDate(dayjs(data).toDate())}
          format="MM/DD/YYYY"
          className="datePicker"
        />
      ) : (
        parameter.filterParameter === FilterParameter.StartTime
      ) ? (
        <TimePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          value={time}
          onChange={(date) => onChangeTime(dayjs(date).toDate())}
          className="datePicker"
        />
      ) : (
        <FormTextField
          value={parameter.filterValue}
          onChange={(_: any, text?: string) =>
            onChange(parameter, "filterValue", text || "")
          }
          autoComplete="off"
        />
      )}
      <div className="filter-buttons">
        <LinkButton onClick={onAddNewFilter}>
          <Icon iconName="Add" className="add" />
          Add Filter
        </LinkButton>
        <LinkButton
          textColor={color.grey}
          onClick={() => removeFilterParameter(parameter.id)}
        >
          Remove
        </LinkButton>
      </div>
    </div>
  );
};
