import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Modal,
  IDropdownOption,
} from "office-ui-fabric-react";
import {
  updateTenantUserAction,
  createTenantUserAction,
} from "../../../actions/tenants-actions";
import { getAdminRoleAction } from "../../../actions/auth-actions";
import { State, UserType } from "../../../store/types";
import { selectReadonly, selectAdminType } from "../../../reducers/auth-reducer";
import { selectSelectedUser } from "../../../reducers/tenants";
import FormTextField from "../../../../../common/components/FormTextField";
import LinkButton from "../../../../../common/components/LinkButton";
import FormSelectField from "../../common/FormSelectField";
import ColorButton from "../../../../../common/components/ColorButton";
import CancelButton from "../../../../../common/components/CancelButton";
import "./editUser.scss";
import { getVenuesAction } from "../../../actions/venue-actions";
import { selectVenues } from "../../../reducers/venues";
import { getIsVenueUser } from "../../../utils/formats";

const typesForAdmin = [{
  key: UserType.venueAdmin,
  text: "Admin",
}, {
  key: UserType.admin,
  text: "Tenant Admin"
}, {
  key: UserType.manager,
  text: "Manager"
}, {
  key: UserType.userLead,
  text: "Staff (Lead)"
}, {
  key: UserType.user,
  text: "Staff (Entry)"
}];

const typesForSuperAdmin = [...typesForAdmin, {
  key: UserType.adminSteltronic,
  text: "Steltronic Admin"
}, {
  key: UserType.adminGoTab,
  text: "Go Tab Admin"
}, {
  key: UserType.adminBowlingMarketing,
  text: "Bowling Marketing Admin"
}];

const mapDispatchToProps = {
  updateUser: updateTenantUserAction,
  createUser: createTenantUserAction,
  getAdminRole: getAdminRoleAction,
  getVenues: getVenuesAction,
};
const mapStateToProps = (state: State) => ({
  user: selectSelectedUser(state),
  readonly: selectReadonly(state),
  adminType: selectAdminType(state),
  venues: selectVenues(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditUser = ({
  updateUser,
  createUser,
  getAdminRole,
  user,
  readonly,
  adminType,
  onClose,
  getVenues,
  venues,
}: Props) => {
  useEffect(() => {
    setUserName(user?.username || "");
    setPassword('');
    setType(user?.type);
    setVenueId(user?.venueId || undefined);
    getAdminRole();
    getVenues();
  }, [user]);
  const [username, setUserName] = useState(user?.username || '');
  const [password, setPassword] = useState('');
  const [type, setType] = useState<UserType | undefined>(user?.type);
  const [venueId, setVenueId] = useState<string | undefined>(user?.venueId || undefined);

  let typeList = typesForAdmin;
  if (adminType === UserType.superAdmin) {
    typeList = typesForSuperAdmin;
  }

  const isValidUser = () => {
    return (
      username &&
      type &&
      (!getIsVenueUser(type) || (getIsVenueUser(type)  && !!venueId))
    )
  }
  const onUpdateUserType = (type: UserType) => {
    setType(type);
  }
  const getPatch = () => {
    let newVenueId = undefined;
    if (type && getIsVenueUser(type)) {
      newVenueId = venueId;
    }
    return {
      username,
      password,
      type,
      venueId: newVenueId,
    };
  }
  const venueList = venues ? venues.map(venue => ({
    key: venue.id,
    text: venue.name,
  })) : [];

  const onUpdate = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isValidUser()) {
      return;
    }
    if (user) {
      const patch = getPatch();
      updateUser({ ...user, ...patch });
    } else {
      if (!(username &&
        password &&
        type
      ) ||
        getIsVenueUser(type) && !venueId
      ) {
        return
      }
      createUser({
        username,
        password,
        type,
        venueId: getIsVenueUser(type) ? venueId : undefined,
      });
    }
    onClose();
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-user"
    >
      <div className="title h4">
        {!!user ? "Edit User" : "Create User"}
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="user" onSubmit={onUpdate}>
        <div className="row">
          <FormTextField
            label="Email"
            value={username}
            onChange={(_: any, text?: string) => setUserName(text || "")}
            autoComplete="off"
            required={true}
            disabled={readonly || !!user}
          />
        </div>
        <div className="row">
          <FormTextField
            label="Password"
            type="password"
            value={password}
            onChange={(_: any, text?: string) => setPassword(text || "")}
            autoComplete="off"
            disabled={readonly}
          />
        </div>
        <div className="row">
          <FormSelectField
            label="Role"
            options={typeList}
            selectedKey={type}
            onChange={(
              _: React.FormEvent<HTMLDivElement>,
              item?: IDropdownOption
            ) => onUpdateUserType(item?.key as UserType)}
            required={true}
            disabled={readonly}
          />
        </div>
        {(type && getIsVenueUser(type)) && (
          <div className="row">
            <FormSelectField
              label="Venue"
              options={venueList}
              selectedKey={venueId}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption
              ) => setVenueId(item?.key as string)}
              required={getIsVenueUser(type)}
              disabled={readonly}
            />
          </div>
        )}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal >
  );
};

export default connector(EditUser);
