import { Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import {
    IconButton,
    Toggle
} from "office-ui-fabric-react";
import React, { useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import FormTextField from "../../../../common/components/FormTextField";
import ContentCopyIcon from '../../assets/content-copy.svgr';
import { State } from "../../store/types";
import "../VenueDetails/VenueMainActions/venueMainActions.scss";
import Panel from "../common/Panel";
import { selectPlan } from "../../reducers/membership";
import { updatePlanAction } from "../../actions/membership-action";
const mapDispatchToProps = {
    updatePlan: updatePlanAction,
};

const mapStateToProps = (state: State) => ({
    currentPlan: selectPlan(state),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>

const MembershipsVenueActions = ({
    currentPlan,
    updatePlan,
}: Props) => {
    const [copySuccess, setCopySuccess] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const stripeId = currentPlan?.id || '';
    const onActivate = () => {
        if (currentPlan) {
            updatePlan({ ...currentPlan, active: !currentPlan.active });
        }
    }
    const handleCopy = () => {
        navigator.clipboard.writeText(stripeId)
            .then(() => {
                setCopySuccess('Successfully Copied!');
            })
            .catch(err => {
                setCopySuccess('Failed to copy!');
            });
        setOpenSnackbar(true);
    };
    return (<>
        <Panel className='venue-main-actions' title={(<div className="h6">Plan Info</div>)} >
            <>
                <div className="venue-toggles-container">
                    <div className="venue-toggle-status body2">
                        <Toggle
                            checked={currentPlan?.active}
                            onChange={onActivate}
                        />
                        <div className="label">{!!currentPlan?.active ? 'Active' : 'Inactive'}</div>
                    </div>

                </div>
                <div className="splitter"></div>

                <div className="memberships-main-actions-stripe-id">
                    <FormTextField
                        value={stripeId}
                        disabled
                        prefix={
                            'Stripe ID:'
                        }
                        // @ts-ignore
                        suffix={
                            <IconButton onClick={handleCopy}>
                                <ContentCopyIcon />
                            </IconButton>
                        }
                        autoComplete="off"

                    />
                </div>
            </>
        </Panel>
        <Snackbar
            open={openSnackbar}
            autoHideDuration={300000}
            onClose={() => setOpenSnackbar(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <MuiAlert onClose={() => setOpenSnackbar(false)} severity={copySuccess.includes('Success') ? "success" : 'error'}>
                {copySuccess}
            </MuiAlert>
        </Snackbar>
    </>
    );
};

export default connector(MembershipsVenueActions);