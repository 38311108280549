import React, { useState } from "react";
import { Modal } from "office-ui-fabric-react";
import { CurrencyType } from "../../../store/types";
import { color } from "../../../constants/styles";
import FormTextField from "../../../../../common/components/FormTextField";
import CancelButton from "../../../../../common/components/CancelButton";
import LinkButton from "../../../../../common/components/LinkButton";
import ColorButton from "../../../../../common/components/ColorButton";
import GrayButton from "../../../../../common/components/GrayButton";
import { getDepositAmountWithCurrency } from "../../../../../common/utils/formats";
import "./confirmRefund.scss";

const valueFieldStyle = {
  prefix: {
    background: "transparent",
    color: color.grey,
    fontSize: "14px",
    fontFamily: "Inter, sans-serif",
  }
};

type Props = {
  onClose: (refund?: number) => void;
  deposit: number;
  currency: CurrencyType;
};

const ConfirmRefund = ({ onClose, deposit, currency }: Props) => {

  const [refund, setRefund] = useState<string | undefined>("0.00");
  const [errorRefund, setErrorRefund] = useState<string>("");

  const totalDeposit = getDepositAmountWithCurrency(+deposit, currency);
  const percents = Array.apply(null, Array(4)).map((_, index) => (
    <GrayButton key={index} onClick={() => onAmountChange(0.25 * (index + 1))}>{25 * (index + 1)}%</GrayButton>
  ));

  const onCancel = () => {
    if (refund) {
      onClose(Number(refund));
      setRefund("0.00");
      return;
    }
    if (!refund) {
      setErrorRefund("This field is required.");
    }
  };
  const onRefundAmountChange = (refund?: string) => {
    setErrorRefund("");
    const newRefund = refund ? +refund : 0;
    if (newRefund > deposit) {
      setRefund(deposit.toFixed(2));
    } else {
      setRefund(refund);
    }
  };
  const onAmountChange = (percent: number) => {
    onRefundAmountChange((deposit * percent).toFixed(2));
  };
  return (
    <Modal
      isOpen={true}
      onDismiss={() => onClose()}
      isBlocking={false}
      containerClassName="confirm-refund"
    >
      <div className="container">
        <div className="header h4">
          <div>Refund</div>
          <CancelButton onClick={() => onClose()} />
        </div>
        <div className="deposit subtitle2">
          Total: {totalDeposit}
        </div>
        <div className="subtitle2">Please enter a refund amount</div>
        <FormTextField
          type="number"
          prefix={currency}
          styles={valueFieldStyle}
          value={refund}
          onChange={(_: any, value?: string) =>
            onRefundAmountChange(
              value ? value : "0.00",
            )
          }
          autoComplete="off"
        />
        <div className="percent-buttons">
          {percents}
        </div>
        <div className="error">{errorRefund}</div>
        <div className="buttons-container">
          <LinkButton onClick={() => onClose()}>Cancel</LinkButton>
          <ColorButton onClick={() => onCancel()}>Refund</ColorButton>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmRefund;
