import { FeeType, GoTabWorkflowType, PaymentType } from '../entities/enums';
import { ScheduleSettingDto } from './scheduleSetting.dto';
import { VenueTaxDto } from './venueTax.dto';
import { MailchimpMappingDto } from './mailchimpMapping.dto';
import { MailchimpTagDto } from './mailchimpTag.dto';

export class VenueSettingDto {
  id: string;
  name: string;
  deposit: number;
  depositType: string;
  address: string;
  address2?: string;
  city: string;
  zipCode: string;
  state: string;
  email: string;
  eventsEmail: string;
  phone: string;
  timeZone: string;
  lat: number;
  lng: number;
  dressCode: string;
  agePolicy: string;
  venueInfo: string;
  allowedReservationInterval: number;
  allowedReservationIntervalForAdmin?: number;
  ignoreStaffCapacityForAdmin: boolean;
  maxGuestsForInquiry: number;
  minGuestsForInquiry: number;
  mapUrl: string;
  locationUrl: string;
  schedules: ScheduleSettingDto[];
  textBox: string;
  prefix: string;
  sandBoxPayment: boolean;
  paymentUrl: string;
  mid: string;
  paymentKey: string;
  paymentSecret: string;
  stripePublishableKey: string;
  stripeSecretKey: string;
  enableCaptureStripePayment: boolean;
  fortisUrl: string;
  fortisDeveloperId: string;
  fortisUserId: string;
  fortisUserApiKey: string;
  fortisLocationId: string;
  sezzlePublishableKey: string;
  sezzleSecretKey: string;
  sezzleUrl: string;
  tripleSeatRoomId: number | null;
  tripleSeatLocationId: number | null;
  tripleSeatBlockedStatus?: string;
  tripleTimezoneOffset: number;
  closed: boolean;
  isComingSoon: boolean;
  enableCheckIn: boolean;
  tax: number;
  taxForServiceFee: string;
  serviceFee: string;
  serviceFeeName: string;
  serviceFeeType: string;
  packagesTitle: string;
  packagesTabName: string;
  choosePackageTitle: string;
  ageForPolicy: string;
  eventInquiryTitle: string;
  eventInquiryTabName: string;
  eventConfirmationPageTitle: string;
  eventConfirmationPageCopy: string;
  eventEmailTitle: string;
  eventEmailCopy: string;
  packagesDescription: string;
  contactUsButtonText: string;
  maxGuests: number;
  maxGuestsForAdmin?: number;
  maxNumberOfLanes?: number;
  guestSplit: number;
  description: string;
  descriptionTitle: string;
  descriptionParty: string;
  partyUrl: string;
  closedMessage: string;
  posLocationId: string;
  showPolicy: boolean;
  allowCancellation: boolean;
  cancellationCutOff: number;
  cancellationFee: number;
  cancellationFeeType: FeeType;
  allowModification: boolean;
  modificationCutOff: number;
  modificationFee: number;
  modificationFeeType: FeeType;
  venueImage: string;
  secondaryVenueImages: string[];
  mapImage: string;
  cardColor: string;
  textCardColor: string;
  steltronicUrl: string;
  steltronicUsername: string;
  steltronicPassword: string;
  enableTripleSeats: boolean;
  enableTripleSeatsParty: boolean;
  enableTripleSeatsScheduleUpdate: boolean;
  enableSalesForce: boolean;
  enablePOS: boolean;
  enableQSR: boolean;
  enableMailChimp: boolean;
  enableMailChimpParty: boolean;
  enableSevenRooms: boolean;
  enableSeatninja: boolean;
  enableSeatninjaParty: boolean;
  enableSteltronic: boolean;
  enableSalesForceReservation: boolean;
  salesForceReservationUrl: string;
  salesForceReservationClientId: string;
  salesForceReservationClientSecret: string;
  salesForceReservationUsername: string;
  salesForceReservationPassword: string;
  useDiscount: boolean;
  useGiftCard: boolean;
  giftCardClientKey: string;
  giftCardTerminalId: string;
  giftCardWsdlUrl: string;
  splitSeatNinjaReservations: boolean;
  daysForBooking: number;
  daysForBookingForAdmin?: number;
  totalCountOfLanes: number;
  durationInSlots: string;
  durationInSlotsForAdmin: string;
  timeSlotDuration: number;
  qsrSiteId: string;
  qsrCompanyId: string;
  sevenRoomsVenueId: string;
  seatninjaRestId: string;
  seatninjaPartyType: string;
  seatninjaPartyFlags?: number | null;
  boldTextInAnEmail: string;
  section1Title: string;
  section2Title: string;
  section2Paragraph: string;
  faqTitle: string;
  faqs: string;
  delineationTextInAnEmail: string;
  makePartyNoteText: string;
  tenantId: string;
  reservationIsNotAvailableText: string;
  salesForceLocation?: string;
  dayReportEmails: string;
  dayReportTime: number;
  reservationDetails: string;
  timeSlotShifting: number[];
  lightScript: string;
  enableDayReport: boolean;
  enableCustomNotes: boolean;
  enableRequiredCustomNotes: boolean;
  customNotesTitle: string;
  customNotesPrefilledText: string;
  customNotesExplanationText: string;
  inactivePackageMessage: string;
  preBufferTimeForReservation: number;
  bufferTimeForReservation: number;
  enableAssignDuration: boolean;
  assignDuration: string;
  assignDurationForAdmin: string;
  shiftingForReservationStart: number;
  addonsTitle: string;
  addonsDescription: string;
  activeCustomFields?: string;
  mandatoryCustomFields?: string;
  tripleSeatCustomFieldsMapping?: string | null;
  taxes?: VenueTaxDto[];
  reservationTaxes: string;
  defaultEmailForSkip: string;
  defaultFirstNameForSkip: string;
  defaultLastNameForSkip: string;
  skipGuestName: boolean;
  defaultPhoneForSkip: string;
  groupResourcesSideBySide: boolean;
  autoCompleteReservations: boolean;
  autoNoShowReservations: boolean;
  showSkipPaymentInHost: boolean;
  showPaymentLinkInHost: boolean;
  showRecordCashInHost: boolean;
  showGiftCardInHost: boolean;
  showManagersCompInHost: boolean;
  showManuallyEnterCardInHost: boolean;
  showCardReaderInHost: boolean;
  showStripeInvoiceInHost: boolean;
  enableAboutGolfIntegration: boolean;
  aboutGolfFacilityId: string;
  enableGoTabIntegration: boolean;
  goTabLocation: string;
  goTabProductId: string;
  goTabSpotUuid: string;
  goTabWorkflowType: GoTabWorkflowType;
  goTabCreateTabOnCheckIn: boolean;
  goTabChargeTaxesFeesOnDeposit: boolean;
  paymentType: PaymentType | '';
  calendarStartTime: number;
  calendarEndTime: number;
  calendarTimeWindow: number;
  calendarZoomLevel: number;
  enableReservationTimer: boolean;
  reservationTimerAlert: string;
  reservationTimerInterval: number;
  quickChatText: string;
  enableTwilio: boolean;
  enableTwilioPhoneVerification: boolean;
  twilioPhoneNumber: string;
  mchMappings: MailchimpMappingDto[];
  squareIsEnable: boolean;
  squareLocationName: string;
  squareLocationId: string;
  isSquareCashAppEnable: boolean;
  isSquareAfterPayEnable: boolean;
  instagramUrl: string;
  facebookUrl: string;
  twitterUrl: string;
  tikTokUrl: string;
  viewMenuUrl: string;
  sidebarTitle: string;
  sidebarDescription: string;
  sidebarLinks: string[];
  sidebarIcons: string[];
  sidebarUrls: string[];
  customTabEnable: boolean;
  customTabTabName: string;
  customTabDescription: string;
  customTabScript: string;
  thankYouEmailEnable: boolean;
  thankYouEmailMinutes: number;
  thankYouEmailSubject: string;
  thankYouEmailHeader: string;
  thankYouEmailBody: string;
  isStripeSandboxPayment: boolean;
  stripeAccountId: string;
  isStripeV2OtherMethodsEnable: boolean;
  stripeAdditionalMethods: string;
  eventInquiryEmailEnable: boolean;
  markAsPaidSourceOptions: string;
  stripeTerminalOptions: string;
  stripeTerminal: string;
  errorNotificationEmails: string;
  mchTags: MailchimpTagDto[];
  enableTwilioConfirmationText: boolean;
  enableTwilioReminderText: boolean;
  twilioConfirmationText: string;
  twilioReminderText: string;
  ageGroupsPricingNote: string;
  enableCalendarLinks: boolean;
  keepMeUpdatedText: string;
}

export class CreateVenueSettingDto {
  name: string;
  deposit: number;
  depositType: string;
  address: string;
  address2?: string;
  city: string;
  zipCode: string;
  state: string;
  email: string;
  eventsEmail: string;
  phone: string;
  timeZone: string;
  venueInfo: string;
  allowedReservationInterval: number;
  allowedReservationIntervalForAdmin?: number;
  ignoreStaffCapacityForAdmin: boolean;
  maxGuestsForInquiry: number;
  minGuestsForInquiry: number;
  mapUrl: string;
  locationUrl: string;
  lat: number;
  lng: number;
  dressCode: string;
  textBox: string;
  closed: boolean;
  isComingSoon: boolean;
  enableCheckIn: boolean;
  agePolicy: string;
  prefix: string;
  sandBoxPayment: boolean;
  paymentUrl: string;
  mid: string;
  paymentKey: string;
  paymentSecret: string;
  stripePublishableKey: string;
  stripeSecretKey: string;
  enableCaptureStripePayment: boolean;
  fortisUrl: string;
  fortisDeveloperId: string;
  fortisUserId: string;
  fortisUserApiKey: string;
  fortisLocationId: string;
  sezzlePublishableKey: string;
  sezzleSecretKey: string;
  sezzleUrl: string;
  tripleSeatRoomId: number | null;
  tripleSeatLocationId: number | null;
  tripleSeatBlockedStatus?: string;
  tripleTimezoneOffset: number;
  tax: number;
  taxForServiceFee: string;
  serviceFee: string;
  serviceFeeName: string;
  serviceFeeType: string;
  packagesTitle: string;
  packagesTabName: string;
  choosePackageTitle: string;
  ageForPolicy: string;
  eventInquiryTitle: string;
  eventInquiryTabName: string;
  eventConfirmationPageTitle: string;
  eventConfirmationPageCopy: string;
  eventEmailTitle: string;
  eventEmailCopy: string;
  packagesDescription: string;
  contactUsButtonText: string;
  maxGuests: number;
  maxGuestsForAdmin?: number;
  maxNumberOfLanes?: number;
  guestSplit: number;
  description: string;
  descriptionTitle: string;
  descriptionParty: string;
  partyUrl: string;
  closedMessage: string;
  posLocationId: string;
  showPolicy: boolean;
  allowCancellation: boolean;
  cancellationCutOff: number;
  cancellationFee: number;
  cancellationFeeType: FeeType;
  allowModification: boolean;
  modificationCutOff: number;
  modificationFee: number;
  modificationFeeType: FeeType;
  venueImage: string;
  secondaryVenueImages: string[];
  mapImage: string;
  cardColor: string;
  textCardColor: string;
  steltronicUrl: string;
  steltronicUsername: string;
  steltronicPassword: string;
  enableTripleSeats: boolean;
  enableTripleSeatsParty: boolean;
  enableTripleSeatsScheduleUpdate: boolean;
  enableSalesForce: boolean;
  enablePOS: boolean;
  enableQSR: boolean;
  enableMailChimp: boolean;
  enableMailChimpParty: boolean;
  enableSevenRooms: boolean;
  enableSeatninja: boolean;
  enableSeatninjaParty: boolean;
  enableSteltronic: boolean;
  enableSalesForceReservation: boolean;
  salesForceReservationUrl: string;
  salesForceReservationClientId: string;
  salesForceReservationClientSecret: string;
  salesForceReservationUsername: string;
  salesForceReservationPassword: string;
  useDiscount: boolean;
  useGiftCard: boolean;
  giftCardClientKey: string;
  giftCardTerminalId: string;
  giftCardWsdlUrl: string;
  splitSeatNinjaReservations: boolean;
  daysForBooking: number;
  daysForBookingForAdmin?: number;
  totalCountOfLanes: number;
  durationInSlots: string;
  durationInSlotsForAdmin: string;
  qsrSiteId: string;
  qsrCompanyId: string;
  sevenRoomsVenueId: string;
  seatninjaRestId: string;
  seatninjaPartyType: string;
  seatninjaPartyFlags?: number | null;
  boldTextInAnEmail: string;
  section1Title: string;
  section2Title: string;
  section2Paragraph: string;
  faqTitle: string;
  faqs: string;
  delineationTextInAnEmail: string;
  makePartyNoteText: string;
  tenantId?: string;
  reservationIsNotAvailableText: string;
  salesForceLocation?: string;
  dayReportEmails: string;
  dayReportTime: number;
  reservationDetails: string;
  timeSlotShifting: number[];
  lightScript: string;
  enableDayReport: boolean;
  enableCustomNotes: boolean;
  enableRequiredCustomNotes: boolean;
  customNotesTitle: string;
  customNotesPrefilledText: string;
  customNotesExplanationText: string;
  inactivePackageMessage: string;
  preBufferTimeForReservation: number;
  bufferTimeForReservation: number;
  enableAssignDuration: boolean;
  assignDuration: string;
  assignDurationForAdmin: string;
  shiftingForReservationStart: number;
  addonsTitle: string;
  addonsDescription: string;
  activeCustomFields?: string;
  mandatoryCustomFields?: string;
  tripleSeatCustomFieldsMapping?: string | null;
  reservationTaxes: string;
  defaultEmailForSkip: string;
  defaultPhoneForSkip: string;
  defaultFirstNameForSkip: string;
  defaultLastNameForSkip: string;
  skipGuestName: boolean;
  groupResourcesSideBySide: boolean;
  autoCompleteReservations: boolean;
  autoNoShowReservations: boolean;
  showSkipPaymentInHost: boolean;
  showPaymentLinkInHost: boolean;
  showRecordCashInHost: boolean;
  showGiftCardInHost: boolean;
  showManagersCompInHost: boolean;
  showManuallyEnterCardInHost: boolean;
  showCardReaderInHost: boolean;
  showStripeInvoiceInHost: boolean;
  enableAboutGolfIntegration: boolean;
  aboutGolfFacilityId: string;
  enableGoTabIntegration: boolean;
  goTabLocation: string;
  goTabProductId: string;
  goTabSpotUuid: string;
  goTabWorkflowType: GoTabWorkflowType;
  goTabCreateTabOnCheckIn: boolean;
  goTabChargeTaxesFeesOnDeposit: boolean;
  paymentType: PaymentType | '';
  calendarStartTime: number;
  calendarEndTime: number;
  calendarTimeWindow: number;
  calendarZoomLevel: number;
  enableReservationTimer: boolean;
  reservationTimerAlert: string;
  reservationTimerInterval: number;
  quickChatText: string;
  enableTwilio: boolean;
  enableTwilioPhoneVerification: boolean;
  twilioPhoneNumber: string;
  squareIsEnable: boolean;
  squareLocationName: string;
  squareLocationId: string;
  isSquareCashAppEnable: boolean;
  isSquareAfterPayEnable: boolean;
  instagramUrl: string;
  facebookUrl: string;
  twitterUrl: string;
  tikTokUrl: string;
  viewMenuUrl: string;
  sidebarTitle: string;
  sidebarDescription: string;
  sidebarLinks: string[];
  sidebarIcons: string[];
  sidebarUrls: string[];
  customTabEnable: boolean;
  customTabTabName: string;
  customTabDescription: string;
  customTabScript: string;
  thankYouEmailEnable: boolean;
  thankYouEmailMinutes: number;
  thankYouEmailSubject: string;
  thankYouEmailHeader: string;
  thankYouEmailBody: string;
  isStripeSandboxPayment: boolean;
  isStripeV2OtherMethodsEnable: boolean;
  stripeAdditionalMethods: string;
  eventInquiryEmailEnable: boolean;
  markAsPaidSourceOptions: string;
  stripeTerminal: string;
  errorNotificationEmails: string;
  stripeTerminalOptions: string;
  enableTwilioConfirmationText: boolean;
  enableTwilioReminderText: boolean;
  twilioConfirmationText: string;
  twilioReminderText: string;
  ageGroupsPricingNote: string;
  enableCalendarLinks: boolean;
  keepMeUpdatedText: string;
}

export class GoTabProduct {
  name: string;
  productUuid: string;
}

export class GoTabSpot {
  name: string;
  spotUuid: string;
  urlName: string;
  zoneId: string;
}
